<template>
  <main class="bg_gray">
    <div class="container margin_30">
      <!-- /top_banner -->
      <div class="page_header">
        <div class="breadcrumbs">
          <ul>
            <li>
              <a href="javascript:void(0)" @click="$router.push('/home')">Home</a>
            </li>
            <li>
              <a href="javascript:void(0)" @click="$router.push('/profile')">My Account</a>
            </li>
            <li><a href="#">Order detail</a></li>
          </ul>
        </div>
        <h1>Order detail</h1>
      </div>
      <!-- /toolbox -->
      <div class="row">
        <siderbar />
        <!-- /col -->
        <div class="col-lg-9" v-if="orderDetails">
          <section class="content-main">
            <div class="card" id="doc">
              <header class="card-header">
                <div class="row align-items-center">
                  <div class="col-lg-6 col-md-6 mb-lg-0 mb-15">
                    <h2 class="content-title card-title">Order detail</h2>
                    <small>Order ID: {{ orderId }}</small>
                  </div>
                  <div class="col-lg-6 col-md-6 ms-auto text-md-end">
                    <span>
                      <i class="material-icons md-calendar_today"></i>
                      <b>{{ $filters.formatDate(orderDetails.createdAt) }}</b>
                    </span>
                    <!-- <a class="btn btn-secondary print ms-2" href="#">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M448 192H64C28.65 192 0 220.7 0 256v96c0 17.67 14.33 32 32 32h32v96c0 17.67 14.33 32 32 32h320c17.67 0 32-14.33 32-32v-96h32c17.67 0 32-14.33 32-32V256C512 220.7 483.3 192 448 192zM384 448H128v-96h256V448zM432 296c-13.25 0-24-10.75-24-24c0-13.27 10.75-24 24-24s24 10.73 24 24C456 285.3 445.3 296 432 296zM128 64h229.5L384 90.51V160h64V77.25c0-8.484-3.375-16.62-9.375-22.62l-45.25-45.25C387.4 3.375 379.2 0 370.8 0H96C78.34 0 64 14.33 64 32v128h64V64z"/></svg>
                    </a>-->
                  </div>
                </div>
              </header>
              <!-- card-header end// -->
              <div class="card-body">
                <div class="row mb-50 mt-20 order-info-wrap">
                  <div class="col-md-4">
                    <article class="icontext align-items-start">
                      <div class="row">
                        <div class="col-md-3">
                          <span class="icon icon-sm rounded-circle bg-primary-light">
                            <i class="ti-user"></i>
                          </span>
                        </div>
                        <div class="col-md-9">
                          <div class="text">
                            <h6 class="mb-1">Customer</h6>
                            <p class="mb-1">
                              <span style="font-size: 16px; font-weight: 600">Name:</span>
                              &nbsp;{{ customerInfo.name }}
                              <br />
                              <span style="font-size: 16px; font-weight: 600">Email:</span>
                              &nbsp;{{ customerInfo.email }}
                              <br />
                              <span style="font-size: 16px; font-weight: 600">Phone:</span>
                              &nbsp;{{ customerInfo.mobile }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </article>
                  </div>
                  <!-- col// -->
                  <div class="col-md-4">
                    <article class="icontext align-items-start">
                      <div class="row">
                        <div class="col-md-3">
                          <span class="icon icon-sm rounded-circle bg-primary-light">
                            <i class="ti-shopping-cart-full"></i>
                          </span>
                        </div>
                        <div class="col-md-9">
                          <div class="text">
                            <h6 class="mb-1">Order Info</h6>
                            <p class="mb-1">
                              <span style="font-size: 16px; font-weight: 600">Pay method:</span>
                              &nbsp;
                              {{ orderDetails.paymentMethodName }}
                              <br />
                              <span style="font-size: 16px; font-weight: 600">Status:</span>
                              &nbsp;
                              {{ orderDetails.status }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </article>
                  </div>
                  <!-- col// -->
                  <div class="col-md-4">
                    <article class="icontext align-items-start">
                      <div class="row">
                        <div class="col-md-3">
                          <span class="icon icon-sm rounded-circle bg-primary-light">
                            <i class="ti-bag"></i>
                          </span>
                        </div>
                        <div class="col-md-9">
                          <div class="text">
                            <h6 class="mb-1">Deliver To</h6>
                            <p class="mb-1">
                              <span style="font-size: 16px; font-weight: 600">Name:</span>
                              &nbsp;{{ customerInfo.name }}
                              <br />
                              <span style="font-size: 16px; font-weight: 600">Address:</span>
                              &nbsp;
                              {{
                              customerInfo.addressOne
                              ? customerInfo.addressOne + ", "
                              : ""
                              }}
                              {{
                              customerInfo.city
                              ? customerInfo.city + ", "
                              : ""
                              }}
                              {{
                              customerInfo.country ? customerInfo.country : ""
                              }}
                              <br />
                            </p>
                          </div>
                        </div>
                      </div>
                    </article>
                  </div>
                  <!-- col// -->
                </div>
                <!-- row // -->
                <div class="row mt-5">
                  <div class="col-lg-8">
                    <div class="table-responsive">
                      <table class="table">
                        <thead>
                          <tr>
                            <th width="40%">Product</th>
                            <th width="20%">Unit Price</th>
                            <th width="20%">Quantity</th>
                            <th width="20%" class="text-end">Total</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(data, index) in orders" :key="index">
                            <td>
                              <a class="itemside" href="javascript:void(0)">
                                <div class="left" data-html2canvas-ignore="true">
                                  <img
                                    :src="imagePath + data.featuredImage"
                                    width="40"
                                    height="40"
                                    class="img-xs"
                                    alt="Item"
                                  />
                                </div>
                                <div class="info">
                                  ({{ data.brandName }}) {{ data.name }}
                                  <br />
                                  <p
                                    v-if="data.returnQuantity>0 && (data.returnStatus == 'Approved' || data.returnStatus == 'Unapproved')"
                                    style="color:green;"
                                  >
                                    <span style="color: red">*</span>
                                    {{`${data.returnQuantity} Item has been` + (data.returnStatus == 'Approved'? ` returned (${data.returnStatus})` : ` requested for return (${data.returnStatus})`)}}
                                  </p>
                                </div>
                              </a>
                            </td>
                            <td>${{ data.itemPrice }}</td>
                            <td>{{ data.quantity }}</td>
                            <td class="text-end">${{ data.itemTotal }}</td>
                          </tr>
                          <tr>
                            <td colspan="4">
                              <article class="float-end">
                                <dl class="dlist">
                                  <dt>Subtotal:</dt>
                                  <dd>${{ orderDetails.subTotal - (orderDetails.totalDiscount ? parseFloat(orderDetails.totalDiscount) : 0) }}</dd>
                                </dl>
                                <dl
                                  class="dlist"
                                  v-if="orderDetails.totalDiscount && orderDetails.totalDiscount != 0"
                                >
                                  <dt>Total Discount:</dt>
                                  <dd>${{ (orderDetails.totalDiscount ? parseFloat(orderDetails.totalDiscount) : 0) }}</dd>
                                </dl>

                                <dl class="dlist" v-if="orderDetails.salesTaxRate">
                                  <dt>Sales Tax ({{orderDetails.salesTaxRate}}%):</dt>
                                  <dd>${{ orderDetails.salesTaxAmount }}</dd>
                                </dl>
                                <dl class="dlist" v-if="orderDetails.shippingType">
                                  <dt>Shipping Cost:</dt>
                                  <dd>${{ orderDetails.shippingCost }}</dd>
                                </dl>
                                <dl class="dlist" v-if="orderDetails.couponDiscount != '0'">
                                  <dt>Coupon discount:</dt>
                                  <dd>- ${{ orderDetails.couponDiscount }}</dd>
                                </dl>
                                <dl class="dlist">
                                  <dt>Grand total:</dt>
                                  <dd>${{getOrderTotal(orderDetails)}}</dd>
                                </dl>
                                <dl class="dlist">
                                  <dt class="text-muted">Payment Status:</dt>
                                  <dd>
                                    <span
                                      :class="[
                                        'badge badge-pill',
                                        orderDetails.paymentStatus == 'PENDING'
                                          ? 'badge-soft-warning'
                                          : orderDetails.paymentStatus == 'Paid'
                                          ? 'badge-soft-success'
                                          : 'badge-soft-danger',
                                      ]"
                                    >{{ orderDetails.paymentStatus }}</span>
                                  </dd>
                                </dl>
                              </article>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <!-- table-responsive// -->
                  </div>
                  <!-- col// -->
                  <!-- <div class="col-lg-1"></div> -->
                  <div class="col-lg-4 mt-5">
                    <div class="box shadow-sm bg-light mb-3">
                      <h6 class="mb-15">Payment Info</h6>
                      <p>{{ orderDetails.paymentMethodName }}</p>
                    </div>
                    <div
                      v-if="orderDetails.isGlobalRefunded == true || orderDetails.refundAmount>0"
                      class="box shadow-sm bg-light mb-3"
                    >
                      <h6
                        class="mb-15"
                      >Refund Status: {{orderDetails.isGlobalRefunded ? 'Full Refunded' : 'Partial Refunded'}}</h6>
                      <p>Refunded Amount: ${{ orderDetails.refundAmount }}</p>
                    </div>
                    <div
                      style="display:flex; justify-content:center"
                      id="return-div"
                      data-html2canvas-ignore="true"
                      class="box shadow-sm bg-light mb-3"
                      v-if="filteredRetrunList.length>0 && orderDetails.paymentStatus=='Paid' && orderDetails.isGlobalRefunded == false"
                    >
                      <button
                        type="button"
                        class="btn btn-primary"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                      >Return</button>
                    </div>
                    <div class="h-25 pt-4" v-if="orderDetails.remarks">
                      <div class="mb-3">
                        <label>Notes</label>
                        <textarea
                          class="form-control"
                          name="notes"
                          id="notes"
                          placeholder="Type some note"
                          spellcheck="false"
                          disabled="disabled"
                          v-html="orderDetails.remarks"
                        ></textarea>
                      </div>
                      <!-- <button class="btn btn-primary">Save note</button> -->
                    </div>
                  </div>

                  <!-- col// -->
                </div>
              </div>
              <!-- card-body end// -->
            </div>
            <!-- card end// -->
          </section>
          <div class="row gutters">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
              <div class="custom-actions-btns mb-5">
                <a
                  class="btn_1"
                  style="float:right"
                  href="javascript:void(0)"
                  role="button"
                  @click="generatePDF"
                >Print</a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-9" v-else>
          <p class="text-center">No Order found.</p>
        </div>
        <!-- /col -->
      </div>
      <!-- /row -->
    </div>
    <!-- /container -->
  </main>
  <div
    v-if="filteredRetrunList.length>0"
    class="modal fade mt-5"
    id="exampleModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Return Product</h5>
          <button
            type="button"
            class="btn-close"
            id="close-btn"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th>
                    <input
                      class="form-check-input"
                      v-model="selectAll"
                      @change="handleSelectAll"
                      type="checkbox"
                      value
                      id="flexCheckDefault"
                    />
                  </th>
                  <th>Product</th>
                  <th>Reason</th>
                  <th>Quantity</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(data, index) in filteredRetrunList" :key="index">
                  <td>
                    <input
                      class="form-check-input"
                      type="checkbox"
                      v-model="selectedReturnProducts[index]"
                      @change="
                            () => {
                              handleSelect(
                                index,
                                selectedReturnProducts[index],
                                data
                              );
                            }
                          "
                      value
                      id="flexCheckDefault"
                    />
                  </td>
                  <td style="width: 35% !important;">
                    <a class="itemside" href="#">
                      <div class="left">
                        <img
                          :src="imagePath + data.featuredImage"
                          width="40"
                          height="40"
                          class="img-xs"
                          alt="Item"
                        />
                      </div>
                      <div class="info">{{ data.name }}</div>
                    </a>
                  </td>
                  <td>
                    <textarea
                      class="form-control"
                      id="exampleFormControlTextarea1"
                      v-model="data.note"
                      rows="1"
                    ></textarea>
                  </td>
                  <td>
                    <div class="numbers-row">
                      <input
                        type="text"
                        :value="data.insertQty"
                        id="quantity_1"
                        class="qty2"
                        name="quantity_1"
                      />
                      <div class="inc button_inc" @click="increaseQuantity(data)">+</div>
                      <div class="dec button_inc" @click="decreaseQuantity(data)">-</div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
            style="color:black"
          >Close</button>
          <button type="button" class="btn btn-primary" @click="confirmReturn">Confirm Return</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="../js/invoice.js"></script>
<style scoped>
@import url(../css/style.css);
textarea.form-control {
  min-height: 38px;
  min-width: 100px;
  border-radius: 4px;
}
</style>