<template>
  <aside class="col-lg-3" id="sidebar_fixed">
    <div class="profile-sidebar position-relative">
      <!-- SIDEBAR USERPIC -->
      <div class="profile-userpic">
        <div
          v-if="
            state.profileImage &&
            state.profileImage != null &&
            state.profileImage == 'null'
          "
        >
          <img
            :src="$imageURL + 'profile-image/' + state.profileImage"
            class="img-responsive"
            :alt="state.profileImage"
          />
        </div>
        <div v-else>
          <img
            :src="$imageURL + 'profile-image/default.png'"
            class="img-responsive"
            alt="profile img"
          />
        </div>
      </div>

      <!-- END SIDEBAR USERPIC -->
      <!-- SIDEBAR USER TITLE -->
      <div class="profile-usertitle">
        <div class="profile-usertitle-name">
          {{ state.fullName ? state.fullName : "User" }}
        </div>
        <a
          href="javascript:void(0)"
          @click="logout()"
          class="btn btn-gray-300 btn-xs"
        >
          <i class="ti-control-forward"></i>
          Sign Out
        </a>
      </div>
      <!-- END SIDEBAR USER TITLE -->
      <!-- END SIDEBAR BUTTONS -->
      <!-- SIDEBAR MENU -->
      <div class="profile-usermenu sidebar-sticky">
        <ul class="nav flex-column">
          <li class="nav-item" @click="activeNavItem(0)">
            <router-link class="nav-link active" to="/dashboard">
              <i class="ti-desktop"></i>
              DashBoard
            </router-link>
          </li>
          <li class="nav-item" @click="activeNavItem(1)">
            <router-link class="nav-link active" to="/profile">
              <i class="ti-user"></i>
              Profile
            </router-link>
          </li>
          <li class="nav-item" @click="activeNavItem(2)">
            <router-link class="nav-link" to="/track-order">
              <i class="ti-truck"></i>
              Track your Order
            </router-link>
          </li>
          <li class="nav-item" @click="activeNavItem(3)">
            <a
              class="nav-link sidebar_link_toggle position-relative collapsed"
              data-bs-toggle="collapse"
              data-bs-target="#Uat-collapse"
              aria-expanded="false"
            >
              <i class="ti-package"></i>
              <span class="sidebar_link_text">My Orders</span>
              <span style="float: right"><i class="ti-arrow-down"></i></span>
            </a>
            <div class="collapse sidebar_submenu mt-1" id="Uat-collapse">
              <ul class="list-unstyled">
                <li class="subNav mb-1" @click="activeSubNav(0)">
                  <router-link to="/total-orders" class="sidebar_submenu_link nav-link">
                    <i class="ti-arrow-circle-right"></i> Total Orders
                  </router-link>
                </li>
                <li class="subNav mb-1" @click="activeSubNav(1)">
                  <router-link to="/pending-orders" class="sidebar_submenu_link nav-link"
                    ><i class="ti-arrow-circle-right"></i> Pending Orders
                  </router-link>
                </li>
                <li class="subNav mb-1" @click="activeSubNav(2)">
                  <router-link
                    to="/processing-orders"
                    class="sidebar_submenu_link nav-link"
                    ><i class="ti-arrow-circle-right"></i> Processing Orders
                  </router-link>
                </li>
                <li class="subNav mb-1" @click="activeSubNav(3)">
                  <router-link to="/shipped-orders" class="sidebar_submenu_link nav-link"
                    ><i class="ti-arrow-circle-right"></i> Shipped Orders
                  </router-link>
                </li>
                <li class="subNav mb-1" @click="activeSubNav(4)">
                  <router-link
                    to="/delivered-orders"
                    class="sidebar_submenu_link nav-link"
                    ><i class="ti-arrow-circle-right"></i> Delivered Orders
                  </router-link>
                </li>
                <li class="subNav mb-1" @click="activeSubNav(5)">
                  <router-link
                    to="/cancelled-orders"
                    class="sidebar_submenu_link nav-link"
                    ><i class="ti-arrow-circle-right"></i> Cancelled Orders
                  </router-link>
                </li>
              </ul>
            </div>
          </li>
          <li class="nav-item" @click="activeNavItem(4)">
            <router-link class="nav-link active" to="/change-password">
            <i class="ti-desktop"></i>
            Change Password
          </router-link>
          </li>
          <li class="nav-item" @click="activeNavItem(5)">
            <router-link class="nav-link" to="/tax-exempt">
                <i class="ti-desktop"></i> Tax Exempt
            </router-link>
          </li>
          <li class="nav-item" @click="activeNavItem(6)">
            <router-link class="nav-link" to="/return-list">
                <i class="ti-desktop"></i> Return Product List
            </router-link>
          </li>
        </ul>
      </div>
      <!-- END MENU -->
    </div>
  </aside>
</template>

<script src="../js/sideBar.js"></script>