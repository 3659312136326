<template>
	<li>
		<span v-if="page === null" class="DotsHolder">
			<img 
			alt="Pagination dot Page" 
			title="Pagination dot Page" 
			src="../../assets/icons/pagination-dots.svg" class="Dots">
		</span>
		<a v-else class="Page" type="button" :aria-label="`Go to page ${page}`" :class="{ 'Page-active': isActive }"
			:style="`background-color: ${isActive ? activeColor : 'transparent'};`" @click="clickHandler($event, page)"
			:href="makeHref"
		>
			{{ page }}
		</a>
	</li>
</template>

<script>
import { defineComponent, computed } from 'vue';
import { useRoute } from 'vue-router';

export default defineComponent({
	name: 'VPage',
	props: {
		page: {
			type: Number,
			default: null,
		},
		current: {
			type: Number,
			default: 0,
		},
		activeColor: {
			type: String,
			default: '#DCEDFF',
		},
	},
	emits: ['update'],

	setup(props, { emit }) {
		const route = useRoute();
		const isActive = computed(() => {
			return props.page === props.current;
		});

		const makeHref = computed(() => {
			const isValidSearch = route.query?.keyword && route.query?.keyword.trim() != '';
			if(isValidSearch) {
				return `?keyword=${route.query?.keyword}&page=${props.page}`;
			}
			return `?page=${props.page}`;
		});		

		function clickHandler(event, page) {
			event.preventDefault();
			const isValidSearch = route.query?.keyword && route.query?.keyword.trim() != '';
			if(isValidSearch) {
				history.pushState({}, null, `?keyword=${route.query?.keyword}&page=${page}`);
			} else {
				history.pushState({}, null, `?page=${page}`);
			}
			emit('update', props.page);
		}

		return { isActive, clickHandler, makeHref };
	},
});
</script>

<style scoped lang="scss">
@import './src/assets/_settings.scss';

.Page {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 2px;
	color: $black_02;
	background-color: transparent;
	box-sizing: border-box;
	cursor: pointer;
	outline: 0;
	user-select: none;
	color: var(--color-black);
	width: 40px;
	height: 40px;
	font-size: 14px;
	line-height: 37px;
	text-align: center;
	padding: 0;
	border-radius: 50px;
	border: 1px solid var(--color-border);
	background: var(--color-tr);
	margin-right: 5px;

	&:hover {
		z-index: 2;
		color: #18191a;
		text-decoration: none;
		border: 1px solid var(--color-border);
		background: var(--color-tr);
	}

	&:focus {
		z-index: 2;
		outline: 0;
		box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .25);
	}

	&-active {
		color: var(--color-white);
		border: 1px solid var(--color-orange);
		background:  rgba(0, 0, 0, 0.1) !important;
	}
}

.DotsHolder {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 22px;
	height: 22px;
	margin: 0 2px;
	box-sizing: border-box;
}

.Dots {
	width: 8px;
	height: 4px;
	fill: #BBBBBB;
}
</style>