import NewsLetter from "../../Newsletter/template/NewsLetter";
import Advertise from "../../Advertise/template/Advertise";
import { state } from "@/globalVariables";
import { authHeader } from "@/auth";

import { Carousel, Slide, Navigation, Pagination } from "vue3-carousel";
// import "vue3-carousel/dist/carousel.css";
import HomeSchemaJson from "../../seoPlugin/HomeSchemaJson";
import VueLazyload from "vue-lazyload";

export default {
  components: {
    NewsLetter,
    Advertise,
    Carousel,
    Slide,
    Navigation,
    HomeSchemaJson,
    Pagination,
    
  },
  directives: {
    lazy: VueLazyload.directive,
  },
  data() {
    return {
      state,
      siteURL: this.$serverURL + this.$api.home.homeURL,
      siteLocationCmsUrl: this.$serverURL + this.$api.home.LocationHomeCms,
      siteLocationUrl: this.$serverURL + this.$api.home.LocationHome,
      subscriberUrl: this.$serverURL + this.$api.subscribe.subscriberAPI,
      silderList: [
        {
          backgroundImage:
            "https://hgshydro.nyc3.cdn.digitaloceanspaces.com/uploads/home/hgshydro.1232517b-169b-4026-b833-92e1a5335d10_do.min.jpg",
          buttonLink: "/contact",
          buttonText: "Contact Us",
          subTitle: "Contact for Hydroponic Indoor Grow Equipment",
          title: "Your One Stop Hydro Store!",
        },
      ],
      placeholderLazyLoading:
        "https://hgshydro.nyc3.cdn.digitaloceanspaces.com/default.jpg",
      showModal: false,
      goRoute: "",
      subEmail: "",
      featuredCategory: [],
      topSellingProducts: [],
      subCategoriesList: [],
      homeBrand: [],
      bestSellingList: [
        {
          id: "0f6ab6d6-79fc-4e94-b5ab-a331c6b5a5cd",
          createdAt: "2022-09-23T12:45:44.909Z",
          updatedAt: "2023-01-19T12:14:49.000Z",
          isDeleted: 0,
          name: "Loading...",
          featuredImage: "loading_dummy.jpg",
          sku: "sk-1002",
          rating: null,
          price: "0",
          discountedPrice: 0,
          stock: 26,
          weight: "15 kg",
          discountId: "",
          brandName: "Loading...",
          parentCategoryId: "08ae4884-89d0-410f-ab42-9229c07b5f1c",
          subCategoryId: "091637e0-d675-4a78-ae32-3b23b20bcb7f",
          shortDescription: "<p>Loading...</p>",
          longDescription: "<p>GRO PRO GROW BAGS 10 GALLON 25/PK</p>",
          productParentCategory: null,
          productSubCategory: null,
          soldQuantity: 0,
          parentCategoryName: "Shoes",
          subCategoryName: "test 12",
          discountTitle: "",
          isFeatured: 1,
          urlSlug: "gro-pro-grow-bags-10-5-gallon-25-pk",
          userId: "",
          userDiscountedPrice: "",
        },
        {
          id: "0f6ab6d6-79fc-4e94-b5ab-a331c6b5a5cd",
          createdAt: "2022-09-23T12:45:44.909Z",
          updatedAt: "2023-01-19T12:14:49.000Z",
          isDeleted: 0,
          name: "Loading...",
          featuredImage: "loading_dummy.jpg",
          sku: "sk-1002",
          rating: null,
          price: "0",
          discountedPrice: 0,
          stock: 26,
          weight: "15 kg",
          discountId: "",
          brandName: "Loading...",
          parentCategoryId: "08ae4884-89d0-410f-ab42-9229c07b5f1c",
          subCategoryId: "091637e0-d675-4a78-ae32-3b23b20bcb7f",
          shortDescription: "<p>Loading...</p>",
          longDescription: "<p>GRO PRO GROW BAGS 10 GALLON 25/PK</p>",
          productParentCategory: null,
          productSubCategory: null,
          soldQuantity: 0,
          parentCategoryName: "Shoes",
          subCategoryName: "test 12",
          discountTitle: "",
          isFeatured: 1,
          urlSlug: "gro-pro-grow-bags-10-5-gallon-25-pk",
          userId: "",
          userDiscountedPrice: "",
        },
        {
          id: "0f6ab6d6-79fc-4e94-b5ab-a331c6b5a5cd",
          createdAt: "2022-09-23T12:45:44.909Z",
          updatedAt: "2023-01-19T12:14:49.000Z",
          isDeleted: 0,
          name: "Loading...",
          featuredImage: "loading_dummy.jpg",
          sku: "sk-1002",
          rating: null,
          price: "0",
          discountedPrice: 0,
          stock: 26,
          weight: "15 kg",
          discountId: "",
          brandName: "Loading...",
          parentCategoryId: "08ae4884-89d0-410f-ab42-9229c07b5f1c",
          subCategoryId: "091637e0-d675-4a78-ae32-3b23b20bcb7f",
          shortDescription: "<p>Loading...</p>",
          longDescription: "<p>GRO PRO GROW BAGS 10 GALLON 25/PK</p>",
          productParentCategory: null,
          productSubCategory: null,
          soldQuantity: 0,
          parentCategoryName: "Shoes",
          subCategoryName: "test 12",
          discountTitle: "",
          isFeatured: 1,
          urlSlug: "gro-pro-grow-bags-10-5-gallon-25-pk",
          userId: "",
          userDiscountedPrice: "",
        },
        {
          id: "0f6ab6d6-79fc-4e94-b5ab-a331c6b5a5cd",
          createdAt: "2022-09-23T12:45:44.909Z",
          updatedAt: "2023-01-19T12:14:49.000Z",
          isDeleted: 0,
          name: "Loading...",
          featuredImage: "loading_dummy.jpg",
          sku: "sk-1002",
          rating: null,
          price: "0",
          discountedPrice: 0,
          stock: 26,
          weight: "15 kg",
          discountId: "",
          brandName: "Loading...",
          parentCategoryId: "08ae4884-89d0-410f-ab42-9229c07b5f1c",
          subCategoryId: "091637e0-d675-4a78-ae32-3b23b20bcb7f",
          shortDescription: "<p>Loading...</p>",
          longDescription: "<p>GRO PRO GROW BAGS 10 GALLON 25/PK</p>",
          productParentCategory: null,
          productSubCategory: null,
          soldQuantity: 0,
          parentCategoryName: "Shoes",
          subCategoryName: "test 12",
          discountTitle: "",
          isFeatured: 1,
          urlSlug: "gro-pro-grow-bags-10-5-gallon-25-pk",
          userId: "",
          userDiscountedPrice: "",
        },
        {
          id: "0f6ab6d6-79fc-4e94-b5ab-a331c6b5a5cd",
          createdAt: "2022-09-23T12:45:44.909Z",
          updatedAt: "2023-01-19T12:14:49.000Z",
          isDeleted: 0,
          name: "Loading...",
          featuredImage: "loading_dummy.jpg",
          sku: "sk-1002",
          rating: null,
          price: "0",
          discountedPrice: 0,
          stock: 26,
          weight: "15 kg",
          discountId: "",
          brandName: "Loading...",
          parentCategoryId: "08ae4884-89d0-410f-ab42-9229c07b5f1c",
          subCategoryId: "091637e0-d675-4a78-ae32-3b23b20bcb7f",
          shortDescription: "<p>Loading...</p>",
          longDescription: "<p>GRO PRO GROW BAGS 10 GALLON 25/PK</p>",
          productParentCategory: null,
          productSubCategory: null,
          soldQuantity: 0,
          parentCategoryName: "Shoes",
          subCategoryName: "test 12",
          discountTitle: "",
          isFeatured: 1,
          urlSlug: "gro-pro-grow-bags-10-5-gallon-25-pk",
          userId: "",
          userDiscountedPrice: "",
        },
        {
          id: "0f6ab6d6-79fc-4e94-b5ab-a331c6b5a5cd",
          createdAt: "2022-09-23T12:45:44.909Z",
          updatedAt: "2023-01-19T12:14:49.000Z",
          isDeleted: 0,
          name: "Loading...",
          featuredImage: "loading_dummy.jpg",
          sku: "sk-1002",
          rating: null,
          price: "0",
          discountedPrice: 0,
          stock: 26,
          weight: "15 kg",
          discountId: "",
          brandName: "Loading...",
          parentCategoryId: "08ae4884-89d0-410f-ab42-9229c07b5f1c",
          subCategoryId: "091637e0-d675-4a78-ae32-3b23b20bcb7f",
          shortDescription: "<p>Loading...</p>",
          longDescription: "<p>GRO PRO GROW BAGS 10 GALLON 25/PK</p>",
          productParentCategory: null,
          productSubCategory: null,
          soldQuantity: 0,
          parentCategoryName: "Shoes",
          subCategoryName: "test 12",
          discountTitle: "",
          isFeatured: 1,
          urlSlug: "gro-pro-grow-bags-10-5-gallon-25-pk",
          userId: "",
          userDiscountedPrice: "",
        },
        {
          id: "0f6ab6d6-79fc-4e94-b5ab-a331c6b5a5cd",
          createdAt: "2022-09-23T12:45:44.909Z",
          updatedAt: "2023-01-19T12:14:49.000Z",
          isDeleted: 0,
          name: "Loading...",
          featuredImage: "loading_dummy.jpg",
          sku: "sk-1002",
          rating: null,
          price: "0",
          discountedPrice: 0,
          stock: 26,
          weight: "15 kg",
          discountId: "",
          brandName: "Loading...",
          parentCategoryId: "08ae4884-89d0-410f-ab42-9229c07b5f1c",
          subCategoryId: "091637e0-d675-4a78-ae32-3b23b20bcb7f",
          shortDescription: "<p>Loading...</p>",
          longDescription: "<p>GRO PRO GROW BAGS 10 GALLON 25/PK</p>",
          productParentCategory: null,
          productSubCategory: null,
          soldQuantity: 0,
          parentCategoryName: "Shoes",
          subCategoryName: "test 12",
          discountTitle: "",
          isFeatured: 1,
          urlSlug: "gro-pro-grow-bags-10-5-gallon-25-pk",
          userId: "",
          userDiscountedPrice: "",
        },
        {
          id: "0f6ab6d6-79fc-4e94-b5ab-a331c6b5a5cd",
          createdAt: "2022-09-23T12:45:44.909Z",
          updatedAt: "2023-01-19T12:14:49.000Z",
          isDeleted: 0,
          name: "Loading...",
          featuredImage: "loading_dummy.jpg",
          sku: "sk-1002",
          rating: null,
          price: "0",
          discountedPrice: 0,
          stock: 26,
          weight: "15 kg",
          discountId: "",
          brandName: "Loading...",
          parentCategoryId: "08ae4884-89d0-410f-ab42-9229c07b5f1c",
          subCategoryId: "091637e0-d675-4a78-ae32-3b23b20bcb7f",
          shortDescription: "<p>Loading...</p>",
          longDescription: "<p>GRO PRO GROW BAGS 10 GALLON 25/PK</p>",
          productParentCategory: null,
          productSubCategory: null,
          soldQuantity: 0,
          parentCategoryName: "Shoes",
          subCategoryName: "test 12",
          discountTitle: "",
          isFeatured: 1,
          urlSlug: "gro-pro-grow-bags-10-5-gallon-25-pk",
          userId: "",
          userDiscountedPrice: "",
        },
        {
          id: "0f6ab6d6-79fc-4e94-b5ab-a331c6b5a5cd",
          createdAt: "2022-09-23T12:45:44.909Z",
          updatedAt: "2023-01-19T12:14:49.000Z",
          isDeleted: 0,
          name: "Loading...",
          featuredImage: "loading_dummy.jpg",
          sku: "sk-1002",
          rating: null,
          price: "0",
          discountedPrice: 0,
          stock: 26,
          weight: "15 kg",
          discountId: "",
          brandName: "Loading...",
          parentCategoryId: "08ae4884-89d0-410f-ab42-9229c07b5f1c",
          subCategoryId: "091637e0-d675-4a78-ae32-3b23b20bcb7f",
          shortDescription: "<p>Loading...</p>",
          longDescription: "<p>GRO PRO GROW BAGS 10 GALLON 25/PK</p>",
          productParentCategory: null,
          productSubCategory: null,
          soldQuantity: 0,
          parentCategoryName: "Shoes",
          subCategoryName: "test 12",
          discountTitle: "",
          isFeatured: 1,
          urlSlug: "gro-pro-grow-bags-10-5-gallon-25-pk",
          userId: "",
          userDiscountedPrice: "",
        },
        {
          id: "0f6ab6d6-79fc-4e94-b5ab-a331c6b5a5cd",
          createdAt: "2022-09-23T12:45:44.909Z",
          updatedAt: "2023-01-19T12:14:49.000Z",
          isDeleted: 0,
          name: "Loading...",
          featuredImage: "loading_dummy.jpg",
          sku: "sk-1002",
          rating: null,
          price: "0",
          discountedPrice: 0,
          stock: 26,
          weight: "15 kg",
          discountId: "",
          brandName: "Loading...",
          parentCategoryId: "08ae4884-89d0-410f-ab42-9229c07b5f1c",
          subCategoryId: "091637e0-d675-4a78-ae32-3b23b20bcb7f",
          shortDescription: "<p>Loading...</p>",
          longDescription: "<p>GRO PRO GROW BAGS 10 GALLON 25/PK</p>",
          productParentCategory: null,
          productSubCategory: null,
          soldQuantity: 0,
          parentCategoryName: "Shoes",
          subCategoryName: "test 12",
          discountTitle: "",
          isFeatured: 1,
          urlSlug: "gro-pro-grow-bags-10-5-gallon-25-pk",
          userId: "",
          userDiscountedPrice: "",
        },
        {
          id: "0f6ab6d6-79fc-4e94-b5ab-a331c6b5a5cd",
          createdAt: "2022-09-23T12:45:44.909Z",
          updatedAt: "2023-01-19T12:14:49.000Z",
          isDeleted: 0,
          name: "Loading...",
          featuredImage: "loading_dummy.jpg",
          sku: "sk-1002",
          rating: null,
          price: "0",
          discountedPrice: 0,
          stock: 26,
          weight: "15 kg",
          discountId: "",
          brandName: "Loading...",
          parentCategoryId: "08ae4884-89d0-410f-ab42-9229c07b5f1c",
          subCategoryId: "091637e0-d675-4a78-ae32-3b23b20bcb7f",
          shortDescription: "<p>Loading...</p>",
          longDescription: "<p>GRO PRO GROW BAGS 10 GALLON 25/PK</p>",
          productParentCategory: null,
          productSubCategory: null,
          soldQuantity: 0,
          parentCategoryName: "Shoes",
          subCategoryName: "test 12",
          discountTitle: "",
          isFeatured: 1,
          urlSlug: "gro-pro-grow-bags-10-5-gallon-25-pk",
          userId: "",
          userDiscountedPrice: "",
        },
        {
          id: "0f6ab6d6-79fc-4e94-b5ab-a331c6b5a5cd",
          createdAt: "2022-09-23T12:45:44.909Z",
          updatedAt: "2023-01-19T12:14:49.000Z",
          isDeleted: 0,
          name: "Loading...",
          featuredImage: "loading_dummy.jpg",
          sku: "sk-1002",
          rating: null,
          price: "0",
          discountedPrice: 0,
          stock: 26,
          weight: "15 kg",
          discountId: "",
          brandName: "Loading...",
          parentCategoryId: "08ae4884-89d0-410f-ab42-9229c07b5f1c",
          subCategoryId: "091637e0-d675-4a78-ae32-3b23b20bcb7f",
          shortDescription: "<p>Loading...</p>",
          longDescription: "<p>GRO PRO GROW BAGS 10 GALLON 25/PK</p>",
          productParentCategory: null,
          productSubCategory: null,
          soldQuantity: 0,
          parentCategoryName: "Shoes",
          subCategoryName: "test 12",
          discountTitle: "",
          isFeatured: 1,
          urlSlug: "gro-pro-grow-bags-10-5-gallon-25-pk",
          userId: "",
          userDiscountedPrice: "",
        },
        {
          id: "0f6ab6d6-79fc-4e94-b5ab-a331c6b5a5cd",
          createdAt: "2022-09-23T12:45:44.909Z",
          updatedAt: "2023-01-19T12:14:49.000Z",
          isDeleted: 0,
          name: "Loading...",
          featuredImage: "loading_dummy.jpg",
          sku: "sk-1002",
          rating: null,
          price: "0",
          discountedPrice: 0,
          stock: 26,
          weight: "15 kg",
          discountId: "",
          brandName: "Loading...",
          parentCategoryId: "08ae4884-89d0-410f-ab42-9229c07b5f1c",
          subCategoryId: "091637e0-d675-4a78-ae32-3b23b20bcb7f",
          shortDescription: "<p>Loading...</p>",
          longDescription: "<p>GRO PRO GROW BAGS 10 GALLON 25/PK</p>",
          productParentCategory: null,
          productSubCategory: null,
          soldQuantity: 0,
          parentCategoryName: "Shoes",
          subCategoryName: "test 12",
          discountTitle: "",
          isFeatured: 1,
          urlSlug: "gro-pro-grow-bags-10-5-gallon-25-pk",
          userId: "",
          userDiscountedPrice: "",
        },
        {
          id: "0f6ab6d6-79fc-4e94-b5ab-a331c6b5a5cd",
          createdAt: "2022-09-23T12:45:44.909Z",
          updatedAt: "2023-01-19T12:14:49.000Z",
          isDeleted: 0,
          name: "Loading...",
          featuredImage: "loading_dummy.jpg",
          sku: "sk-1002",
          rating: null,
          price: "0",
          discountedPrice: 0,
          stock: 26,
          weight: "15 kg",
          discountId: "",
          brandName: "Loading...",
          parentCategoryId: "08ae4884-89d0-410f-ab42-9229c07b5f1c",
          subCategoryId: "091637e0-d675-4a78-ae32-3b23b20bcb7f",
          shortDescription: "<p>Loading...</p>",
          longDescription: "<p>GRO PRO GROW BAGS 10 GALLON 25/PK</p>",
          productParentCategory: null,
          productSubCategory: null,
          soldQuantity: 0,
          parentCategoryName: "Shoes",
          subCategoryName: "test 12",
          discountTitle: "",
          isFeatured: 1,
          urlSlug: "gro-pro-grow-bags-10-5-gallon-25-pk",
          userId: "",
          userDiscountedPrice: "",
        },
        {
          id: "0f6ab6d6-79fc-4e94-b5ab-a331c6b5a5cd",
          createdAt: "2022-09-23T12:45:44.909Z",
          updatedAt: "2023-01-19T12:14:49.000Z",
          isDeleted: 0,
          name: "Loading...",
          featuredImage: "loading_dummy.jpg",
          sku: "sk-1002",
          rating: null,
          price: "0",
          discountedPrice: 0,
          stock: 26,
          weight: "15 kg",
          discountId: "",
          brandName: "Loading...",
          parentCategoryId: "08ae4884-89d0-410f-ab42-9229c07b5f1c",
          subCategoryId: "091637e0-d675-4a78-ae32-3b23b20bcb7f",
          shortDescription: "<p>Loading...</p>",
          longDescription: "<p>GRO PRO GROW BAGS 10 GALLON 25/PK</p>",
          productParentCategory: null,
          productSubCategory: null,
          soldQuantity: 0,
          parentCategoryName: "Shoes",
          subCategoryName: "test 12",
          discountTitle: "",
          isFeatured: 1,
          urlSlug: "gro-pro-grow-bags-10-5-gallon-25-pk",
          userId: "",
          userDiscountedPrice: "",
        },
        {
          id: "0f6ab6d6-79fc-4e94-b5ab-a331c6b5a5cd",
          createdAt: "2022-09-23T12:45:44.909Z",
          updatedAt: "2023-01-19T12:14:49.000Z",
          isDeleted: 0,
          name: "Loading...",
          featuredImage: "loading_dummy.jpg",
          sku: "sk-1002",
          rating: null,
          price: "0",
          discountedPrice: 0,
          stock: 26,
          weight: "15 kg",
          discountId: "",
          brandName: "Loading...",
          parentCategoryId: "08ae4884-89d0-410f-ab42-9229c07b5f1c",
          subCategoryId: "091637e0-d675-4a78-ae32-3b23b20bcb7f",
          shortDescription: "<p>Loading...</p>",
          longDescription: "<p>GRO PRO GROW BAGS 10 GALLON 25/PK</p>",
          productParentCategory: null,
          productSubCategory: null,
          soldQuantity: 0,
          parentCategoryName: "Shoes",
          subCategoryName: "test 12",
          discountTitle: "",
          isFeatured: 1,
          urlSlug: "gro-pro-grow-bags-10-5-gallon-25-pk",
          userId: "",
          userDiscountedPrice: "",
        },
        {
          id: "0f6ab6d6-79fc-4e94-b5ab-a331c6b5a5cd",
          createdAt: "2022-09-23T12:45:44.909Z",
          updatedAt: "2023-01-19T12:14:49.000Z",
          isDeleted: 0,
          name: "Loading...",
          featuredImage: "loading_dummy.jpg",
          sku: "sk-1002",
          rating: null,
          price: "0",
          discountedPrice: 0,
          stock: 26,
          weight: "15 kg",
          discountId: "",
          brandName: "Loading...",
          parentCategoryId: "08ae4884-89d0-410f-ab42-9229c07b5f1c",
          subCategoryId: "091637e0-d675-4a78-ae32-3b23b20bcb7f",
          shortDescription: "<p>Loading...</p>",
          longDescription: "<p>GRO PRO GROW BAGS 10 GALLON 25/PK</p>",
          productParentCategory: null,
          productSubCategory: null,
          soldQuantity: 0,
          parentCategoryName: "Shoes",
          subCategoryName: "test 12",
          discountTitle: "",
          isFeatured: 1,
          urlSlug: "gro-pro-grow-bags-10-5-gallon-25-pk",
          userId: "",
          userDiscountedPrice: "",
        },
        {
          id: "0f6ab6d6-79fc-4e94-b5ab-a331c6b5a5cd",
          createdAt: "2022-09-23T12:45:44.909Z",
          updatedAt: "2023-01-19T12:14:49.000Z",
          isDeleted: 0,
          name: "Loading...",
          featuredImage: "loading_dummy.jpg",
          sku: "sk-1002",
          rating: null,
          price: "0",
          discountedPrice: 0,
          stock: 26,
          weight: "15 kg",
          discountId: "",
          brandName: "Loading...",
          parentCategoryId: "08ae4884-89d0-410f-ab42-9229c07b5f1c",
          subCategoryId: "091637e0-d675-4a78-ae32-3b23b20bcb7f",
          shortDescription: "<p>Loading...</p>",
          longDescription: "<p>GRO PRO GROW BAGS 10 GALLON 25/PK</p>",
          productParentCategory: null,
          productSubCategory: null,
          soldQuantity: 0,
          parentCategoryName: "Shoes",
          subCategoryName: "test 12",
          discountTitle: "",
          isFeatured: 1,
          urlSlug: "gro-pro-grow-bags-10-5-gallon-25-pk",
          userId: "",
          userDiscountedPrice: "",
        },
        {
          id: "0f6ab6d6-79fc-4e94-b5ab-a331c6b5a5cd",
          createdAt: "2022-09-23T12:45:44.909Z",
          updatedAt: "2023-01-19T12:14:49.000Z",
          isDeleted: 0,
          name: "Loading...",
          featuredImage: "loading_dummy.jpg",
          sku: "sk-1002",
          rating: null,
          price: "0",
          discountedPrice: 0,
          stock: 26,
          weight: "15 kg",
          discountId: "",
          brandName: "Loading...",
          parentCategoryId: "08ae4884-89d0-410f-ab42-9229c07b5f1c",
          subCategoryId: "091637e0-d675-4a78-ae32-3b23b20bcb7f",
          shortDescription: "<p>Loading...</p>",
          longDescription: "<p>GRO PRO GROW BAGS 10 GALLON 25/PK</p>",
          productParentCategory: null,
          productSubCategory: null,
          soldQuantity: 0,
          parentCategoryName: "Shoes",
          subCategoryName: "test 12",
          discountTitle: "",
          isFeatured: 1,
          urlSlug: "gro-pro-grow-bags-10-5-gallon-25-pk",
          userId: "",
          userDiscountedPrice: "",
        },
        {
          id: "0f6ab6d6-79fc-4e94-b5ab-a331c6b5a5cd",
          createdAt: "2022-09-23T12:45:44.909Z",
          updatedAt: "2023-01-19T12:14:49.000Z",
          isDeleted: 0,
          name: "Loading...",
          featuredImage: "loading_dummy.jpg",
          sku: "sk-1002",
          rating: null,
          price: "0",
          discountedPrice: 0,
          stock: 26,
          weight: "15 kg",
          discountId: "",
          brandName: "Loading...",
          parentCategoryId: "08ae4884-89d0-410f-ab42-9229c07b5f1c",
          subCategoryId: "091637e0-d675-4a78-ae32-3b23b20bcb7f",
          shortDescription: "<p>Loading...</p>",
          longDescription: "<p>GRO PRO GROW BAGS 10 GALLON 25/PK</p>",
          productParentCategory: null,
          productSubCategory: null,
          soldQuantity: 0,
          parentCategoryName: "Shoes",
          subCategoryName: "test 12",
          discountTitle: "",
          isFeatured: 1,
          urlSlug: "gro-pro-grow-bags-10-5-gallon-25-pk",
          userId: "",
          userDiscountedPrice: "",
        },
      ],
      featuredProductList: [],
      imageURL: this.$imageURL,
      singleProduct: {},
      brandList: [],
      blogList: [],
      pageCms: [],
      startYourAccount: [],
      productReviewList: [],
      metaKeyword: "",
      metaDescription: "",
      // carousel settings
      settings: {
        itemsToShow: 1,
        snapAlign: "start",
      },
      // breakpoints are mobile first
      // any settings not specified will fallback to the carousel settings
      heroBreakpoint: {
        // 700px and up
        0: {
          itemsToShow: 1,
        },
        575: {
          itemsToShow: 1,
          snapAlign: "start",
        },
        767: {
          itemsToShow: 1,
          snapAlign: "start",
        },
        // 1024 and up
        1024: {
          itemsToShow: 1,
          snapAlign: "start",
        },
      },
      breakpoint: {
        // 700px and up
        0: {
          itemsToShow: 2,
        },
        575: {
          itemsToShow: 2,
          snapAlign: "start",
        },
        767: {
          itemsToShow: 3,
          snapAlign: "start",
        },
        // 1024 and up
        1024: {
          itemsToShow: 5,
          snapAlign: "start",
        },
      },
      breakpoint2: {
        // 700px and up
        0: {
          itemsToShow: 1,
        },
        575: {
          itemsToShow: 2,
          snapAlign: "start",
        },
        767: {
          itemsToShow: 3,
          snapAlign: "start",
        },
        // 1024 and up
        1024: {
          itemsToShow: 4,
          snapAlign: "start",
        },
      },
      breakpoint3: {
        // 700px and up
        0: {
          itemsToShow: 2,
        },
        575: {
          itemsToShow: 2,
          snapAlign: "start",
        },
        767: {
          itemsToShow: 3,
          snapAlign: "start",
        },
        // 1024 and up
        1024: {
          itemsToShow: 6,
          snapAlign: "start",
        },
      },
      breakpoint4: {
        // 700px and up
        575: {
          itemsToShow: 2,
          snapAlign: "start",
        },
        767: {
          itemsToShow: 3,
          snapAlign: "start",
        },
        // 1024 and up
        1024: {
          itemsToShow: 4,
          snapAlign: "start",
        },
      },
      homeIntro: {
        id: "f5be7dbd-68d2-4986-8205-a34f90b69533",
        createdAt: "2023-02-24T13:43:14.766Z",
        updatedAt: "2023-02-24T13:59:20.000Z",
        isDeleted: false,
        introVideo:
          "https://hgshydro.nyc3.cdn.digitaloceanspaces.com/default.jpg",
        introUrl: "#",
        boxOneLink: "#",
        boxOneImage:
          "https://hgshydro.nyc3.cdn.digitaloceanspaces.com/default.jpg",
        boxOneTitle: "Loading...",
        boxOneSub: "Loading...",
        boxOneBgColor: "#009346",
        boxOneFontColor: "#ccc",
        boxTwoLink: "#",
        boxTwoImage:
          "https://hgshydro.nyc3.cdn.digitaloceanspaces.com/default.jpg",
        boxTwoTitle: "Loading...",
        boxTwoSub: "Loading...",
        boxTwoBgColor: "#34a50a",
        boxTwoFontColor: "#ccc",
        boxThreeLink: "#",
        boxThreeImage:
          "https://hgshydro.nyc3.cdn.digitaloceanspaces.com/default.jpg",
        boxThreeTitle: "Loading...",
        boxThreeSub: "Loading...",
        boxThreeBgColor: "#5dcba0",
        boxThreeFontColor: "#ccc",
        boxFourLink: "Loading...",
        boxFourImage:
          "https://hgshydro.nyc3.cdn.digitaloceanspaces.com/default.jpg",
        boxFourTitle: "Loading...",
        boxFourSub: "Loading...",
        boxFourBgColor: "#2aa6da",
        boxFourFontColor: "#ccc",
        boxFiveLink: "#",
        boxFiveImage:
          "https://hgshydro.nyc3.cdn.digitaloceanspaces.com/default.jpg",
        boxFiveTitle: "Loading...",
        boxFiveSub: "Loading...",
        boxFiveBgColor: "#2396d0",
        boxFiveFontColor: "#ccc",
      },
      homeLocation: [],
      homeLocationCms: {},
      shoppingJourney: [],
      servicesSection: [],
      heroSection: [],
      isFeaturedShowAll: false,
      categoryHoverIndex: null,
      categoryShowOnMobile: false,
      customerReviewList:[],
    };
  },

  async created() {
    await this.getCategories();
    await this.getPageTitles();
    document.title = this.pageTitles?.homePageTitle
      ? this.pageTitles?.homePageTitle
      : "";
    this.metaKeyword = this.pageTitles.homePageMetaKeyword;
    this.metaDescription = this.pageTitles.homePageMetaDescription;
    this.state.isLoading = true;
    await this.getData();
    //await this.getLocationHomeData();
    //await this.getLocationHomeCmsData();
  },
  methods: {
    nextSlide() {
      this.$refs.carousel.next(); // Go to the next slide
    },
    prevSlide() {
      this.$refs.carousel.prev(); // Go to the previous slide
    },
    nextSlideBrand() {
      this.$refs.carouselBrand.next(); // Go to the next slide
    },
    prevSlideBrand() {
      this.$refs.carouselBrand.prev(); // Go to the previous slide
    },
    nextSlideTestimonial() {
      this.$refs.testimonial.next(); // Go to the next slide
    },
    prevSlideTestimonial() {
      this.$refs.testimonial.prev(); // Go to the previous slide
    },
    categoryHover: function (index) {
      this.categoryHoverIndex = index;
    },
    categoryShowOnMobileToggle: function () {
      //this.categoryShowOnMobile = !this.categoryShowOnMobile;\\
      const buttonElement = document.querySelector('.btn_cat_mob.cp');
      if (buttonElement) {
        buttonElement.click();
      }
    },
    loadJSCSS: async function () {
      try {
        this.state.isLoading = true;

        const loadCSS = (url) => {
          return new Promise((resolve, reject) => {
            const tag = document.createElement("link");
            tag.type = "text/css";
            tag.rel = "stylesheet";
            tag.href = url;
            tag.onload = resolve;
            tag.onerror = reject;
            document.head.appendChild(tag);
          });
        };

        const loadScript = (url) => {
          return new Promise((resolve, reject) => {
            const tag = document.createElement("script");
            tag.src = url;
            tag.onload = resolve;
            tag.onerror = reject;
            document.head.appendChild(tag);
          });
        };

        const cssFiles = ["/core/assets/css/home_1.css"];
        const jsFiles = [
          "/core/assets/js/main.js",
          "/core/assets/js/carousel-home.min.js",
        ];

       await Promise.all(cssFiles.map(loadCSS));
        await Promise.all(jsFiles.map(loadScript));

        this.state.isLoading = false;
      } catch (error) {
        console.log(error);
        this.state.isLoading = false;
      }
    },
    openLinkInNewTab: async function (linkUrl) {
      window.open(linkUrl, "_blank");
    },
    countPostedTime: function (dateStr) {
      const now = new Date();
      const diff = now - new Date(dateStr);

      const second = 1000;
      const minute = 60 * second;
      const hour = 60 * minute;
      const day = 24 * hour;
      const month = 30 * day;
      const year = 365 * day;

      if (diff < minute) {
        return "just now";
      } else if (diff < hour) {
        const minutesAgo = Math.floor(diff / minute);
        return `${minutesAgo} minute${minutesAgo > 1 ? "s" : ""} ago`;
      } else if (diff < day) {
        const hoursAgo = Math.floor(diff / hour);
        return `${hoursAgo} hour${hoursAgo > 1 ? "s" : ""} ago`;
      } else if (diff < month) {
        const daysAgo = Math.floor(diff / day);
        return `${daysAgo} day${daysAgo > 1 ? "s" : ""} ago`;
      } else if (diff < year) {
        const monthsAgo = Math.floor(diff / month);
        return `${monthsAgo} month${monthsAgo > 1 ? "s" : ""} ago`;
      } else {
        const yearsAgo = Math.floor(diff / year);
        return `${yearsAgo} year${yearsAgo > 1 ? "s" : ""} ago`;
      }
    },
    getData: async function () {
      try {
        const config = {
          method: "GET",
          url: this.siteURL,
          ...(this.state.token && { headers: { Authorization: authHeader() } }),
        };

        if (this.state.token) {
          config.url = this.$serverURL + this.$api.home.homeAuthorizedURL;
        }

        const response = await this.$axios(config);

        const {
          sliderList,
          featuredCategories = [],
          bestSellingList = [],
          featuredProductList = [],
          homeIntro = {},
          latestBlog = [],
          homePageCMS = [{}],
          customerReviewList = [],
          instagramFeedList = [],
          homePageSectionTwo = [],
          homeBrand = [],
          startYourAccount = [],
          shoppingJourney = [],
          servicesSection = [],
          heroSection = [],
        } = response.data;

        this.sliderList = sliderList;
        this.featuredCategory = featuredCategories;
        this.bestSellingList = bestSellingList;
        this.featuredProductList = featuredProductList;
        this.homeIntro = homeIntro;
        this.blogList = latestBlog;
        this.pageCms = homePageCMS[0];
        this.customerReviewList = customerReviewList;
        this.instagramFeedList = instagramFeedList;
        this.topSellingProducts = homePageSectionTwo;
        this.homeBrand = homeBrand;
        this.startYourAccount = startYourAccount;
        this.servicesSection = servicesSection;
        this.shoppingJourney = shoppingJourney;
        this.heroSection = heroSection;

        if(this.blogList.length > 3)
        {
          this.blogList.length = 3;
        }

        this.loadJSCSS();

        this.scrollToTop();
      } catch (error) {
        console.log(error);
      }
    },
    // getLocationHomeData: async function () {
    //   try {
    //     const config = {
    //       method: "GET",
    //       url: this.siteLocationUrl,
    //     };
    //     const response = await this.$axios(config);
    //     this.homeLocation = response?.data?.data;
    //   } catch (error) {
    //     console.log(error);
    //   }
    // },
    // getLocationHomeCmsData: async function () {
    //   try {
    //     const config = {
    //       method: "GET",
    //       url: this.siteLocationCmsUrl,
    //     };
    //     const response = await this.$axios(config);
    //     this.homeLocationCms = response?.data?.data?.[0];
    //   } catch (error) {
    //     console.log(error);
    //   }
    // },
    processWishListData(data, quantity) {
      // data.choosedColor = "N/A";
      // data.choosedSize = "N/A";
      this.addToWishList(data, quantity);
    },
    processCartData(data, quantity) {
      // data.choosedColor = "N/A";
      // data.choosedSize = "N/A";
      this.addToCart(data, quantity, true);
    },
    handleInitialPage() {
      localStorage.setItem("InitialPage", true);
      if (this.goRoute == "home") {
        this.showModal = false;
      } else {
        this.$router.push("/" + this.goRoute);
      }
    },
    handleInitialPageValue() {
      let initialPage = localStorage.getItem("InitialPage");
      if (initialPage && initialPage == "true") {
        this.showModal = false;
      }
    },
    getSubCategories: async function (id) {
      if (this.lastId != id) {
        this.lastId = id;
        this.subCategoriesList = [];
        try {
          let config = {
            method: "GET",
            url:
              this.$serverURL +
              this.$api.product.productSubCategoryByParentId +
              "?parentCategoryId=" +
              id,
            headers: {
              "Content-Type": "application/json",
            },
          };
          await this.$axios(config)
            .then(async (response) => {
              if (response.data.statusCode == 200) {
                this.subCategoriesList = await response.data.data;
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } catch (error) {
          console.log(error);
        }
      }
    },
    saveSubscriberEmail: async function() {
      if(!this.subEmail) {
        this.$swal.fire({
          icon: "info",
          text: "Please enter email address"
        });

        return;
      }

      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if(!regex.test(this.subEmail)) {
        this.$swal.fire({
          icon: "info",
          text: "Please enter valid email address"
        });

        return;
      }

      this.state.isLoading = true;

      const data = { email: this.subEmail };
      const config = {
        method: "POST",
        url: this.subscriberUrl,
        data
      };
      await this.$axios(config).then((response) => {
        if (response.status == 201) {
          this.$swal.fire({
            toast: true,
            position: 'top-end',
            text: "Subscription Successful",
            icon: "success",
            showConfirmButton: false,
            timer: 3000,
            animation: false,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', this.$swal.stopTimer)
              toast.addEventListener('mouseleave', this.$swal.resumeTimer)
            }
          });

          this.state.isLoading = false;
          this.subEmail = "";
        } else {
          this.$swal.fire({
            icon: "error",
            text: "Failed! please try again."
          });

          this.state.isLoading = false;
        }
      }).catch(error => {
        this.$swal.fire({
          icon: "error",
          text: error.response.data.message
        });

        this.state.isLoading = false;
      });
    }
  },
};
