import { state } from "./globalVariables";
import { authHeader } from '@/auth';
export default {
    data() {
        return {
            state,
            categoriesList: [],
            subCategoryList: [],
            orderList: [],
            pageCount: 0,
            pageTitles: {},
        }
    },
    methods: {
        limitText(text, limit) {
            const words = text.trim().split(' ');
            if (words.length > limit) {
              return words.slice(0, limit).join(' ') + '...';
            } else {
              return text;
            }
        },
        cleanText(text) {
            // Remove HTML tags
            const strippedText = text.replace(/(<([^>]+)>)/gi, "");
      
            // Remove special characters
            const cleanedText = strippedText.replace(/[^\w\s]/gi, "");
      
            // Trim whitespace
            const trimmedText = cleanedText.trim();
      
            return trimmedText;
        },
        getCategories: async function () {
            try {
                let config = {
                    method: "GET",
                    url: this.$serverURL + this.$api.product.productCategory,
                    headers: {
                        "Content-Type": "application/json"
                    }
                };
                await this.$axios(config).then((response) => {
                    if (response.status == 200) {
                        this.categoriesList = response.data;
                    }
                }).catch(error => {
                    console.log(error);
                });
            } catch (error) {
                console.log(error);
            }
        },
        getAllSubCategories: async function () {
            try {
                let config = {
                    method: "GET",
                    url: this.$serverURL + this.$api.product.getAllSubCategory,
                    headers: {
                        "Content-Type": "application/json"
                    }
                };
                await this.$axios(config).then((response) => {
                    if (response.data.statusCode == 200) {
                        this.subCategoryList = response.data.data;
                    }
                }).catch(error => {
                    console.log(error);
                });
            } catch (error) {
                console.log(error);
            }
        },
        checkUserType: async function () {
            let userId = localStorage.getItem('id');
            console.log("userId", userId);
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.checkUser.checkUserURL + '?userId=' + userId,
            };
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {
                    this.state.isNewUser = response.data.isNewUser;
                } else {
                    this.state.isNewUser = false;
                }
            }).catch(error => {
                console.log(error);
            });
        },
        getComponentUi: async function () {
            try {
                let config = {
                    method: "GET",
                    url: this.$serverURL + this.$api.componentUi.requestURL,
                };
                await this.$axios(config).then((response) => {
                    if (response.status == 200) {
                        let res = response.data;
                        this.state.componentUi.aboutUsComponentUI = res.aboutUsComponentUI.length > 0 ? res.aboutUsComponentUI[0] : {};
                        this.state.componentUi.blogComponentUI = res.blogComponentUI.length > 0 ? res.blogComponentUI[0] : {};
                        this.state.componentUi.careerComponentUI = res.careerComponentUI.length > 0 ? res.careerComponentUI[0] : {};
                        this.state.componentUi.contactComponentUI = res.contactComponentUI.length > 0 ? res.contactComponentUI[0] : {};
                        this.state.componentUi.franchiseComponentUI = res.franchiseComponentUI.length > 0 ? res.franchiseComponentUI[0] : {};
                        this.state.componentUi.quotationComponentUI = res.quotationComponentUI.length > 0 ? res.quotationComponentUI[0] : {};
                        this.state.componentUi.helpComponentUI = res.helpComponentUI.length > 0 ? res.helpComponentUI[0] : {};
                        this.state.componentUi.homeComponentUI = res.homeComponentUI.length > 0 ? res.homeComponentUI[0] : {};
                        this.state.componentUi.locationComponentUI = res.locationComponentUI.length > 0 ? res.locationComponentUI[0] : {};
                        this.state.componentUi.policyComponentUI = res.policyComponentUI.length > 0 ? res.policyComponentUI[0] : {};
                        this.state.componentUi.productsComponentUI = res.productsComponentUI.length > 0 ? res.productsComponentUI[0] : {};
                        this.state.componentUi.productsDetailsComponentUI = res.productsDetailsComponentUI.length > 0 ? res.productsDetailsComponentUI[0] : {};
                        this.state.componentUi.locationDetailsComponentUI = res.locationDetailsComponentUI.length > 0 ? res.locationDetailsComponentUI[0] : {};
                    }
                }).catch(error => {
                    console.log(error);
                });
            } catch (error) {
                console.log(error);
            }
        },
        getPageTitles: async function () {
            try {
                let config = {
                    method: "GET",
                    url: this.$serverURL + this.$api.pageTitleCMS.pageTitleCMSURL
                };
                await this.$axios(config).then(async (response) => {
                    if (response.data.statusCode == 200) {
                        this.pageTitles = response.data.data.length > 0 ? response.data.data[0] : {};
                    }
                }).catch(error => {
                    console.log(error);
                });
            } catch (error) {
                console.log(error);
            }
        },
        getStatusWiseOrders: async function (params, status) {
            try {
                let config = {
                    method: "GET",
                    url: this.$serverURL + this.$api.dashboard.filterOrder + params + "&tabStatus=" + status,
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": authHeader()
                    }
                };
                await this.$axios(config).then((response) => {
                    if (response.status == 200) {
                        this.orderList = response.data.data;
                        this.pageCount = response.data.pageCount;
                    }
                }).catch(error => {
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
            } catch (error) {
                console.log(error)
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again! "
                });
            }
        },
        scrollToTop() {
            document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0;
        },
        logout: function () {
            // localStorage.removeItem('token');
            // localStorage.removeItem("id");
            // localStorage.removeItem("email");
            // localStorage.removeItem("name");
            // localStorage.removeItem("firstname");
            // localStorage.removeItem("firstName");
            // localStorage.removeItem("lastname");
            // localStorage.removeItem("lastName");
            // localStorage.removeItem("image");
            // localStorage.removeItem("mobile");
            // localStorage.removeItem("phone");
            // localStorage.removeItem("_grecaptcha");
            // localStorage.removeItem("usrDiscountAmount");
            // localStorage.removeItem("usrDiscountType");
            // localStorage.removeItem("cartList");
            // localStorage.removeItem("wishList");
            // localStorage.removeItem("discountAmount");
            // localStorage.removeItem("discountType");
            // this.state.token = null;
            localStorage.clear();
            //this.$router.push('/home');
            window.location.replace("/home");
        },
        clearLocalVariables() {
            localStorage.removeItem('token');
            localStorage.removeItem("id");
            localStorage.removeItem("email");
            localStorage.removeItem("name");
            localStorage.removeItem("firstname");
            localStorage.removeItem("lastname");
            localStorage.removeItem("image");
            localStorage.removeItem("mobile");
            this.state.token = null;
        },

        getOrderTotal(data) {
            let grandTotal = parseFloat(data.subTotal) - (data.totalDiscount ? parseFloat(data.totalDiscount) : 0);
            if (data.shippingCost) {
                grandTotal = grandTotal + parseFloat(data.shippingCost)
            }
            if (data.salesTaxAmount) {
                grandTotal = grandTotal + parseFloat(data.salesTaxAmount)
            }
            if (data.couponNumber) {
                grandTotal = grandTotal - parseFloat(data.couponDiscount)
            }
            return grandTotal.toFixed(2);
        },

        showSweetAlert(msg, icon) {
            this.$swal.fire({
                toast: true,
                position: 'top-end',
                text: msg,
                icon: icon,
                showConfirmButton: false,
                timer: 2000,
                animation: false,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            });
        },
    }
}