import { state } from '@/globalVariables';
import NewsLetter from '../../Newsletter/template/NewsLetter';
import Advertise from '../../Advertise/template/Advertise';
import CartSchemaJson from '../../seoPlugin/CartSchemaJson';

export default {
    components: {
        NewsLetter,
        Advertise,
        CartSchemaJson
    },
    data() {
        return {
            state,
            couponList: [],
            couponCode: "",
            token: localStorage.getItem('token'),
            metaKeyword: "",
            metaDescription: ""
        }
    },
    async created() {
        await this.getPageTitles();
        document.title = (this.pageTitles?.cartPageTitle ? this.pageTitles?.cartPageTitle : '');
        this.metaKeyword = this.pageTitles.cartPageMetaKeyword;
        this.metaDescription = this.pageTitles.cartPageMetaDescription;
        this.scrollToTop();
        await this.getCoupons();
        this.calculateTotalAmount();
        const modal = document.querySelector("#offcanvasRight");
        const button = document.querySelector('.cart_bt');

        if (!modal.classList.contains("show")) {
            console.log('Modal not open');
        }
        else
        {
            button.click();
        }
    },
    mounted() {
        this.loadCSS();
    },
    methods: {
        findActualPrice: function(data)
        {
            console.log('data.discountedPrice =',data.discountedPrice);
            console.log('data.discountedPrice =',data.userDiscountedPrice);
            // return 10;
            let returnPrice=0;
            if(data.discountedPrice || data.userDiscountedPrice)
            {
                if(data.discountedPrice>0 && data.price > data.discountedPrice)
                {
                    if(!data.userDiscountedPrice || ( data.userDiscountedPrice > data.discountedPrice))
                    {
                        returnPrice = data.discountedPrice;
                    }
                    else
                    {
                        returnPrice = data.userDiscountedPrice;
                    }
                    
                }
                else if(data.userDiscountedPrice>0 && data.price > data.userDiscountedPrice)
                {
                    if(!data.discountedPrice || (data.discountedPrice > data.userDiscountedPrice))
                    {
                        returnPrice = data.userDiscountedPrice;
                    }
                    else
                    {
                        returnPrice = data.userDiscountedPrice;
                    }
                }
            }
            else if(data.discountedPrice > 0 || data.userDiscountedPrice > 0)
            {
                if(data.discountedPrice > 0 && data.discountedPrice > data.userDiscountedPrice)
                {
                    returnPrice = data.userDiscountedPrice;
                }
                else if(data.userDiscountedPrice > 0 && data.userDiscountedPrice > data.discountedPrice)
                {
                    returnPrice = data.discountedPrice;
                }
            }
            else
            {
                returnPrice = data.price;
            }
            console.log(' returnPrice =', returnPrice);
            return returnPrice;
        },
        loadCSS: function () {
            var styles = [
                '/core/assets/css/cart.css'
            ]
            styles.forEach(style => {
                let tag = document.createElement('link');
                tag.type = "text/css";
                tag.rel = "stylesheet";
                tag.href = style;
                document.head.appendChild(tag);
            });
        },
        async getCoupons() {
            try {
                let config = {
                    method: "GET",
                    url: this.$serverURL + this.$api.coupon.getCouponsURL,
                    header: {
                        'Content-Type': "application/json"
                    }
                };
                await this.$axios(config).then(response => {
                    this.couponList = response.data.data;
                }).catch(error => {
                    console.log(error);
                })
            } catch (error) {
                console.log(error);
            }
        },
        applyCoupon() {
            let data = this.couponList.find(el => el.couponCode == this.couponCode)
            if (data) {
                this.state.couponId = data.id;
                if (data.couponType == 'percent') {
                    this.state.couponDiscount = this.state.totalAmount * (parseFloat(data.discount) / 100);
                } else {
                    this.state.couponDiscount = parseFloat(data.discount);
                }
            } else {
                this.$swal.fire({
                    icon: "error",
                    text: "Not a valid coupon!!!"
                });
            }
        }
    }
}