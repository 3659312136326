<template>
  <teleport to="head">
    <link rel="canonical" :href="$route.path" />
    <meta name="keywords" :content="metaData.checkoutPageMetaKeyword" />
    <meta name="description" :content="metaData.checkoutPageMetaDescription" />
    <meta name="author" content="HGSHYDRO">
    <meta name="publisher" content="HGSHYDRO">
  </teleport>
</template>

<script>
export default {
  name: "CheckoutSchemajson ",
  props: ["metaData"],
  data() {
    return {
      homeMetaKeyword: "",
      homeMetaDescription: ""
    };
  },
  created() {
  }
};
</script>
