<template>
  <main class="bg_gray">
    <div class="container margin_30" style="min-height:35rem;">
      <div class="page_header">
        <div class="breadcrumbs">
          <ul>
            <li>
              <a href="javascript:void(0)" @click="$router.push('/home')">Home</a>
            </li>
            <li>
              <a hrefj="avascript:void(0)" @click="$router.push('/wishlist')"
                >Wishlist</a
              >
            </li>
          </ul>
        </div>
        <h1>Wishlist page</h1>
      </div>
      <!-- /page_header -->
      <table class="table table-striped cart-list" v-if="wishList.length>0">
        <thead>
          <tr>
            <th>Product</th>
            <th>Price</th>
            <!-- <th>Color</th>
            <th>Size</th> -->
            <th>Quantity</th>
            <th>Subtotal</th>
            <th width="200" style="width:200px;"></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(data, index) in wishList" :key="index">
            <td>
              <div class="thumb_cart">
                <img  @click="$router.push('/product-details/' + data.data.urlSlug)"
                  v-if="data.data.featuredImage"
                  :src="$imageURL + 'productImage/' + data.data.featuredImage"
                  :data-src="$imageURL + 'productImage/' + data.featuredImage"
                  class="lazy"
                  alt="Image"
                />
                <img  v-else
                  src="core/assets/img/products/product_placeholder_square_small.jpg"
                  data-src="core/assets/img/products/shoes/1.jpg"
                  class="lazy"
                  alt="Image" 
                  @click="$router.push('/product-details/' + data.data.urlSlug)"
                />
              </div>
              <span class="item_cart"  @click="$router.push('/product-details/' + data.data.urlSlug)" style="cursor:pointer;">({{ data.data.brandName }}) {{ data.data.name }}</span>
            </td>
            <td>
                <strong> ${{ 
                            (data.data.userDiscountedPrice && data.data.userDiscountedPrice != null && data.data.userDiscountedPrice != "") 
                            ? data.data.userDiscountedPrice > data.data.discountedPrice 
                            ? data.data.discountedPrice : data.data.userDiscountedPrice : data.data.discountedPrice ? data.data.discountedPrice : data.data.price
                        }}</strong>
              <!-- <strong>${{data.data.discountedPrice ? data.data.discountedPrice : data.data.price}}</strong> -->
            </td>
            <!-- <td>{{ data.data.choosedColor }}</td>
            <td>{{ data.data.choosedSize }}</td> -->
            <td>
              <div class="numbers-row">
                <input
                  type="text"
                  :value="data.quantity"
                  id="quantity_1"
                  class="qty2"
                  name="quantity_1"
                />
                <div
                  class="inc button_inc"
                  @click="increaseWishListProductQuantity(index)"
                >
                  +
                </div>
                <div
                  class="dec button_inc"
                  @click="decreaseWishListProductQuantity(index)"
                >
                  -
                </div>
              </div>
            </td>
            <td>
              <strong
                >${{
                //   data.data.discountedPrice
                //     ? data.data.discountedPrice * data.quantity
                //     : data.data.price * data.quantity
                ((data.data.userDiscountedPrice && data.data.userDiscountedPrice != null && data.data.userDiscountedPrice != "") 
                            ? data.data.userDiscountedPrice > data.data.discountedPrice 
                            ? data.data.discountedPrice : data.data.userDiscountedPrice : data.data.discountedPrice ? data.data.discountedPrice : data.data.price) * data.quantity
                }}</strong
              >
            </td>
            <td>
              <a
                class="btn_1"
                style="width: 121px;"
                href="javascript:void(0)"
                role="button"
                @click="moveFromWishListToCart(data, index)"
                >Add to Cart</a
              >
            </td>
            <td class="options">
              <a href="#"
                ><i class="ti-trash" @click="removeFromWishList(index)"></i
              ></a>
            </td>
          </tr>
        </tbody>
      </table>
      <div v-else class="alert alert-info " style="text-align: center;" role="alert">
        Wishlist Is Empty!
      </div>
    </div>
  </main>
  <Advertise v-if="state.advertiseList.includes('Wishlist') && state.isAdvertise==true"/>
  <NewsLetter v-if="state.newsletterList.includes('Wishlist') && state.isNewsLetter==true"/>
  <WishlistSchemaJson :metaKeyword="metaKeyword" :metaDescription="metaDescription"></WishlistSchemaJson>
</template>
<script src="../js/wishlist.js"></script>

<style scoped>
.addToCartBtn {
  border: none;
  color: #fff;
  background: #004dda;
  outline: none;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  padding: 1px 25px;
  color: #fff;
  text-align: center;
}
</style>