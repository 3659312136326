<template>
	<ul class="Pagination">
		<li v-if="!hideFirstButton" class="PaginationControl">
			<a :href="firstPageHref" @click="goToFirst($event, firstPageHref)" :class="{ 'Control-active': isPrevControlsActive }">
				<img 
				alt="Pagination First Page" 
				title="Pagination First Page" 
				src="../../assets/icons/page-first.svg" class="Control" />
			</a>
		</li>
		<li class="PaginationControl">
			<a :href="prevPageHref" @click="goToPrev($event, prevPageHref)" :class="{ 'Control-active': isPrevControlsActive }">
				<img 
				alt="Pagination Left Arrow Page" 
				title="Pagination Left Arrow Page" 
				src="../../assets/icons/chevron-left.svg" class="Control" />
			</a>
		</li>
		<v-page v-for="page in pagination" :key="`pagination-page-${page}`" :page="page" :current="modelValue"
			:active-color="activeColor" @update="updatePageHandler" />
		<li class="PaginationControl">
			<a :href="nextPageHref" @click="goToNext($event, nextPageHref)" :class="{ 'Control-active': isNextControlsActive }">
				<img 
				alt="Pagination Right Arrow Page" 
				title="Pagination Right Arrow Page" 
				src="../../assets/icons/chevron-right.svg" class="Control" />
			</a>
		</li>
		<li v-if="!hideLastButton" class="PaginationControl">
			<a :href="lastPageHref" @click="goToLast($event, lastPageHref)" :class="{ 'Control-active': isNextControlsActive }">
				<img 
				alt="Pagination Last Page" 
				title="Pagination Last Page" 
				src="../../assets/icons/page-last.svg" class="Control" />
			</a>
		</li>
	</ul>
</template>

<script>
import { defineComponent, computed } from 'vue';
import { useRoute } from 'vue-router';
import VPage from './VPage.vue';

export default defineComponent({
	name: 'VPagination',
	components: { VPage },
	props: {
		pages: {
			type: Number,
			default: 0,
		},
		rangeSize: {
			type: Number,
			default: 1,
		},
		modelValue: {
			type: Number,
			default: 0,
		},
		activeColor: {
			type: String,
			default: '#DCEDFF',
		},
		hideFirstButton: {
			type: Boolean,
			default: false,
		},
		hideLastButton: {
			type: Boolean,
			default: false,
		},
	},
	emits: ['update:modelValue'],

	setup(props, { emit })
	{
		const route = useRoute();
		// pagination
		const pagination = computed(() =>
		{
			const res = [];
			const minPaginationElems = 5 + props.rangeSize * 2;

			let rangeStart = props.pages <= minPaginationElems ? 1 : props.modelValue - props.rangeSize;
			let rangeEnd =
				props.pages <= minPaginationElems ? props.pages : props.modelValue + props.rangeSize;

			rangeEnd = rangeEnd > props.pages ? props.pages : rangeEnd;
			rangeStart = rangeStart < 1 ? 1 : rangeStart;

			if (props.pages > minPaginationElems)
			{
				const isStartBoundaryReached = rangeStart - 1 < 3;
				const isEndBoundaryReached = props.pages - rangeEnd < 3;

				if (isStartBoundaryReached)
				{
					rangeEnd = minPaginationElems - 2;
					for (let i = 1; i < rangeStart; i++)
					{
						res.push(i);
					}
				} else
				{
					res.push(1);
					res.push(null);
				}

				if (isEndBoundaryReached)
				{
					rangeStart = props.pages - (minPaginationElems - 3);
					for (let i = rangeStart; i <= props.pages; i++)
					{
						res.push(i);
					}
				} else
				{
					for (let i = rangeStart; i <= rangeEnd; i++)
					{
						res.push(i);
					}
					res.push(null);
					res.push(props.pages);
				}
			} else
			{
				for (let i = rangeStart; i <= rangeEnd; i++)
				{
					res.push(i);
				}
			}

			return res;
		});

		const firstPageHref = computed(() => {
			const isValidSearch = route.query?.keyword && route.query?.keyword.trim() != '';
			if(isValidSearch) {
				return `?keyword=${route.query?.keyword}&page=1`;
			}
			return "?page=1";
		});

		const lastPageHref = computed(() => {
			const isValidSearch = route.query?.keyword && route.query?.keyword.trim() != '';
			if(isValidSearch) {
				return `?keyword=${route.query?.keyword}&page=${props.pages}`;
			}
			return `?page=${props.pages}`;
		});

		const prevPageHref = computed(() => {
			const isValidSearch = route.query?.keyword && route.query?.keyword.trim() != '';
			const prevPage = +props.modelValue > 1 ? +props.modelValue - 1 : +props.modelValue;
			if(isValidSearch) {
				return `?keyword=${route.query?.keyword}&page=${prevPage}`;
			}
			return `?page=${prevPage}`;
		});

		const nextPageHref = computed(() => {
			const isValidSearch = route.query?.keyword && route.query?.keyword.trim() != '';
			const lastPage = +props.modelValue < props.pages ? +props.modelValue + 1 : +props.modelValue;
			if(isValidSearch) {
				return `?keyword=${route.query?.keyword}&page=${lastPage}`;
			}
			return `?page=${lastPage}`;
		});

		function updatePageHandler(params)
		{
			emit('update:modelValue', params);
		}

		// controls
		const isPrevControlsActive = computed(() =>
		{
			return props.modelValue > 1;
		});
		const isNextControlsActive = computed(() =>
		{
			return props.modelValue < props.pages;
		});

		function goToFirst(event, firstPage)
		{
			event.preventDefault();
			if (isPrevControlsActive.value)
			{
				history.pushState({}, null, firstPage);
				emit('update:modelValue', 1);
			}
		}
		function goToPrev(event, prevPage)
		{
			event.preventDefault();
			if (isPrevControlsActive.value)
			{
				history.pushState({}, null, prevPage);
				emit('update:modelValue', +props.modelValue - 1);
			}
		}

		function goToLast(event, lastPage)
		{
			event.preventDefault();
			if (isNextControlsActive.value)
			{
				history.pushState({}, null, lastPage);
				emit('update:modelValue', props.pages);
			}
		}
	
		function goToNext(event, nextPage)
		{
			event.preventDefault();
			if (isNextControlsActive.value)
			{
				history.pushState({}, null, nextPage);
				emit('update:modelValue', +props.modelValue + 1);
			}
		}

		return {
			pagination,
			updatePageHandler,
			isPrevControlsActive,
			isNextControlsActive,
			goToFirst,
			goToLast,
			goToPrev,
			goToNext,
			prevPageHref,
			nextPageHref,
			firstPageHref,
			lastPageHref
		};
	},
});
</script>

<style scoped lang="scss">
@import './src/assets/settings.scss';

.Pagination {
	display: flex;
	flex-flow: row;
	flex-wrap: wrap;
	align-items: center;
	margin: 0;
	padding: 0;
	list-style-type: none;
}

.PaginationControl {
	display: flex;
	align-items: center;
}

.PaginationControl:first-child .Page {
	border-top-left-radius: 0.25rem;
	border-bottom-left-radius: 0.25rem;
}

.Control {
	position: relative;
	display: block;
	width: 18px;
	height: 18px;
	margin: 0 2px;
	fill: $grey_01;

	&-active {
		color: white;
		cursor: pointer;
		transition: fill 0.2s ease-in-out;

		&:hover {
			fill: $black_00;
			transition: fill 0.2s ease-in-out;
		}
	}
}
</style>