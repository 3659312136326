<template>
  <main
    :style="{
      'background-color': state.componentUi.homeComponentUI.pageBackgroundColor,
    }"
  >
    <!-- Hero Section -->
    <div class="hero pt-3 pt-md-5">
      <div data-aos="fade-in" class="container">
        <div class="row">
          <div
            class="col-md-3 p-0 pe-md-3 gap-0 position-relative"
            @mouseleave="categoryHover(null)"
          >
            <div
              class="d-flex align-items-center py-2 ps-4 mb-2 mb-md-0 m-0"
              style="
                background-color: #42b3e0;
                cursor: pointer;
                border-radius: 5px 5px 0 0;
              "
              @click="categoryShowOnMobileToggle()"
            >
              <i
                class="fas fa-bars"
                style="
                  background-color: white;
                  padding: 10px;
                  margin-right: 8px;
                  border-radius: 50%;
                "
              ></i>
              <h4 class="mb-0 text-white fw-bold">All Categories</h4>
            </div>
            <div
              class="d-md-block"
              style="background-color: #fafbff; padding: 0 5px"
            >
              <div
                class="d-md-block category-items"
                :class="categoryShowOnMobile ? 'd-block' : 'd-none'"
              >
                <a
                  v-for="(data, index) in categoriesList"
                  :key="index"
                  @mouseover="getSubCategories(data.id), categoryHover(data.id)"
                  :href="'/category/' + data.categorySlug"
                  class="category-item d-flex align-items-center justify-content-between py-2"
                >
                  <span>
                    <img src="/core/images/leaf.png" :alt="data.name" />
                    <span style="color: #333e48; font-size: 15px" class="ms-2">
                      {{ data.name }}
                    </span>
                  </span>
                  <i
                    v-if="data.hasChild"
                    class="fas fa-chevron-right"
                    style="color: #999"
                  ></i>
                  
                  <div
                  :style="
                      categoryHoverIndex === data.id && data.hasChild
                        ? 'display: block'
                        : 'display: none'
                    "
                    style="
                      position: absolute;
                      top: 50px;
                      /* right: 0; */
                      left: -12px;
                      z-index: 999;
                      transform: translateX(299px);
                      /* width: 300px; */
                      min-width: 200px;
                      min-height: 340px;
                      padding: 10px 0px 0 0px;
                      background-color: #fafbff;
                    "
                    class="shadow"
                  >
                    <ul style="padding: 0 10px 0 10px; z-index: 999;">
                      <li
                        v-for="(data, index) in subCategoriesList"
                        :key="index"
                        style="list-style: none"
                      >
                        <a
                          :href="'/sub-category/' + encodeURIComponent(data.subCategorySlug)"
                          @click.prevent="$router.push('/sub-category/' + data.subCategorySlug)"
                          :title="data.name"
                          style="color: #333e48"
                          class="category-item d-block py-1"
                        >
                          <img src="/core/images/leaf.png" :alt="data.name" />
                          <span
                            style="color: #333e48; font-size: 15px"
                            class="ms-2"
                            >{{ data.name }}</span
                          >
                        </a>
                      </li>
                    </ul>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div class="col-md-9 g-0">
            <carousel
              :wrapAround="true"
              :settings="settings"
              :breakpoints="heroBreakpoint"
              :autoplay="2000"
              
              pause-autoplay-on-hover
            >
              <slide
                v-for="(data, index) in heroSection"
                :key="index"
                class="hero-content d-flex flex-column flex-lg-row justify-content-between align-items-center"
              >
                <div style="color: #003a09">
                  <span class="hero-discount">
                    {{ data.discountName }}
                  </span>
                  <p class="fs hero-slider-title" style="margin: 20px 0 0 0">
                    {{ data.titleOne }}
                  </p>
                  <p
                    class="fs"
                    style="font-weight: 600; color: #003a09; margin-top: 10px; margin-bottom: 0"
                  >
                    {{ data.titleTwo }}
                  </p>
                  <p
                    class="hero-slider-price"
                  >
                    ${{ data.price }}
                  </p>
                  <a
                    :href="data.buttonLink"
                    @click.prevent="$router.push(data.buttonLink)"
                    style="
                      text-decoration: none;
                      background-color: #003a09;
                      padding: 12px 30px 8px 30px;
                      border-radius: 30px;
                      color: #FFF;
                      font-size: 16px;
                      font-family: Jost;
                      font-style: normal;
                      font-weight: 600;
                      line-height: normal;
                    "
                  >
                    {{ data.buttonText }}
                  </a>
                </div>
                <div>
                  <img
                    v-lazy="data.image"
                    :src="data.image"
                    :alt="`${data.titleOne} ${data.titleTwo}`"
                    height="270"
                  />
                </div>
              </slide>
              <template #addons>
                <pagination />
              </template>
            </carousel>
          </div>
        </div>
      </div>
    </div>

    <!-- Services section -->
    <div data-aos="fade-up" class="service container">
      <div class="row row-col-md-3 g-0" style="background-color: white">
        <div
          v-for="(data, index) in servicesSection"
          :key="index"
          class="col gx-5 gy-4"
        >
          <div class="d-flex align-items-center position-relative" style="z-index: 1;">
            <a :href="data.link" @click.prevent="$router.push(data.link)" style="z-index: 1;">
              <img
                v-lazy="data.image"
                :src="placeholderLazyLoading"
                class="p-3 me-3"
                :alt="data.title"
                style="
                  border-radius: 50%;
                  font-size: 36px;
                  z-index: 1;
                  background-color: #f2f2f2;
                  border: 3px solid #e2e1e1;
                "
              />
            </a>
            
            <div style="z-index: 1;">
              <a :href="data.link" @click.prevent="$router.push(data.link)">
                <h5 class="service-heading-h5">
                  {{ data.title }}
                </h5>
              </a>
              <p
                class="service-detail-p"
              >
                {{ data.description }}
              </p>
            </div>

            <img
              v-if="index === 0"
              src="/core/images/curve-arrow.png"
              alt=""
              style="height: 30px; right: -44px; z-index: 1;"
              class="d-none d-md-block position-absolute"
            />
            <img
              v-if="index === 1"
              src="/core/images/curve-arrow copy.png"
              alt=""
              style="height: 27px; right: -44px;  z-index: 1"
              class="d-none d-md-block position-absolute"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- Grow Smarter with Our Hydroponic Store -->
    <div data-aos="fade-up" class="growSmarter container">
      <h2
        class="title mb-5"
        :style="{
          color:
            state.componentUi.homeComponentUI.featuredProductTitleFontColor,
        }" v-html="pageCms.startYourAccountTitle"
      >
      </h2>
      <div class="row row-cols-1 row-cols-md-3 justify-content-center">
        <div v-for="(data, index) in startYourAccount" :key="index" class="col">
          <div class="border" style="border-radius: 10px; overflow: hidden">
            <a :href="data.link" style="position: relative; display: block">
              <img
                class="w-100"
                width="100%"
                height="250"
                v-lazy="data.image"
                :src="placeholderLazyLoading"
                :alt="data.title"
                :title="data.title"
                style="object-fit: cover"
              />
              <!-- image gradient overlay -->
              <div
                style="
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                  background: linear-gradient(
                    180deg,
                    rgba(0, 0, 0, 0) 0%,
                    rgba(0, 145, 31, 0.541) 100%
                  );
                "
              ></div>
            </a>
            <div style="padding: 5px 30px; border-bottom: 1px solid #dfd7d7">
              <a :href="data.link">
                <h3 class="mt-2 grow-heading">
                  {{ data.title }}
                </h3>
              </a>
              <p class="mb-2 grow-p">
                {{ data?.description }}
              </p>
            </div>
            <a :href="data.link">
              <button
                class="btn w-100 py-3 grow-link"
                style="
                  background-color: transparent;
                "
              >
                <span>
                  {{ data.linkText }}
                </span>
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>

    <!-- Start Your Shopping Journey -->
    <div
      data-aos="fade-up"
      class="startShopping container"
      v-if="shoppingJourney.length > 0"
    >
      <h2 class="title mb-5" :style="{
          color:
            state.componentUi.homeComponentUI.featuredProductTitleFontColor,
        }">{{ pageCms?.shoppingJourneyTitle }}</h2>
      <div class="row row-cols-md-4 position-relative">
        <div v-for="(data, index) in shoppingJourney" :key="index" class="col">
          <a :href="data.link" @click.prevent="$router.push(data.link)">
            <div
              style="
                width: 280px;
                height: 280px;
                background-color: #f8f8f8;
                border-radius: 50%;
                display: grid;
                place-items: center;
                padding: 50px;
                border: 3px dashed #ddd;
              "
              class="bol position-absolute"
              :style="{
                zIndex: 10 - index,
              }"
            >
              <div>
                <img
                  v-lazy="data.image"
                  :src="placeholderLazyLoading"
                  :alt="`${data.titleOne} ${data.titleTwo}`"
                />
              </div>
              <h4 class="text-center journey-heading">
                {{ data.titleOne }} <br />
                {{ data.titleTwo }}
              </h4>
            </div>
          </a>
        </div>
      </div>
      <a
        :href="pageCms?.shoppingJourneyButtonLink"
        @click.prevent="$router.push(pageCms?.shoppingJourneyButtonLink)"
      >
        <button class="startBtn">
          {{ pageCms?.shoppingJourneyButtonText }}
        </button>
      </a>
    </div>

    <!-- Top Selling Categories -->
    <div data-aos="fade-up" class="container" style="margin-top: 50px">
      <h2
        class="title"
        :style="{
          color:
            state.componentUi.homeComponentUI.topSellingProductTitleFontColor,
        }"
      >
        {{ pageCms.topSellingTitle }}
      </h2>
      <p
        style="text-align: center; font-family: roboto"
        :style="{
          color:
            state.componentUi.homeComponentUI
              .topSellingProductSubTitleFontColor,
        }"
      >
        {{ pageCms.topsellingSubtitle }}
      </p>
      <carousel  ref="carousel"
        :wrapAround="true"
        :settings="settings"
        :breakpoints="breakpoint"
        :autoplay="2000"
        pause-autoplay-on-hover
        style="margin-top: 50px"
      >
        <slide v-for="(data, index) in topSellingProducts" :key="index">
          <a :href="data.buttonLink">
            <div
              style="
                padding: 25px;
                border-radius: 50%;
                position: relative;
                background-color: #f7f7f7;
              "
              class="p-lg-5 mb-3"
            >
              <img
                style="object-fit: cover"
                v-lazy="data.image"
                :src="placeholderLazyLoading"
                :alt="data.imageAlt"
                :title="data.imageAlt"
                width="120"
                height="120"
              />
            </div>
            <h5 class="top-selling-heading">{{ data?.title?data?.title:'No Title' }}</h5>
          </a>
        </slide>
        <template #addons>
          <div class="custom-next-arrow" @click="nextSlide">
              <i class="fas fa-chevron-right"></i>
          </div>
          <div class="custom-prev-arrow" @click="prevSlide">
            <i class="fas fa-chevron-left"></i>
          </div>
        </template>
      </carousel>
    </div>

    <!-- Featured Products -->
    <div
      data-aos="fade-up"
      class="container"
      style="margin-top: 100px"
      v-if="featuredProductList && featuredProductList.length > 0"
    >
      <h2
        class="title"
        :style="{
          color:
            state.componentUi.homeComponentUI.featuredProductTitleFontColor,
        }"
      >
        {{ pageCms.featuredProductTitle }}
      </h2>
      <p
        style="text-align: center; font-family: roboto"
        :style="{
          color:
            state.componentUi.homeComponentUI.featuredProductTitleFontColor,
        }"
      >
        {{ pageCms.featuredProductSubtitle }}
      </p>
      <div :class="isFeaturedShowAll ? '' : 'feature-product'">
        <div class="row row-cols-1 row-cols-md-3 row-cols-lg-5">
          <div
            class="col"
            v-for="(data, index) in featuredProductList"
            :key="index"
          >
            <div
              style="
                width: 100%;
                height: 220px;
                padding: 10px;
                border-radius: 5px;
                border: 1px solid #f7f7f7;
                background-color: #eeeeee;
              "
            >
              <div
                style="
                  position: relative;
                  display: flex;
                  justify-content: center;
                "
              >
                <a
                  :href="'/product-details/' + data.urlSlug"
                  @click.prevent="
                    $router.push('/product-details/' + data.urlSlug)
                  "
                >
                  <img
                    style="object-fit: cover"
                    v-lazy="`${$imageURL}productImage/${data.featuredImage}`"
                    :alt="data.name"
                    :title="data.name"
                    width="100"
                    height="150"
                  />
                </a>
                <li
                  class="fa fa-shopping-cart shadow fs-12"
                  @click="processCartData(data, 1)"
                  style="
                    position: absolute;
                    top: 45px;
                    right: 10px;
                    padding: 8px;
                    border-radius: 5px;
                    background-color: #fff;
                    cursor: pointer;
                  "
                ></li>
                <a
                  :href="'/product-details/' + data.urlSlug"
                  @click.prevent="
                    $router.push('/product-details/' + data.urlSlug)
                  "
                  style="cursor: pointer"
                >
                  <li
                    class="fa fa-eye shadow fs-12"
                    style="
                      position: absolute;
                      top: 85px;
                      right: 10px;
                      padding: 8px;
                      border-radius: 5px;
                      background-color: #fff;
                    "
                  ></li>
                </a>
              </div>
              <a
                :href="'/product-details/' + data.urlSlug"
                @click.prevent="
                  $router.push('/product-details/' + data.urlSlug)
                "
              >
                <h6 class="mt-3 text-center feature-prod-title">
                  {{ data.name }}
                </h6>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div
        class="d-flex justify-content-center"
        v-if="featuredProductList.length > 5"
      >
        <li
          v-if="isFeaturedShowAll"
          @click="isFeaturedShowAll = !isFeaturedShowAll"
          style="cursor: pointer"
          class="fa fa-chevron-up border rounded-circle p-2 mt-2"
        ></li>
        <li
          v-else
          class="fa fa-chevron-down border rounded-circle p-2 mt-2"
          style="cursor: pointer"
          @click="isFeaturedShowAll = !isFeaturedShowAll"
        ></li>
      </div>
    </div>

    <!-- Top Brands at HGS Hydro Store -->
    <div data-aos="fade-up" class="container" style="margin-top: 100px">
      <h2
        class="title"
        :style="{
          color:
            state.componentUi.homeComponentUI.topSellingProductTitleFontColor,
        }"
      >
        {{ pageCms.brandTitle }}
      </h2>
      <p style="text-align: center; font-family: roboto">
        {{ pageCms?.brandSubtitle }}
      </p>

      <carousel  ref="carouselBrand"
        :wrapAround="true"
        :breakpoints="breakpoint3"
        :autoplay="2000"
        pause-autoplay-on-hover
        style="margin-top: 50px"
      >
        <slide v-for="(data, index) in homeBrand" :key="index">
          <a :href="data.imageLink">
            <img
              width="150"
              style="object-fit: cover; margin: 0 10px"
              v-lazy="data.image"
              :src="placeholderLazyLoading"
              :alt="data.imageAlt"
              :title="data.imageAlt"
            />
          </a>
        </slide>
        <template #addons>
          <div class="custom-next-arrow" @click="nextSlideBrand">
              <i class="fas fa-chevron-right"></i>
          </div>
          <div class="custom-prev-arrow" @click="prevSlideBrand">
            <i class="fas fa-chevron-left"></i>
          </div>
        </template>
      </carousel>
      <a
        :href="pageCms?.brandButtonLink"
        @click.prevent="$router.push(pageCms?.brandButtonLink)"
      >
        <button class="startBtn">{{ pageCms?.brandButtonText }}</button>
      </a>
    </div>

    <!-- Latest News -->
    <div data-aos="fade-up" class="latestNews container">
      <h2
        class="title mb-5"
        :style="{ color: state.componentUi.homeComponentUI.newsTitleFontColor }"
      >
        {{ pageCms.homeBlogTitle }}
      </h2>
      <div class="row row-cols-1 row-cols-md-3" v-if="blogList.length > 0">
        <div
          v-for="(data, index) in blogList"
          :key="index"
          class="col position-relative mb-4"
        >
          <p
            style="
              position: absolute;
              top: -20px;
              left: 40px;
              background-color: #6abe50;
              padding: 10px 14px;
              z-index: 20;
              color: white;
              font-weight: 700;
              border-radius: 10px;
              line-height: 1.3;
              font-size: 14px;
              text-align: center;
            "
          >
            {{ $filters.getDate(data.createdAt) }}<br />
            {{ $filters.getMonth(data.createdAt) }}
          </p>
          <div class="border" style="border-radius: 10px; overflow: hidden">
            <a
              :href="`/blog-details/${data.urlSlug}`"
              @click.prevent="$router.push(`/blog-details/${data.urlSlug}`)"
            >
              <img
                class="w-100"
                width="100%"
                height="275"
                :src="placeholderLazyLoading"
                v-lazy="imageURL + 'blog/' + data.photo"
                :alt="pageCms.homeBlogTitle"
                :title="pageCms.homeBlogTitle"
                style="object-fit: cover"
              />
            </a>
            <div style="padding: 5px 30px; border-bottom: 1px solid #dfd7d7">
              <small class="mt-1 mb-2 py-2 d-block home-blog-submitter">
                {{ data.postedBy }}
              </small>
              <a
                :href="`/blog-details/${data.urlSlug}`"
                @click.prevent="$router.push(`/blog-details/${data.urlSlug}`)"
              >
                <h4 class="home-blog-title">
                  {{ data.title }}
                </h4>
              </a>
              <p class="mb-2 home-blog-detail">
                {{ limitText(cleanText(data.shortDetails), 35) }}
              </p>
            </div>
            <a
              :href="`/blog-details/${data.urlSlug}`"
              @click.prevent="$router.push(`/blog-details/${data.urlSlug}`)"
            >
              <button
                class="btn w-100 py-3 home-blog-detail-link"
                style="
                  background-color: transparent;
                  text-align: left;
                  padding-left: 30px;
                "
              >
                Read More
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>

    <!-- Follow Us -->
    <div data-aos="fade-up" style="margin-top: 100px">
      <h2
        class="title"
        :style="{
          color: state.componentUi.homeComponentUI.instagramTitleFontColor,
        }"
      >
        {{ pageCms.instaPostTitle }}
      </h2>
      <p
        style="text-align: center"
        :style="{
          color: state.componentUi.homeComponentUI.instagramSubTitleFontColor,
        }"
      >
        {{ pageCms.instaPostSubtitle }}
      </p>
      <div style="background-color: black; padding: 30px 0">
        <div class="container" style="padding-bottom: 0px;">
            <carousel
            :wrapAround="true"
            :breakpoints="breakpoint4"
            :autoplay="2000"
            pause-autoplay-on-hover
            v-if="state.instagramData"
          >
            <slide v-for="(data, index) in state.instagramData" :key="index">
              <div
                class="card round-16 h-100 flex-row align-items-start card-insta"
                :style="{ 'box-shadow': 'none !important' }"
              >
                <div>
                  <img
                    height="330"
                    width="280"
                    :src="placeholderLazyLoading"
                    v-lazy="data?.media_url"
                    :alt="limitText(cleanText(data?.caption), 25) + '...'"
                    :title="limitText(cleanText(data?.caption), 25) + '...'"
                  />
                </div>
                <div
                  class="card-insta-box"
                  @click="openLinkInNewTab(data.permalink)"
                >
                  <div class="card-insta-box-inner">
                    <div class="card-insta-logo">
                      <i class="ti-instagram"></i>
                    </div>
                    <div class="card-insta-caption-text">
                      {{ data?.caption }}
                    </div>
                    <div class="card-insta-box-items">
                      <div class="card-insta-box-items-inner">
                        <div class="card-insta-details">
                          <div class="card-insta-username">HgsHydro</div>
                          <div class="card-insta-date">
                            {{ countPostedTime(data.timestamp) }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <img
                      class="card-insta-avatar"
                      height="10"
                      width="10"
                      src="https://hgshydro.nyc3.cdn.digitaloceanspaces.com/core/assets/hgsFavicon.png"
                      alt="HgsHydro Instagram"
                      title="HgsHydro Instagram"
                    />
                  </div>
                </div>
                <div class="card-insta-bottom-container">
                  <div
                    class="d-flex gap-2"
                    @click="openLinkInNewTab(data.permalink)"
                  >
                    <img
                      class="card-insta-bottom-avatar"
                      height="10"
                      width="10"
                      src="https://hgshydro.nyc3.cdn.digitaloceanspaces.com/core/assets/hgsFavicon.png"
                      alt="HgsHydro Instagram"
                      title="HgsHydro Instagram"
                    />
                    <div class="d-flex flex-column gap-1">
                      <div class="card-insta-bottom-username">HgsHydro</div>
                      <div class="card-insta-bottom-date">
                        {{ countPostedTime(data.timestamp) }}
                      </div>
                    </div>
                  </div>
                  <div class="card-insta-bottom-logo">
                    <i class="ti-instagram"></i>
                  </div>
                </div>
              </div>
            </slide>
            <template #addons>
              <navigation />
            </template>
          </carousel>
        </div>
        

      </div>
    </div>

    <!-- See What Our Customers Say -->
    <div
      v-if="customerReviewList"
      data-aos="fade-up"
      class="container"
      style="margin-top: 100px; padding-bottom: 100px"
    >
      <h2
        class="title"
        :style="{
          color: state.componentUi.homeComponentUI.reviewTitleFontColor,
        }"
      >
        {{ pageCms.customerReviewTitle }}
      </h2>
      <p
        style="text-align: center; font-family: roboto"
        :style="{
          color: state.componentUi.homeComponentUI.reviewSubTitleFontColor,
        }"
      >
        {{ pageCms.customerReviewSubtitle }}
      </p>

      <carousel ref="testimonial"
        :wrapAround="true"
       
        :breakpoints="breakpoint4"
        :autoplay="2000"
        pause-autoplay-on-hover
        style="margin-top: 50px" v-if="customerReviewList"
      >
        <slide v-for="(data, index) in customerReviewList" :key="index">
          <div
            style="
              border: 2px solid #eeeeee;
              padding: 10px;
              padding: 10px;
              text-align: left;
              margin: 0 5px;
              border-radius: 5px;
              height: 236px;
              width: 100%;
            "
          >
            <div className="d-flex">
              <span
                v-for="i in data.rating ? data.rating : 0"
                :key="i"
                style="font-size: 18px; color: #fabe3c"
                >★</span
              >
            </div>
            <h5 class="home-testimonial-title">
              {{ data?.reviewTitle ?? "" }}
            </h5>
            <p class="home-testimonial-detail"
              style="
                margin-bottom: 10px;
                min-height: 80px;
              "
            >
              {{ limitText(cleanText(data.reviewDescription), 20) }}
            </p>
            <h6 class="home-testimonial-reviewer">
              {{ data.reviewerName ? data.reviewerName : "N/A" }}
              <li class="fa fa-check-circle" style="color: #6abe50"></li>
            </h6>
            <small class="home-testimonial-review-date">{{
              $filters.formatDate2(data.createdAt)
            }}</small>
          </div>
        </slide>
        <template #addons>
          <div class="custom-next-arrowTestimonial" @click="nextSlideTestimonial">
              <i class="fas fa-chevron-right"></i>
          </div>
          <div class="custom-prev-arrowTestimonial" @click="prevSlideTestimonial">
            <i class="fas fa-chevron-left"></i>
          </div>
        </template>
      </carousel>
    </div>

    <div class="container" style="padding-bottom: 100px">
      <div class="row sub-layout">
        <div class="col-md-6">
          <h4 class="sub-text">Subscribe To Save 10% Off</h4>
        </div>
        <div class="col-md-6">
          <div class="input-group">
            <input type="email" class="form-control sub-input" v-model="subEmail" placeholder="Enter your e-mail">
            <div class="input-group-append">
              <button class="btn sub-btn" type="button" @click="saveSubscriberEmail">SUBSCRIBE</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
  <HomeSchemaJson :metaKeyword="metaKeyword" :metaDescription="metaDescription"></HomeSchemaJson>
</template>
<script src="../js/home.js"></script>
<style scoped>
@import url("../css/home.css");
@import url("../css/carousel.css");
@import url("https://fonts.googleapis.com/css2?family=Jost:wght@300;400;500&display=swap&family=Urbanist&display=swap&family=Montserrat");

/* .container {
  padding-bottom: 100px;
} */

.sub-layout {
  background: #F7F7F7;
  /* background: #e75d5d; */
  flex-shrink: 0;
  padding: 40px 60px 40px 60px;
}

.sub-input {
  height: 50px;
  flex-shrink: 0;
}

.sub-btn {
  width: 125px;
  height: 50px;
  border-radius: 0px 5px 5px 0px;
  background: #121212;
  color: #FFF;
  text-align: center;
  font-family: Urbanist;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 1.4px;
  text-transform: uppercase;
}

.sub-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;

  color: #121212;
  font-family: Roboto;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 44px;
}

.custom-prev-arrow {
    background: white;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    text-align: center;
    font-size: calc(var(--vc-nav-width) * 2 / 3);
    padding: 0;
    color: #464646;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    border: 1px #464646 solid;
    cursor: pointer;
    left: -42px;
    top: 34%;
}
.custom-next-arrow {
    background: white;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    text-align: center;
    font-size: calc(var(--vc-nav-width) * 2 / 3);
    padding: 0;
    color: #464646;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    border: 1px #464646 solid;
    cursor: pointer;
    right: -42px;
    top: 34%;
}
.custom-prev-arrowTestimonial {
    background: white;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    text-align: center;
    font-size: calc(var(--vc-nav-width) * 2 / 3);
    padding: 0;
    color: #464646;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    border: 1px #464646 solid;
    cursor: pointer;
    left: -42px;
    top: 42%;
}
.custom-next-arrowTestimonial {
    background: white;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    text-align: center;
    font-size: calc(var(--vc-nav-width) * 2 / 3);
    padding: 0;
    color: #464646;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    border: 1px #464646 solid;
    cursor: pointer;
    right: -42px;
    top: 42%;
}


.carousel__pagination {
  position: absolute;
  bottom: 5px;
  right: 50px;
}

.pagination-item {
  background-color: red; /* Change to your desired color */
}

.carousel__pagination-button
{
  height: 10px !important;
  width: 35px !important;
}


main {
  font-family: "Jost", sans-serif !important;
}
.hero {
  height: 380px;
  background-color: #bae7bf;
  position: relative;
}
.hero-content {
  height: 400px;
  padding: 0 8%;
  text-align: left;
  border-radius: 5px 5px 2px 2px;
  background-color: #e6f3e2;
}
.hero-content .fs {
  font-size: 40px;
}
.category-items {
  height: 350px;
  /* background-color: #fafbff; */
  overflow-y: auto;
  padding: 0 16px;
}
/* style scroll bar */
.category-items::-webkit-scrollbar {
  width: 9px;
}
.category-items::-webkit-scrollbar-track {
  background: #f1f1f1;
  margin: 36px;
  border-radius: 15px;
}
.category-items::-webkit-scrollbar-thumb {
  background: #6abe50;
  border-radius: 15px;
}
.category-items::-webkit-scrollbar-thumb:hover {
  background: green;
}
.category-item {
  margin: 10px 0x;
  border-bottom: 1px solid #f1eaea;
  cursor: pointer;
}

.startBtn {
  background-color: #6abe50;
  /* color: #fff; */
  margin: 50px auto auto auto;
  padding: 10px 30px;
  border-radius: 20px;
  border: none;
  display: block;

  color: #FFF;
  font-size: 16px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.title {
  font-size: 36px;
  font-weight: 700;
  color: #333e48;
  text-align: center;
  margin-bottom: 10px;
}

.service {
  margin-top: 150px;
}
.growSmarter {
  margin-top: 100px;
}
.startShopping {
  margin-top: 50px;
  padding: 50px 100px;
}
.startShopping .row {
  height: 290px;
}
.latestNews {
  margin-top: 100px;
}

.feature-product {
  height: 300px;
  overflow: hidden;
}
@media screen and (max-width: 768px) {
  .carousel__pagination {
    /* make it center */
    right: 0;
    left: 0;
    margin: auto;
  }

  .sub-text {
    padding: 0 1rem 0 1rem;
    text-align: center!important;
  }
  .sub-layout {
    padding: 1.5rem;
  }
  .title {
    font-size: 24px;
  }
  .category-items {
    height: auto;
  }
  .hero-content {
    height: auto;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 10px;
  }
  .hero-content .fs {
    font-size: 24px;
  }
  .hero-content img {
    height: 170px;
    margin-top: 30px;
    margin-bottom: 10px;
  }
  .service {
    margin-top: 180px;
  }
  .service .col {
    margin-bottom: 20px;
  }
  .growSmarter {
    margin-top: 50px;
  }
  .growSmarter .col {
    margin-bottom: 20px;
  }
  .startShopping {
    padding: 50px 0;
  }
  .startShopping .row {
    height: auto;
  }
  .startShopping .col {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }
  .startShopping .bol {
    position: inherit !important;
  }
  .latestNews {
    margin-top: 50px;
  }
  .latestNews .col {
    margin-bottom: 30px;
  }
  .md {
    display: none;
  }
  .sm {
    display: block;
  }
  .feature-product {
    height: 1300px;
    overflow: hidden;
  }

  .custom-prev-arrow {
      background: white;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      text-align: center;
      font-size: calc(var(--vc-nav-width) * 2 / 3);
      padding: 0;
      color: #464646;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      border: 1px #464646 solid;
      cursor: pointer;
      left: -14px;
      top: 34%;
  }
  .custom-next-arrow {
      background: white;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      text-align: center;
      font-size: calc(var(--vc-nav-width) * 2 / 3);
      padding: 0;
      color: #464646;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      border: 1px #464646 solid;
      cursor: pointer;
      right: -14px;
      top: 34%;
  }
  .custom-prev-arrowTestimonial {
      background: white;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      text-align: center;
      font-size: calc(var(--vc-nav-width) * 2 / 3);
      padding: 0;
      color: #464646;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      border: 1px #464646 solid;
      cursor: pointer;
      left: -14px;
      top: 42%;
  }
  .custom-next-arrowTestimonial {
      background: white;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      text-align: center;
      font-size: calc(var(--vc-nav-width) * 2 / 3);
      padding: 0;
      color: #464646;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      border: 1px #464646 solid;
      cursor: pointer;
      right: -14px;
      top: 42%;
  }
}

.hero-discount
{
  font-size: 12px;
  background-color: #66c2e5;
  color: white;
  padding: 5px 10px 0px 10px;
  border-radius: 20px;
  text-transform: uppercase;
  font-family: Jost;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.hero-slider-title
{
  color: #282828;
  font-size: 40px;
  font-family: Jost;
  font-style: normal;
  font-weight: 300;
  line-height: 45px;
  letter-spacing: -0.4px;
}
.hero-slider-price
{
  color: #282828;
  font-size: 30px;
  font-family: Jost;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.3px;
}

.service-heading-h5
{
  font-size: 18px; line-height: 28px; font-weight: 600; color:#151515; font-style: normal;
}
.service-detail-p
{
  color: #878787;
  font-size: 14.175px;
  font-family: Jost;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.grow-heading{
  color: #0C0C0C;
  font-size: 24px;
  font-family: Jost;
  font-style: normal;
  font-weight: 600;
  line-height: 35px;
  letter-spacing: -0.56px;
}

.grow-p{
  color: #747474;
  font-size: 18px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  text-overflow: ellipsis;

  display: -webkit-box; -webkit-line-clamp: 4; -webkit-box-orient: vertical; overflow: hidden;
}
.grow-link{
  color: #4E4E4E;
  font-size: 17px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
  text-decoration-line: underline;
  text-align: left;
  text-indent: 25px;
}

.journey-heading
{
  color: #333E48;
  text-align: center;
  font-size: 35px;
  font-family: Jost;
  font-style: normal;
  font-weight: 500;
  line-height: 35px;
}

.top-selling-heading
{
  color: #171717;
  font-size: 22px;
  font-family: Jost;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.5px;
}

.feature-prod-title
{
  color: var(--on-surface, #121212);
  font-size: 14px;
  font-family: Jost;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.home-blog-submitter
{
  color: #6ABE50;
  font-size: 13px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.home-blog-title
{
  color: #0C0C0C;
  font-size: 20px;
  font-family: Jost;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: -0.56px;
}
.home-blog-detail
{
  color: #747474;
  font-size: 18px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  height: 160px;
  white-space:normal;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box; -webkit-line-clamp: 6; -webkit-box-orient: vertical; overflow: hidden;
}
.home-blog-detail-link
{
  color: #4E4E4E;
  font-size: 17px;
  font-family: Jost;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration: none;
  text-decoration-line: underline;
}
.home-testimonial-title
{
  color: var(--on-surface, #121212);
  /* Heading 06 */
  font-size: 20px;
  font-family: Urbanist;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  text-transform: capitalize;
}
.home-testimonial-detail
{
  color: var(--on-surface, #121212);

  /* body 03 (default) */
  font-size: 16px;
  font-family: Urbanist;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}
.home-testimonial-reviewer
{
  color: var(--on-surface, #121212);

  /* Button */
  font-size: 16px;
  font-family: Urbanist;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  text-transform: capitalize;
  margin-bottom:0px;
}
.home-testimonial-review-date
{
  color: var(--secondary-2, #A0A0A0);

  /* Caption 02 */
  font-size: 12px;
  font-family: Urbanist;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}


</style>


