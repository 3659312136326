<template>
    <main class="bg_gray">
		<div id="error_page">
			<div class="container">
				<div class="row justify-content-center text-center">
					<div class="col-xl-7 col-lg-9">
						<img src="/core/assets/img/404.svg" alt="" class="img-fluid" width="400" height="212">
						<p>The page you're looking is not founded!</p>
						<form action="/products" method="get">
							<div class="search_bar">
								<input type="text" name="keyword" class="form-control" placeholder="What are you looking for?">
								<input type="submit" value="Search">
							</div>
						</form>
						<a href="/" class="btn btn-primary" @click="backToHome($event)">Back To Home</a>
					</div>
				</div>
				<!-- /row -->
			</div>
			<!-- /container -->
		</div>
		<!-- /error_page -->
	</main>
</template>

<script>
export default {
	// asyncData({ error }) {
	// 	error({ statusCode: 404, message: 'Page not found' })
	// },
	async mounted() {
    document.title = "404";
		//document.status = 404;
	},
	methods: {
		backToHome: function(event) {
			event.preventDefault();
			this.$router.push("/")
		}
	},
}
</script>

<style scoped>
@import '../../../../public/core/assets/css/error_track.css';
</style>