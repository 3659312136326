<template>
  <div class="d-flex justify-content-center justify-content-md-end">
    <div
      style="z-index:99999;right: 0; box-shadow: -1px -1px 1px 0px rgba(225,227,227,0.75);
-webkit-box-shadow: -1px -1px 1px 0px rgba(225,227,227,0.75);
-moz-box-shadow: -1px -1px 1px 0px rgba(225,227,227,0.75);"
      class="offcanvas offcanvas-end custom_offcanvas_cart"
      tabindex="-1"
      id="offcanvasRight"
      aria-labelledby="offcanvasRightLabel"
      data-bs-scroll="true" 
    >
      <template v-if="!state.token">
          <p class="fs-12 px-4 fw-bold" v-if="isWelcomeCouponExist" ><span style="color:red" class="me-1">*</span>{{ cartCMS.regularUserWelcomeMessage }}</p>
      </template>
      
      <template v-else>
          <p class="fs-12 px-4 fw-bold"
          v-if="isWelcomeCouponExist==true && state.isNewUser==true"
        ><span style="color:red" class="me-1">*</span>{{ cartCMS.newUserWelcomeMessage }}</p>
      </template>
      <div class="offcanvas-header px-4">
          <div class="d-flex justify-content-between">
            <h5 id="offcanvasRightLabel" class="">Cart</h5>
            <button
              type="button"
              class="btn-close text-reset"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              id="cartCloseButton"
            ></button>
          </div>
          <div v-if="isFreeShipping==true && state.cartValue.length>0">
            <p class="fs-12 mt-3 fw-bold"><span style="color:red" class="me-1">*</span>Congratulations! Your order qualifies for free shipping.</p>
          </div>
          <div v-else>
            <p class="fs-12 mt-3 fw-bold" ><span style="color:red" class="me-1">*</span>You are ${{ dueAmount }} away from free shipping.</p>
          </div>
          <div class="progress my-custom">
            <div class="progress-bar badge-soft-success" role="progressbar" :style="{ width: value + '%', }" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
          </div>
      </div>
      <hr class="hr_custom"/>
      <div id="places_retailer" class="offcanvas-body px-4 places_retailer">
        
        
        <li class="list-unstyled mt-3" v-for="(data, index) in state.cartValue" :key="index">
          <div class="d-flex gap-3">
            <a :href="'/product-details/' + data.data.urlSlug">
              <figure>
                <img
                  v-if="data.data.featuredImage"
                  :src="
                              $imageURL +
                              'productImage/' +
                              data.data.featuredImage
                            "
                  :data-src="
                                  $imageURL +
                                  'productImage/' +
                                  data.data.featuredImage
                                "
                  alt
                  width="50"
                  height="50"
                  class="lazy" style="border: 1px #ccc solid;"
                />
                <img
                  v-else
                  src="/core/assets/img/products/product_placeholder_square_small.jpg"
                  data-src="/core/assets/img/products/shoes/thumb/1.jpg"
                  alt
                  width="50"
                  height="50"
                  class="lazy" style="border: 1px #ccc solid;"
                />
              </figure>
            </a>
            <div class="">
              <a class="my-auto fw-bold product_name" style="text-decoration: none; color: rgb(26, 26, 26);" :href="'/product-details/' + data.data.urlSlug">{{ data.data.name }}</a>
              <div class="numbers-row my-2">
                <input
                  type="text"
                  :value="data.quantity"
                  id="quantity_1"
                  class="qty2"
                  name="quantity_1"
                />
                <div class="inc button_inc" @click="increaseProductQuantity(index)">+</div>
                <div class="dec button_inc" @click="decreaseProductQuantity(index)">-</div>
              </div>
              <P class="fw-bold">
                &nbsp;${{
                parseFloat(findMixinActualPrice(data.data) * data.quantity).toFixed(2)
                }}
              </P>
            </div>
            <div class="my-auto ms-auto">
              <a
                href="javascript:void(0)"
                class="badge badge-pill-sm badge-soft-success"
                @click="removeFromCart(index)"
              >
                <i class="ti-trash"></i>
              </a>
            </div>
          </div>
          <hr class="hr_tag" />
        </li>
        <div class="total_drop" v-if="state.cartValue.length > 0">
          <div class="d-flex justify-content-between">
            <p>Sub-Total</p>
            <p>${{ parseFloat(state.totalAmount).toFixed(2) }}</p>
          </div>
          <div style="display:flex; flex-direction: column">
            
            <a
              href="javascript:void(0)"
              data-bs-dismiss="offcanvas"
              @click="$router.push('/checkout-billing')"
              class="btn_1 outline m-2"
            >Guest Checkout</a>
            <a
              v-if="state.token"
              href="javascript:void(0)"
              data-bs-dismiss="offcanvas"
              @click="$router.push('/checkout-billing')"
              class="btn_1 m-2"
            >Checkout</a>
            <a
              v-else
              href="javascript:void(0)"
              data-bs-dismiss="offcanvas"
              @click="$router.push('/account')"
              class="btn_1 m-2"
            >Login & Checkout</a>
            <br />
              <p class="fs-12">{{ cartCMS.footerMessage }}</p>
            
            <a
              href="javascript:void(0)"
              class="btn_1 outline m-2"
              @click="$router.push('/cart')"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            >View Shopping Cart</a>
          </div>
        </div>
        <div v-else>
          <div class="alert alert-info mt-5" role="alert" style="text-align:center">Cart Is Empty!!</div>
        </div>
      </div>
    </div>
    
  </div>
  <!-- <div class="offcanvas-backdrop show"></div> -->
</template>

<script src="../js/cart-component.js"></script>
<style scoped>

@import "../css/cartComponent.css";

.fs-12{
  font-size: 0.75rem
}


@media screen and (max-width: 380px) {
  .fs-12{
    font-size: 0.68rem
  }
}

#places_retailer {
  overflow-y: scroll;
}

#places_retailer::-webkit-scrollbar {
  width: 10px;
}

#places_retailer::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
  background-color: #FFFFFF;
}

#places_retailer::-webkit-scrollbar-thumb {
  background: #34c38f;
  border-radius: 10px;
}

#places_retailer::-webkit-scrollbar-thumb:hover {
  background: #34c38f;
}

#places_retailer::-moz-scrollbar-thumb {
  background-color: #34c38f;
  border-radius: 10px;
}

#places_retailer::-moz-scrollbar-track {
  background-color: #FFFFFF;
}

.places_retailer {
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: #34c38f #FFFFFF;
}

/* style the scrollbar thumb */
.places_retailer::-webkit-scrollbar-thumb {
  background-color: #34c38f;
  border-radius: 10px;
}

/* style the scrollbar thumb for Firefox */
.places_retailer::-moz-scrollbar-thumb {
  background-color: #34c38f;
  border-radius: 10px;
}

/* style the scrollbar track */
.places_retailer::-webkit-scrollbar-track {
  background-color: #FFFFFF;
}

/* style the scrollbar track for Firefox */
.places_retailer::-moz-scrollbar-track {
  background-color: #FFFFFF;
}

</style>
