import { authHeader } from "@/auth";
export default {
    data() {
        return {
            rating: 0,
            title: '',
            review: '',
            image: null,
            productId: this.$route.params.productId,
            productName: this.$route.query.productName
        }
    },
    async mounted() {
        await this.getPageTitles();
        document.title = (this.pageTitles?.productPagetitle ? this.pageTitles?.productPagetitle : '');
        this.loadJSCSS();
    },
    methods: {
        uploadImage(event) {
            this.image = event.target.files[0];
        },
        loadJSCSS: function () {
            var styles = [
                '/core/assets/css/leave_review.css'
            ]
            styles.forEach(style => {
                let tag = document.createElement('link');
                tag.type = "text/css";
                tag.rel = "stylesheet";
                tag.href = style;
                document.head.appendChild(tag);
            });
        },
        addReview: async function () {
            if (!this.title) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please Enter your title"
                });
            }
            else if (!this.review) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please Enter your review"
                });
            }
            else {
                let formData = new FormData();
                formData.append("productId", this.productId);
                formData.append("reviewTitle", this.title);
                formData.append("reviewDescription", this.review);
                formData.append("rating", this.rating);
                formData.append("image", this.image);
                let config = {
                    method: "POST",
                    data: formData,
                    url: this.$serverURL + this.$api.product.productReview,
                    headers: {
                        "Authorization": authHeader(),
                        "Content-Type": "multipart/form-data",
                    }

                };
                await this.$axios(config).then(async (response) => {
                    if (response.data.statusCode == 200) {

                        this.$swal.fire({
                            icon: "success",
                            text: "Your Review has been created."
                        });
                        this.title = '';
                        this.review = '';
                        this.rating = '';
                        this.$refs.addImage.value = null;

                    }
                    else {
                        this.$swal.fire({
                            icon: "error",
                            text: "Failed! please try again."
                        });
                    }
                }).catch(error => {
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
            }
        }
    }
}