import vueRecaptcha from 'vue3-recaptcha2';
import VueClientRecaptcha from "vue-client-recaptcha";
import QuoteSchemajson from '../../seoPlugin/QuoteSchemajson'
export default {
    components: {
        vueRecaptcha,
        QuoteSchemajson,
        VueClientRecaptcha
    },
      
    data() {
        return {
            rating: 0,
            inputValue: null,
            franchiseName: '',
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            company: '',
            notes: '',
            isSubmit: false,
            showRecaptcha: true,
            captchaCode: '',
            token: '',
            pageCMS: [],
            url: this.$serverURL + this.$api.subscribe.quotationCMS
        }
    },
    async mounted() {
        await this.getPageTitles();
        document.title = (this.pageTitles?.quotationPageTitle ? this.pageTitles?.quotationPageTitle : '');
        this.loadJSCSS();
        this.scrollToTop();
        await this.getQuotationCMS();
    },
    methods: {
        getQuotationCMS: async function () {

            let config = {
                method: "GET",
                url: this.url,
            }
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {

                    this.pageCMS = await response.data.data[0];

                }
                else {

                    this.$swal.fire({
                        icon: "error",
                        text: "Failed! please try again."
                    });
                }
            }).catch(error => {

                this.$swal.fire({
                    icon: "error",
                    text: "Failed! please try again." + error
                });
            });
        },
        getCaptchaCode: function(value) {
            this.captchaCode = value;
        },
        checkValidCaptcha: function(value) {
            if(value == true) {
                this.isSubmit = true;
            }
            else {
                this.isSubmit = false;
            }
        },
        uploadImage(event) {
            this.image = event.target.files[0];
        },
        loadJSCSS: function () {
            var styles = [
                '/core/assets/css/leave_review.css'
            ]
            styles.forEach(style => {
                let tag = document.createElement('link');
                tag.type = "text/css";
                tag.rel = "stylesheet";
                tag.href = style;
                document.head.appendChild(tag);
            });
        },
        checkValidation: function() {
            if(!this.firstName) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter first name"
                });
                return false;
            }
            if(!this.email) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter email address"
                });
                return false;
            }
            if(!this.notes) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter your message"
                });
                return false;
            }
            return true;
            
        },
        addQuote: async function () {
            this.token = await this.recaptcha();
            if(this.checkValidation()) {
                this.state.isLoading = true;
                let data = {
                    firstName: this.firstName,
                    lastName: this.lastName,
                    email: this.email,
                    phone: this.phone,
                    company: this.company,
                    quote: this.notes,
                    token: this.token
                };
                let config = {
                    method: "POST",
                    data: data,
                    url: this.$serverURL + this.$api.subscribe.quotationAPI,
                    // headers: {
                    //     "Authorization": authHeader(),
                    // }
                };
                await this.$axios(config).then(async (response) => {
                    this.state.isLoading = false;
                    if (response.data.statusCode == 201) {

                        
                        this.firstName = '';
                        this.lastName = '';
                        this.email - '';
                        this.phone = '';
                        this.company = '';
                        this.notes = '';
                        this.token = '';
                        this.inputValue = '';

                        // let successData ={
                        //     title:"Success",
                        //     message:"Your quotation request has been success"
                        // }
                        this.$router.push('/success')

                        this.$swal.fire({
                            icon: "success",
                            text: "Your quotation request has been success."
                        });
                    }
                    else {
                        this.$swal.fire({
                            icon: "error",
                            text: "Failed! please try again."
                        });
                    }
                }).catch(error => {
                    this.state.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
            }
        },
        async recaptcha() {
            // (optional) Wait until recaptcha has been loaded.
            await this.$recaptchaLoaded()
      
            // Execute reCAPTCHA with action "login".
            const token = await this.$recaptcha('login');
            return token;
            // Do stuff with the received token.
          }
    }
}