import { state } from '@/globalVariables';

export default {

    data() {
        return {
            state,
            couponList: [],
            couponCode: "",
            token: localStorage.getItem('token'),
            isFreeShipping: false,
            isWelcomeCouponExist: false,
            welcomeCupon: {},
            dueAmount: 0,
            value: '',
            cartCMS: []
        }
    },
    async created() {
        await this.getCoupons();
        await this.getCartCmsData();
        this.calculateTotalAmount();
    },
    mounted() {
        this.loadCSS();
    },
    methods: {
        findActualPrice: function (data) {
            // return 10;
            let returnPrice = 0;
            if (data.discountedPrice || data.userDiscountedPrice) {
                if (data.discountedPrice > 0 && data.price > data.discountedPrice) {
                    if (!data.userDiscountedPrice || (data.userDiscountedPrice > data.discountedPrice)) {
                        returnPrice = data.discountedPrice;
                    }
                    else {
                        returnPrice = data.userDiscountedPrice;
                    }

                }
                else if (data.userDiscountedPrice > 0 && data.price > data.userDiscountedPrice) {
                    if (!data.discountedPrice || (data.discountedPrice > data.userDiscountedPrice)) {
                        returnPrice = data.userDiscountedPrice;
                    }
                    else {
                        returnPrice = data.userDiscountedPrice;
                    }
                }
            }
            else if (data.discountedPrice > 0 || data.userDiscountedPrice > 0) {
                if (data.discountedPrice > 0 && data.discountedPrice > data.userDiscountedPrice) {
                    returnPrice = data.userDiscountedPrice;
                }
                else if (data.userDiscountedPrice > 0 && data.userDiscountedPrice > data.discountedPrice) {
                    returnPrice = data.discountedPrice;
                }
            }
            else {
                returnPrice = data.price;
            }
            console.log(' returnPrice =', returnPrice);
            return returnPrice;
        },
        loadCSS: function () {
            var styles = [
                '/core/assets/css/cart.css'
            ]
            styles.forEach(style => {
                let tag = document.createElement('link');
                tag.type = "text/css";
                tag.rel = "stylesheet";
                tag.href = style;
                document.head.appendChild(tag);
            });
        },
        async getCoupons() {
            try {
                let config = {
                    method: "GET",
                    url: this.$serverURL + this.$api.coupon.getCouponsURL,
                    header: {
                        'Content-Type': "application/json"
                    }
                };
                await this.$axios(config).then(response => {
                    this.couponList = response.data.data;
                    for (let index = 0; index < this.couponList.length; index++) {
                        const cupon = this.couponList[index];
                        if (cupon.couponType == "free shipping") {
                            if (this.state.totalAmount >= parseInt(cupon.discount)) {
                                this.isFreeShipping = true;
                                this.value = 100;
                                this.state.freeShippingCoupon = cupon;
                            } else {
                                this.isFreeShipping = false;
                                let res = parseInt(cupon.discount) - this.state.totalAmount;
                                this.dueAmount = res.toFixed(2);
                                let due = (this.state.totalAmount / parseInt(cupon.discount)) * 100
                                let remain = parseInt(due)
                                this.value = remain
                            }
                            break;
                        }
                    }
                    let tempArr = this.couponList.filter(el => el.couponType == 'welcome');
                    if (tempArr.length > 0) {
                        this.welcomeCupon = tempArr[0];
                        this.isWelcomeCouponExist = true;
                    } else {
                        this.welcomeCupon = {};
                        this.isWelcomeCouponExist = false;
                    }
                }).catch(error => {
                    console.log(error);
                })
            } catch (error) {
                console.log(error);
            }
        },
        applyCoupon() {
            let data = this.couponList.find(el => el.couponCode == this.couponCode)
            if (data) {
                this.state.couponId = data.id;
                if (data.couponType == 'percent') {
                    this.state.couponDiscount = this.state.totalAmount * (parseFloat(data.discount) / 100);
                } else {
                    this.state.couponDiscount = parseFloat(data.discount);
                }
            } else {
                this.$swal.fire({
                    icon: "error",
                    text: "Not a valid coupon!!!"
                });
            }
        },
        getCartCmsData: async function () {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.cart.cmsData
            };
            await this.$axios(config).then((response) => {
                if (response.data.statusCode == 200) {
                    this.cartCMS = response.data.data[0];
                }
            }).catch(error => {
                console.log(error);
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            })
        },
        
    },
    computed: {
        checkAmount() {
            return this.state.totalAmount;
        },
    },
    watch: {
        checkAmount: function () {
            this.getCoupons();
        },
    }
}