import BlogDetailsSchemaJson from "../../seoPlugin/BlogDetailsSchemaJson";
import { authHeader } from "../../../auth";
import { state } from "@/globalVariables";
import NewsLetter from "../../Newsletter/template/NewsLetter";
import Advertise from "../../Advertise/template/Advertise";
import VueLazyload from "vue-lazyload";
import moment from "moment";

export default {
  components: {
    NewsLetter,
    Advertise,
    BlogDetailsSchemaJson,
  },
  directives: {
    lazy: VueLazyload.directive,
  },
  data() {
    return {
      state,
      placeholderLazyLoading:
        "https://hgshydro.nyc3.cdn.digitaloceanspaces.com/default.jpg",
      singleBlog: {},
      blogCategoryList: [],
      latestBlogs: [],
      blogTagList: [],
      blogComments: [],
      blogURL: "",
      keyword: "",
      page: 1,
      limit: 6,
      totalPage: 0,
      imagePath: this.$imageURL + "blog/",
      id: "",
      //setURL: this.$serverURL + this.$api.blog.blogDetails + this.$route.params.id,
      params: "",
      categoryId: "",
      tag: "",
      name: "",
      email: "",
      website: "",
      comment: "",
      blogList: [],
      seoMeta: {},
    };
  },
  async created() {
    this.state.isLoading = true;
    this.loadCSS();
    this.scrollToTop();
    let config = {
      method: "GET",
      url:
        this.$serverURL +
        this.$api.blog.blogSlugDetails +
        this.$route.params.blogSlug,
      headers: {
        Authorization: authHeader(),
      },
    };
    await this.getBlogData(config);
    await this.getPageTitles();
    document.title = this.singleBlog.title;
    // await this.getBlogSeoMeta(this.id);
  },
  methods: {
    loadCSS: function () {
      this.state.isLoading = true;
      var styles = ["/core/assets/css/blog.css"];
      styles.forEach((style) => {
        let tag = document.createElement("link");
        tag.type = "text/css";
        tag.rel = "stylesheet";
        tag.href = style;
        document.head.appendChild(tag);
      });
      var scripts = ["/core/assets/js/main.js"];
      scripts.forEach((script) => {
        let tag = document.createElement("script");
        tag.setAttribute("src", script);
        document.head.appendChild(tag);
      });
      this.state.isLoading = false;
    },
    getAllBlogs: async function () {
      try {
        let config = {
          method: "GET",
          url: this.$serverURL + this.$api.blog.getAllBlogs,
          headers: {
            "Content-Type": "application/json",
          },
        };
        await this.$axios(config)
          .then((response) => {
            if (response.data.statusCode == 200) {
              this.blogList = response.data.data;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.log(error);
      }
    },
    getBlogSeoMeta: async function (id) {
      this.seoMeta["metaDescription"] = this.singleBlog?.title;
      this.seoMeta["metaKeyword"] = this.singleBlog?.title;
      try {
        let config = {
          method: "GET",
          url: this.$serverURL + this.$api.blog.getBlogWiseMeta + "/" + id,
          headers: {
            "Content-Type": "application/json",
          },
        };
        await this.$axios(config)
          .then((response) => {
            console.log("this.seoMeta =", this.seoMeta);
            if (response.data.statusCode == 200) {
              this.seoMeta = response.data.data;

              this.seoMeta["title"] = this.singleBlog?.title;
              console.log("this.seoMeta =", this.seoMeta);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.log(error);
      }
    },
    sendComment: async function () {
      const re = /\S+@\S+\.\S+/;
      if (!this.name) {
        this.$swal.fire({
          icon: "error",
          text: "Please enter your name",
        });
      } else if (!this.email || !re.test(this.email)) {
        this.$swal.fire({
          icon: "error",
          text: "Please enter your email",
        });
      } else if (!this.comment) {
        this.$swal.fire({
          icon: "error",
          text: "Please enter your comment",
        });
      } else {
        let data = {
          postId: this.singleBlog.id,
          postTitle: this.singleBlog.title,
          name: this.name,
          email: this.email,
          website: this.website,
          comment: this.comment,
        };
        let config = {
          method: "POST",
          data: data,
          url: this.$serverURL + this.$api.blog.blogComment,
        };
        this.state.isLoading = true;
        await this.$axios(config)
          .then(async (response) => {
            if (response.data.statusCode == 201) {
              this.name = "";
              this.email = "";
              this.website = "";
              this.comment = "";
              this.state.isLoading = false;
              this.$swal.fire({
                icon: "success",
                text: "Your message has been send.",
              });
              this.state.isLoading = false;
            } else {
              this.state.isLoading = false;
              this.$swal.fire({
                icon: "error",
                text: "Failed! please try again.",
              });
            }
          })
          .catch((error) => {
            this.state.isLoading = false;
            this.$swal.fire({
              icon: "error",
              text: error.response.data.message,
            });
          });
      }
    },
    getBlogData: async function (config) {
      this.seoMeta["metaDescription"] = this.singleBlog?.title || "";
      this.seoMeta["metaKeyword"] = this.singleBlog?.title || "";

      this.state.isLoading = true;

      try {
        const response = await this.$axios(config);

        if (response.status === 200) {
          this.singleBlog = response.data.data.blogData[0] || {};
          this.blogCategoryList = response.data.data.blogCategoryList || [];
          this.blogTagList = response.data.data.blogTagList || [];
          this.latestBlogs = response.data.data.latestBlogList || [];
          this.blogComments = response.data.data.blogComments || [];
          this.state.isLoading = false;
          this.blogURL = this.singleBlog.urlSlug || this.singleBlog.id;

          this.seoMeta = response.data.data.blogMeta || {};
          this.seoMeta["title"] = this.singleBlog?.title || "";
        }
        this.state.isLoading = false;
      } catch (error) {
        this.state.isLoading = false;
        console.log(error);
      }
    },
    formatBlogDate(date) {
      return moment(date, "YYYY-MM-DD").format("DD/MM/YYYY");
    },
  },
};
