<template>
  <main
    :style="{
      'background-color':
        state.componentUi.productsDetailsComponentUI.pageBackgroundColor,
    }"
  >
    <div class="container margin_30">
      <div class="page_header">
        <div class="breadcrumbs">
          <ul>
            <li>
              <a :href="'/'">Home</a>
            </li>
            <li>
              <a :href="'/products'">Products</a>
            </li>
            <li>{{ productDetails.name }}</li>
          </ul>
        </div>
      </div>
      <!-- /page_header -->
      <!-- product_information start -->
      <div class="container">
        <div class="row gy-4">
          <div class="col-12 col-md-5">
            <div>
              <img 
                class="details-main-img img-fluid w-100"
                :src="imagePath + `${showImage ? showImage : productDetails.featuredImage}`"
                :data-src="imagePath + `${showImage ? showImage : productDetails.featuredImage}`"
                :alt="productDetails?.featuredImageAlt?productDetails?.featuredImageAlt:productDetails.name"
              />
            </div>
            <div class="d-flex gap-3 mt-4" v-if="gallery">
              <!-- <img
                :src="imagePath + productDetails.featuredImage"
                :data-src="imagePath + productDetails.featuredImage"
                :alt="productDetails.name"
                @click="showImage = productDetails.featuredImage"
                style="cursor: pointer;"
                width="70"
                height="70"
              /> -->
              <img 
                
                v-for="(data, index) in gallery"
                :key="index"
                :src="imagePath + data.photoUrl"
                :data-src="imagePath + data.photoUrl"
                :alt="productDetails?.featuredImageAlt?productDetails?.featuredImageAlt:productDetails.name"
                @click="showImage= data.photoUrl"
                style="cursor: pointer;"
                width="70"
                height="70"
              />
            </div>
          </div>
          <div class="col-12 col-md-4">
            <p
              class="mb-2 mb-md-1 text-uppercase fs-14 text-gray ff-Roboto400"
              style="cursor: pointer"
              @click="
                $router.push({
                  path: '/products',
                  query: {
                    ...(productDetails.brandName && {
                      brandName: productDetails.brandName,
                    }),
                  },
                })
              "
            >
              {{ productDetails.brandName }}
            </p>
            <h1 class="mb-0 fs-16 fw-bold text-gray ff-Roboto500">{{ productDetails.name }}</h1>
            <div class="d-flex align-items-center py-2">
              <div class="rating mb-0" style="font-size: 15px;">
                  <i class="icon-star voted" v-for="index in voted" :key="index"></i>
                  <i class="icon-star" v-for="index in (5 - voted)" :key="index"></i>
              </div>
              <p class="mb-0 fs-10 ms-1 ff-Popins400" style="color:#556260">({{ reviewListData.length }} customer review)</p>
            </div>
            <p class="fs-14 text-light-gray ff-Roboto400" v-html="productDetails.shortDescription"></p>
            <p class="ff-Popins400 fs-14" style="color:#5B5B5B">SKU : <span class="fw-bold ff-Popins700" style="color:black">{{ productDetails.sku }}</span></p>
          </div>
          <div class="col-12 col-md-3">
            <div class="border rounded-18 p-4 w-100">
              <p class="ff-Popins400 fs-14 text-light-gray mb-0">Availability: <span class="ff-Popins400" style="color:#0076CD">{{ productDetails.stock }} in stock</span></p>
              <hr class="my-3 my-md-2" style="width:214px"/>
              <p class="mb-0 ff-Popins400" style="font-size:34px; font-weight:bold;">${{ findMixinActualPrice(productDetails) }}</p>
              <div class="py-2">
                <p class="mb-2 fs-14 ff-Popins400">Quantity</p>
                <div class="d-flex justify-content-between align-items-center border rounded-pill p-2" style="width:128px;height:35px">
                  <p class="mb-0 ms-2 ff-Popins400">{{ quantity }}</p>
                  <div class="me-1">
                    <button class="me-1 button-quantity mb-0 ff-Popins400" @click="deductQuantity()">-</button>
                    <button class="button-quantity mb-0 ff-Popins400" @click="increaseQuantity()">+</button>
                  </div>
                </div>
              </div>
              <!-- <div class="py-2">
                <p class="mb-2 fs-14 ff-Popins400">Container Size</p>
                <div class="dropdown">
                  <button class="dropdown-toggle dropdown-container-size border rounded-pill ps-3 fs-10 text-light-gray ff-Popins400" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                    Select Container Size
                  </button>
                  <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                    <li><a class="dropdown-item ff-Popins400" href="#">Action</a></li>
                    <li><a class="dropdown-item ff-Popins400" href="#">Another action</a></li>
                    <li><a class="dropdown-item ff-Popins400" href="#">Something else here</a></li>
                  </ul>
                </div>
              </div> -->
              <div class="pt-4">
                <button class="add-to-cart-button rounded-pill fs-12 ff-Popins400" @click="processCartData(productDetails)">
                  <i class="ti-shopping-cart me-1"></i>
                  <span class="align-middle">&nbsp;Add to Cart</span>
                </button>
              </div>
              <div class="pt-3">
                <button class="add-to-favourites-button rounded-pill fs-12 ff-Popins400" @click="processWishListData(productDetails)">
                  <i class="ti-heart me-1 fw-bold"></i>
                  <span class="align-middle">&nbsp;Add to Favourite</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- product_information end -->
      <!-- accessories start -->
      <div class="container py-4" v-if="productDetails.productAccessories?.length">
        <p class="accessories-text text-gray ff-Roboto400">Accessories</p>
        <div class="row g-4">
          <div
            class="col-12 col-md-3"
            v-for="(product, index) in productDetails.productAccessories"
            :key="index"
          >
            <div class="custom-accessories-card border rounded-3 p-4">
              <img
                class=""
                style="width:200px;height:200px;cursor: pointer;"
                :src="imagePath + product.featuredImage"
                :data-src="imagePath + product.featuredImage"
                :alt="product.name"
                @click="$router.push(product.urlSlug)"
              />
              <div class="text-center">
                <p
                  class="mb-0 fs-18 text-gray line-clamp-1 ff-Roboto400"
                  style="cursor: pointer;"
                  @click="$router.push(product.urlSlug)"
                >
                  {{ product.name }}
                </p>
                <p class="fs-20 fw-bold ff-Roboto500">${{ findMixinActualPrice(product) }}</p>
              </div>
              <div class="text-center">
                <button class="accessories-add-to-cart-button rounded-pill fs-12 ff-Popins400" @click="processCartData(product)">
                  <i class="ti-shopping-cart me-1"></i>
                  <span class="align-middle">&nbsp;Add to Cart</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
      <!-- accessories end -->
      <!-- tabs start -->
      <div style="background-color:#F9F9F9;">
        <div class="container margin_30">
          <nav>
        <div class="nav justify-content-center nav-tabs" id="nav-tab" role="tablist">
          <button class="nav-link active ff-Popins500" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Description</button>
          <button class="nav-link ff-Popins500" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Reviews</button>
          <button class="nav-link ff-Popins500" id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#nav-contact" type="button" role="tab" aria-controls="nav-contact" aria-selected="false">Specifications</button>
        </div>
      </nav>
      <div class="tab-content" id="nav-tabContent">
        <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
          <div class="pt-5 pb-4 ff-Popins500">
            <p v-if="!productDetails.longDescription" class="fs-14 text-gray mb-0 ff-Popins400">N/a</p>
            <p v-else v-html="productDetails.longDescription" class="fs-14 text-gray mb-0 ff-Popins400"></p>
          </div>
          <div class="mt-5">
            <!-- <p><span class="me-2 me-md-5">SKU:</span><span class="me-2 me-md-5">/</span><span class="me-2 me-md-2">Category : Hand bags</span><span class="me-2 me-md-5">/</span><span class="me-2 me-md-5">Tags : Hand bags</span></p> -->
            <p class="mb-0 tag_text text-gray ff-Popins500">
              <span class="me-2 me-md-5">SKU: <span class="fw-bold" style="color:black">{{ productDetails.sku }}</span></span>
              <span class="me-2 me-md-5">/</span>
              <span class="me-2 me-md-5">Category : <span class="" style="color:#303CD5">{{ productDetails.parentCategoryName }}</span></span>/
              <span class="ms-2 ms-md-5">Tags :</span>
              <span class="ms-2 ms-md-5" v-for="(data, index) in productDetails.productTagVariants" :key="index">{{ data.tagName }}</span>
            </p>
          </div>
        </div>
        <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab"></div>
        <div class="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
          <div class="row mt-3">
            <div class="col-3"></div>
            <div class="col-6">
              <table class="table table-sm">
                <tbody>
                  <tr>
                    <td><strong>Weight</strong></td>
                    <td>{{ productDetails.weight }}</td>
                  </tr>
                  <tr>
                    <td><strong>Brand</strong></td>
                    <td>{{ productDetails.brandName }}</td>
                  </tr>
                  <tr v-if="productDetails?.parentCategoryName">
                    <td><strong>Category</strong></td>
                    <td>{{ productDetails?.parentCategoryName }}</td>
                  </tr>
                  <tr v-if="productDetails?.subCategoryName">
                    <td><strong>Subcategory</strong></td>
                    <td>{{ productDetails?.subCategoryName }}</td>
                  </tr>
                  <tr v-if="productDetails?.childCategoryName">
                    <td><strong>Child category</strong></td>
                    <td>{{ productDetails?.childCategoryName }}</td>
                  </tr>
                  <tr v-if="productDetails?.sku">
                    <td><strong>SKU</strong></td>
                    <td>{{ productDetails?.sku }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      </div>
      </div>
      
      <!-- tabs end  -->
      <!-- review start -->
      <div class="container margin_30">
        <div class="review_section">
          <p class="fs-18 text-gray ff-Popins500">Based on {{ reviewListData.length }} reviews</p>
          <p class="mb-0 fw-bold ff-Popins700" style="font-size:29px">{{ productRating }}</p>
          <p style="font-size:10px" class="ff-Popins400">OVERALL</p>
          <div class="row gy-4">
            <div class="col-12 col-md-6">
              <div class="reviewbar-section">
                <div>
                <div class="d-flex align-items-center mb-3">
                <div class="rating mb-0 fs-14 w-25">
                  <i class="icon-star voted" v-for="index in 5" :key="index"></i>
                </div>
                <div class="w-75 d-flex align-items-center">
                  <div class="progress w-75 me-3">
                    <div
                      class="progress-bar rounded-pill"
                      role="progressbar"
                      :style="{ width: `${reviewListData.length ? starCount[4]/reviewListData.length*100 : 0}%`, backgroundColor: '#D9D9D9'}"
                      aria-valuenow="25"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                    </div>
                  </div>
                  <p class="mb-0 ff-Popins400 fs-12">{{ starCount[4] }}</p>
                </div>
              </div>
              <div class="d-flex align-items-center mb-3">
                <div class="rating mb-0 fs-14 w-25">
                  <i class="icon-star voted" v-for="index in 4" :key="index"></i>
                  <i class="icon-star"></i>
                </div>
                <div class="w-75 d-flex align-items-center">
                  <div class="progress w-75 me-3">
                    <div
                      class="progress-bar rounded-pill"
                      role="progressbar"
                      :style="{ width: `${reviewListData.length ? starCount[3]/reviewListData.length*100 : 0}%`, backgroundColor: '#D9D9D9'}"
                      aria-valuenow="25"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                    </div>
                  </div>
                  <p class="mb-0 ff-Popins400 fs-12">{{ starCount[3] }}</p>
                </div>
              </div>
              <div class="d-flex align-items-center mb-3">
                <div class="rating mb-0 fs-14 w-25">
                  <i class="icon-star voted" v-for="index in 3" :key="index"></i>
                  <i class="icon-star" v-for="index in 2" :key="index"></i>
                </div>
                <div class="w-75 d-flex align-items-center">
                  <div class="progress w-75 me-3">
                    <div
                      class="progress-bar rounded-pill"
                      role="progressbar"
                      :style="{ width: `${reviewListData.length ? starCount[2]/reviewListData.length*100 : 0}%`, backgroundColor: '#D9D9D9'}"
                      aria-valuenow="25"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                    </div>
                  </div>
                  <p class="mb-0 ff-Popins400 fs-12">{{ starCount[2] }}</p>
                </div>
              </div>
              <div class="d-flex align-items-center mb-3">
                <div class="rating mb-0 fs-14 w-25">
                  <i class="icon-star voted" v-for="index in 2" :key="index"></i>
                  <i class="icon-star" v-for="index in 3" :key="index"></i>
                </div>
                <div class="w-75 d-flex align-items-center">
                  <div class="progress w-75 me-3">
                    <div
                      class="progress-bar rounded-pill"
                      role="progressbar"
                      :style="{ width: `${reviewListData.length ? starCount[1]/reviewListData.length*100 : 0}%`, backgroundColor: '#D9D9D9'}"
                      aria-valuenow="25"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                    </div>
                  </div>
                  <p class="mb-0 ff-Popins400 fs-12">{{ starCount[1] }}</p>
                </div>
              </div>
              <div class="d-flex align-items-center mb-3">
                <div class="rating mb-0 fs-14 w-25">
                  <i class="icon-star voted"></i>
                  <i class="icon-star" v-for="index in 4" :key="index"></i>
                </div>
                <div class="w-75 d-flex align-items-center">
                  <div class="progress w-75 me-3">
                    <div
                      class="progress-bar rounded-pill"
                      role="progressbar"
                      :style="{ width: `${reviewListData.length ? starCount[0]/reviewListData.length*100 : 0}%`, backgroundColor: '#D9D9D9'}"
                      aria-valuenow="25"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                    </div>
                  </div>
                  <p class="mb-0 ff-Popins400 fs-12">{{ starCount[0] }}</p>
                </div>
              </div>
            </div>
              </div>
              

            </div>
            <div class="col-12 col-md-6">
              <p class="fs-18 mb-4 text-gray ff-Popins500">Add a review</p>
              <div class="d-flex flex-column flex-md-row mb-0">
                <p class="fs-14 mb-0 text-gray w-25 ff-Popins500">Your Review</p>
                <div class="rating mb-3 your-review-rating">
                  <i
                    v-for="index in 5"
                    :class="`icon-star ${index <= reviewRating ? 'voted' : ''}`"
                    :key="index"
                    @click="reviewRating = index"
                    style="cursor: pointer"
                  >
                  </i>
                </div>
              </div>
              <div class="d-flex flex-column flex-md-row mb-3">
                <p class="fs-14 mb-0 text-gray w-25 ff-Popins500">Your Review</p>
                <textarea class="form-control" style="border-radius:13px" id="exampleFormControlTextarea1" rows="3" v-model="reviewText"></textarea>
              </div>
              <div class="d-flex flex-column flex-md-row mb-3">
                <p class="fs-14 mb-0 text-gray w-25 ff-Popins500">Name<span class="ms-1" style="color:#FF0000">*</span></p>
                <input type="text" class="form-control" style="border-radius:43px" id="exampleFormControlInput1" v-model="reviewName" />
              </div>
              <div class="d-flex flex-column flex-md-row mb-3">
                <p class="fs-14 mb-0 text-gray w-25 ff-Popins500">Email<span class="ms-1" style="color:#FF0000">*</span></p>
                <input type="email" class="form-control" style="border-radius:43px" id="exampleFormControlInput1" v-model="reviewEmail" />
              </div>
              <div class="d-flex mb-3">
                <div class="w-25 d-none d-md-flex"></div>
                <button class="review_submit_button ff-Popins500" @click="addReview">Add Review</button>
              </div>
            </div>
          </div>
          <div class="pt-4">
            <div v-for="(data, index) in reviewListDataLimit" :key="index">
              <div>
                <div class="d-flex justify-content-start rating mb-3 fs-12">
                  <i class="icon-star voted" v-for="index in parseInt(data.rating)" :key="index"></i>
                  <i class="icon-star" v-for="index in (5 - parseInt(data.rating))" :key="index"></i>
                </div>
                <p class="text-light-gray fs-12 ff-Popins400">{{ data.reviewDescription }}</p>
                <p class="fs-14">
                  <span class="fw-bold ff-Popins500">{{ data.userName }}</span>
                  <span class="ms-2 fs-10 ff-Popins400" style="color:#A0A0A0">{{ $filters.formatReviewDate(data.createdAt) }}</span>
                </p>
              </div>
              <hr/>
            </div>
            <div class="d-flex justify-content-center">
              <button
                class="more_review ff-Popins500"
                type="button"
                v-if="isShowMoreReview && reviewListData?.length > 3"
                @click="showAllReview"
                style="cursor: pointer;"
              >
                <div class="me-2 fs-20">+ </div>
                <div>More Review</div>
              </button>
            </div>
          </div>
          
        </div>
      </div>
      <!-- review end -->

    <div class="pb-4">
      <div class="container margin_60_35" v-if="relatedProduct">
        <div class="main_title">
          <h2
            :style="{
              color:
                state.componentUi.productsDetailsComponentUI
                  .relatedProductTitleFontColor,
            }"
          >
            {{ pageCMS.pageTitle }}
          </h2>
          <span>{{ pageCMS.backgroundText }}</span>
          <p
            :style="{
              color:
                state.componentUi.productsDetailsComponentUI
                  .relatedProductSubTitleFontColor,
            }"
          >
            {{ pageCMS.pageSubTitle }}
          </p>
        </div>
        <div class="row g-4">
          <div
            class="col-6 col-md-3"
            v-for="(data, index) in relatedProduct"
            :key="index"
          >
            <div class="custom-accessories-card border p-4" style="border-radius: 20px;">
              <div class="text-end">
                <i class="ti-heart fw-bold" style="font-size:20px; cursor: pointer;" @click="processWishListData(data)"></i>
              </div>
              <div class="d-flex justify-content-center">
                <img
                  class="card-img-box" 
                  :src="imagePath + data?.featuredImage"
                  :data-src="imagePath + data?.featuredImage"
                  :alt="data?.name"
                  style="cursor: pointer;"
                  @click="$router.push(data.urlSlug)"
                />
              </div>
              <div class="text-center">
                <div class="d-flex justify-content-center rating mb-2 gap-1">
                <i class="icon-star voted" v-for="index in (data?.rating ? data.rating : 0)" :key="index"></i>
                <i class="icon-star" v-for="index in (5 - (data?.rating ? data.rating : 0))" :key="index"></i>
              </div>
                <p
                  class="mb-0 fs-18 text-gray line-clamp-1 ff-Popins500"
                  style="cursor: pointer;"
                  @click="$router.push(data.urlSlug)"
                >
                  {{ data.name }}
                </p>
                <p class="fs-20 fw-bold ff-Popins500">${{ findMixinActualPrice(data) }}</p>
              </div>
              <div class="text-center">
                <button class="accessories-add-to-cart-button rounded-pill fs-12 ff-Popins400" @click="processCartData(data)">
                  <i class="ti-shopping-cart me-1"></i>
                  <span class="align-middle ">&nbsp;Add to Cart</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /container -->
    </div>
    <!-- /bg_white -->
  </main>
  <Advertise
    v-if="
      state.advertiseList.includes('ProductDetails') &&
      state.isAdvertise == true
    "
  />
  <NewsLetter
    v-if="
      state.newsletterList.includes('ProductDetails') &&
      state.isNewsLetter == true
    "
  />
  <ProductDetailSchemaJson
    v-bind:prodData="productDetails"
    v-bind:prodReviewData="reviewListData"
    v-bind:productMeta="productMeta"
  ></ProductDetailSchemaJson>
</template>

<script src="../js/product-details.js"></script>
<style scoped>
@import url("../css/carousel.css");
.carousel__prev,
.carousel__next {
  background: #000 !important;
}

.carousel__prev:hover,
.carousel__next:hover {
  background: #000 !important;
}

.carousel__prev {
  left: 0px !important;
  transform: translateY(-50%) translateX(0) !important;
}

.carousel__next {
  right: 0px !important;
  transform: translateY(-50%) translateX(0) !important;
}
@font-face {
  font-family: Roboto400;
  src: url('../../../../public/fonts/Roboto-Regular.ttf');
}

@font-face {
  font-family: Roboto700;
  src: url('../../../../public/fonts/Roboto-Medium.ttf');
}

@font-face {
  font-family: Roboto500;
  src: url('../../../../public/fonts/Roboto-Bold.ttf');
}
@font-face {
  font-family: Popins400;
  src: url('../../../../public/fonts/Poppins-Regular.ttf');
}

@font-face {
  font-family: Popins700;
  src: url('../../../../public/fonts/Poppins-Bold.ttf');
}

@font-face {
  font-family: Popins500;
  src: url('../../../../public/fonts/Poppins-Medium.ttf');
}
.ff-Roboto400 {
  font-family: Roboto400, sans-serif !important;
}
.ff-Roboto700 {
  font-family: Roboto700, sans-serif !important;
}
.ff-Roboto500 {
  font-family: Roboto500, sans-serif !important;
}
.ff-Popins400 {
  font-family: Popins400, sans-serif !important;
}
.ff-Popins700 {
  font-family: Popins700, sans-serif !important;
}
.ff-Popins500 {
  font-family: Popins500, sans-serif !important;
}
.fs-10{
  font-size:10px !important;
}
.fs-12{
  font-size: 12px !important;
}
.fs-14{
  font-size: 14px;
}
.fs-16 {
  font-size: 16px;
}
.fs-18{
  font-size:18px;
}
.fs-20{
  font-size:20px;
}
.rounded-18{
  border-radius:18px !important;
}
.line-clamp-1 {
  display: -webkit-box;
  -webkit-line-clamp:1;
  -webkit-box-orient:vertical;
  overflow:hidden;
}
.line-clamp-4 {
  display: -webkit-box;
  -webkit-line-clamp:4;
  -webkit-box-orient:vertical;
  overflow:hidden;
}
.text-gray{
  color:#343F49 !important;
}
.text-light-gray{
  color:#646464 !important;
}
.button-quantity{
  border:none !important;
  background:transparent !important;
}
.dropdown-container-size{
  /* border:none !important; */
  width:212px;
  height:36px;
  background:transparent !important;
}
.dropdown-toggle::after{
  margin-left:50px !important;
  margin-right:10px !important;
}
.add-to-cart-button{
  border:1px solid #303CD5;
  background-color:#303CD5;
  color:white;
  width:212px;
  height:40px;
}
.add-to-cart-button:hover{
  background-color:transparent !important;
  color:#303cd5;
}
.add-to-favourites-button{
  border:2px solid #646464;
  background-color:transparent !important;
  color:#646464 !important;
  width:212px;
  height:40px;
}
.add-to-favourites-button:hover{
  background-color:#646464 !important;
  color:white !important;
}
.accessories-text{
  font-size:25px !important;
}
.custom-accessories-card{
  transition: transform 0.5s;
  
}
.custom-accessories-card:hover{
  transform: scale(1.02);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}
.accessories-add-to-cart-button{
  border:1px solid #303CD5;
  background-color:transparent;
  color:#303CD5;
  width:212px;
  height:40px;
}
.accessories-add-to-cart-button:hover{
  background-color:#303cd5 !important;
  color:white;
}
.tag_text{
  font-size:14px;
}
.tab_main_image{
  width:300px;
  object-fit:cover;
}
.review_section{
  padding-top:50px;
}
.review_submit_button{
  width:145px;
  height:48px;
  background-color:#FCDC3A;
  color:black;
  border:none;
  font-size:12px;
  font-weight:500 !important;
  border-radius:50px;
  margin-left:-30px;
}
.reviewbar-section{
  margin-top:130px;
  width:450px;
}
.your-review-rating{
  font-size: 15px;
  margin-left: -30px;
}
.more_review{
  display:flex;
  justify-content:center;
  align-items:center;
  background-color:transparent;
  color:#646464;
  border:none;
  font-size:14px;
}
.card-img-box{
  width:150px;
  height:200px;
  object-fit:contain;
}
.details-main-img{
  max-width:500px;
  /* object-fit:cover;
  max-height: 335px; */
  object-fit: contain;
  max-height: 335px;
}
.zoom-img {
  height: 550px;
  widows: 900px;
}
.price_section {
  display: flex;
  justify-content: space-between !important;
}

.price_main label {
  text-align: left;
}
.cart_button {
  place-content: end;
}
.quantity_b {
  display: flex;
  place-content: flex-end;
}

.image-accessory {
  height: 120px;
  width: 120px;
  object-fit: cover;
}

.product-selected {
  border: 2px double #6ce76c;
}
/*Accessories-card*/
/* .Accessories-card{
  box-shadow: none;
  border: 1px solid rgba(0, 0, 0, 0.175);
  border-radius: 0;
} */
/* .Accessories-card:hover{
   transform: translateY(0)!important;
   transition-duration: 0.3s;
   box-shadow: none;
   cursor: pointer;
} */
/* .Accessories-card .card-header{
  box-shadow: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.175);
  border-radius: 0;
  background-color: rgba(0, 0, 0, 0.03);
  padding: 0.5rem 1rem;
} */
/* .Accessories-card .card-body{
  max-height: 400px!important;
  overflow-y: auto;
} */
/* .Accessories-card-item{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
} */
/* .Accessories-card-item:hover{
  transform: translateY(0)!important;
  transition-duration: 0.3s;
  box-shadow: 0 2px 6px 0 rgb(218 218 253 / 65%), 0 2px 6px 0 rgb(206 206 238 / 54%)!important;
  cursor: pointer;
} */
.Accessories-card-item_price{
  color: #000000;
  font-weight: 700;
  font-size: 1rem;
  font-family: inherit;
}
.Accessories-card-item_check-icon{
  position: absolute;
  top:50%;
  right: 10px;
  color: #6ce76c!important;
  transform: translateY(-50%);
  font-weight: 900;
  font-size: 20px;
}
.Accessories-card .card-body::-webkit-scrollbar {
  width: 0.4em;
}

.Accessories-card .card-body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.Accessories-card .card-body::-webkit-scrollbar-thumb {
  background-color: #009245;
  outline: 1px solid #009245;
  border-radius: 5px;
}
.nav-tabs{
  border-bottom: 2px solid transparent;
}

@media (min-width: 1000px) {
  .accessories-position {
    position: relative;
    transform: translateY(-100px);
  }
}
@media (max-width: 768px) {
  .dropdown-container-size{
    width:100% !important;
  }
  .dropdown-toggle::after{
    margin-left:55% !important;
  }
  .add-to-cart-button{
    width:100%;
  }
  .add-to-favourites-button{
    width:100%;
  }
  .accessories-text{
    font-size:20px !important;
  }
  .accessories-add-to-cart-button{
    width:100%;
  }
  .tag_text{
    font-size:10px;
  }
  .review_section{
    padding-top:10px;
  }
  .review_submit_button{
    margin-left:0;
    width:100%;
  }
  .card-img-box{
    width:100px;
    height:100px;
  }
  .reviewbar-section{
    margin-top:0px;
    width:100vw;
  }
  .details-main-img{
    width:300px;
    object-fit:cover;
  }
  .your-review-rating{
    margin-top: 7px;
    margin-left:0px;
  }
}

</style>
