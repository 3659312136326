<template>
    <teleport to="head">
        <link rel="canonical" :href="$route.path" />
        <meta name="keywords" :content="metaKeyword">
        <meta name="description" :content="metaDescription">
        <meta name="author" content="HGSHYDRO">
        <meta name="publisher" content="HGSHYDRO">
    </teleport>
</template>

<script>
export default {
    name: 'ContactSchemaJson',
    props: ['metaKeyword', 'metaDescription'],
    data() {
        return {
           homeMetaKeyword: "",
           homeMetaDescription: ""
        }
    },
    watch:{
        metaKeyword: {
            handler: function(metaKeyword) {
                this.homeMetaKeyword = metaKeyword;
            }
        },
        metaDescription: {
            handler: function(metaDescription) {
                this.homeMetaDescription = metaDescription;
            }
        }
    }
}   
</script>
