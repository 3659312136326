export default {
  components: {
  },
  data() {
    return {
      resetFlag: false,
      passToken: this.$route.params.token,
      password: '',
      confirm_password: ''
    }
  },
  async mounted() {
    await this.getPageTitles();
    document.title = (this.pageTitles?.accountPageTitle ? this.pageTitles?.accountPageTitle : '');
    await this.verifyPassToken(this.passToken);
    this.loadJSCSS();
  },
  methods: {
    loadJSCSS: function () {
      var styles = [
        '/core/assets/css/account.css'
      ]
      styles.forEach(style => {
        let tag = document.createElement('link');
        tag.type = "text/css";
        tag.rel = "stylesheet";
        tag.href = style;
        document.head.appendChild(tag);
      });
      var scripts = [
        "/core/assets/js/main.js",
      ];
      scripts.forEach(script => {
        let tag = document.createElement("script");
        tag.setAttribute("src", script);
        document.head.appendChild(tag);
      });
    },
    async verifyPassToken(hashPass) {
      try {
        let data = {
          hashPass: hashPass,
        };
        let config = {
          method: "POST",
          data: data,
          url: this.$serverURL + this.$api.auth.verifyToken,
        };
        await this.$axios(config)
          .then((response) => {
            if (response.status == 201 && response.data.data) {
              this.resetFlag = true;
            }
            else {
              this.$swal.fire({
                icon: "error",
                text: "Invalid reset password link.",
              });
              this.$router.push('/account');
            }
          })
          .catch((error) => {
            this.$swal.fire({
              icon: "error",
              text: error.response.data.message
            });
            this.$router.push('/account');
          });
      } catch (error) {
        console.log(error);
        this.$swal.fire({
          icon: "error",
          text: "Something went wrong, please try agian later."
        });
      }
    },
    updatePassword: async function () {

      if (this.password == "") {
        this.$swal.fire({
          icon: "error",
          text: "Please enter your new password!",
        });
      } else if (this.confirm_password == "") {

        this.$swal.fire({
          icon: "error",
          text: "Please enter your confirm new password!",
        });
      } else if (this.password != this.confirm_password) {

        this.$swal.fire({
          icon: "error",
          text: "New Password & Confirm New Password does not match!",
        });
      } else {
        if (this.password.length < 6) {

          this.$swal.fire({
            icon: "error",
            text: "You have to enter at least 6 digit!",
          });
        } else {

          let data = {
            passToken: this.passToken,
            password: this.password,
          };
          try {
            let config = {
              method: "POST",
              data: data,
              url: this.$serverURL + this.$api.auth.updatePasswordVerified,
            };
            this.$axios(config)
              .then((response) => {
                if (response.status == 201 && response.data.data) {

                  this.$swal.fire({
                    icon: "success",
                    text: "Your password updated successfully",
                  });
                  this.$router.push('/account');
                }
              })
              .catch((error) => {
                this.$swal.fire({
                  icon: "error",
                  text: error.response.data.message
                });
              });
          } catch (error) {
            this.$swal.fire({
              icon: "error",
              text: "Failed!! Please try again",
            });
          }
        }
      }
    },
  }
}