<template>
<main class="bg_gray">
		<div class="container margin_30">
			<div class="page_header">
				<div class="breadcrumbs">
					<ul>
						<li><a :href="'/'" title="Back to Home">Home</a></li>
						<li><a :href="'/blog'" title="Blogs">Blogs</a></li>
						<li>{{ singleBlog.title }}</li>
					</ul>
				</div>
			</div>
			<!-- /page_header -->
			
			<div class="row">
				<div class="col-lg-9">
					<div class="singlepost">
						<figure>
							<img 
								:alt="singleBlog.title" 
								:title="singleBlog.title" 
								class="img-fluid" 
								:src="placeholderLazyLoading" 
								v-lazy="imagePath + singleBlog.photo" 
								style="width: 100%;" 
							/>
						</figure>
						<h1>{{singleBlog.title}}</h1>
						<div class="postmeta">
							<ul>
								<li><a href="#"><i class="ti-folder"></i>{{singleBlog.categoryName}}</a></li>
								<li><i class="ti-calendar"></i> {{formatBlogDate(singleBlog.createdAt)}}</li>
								<li><a href="#"><i class="ti-user"></i> {{singleBlog.postedBy}}</a></li>
								<li><a href="#"><i class="ti-comment"></i> ({{singleBlog.totalComments}}) Comments</a></li>
							</ul>
						</div>
						<!-- <div class="post-content">
							<p v-html="singleBlog.shortDetails"></p>
						</div> -->
						<!-- /post meta -->
						<div class="post-content">
							<p v-html="singleBlog.longDetails"></p>
						</div>
						<!-- /post -->
					</div>
					<!-- /single-post -->

					<div id="comments">
						<h5>Comments ({{singleBlog.totalComments}})</h5>
						<ul>
							<li v-for="(data, index) in blogComments" :key="index">
								<div class="avatar">
									<a href="#"><img src="/core/assets/img/avatar1.jpg" alt="">
									</a>
								</div>
								<div class="comment_right clearfix">
									<div class="comment_info">
										By <a href="#">{{data.name}}</a><span>|</span>{{formatBlogDate(data.createdAt)}}<span>|</span><a href="javascript:void(0)"><i class="icon-reply"></i></a>
									</div>
									<p>
										{{data.comment}}
									</p>
								</div>
							</li>
						</ul>
					</div>

					<hr>

					<h5>Leave a comment</h5>
					<div class="row">
						<div class="col-md-4 col-sm-6">
							<div class="form-group">
								<input type="text" v-model="name" name="name" id="name2" class="form-control" placeholder="Name">
							</div>
						</div>
						<div class="col-md-4 col-sm-6">
							<div class="form-group">
								<input type="email" v-model="email" id="email2" class="form-control" placeholder="Email">
							</div>
						</div>
						<div class="col-md-4 col-sm-12">
							<div class="form-group">
								<input type="text" v-model="website"  id="website3" class="form-control" placeholder="Website">
							</div>
						</div>
					</div>
					<div class="form-group">
						<textarea class="form-control" v-model="comment" name="comments" id="comments2" rows="30" placeholder="Comment"></textarea>
					</div>
					<div class="form-group">
						<button type="submit" @click="sendComment" id="submit2" class="btn_1 add_bottom_15">Submit</button>
					</div>
					
				</div>
				<!-- /col -->

				<aside class="col-lg-3">
					<div class="widget search_blog">
						<div class="form-group">
							<input type="text" name="search" id="search" class="form-control" placeholder="Search..">
							<button type="submit"><i class="ti-search"></i></button>
						</div>
					</div>
					<!-- /widget -->
					<div class="widget">
						<div class="widget-title">
							<h4>Latest Post</h4>
						</div>
						<ul class="comments-list">
							<li v-for="(data, index) in latestBlogs" :key="index">
								<div class="alignleft">
									<a :title="data.title" href="javascript:void(0)" @click="$router.push('/blog-details/' + data.urlSlug)">
										<img  :src="placeholderLazyLoading" v-lazy="imagePath + data.photo" 
											:alt="data.title" 
											:title="data.title"
										/>
									</a>
								</div>
								<small>{{data.categoryName}} - {{$filters.formatDate(data.createdAt)}}</small>
								<h3><a href="javascript:void(0)" @click="$router.push('/blog-details/' + data.urlSlug)">{{data.title}}</a></h3>
							</li>
						</ul>
					</div>
					<!-- /widget -->
					<div class="widget">
						<div class="widget-title">
							<h4>Categories</h4>
						</div>
						<ul class="cats">
							<li v-for="(data, index) in blogCategoryList" :key="index">
								<a href="javascript:void(0)" @click="$router.push({name: 'Blog', query: {categoryId: data.id}})">{{data.categoryName}} <span>({{ data.count }})</span></a>
							</li>
						</ul>
					</div>
					<!-- /widget -->
					<div class="widget">
						<div class="widget-title">
							<h4>Popular Tags</h4>
						</div>
						<div class="tags">
							<a class="me-2" href="javascript:void(0)" @click="$router.push({name: 'Blog', query: {tag: data.tagName}})" v-for="(data, index) in blogTagList" :key="index">{{data.tagName}}</a>
						</div>
					</div>
					<!-- /widget -->
				</aside>
				<!-- /aside -->
			</div>
			<!-- /row -->
		</div>
		<!-- /container -->
	</main>
	<Advertise v-if="state.advertiseList.includes('BlogDetails') && state.isAdvertise==true"/>
    <NewsLetter v-if="state.newsletterList.includes('BlogDetails') && state.isNewsLetter==true"/>
	<BlogDetailsSchemaJson :metaData="seoMeta" />
</template>
<script src="../js/blog-details.js"></script>