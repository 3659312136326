<template>
    <main class="bg_gray" :style="{'background-color': state.componentUi.contactComponentUI.pageBackgroundColor}">

        <div class="container margin_60">
            <div class="main_title">
                <h2 :style="{color: state.componentUi.contactComponentUI.headerTitleFontColor}">{{pageCMS.title}}</h2>
                <p :style="{color: state.componentUi.contactComponentUI.headerSubTitleFontColor}">{{pageCMS.subTitle}}.</p>
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-4" v-for="(data, index) in contentData" :key="index">
                    <div class="box_contacts">
                        <i :class="data.icon"></i>
                        <h2>{{data.title}}</h2>
                        <div class="container" v-html="data.description.replace(/(&gt;)(?:&nbsp;|<br>)+(\s?&lt;)/g,'$1$2')"></div>
                        
                    </div>
                </div>
                
            </div>
            <!-- /row -->
        </div>
        <!-- /container -->
        <div class="bg_white" :style="{'background-color': state.componentUi.contactComponentUI.pageBackgroundColor}">
            <div class="container margin_60_35">
                <h4 class="pb-3" :style="{color: state.componentUi.contactComponentUI.formTitleFontColor}">{{pageCMS.contactFormTitle}}</h4>
                <div class="row">
                    <div class="col-lg-4 col-md-6 add_bottom_25">
                        <div class="form-group">
                            <input class="form-control" v-model="name" type="text" placeholder="Name *">
                        </div>
                        <div class="form-group">
                            <input class="form-control" v-model="email" type="email" placeholder="Email *">
                        </div>
                        <div class="form-group">
                            <textarea class="form-control" v-model="message" style="height: 150px;" placeholder="Message *"></textarea>
                        </div>
                        <div class="form-group">
                            <div class="sample-captcha">
                                <VueClientRecaptcha
                                :value="inputValue"
                                @getCode="getCaptchaCode"
                                @isValid="checkValidCaptcha"
                                />
                                <input
                                type="text"
                                class="input__field mt-2"
                                v-model="inputValue"
                                placeholder="Enter captcha code*"
                                />
                                <p class="text-success mt-2" v-if="isSubmit">
                                <strong>Captcha Verified!</strong>
                                </p>
                                <p class="mt-2" style="color: red;" v-else>Please verify captcha!</p>
                            </div>
                            </div>
                        <div class="form-group">
                            <input class="btn_1 full-width" :disabled="!isSubmit" @click="sendMessage" type="button" :value="pageCMS.buttonText">
                        </div>
                    </div>
                    <div class="col-lg-8 col-md-6 add_bottom_25">
                        <iframe class="map_contact"
                            :src="pageCMS.googleMapLink"
                            style="border: 0" allowfullscreen></iframe>
                    </div>
                </div>
                <!-- /row -->
            </div>
            <!-- /container -->
        </div>
        <!-- /bg_white -->
    </main>
    <Advertise v-if="state.advertiseList.includes('Contact') && state.isAdvertise==true"/>
    <NewsLetter v-if="state.newsletterList.includes('Contact') && state.isNewsLetter==true"/>
    <ContactSchemaJson v-if="Object.values(pageTitles).length>0" :metaData="pageTitles" />
</template>
<style>

.vue_client_recaptcha {
  display: flex;
  justify-content: left;
  flex-direction: row;
}

.vue_client_recaptcha_icon {
  text-align: center;
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #eee;
}

.vue_client_recaptcha_icon:hover {
  background-color: #cccccc;
}

.vue_client_recaptcha .captcha_canvas {
  background: #eee;
  padding: 10px 0px;
}
</style>
<script src="../js/contact.js"></script>