import { createRouter, createWebHistory } from 'vue-router'
import additionalRoutes from './additional-routes';
import Guard from '@/guard';
import Home from '../components/Home/template/Home';
// import IntroPage from '../components/IntoPage/template/IntroPage';
import Blog from '../components/Blog/template/Blog';
import BlogDetails from '../components/Blog/template/BlogDetails';
import Product from '../components/Product/template/Product';
import Sale from '../components/Product/template/Sale';
import ProductDetails from '../components/Product/template/ProductDetails';
import ProductReview from '../components/Product/template/ProductReview';
import Cart from '../components/Cart/template/Cart';
import Wishlist from '../components/Wishlist/template/Wishlist';
import TrackOrder from '../components/TrackOrder/template/TrackOrder';
// import Checkout from '../components/Checkout/template/Checkout';
import AboutUs from '../components/About/template/AboutUs';
import Help from '../components/Help/template/Help';
import Contact from '../components/Contact/template/Contact';
import Account from '../components/Auth/template/Account';
import ResetPassword from '../components/Auth/template/ResetPassword';
import NotFoundPage from '../components/404Page/template/404Page';
import Profile from '../components/Dashboard/template/Profile';
import Dashboard from '../components/Dashboard/template/Dashboard';
import PendingOrders from '../components/Dashboard/template/PendingOrders';
import ProcessingOrders from '../components/Dashboard/template/ProcessingOrders';
import ShippedOrders from '../components/Dashboard/template/ShippedOrders';
import CancelledOrders from '../components/Dashboard/template/CancelledOrders';
import TotalOrders from '../components/Dashboard/template/TotalOrders';
import DeliveredOrders from '../components/Dashboard/template/DeliveredOrders';
import ChangePassword from '../components/Dashboard/template/ChangePassword';
import Invoice from '../components/Dashboard/template/Invoice';
import PrivacyPolicy from "../components/Policies/template/PrivacyPolicy"
import ReturnPolicy from "../components/Policies/template/ReturnPolicy"
import CancellationPolicy from "../components/Policies/template/CancellationPolicy"
import TeramsAndCondition from "../components/Policies/template/TeramsAndCondition";
import WhyCHooseUsPage from "../components/Policies/template/whyChooseUs";
import Location from "../components/location/template/Location";
import LocationDetails from "../components/location/template/LocationDetails";
import Franchise from "../components/Franchise/template/Franchise";
import Career from "../components/Career/template/Career";
import TaxExempt from "../components/Dashboard/template/TaxExempt";
import ReturnList from "../components/Dashboard/template/ReturnList";
import Quote from "../components/Quote/template/Quote";
import Success from "../components/Success/template/Success";
import OrderConfirm from '../components/order-confirm/template/OrderConfirm';
import CheckoutBilling from "../components/Checkout/template/CheckoutBilling";
import CheckoutShipping from "../components/Checkout/template/CheckoutShipping";
import CheckoutShippingMethods from "../components/Checkout/template/CheckoutShippingMethods";
import CheckoutPayment from "../components/Checkout/template/CheckoutPayment";
import CheckoutCompleteOrder from "../components/Checkout/template/CheckoutCompleteOrder";


let routes = [
  { path: '/', name: "Home", component:Home},
  { path: '/home', redirect: {name:"Home"} },
  { path: '/account', name: "Account", component: Account },
  { path: '/reset-password/:token', name: "ResetPassword", component: ResetPassword },
  { path: '/about-us', name: "AboutUs", component: AboutUs },
  { path: '/help', name: "Help", component: Help },
  { path: '/contact', name: "Contact", component: Contact },
  { path: '/career', name: "Career", component: Career },
  { path: '/blog', name: "Blog", component: Blog },
  { path: '/blog-details/:blogSlug', name: "BlogDetails", component: BlogDetails },
  // checkout
  { path: '/checkout-billing', name: "CheckoutBilling", component: CheckoutBilling },
  { path: '/checkout-shipping', name: "CheckoutShipping", component: CheckoutShipping },
  { path: '/checkout-payment', name: "CheckoutPayment", component: CheckoutPayment },
  { path: '/checkout-shipping-methods', name: "CheckoutShippingMethods", component: CheckoutShippingMethods },
  { path: '/checkout-complete-order', name: "CheckoutCompleteOrder", component: CheckoutCompleteOrder },


  { path: "/category/:categorySlug", name: "CategoryProduct", component: Product,
    beforeEnter: (to, from, next) => {
      const lowerCaseSlug = to.params.categorySlug.toLowerCase().replace(/[^a-z0-9]+/g, '-').replace(/(^-|-$)+/g, '');
      if (to.params.categorySlug !== lowerCaseSlug) {
        next({ path: '/category/' + lowerCaseSlug });
      } else {
        next();
      }
    }
  },
  { path: "/sub-category/:subCategorySlug", name: "SubCategoryProduct", component: Product,
  beforeEnter: (to, from, next) => {
      const lowerCaseSlug = to.params.subCategorySlug.toLowerCase().replace(/[^a-z0-9]+/g, '-').replace(/(^-|-$)+/g, '');
      if (to.params.subCategorySlug !== lowerCaseSlug) {
        next({ path: '/sub-category/' + lowerCaseSlug });
      } else {
        next();
      }
    }
  },
  { path: '/products', name: "Product", component: Product },

  { path: '/sale', name: "Sale", component: Sale },
  { path: '/deals', name: "deals", component: Sale },
  { path: '/quote', name: "Quote", component: Quote },
  { path: '/quotes', name: "Quotes", component: Quote },
  { path: '/success', name: "Success", component: Success, props: true },

  { path: '/product-details/:id', name: "ProductDetails", component: ProductDetails },

  { path: '/product-review/:productId', name: "ProductReview", component: ProductReview },
  
  { path: '/cart', name: "Cart", component: Cart },
  { path: '/wishlist', name: "Wishlist", component: Wishlist },
  // { path: '/checkout', name: "Checkout", component: Checkout },
  { path: '/track-order', name: "TrackOrder", component: TrackOrder, beforeEnter: Guard },
  { path: '/profile', name: "Profile", component: Profile, beforeEnter: Guard },
  { path: '/dashboard', name: "Dashboard", component: Dashboard, beforeEnter: Guard },
  { path: '/pending-orders', name: "PendingOrders", component: PendingOrders, beforeEnter: Guard },
  { path: '/processing-orders', name: "ProcessingOrders", component: ProcessingOrders, beforeEnter: Guard },
  { path: '/shipped-orders', name: "ShippedOrders", component: ShippedOrders, beforeEnter: Guard },
  { path: '/cancelled-orders', name: "CancelledOrders", component: CancelledOrders, beforeEnter: Guard },
  { path: '/total-orders', name: "TotalOrders", component: TotalOrders, beforeEnter: Guard },
  { path: '/delivered-orders', name: "DeliveredOrders", component: DeliveredOrders, beforeEnter: Guard },
  { path: '/change-password', name: "ChangePassword", component: ChangePassword, beforeEnter: Guard },
  { path: '/order-details/:id', name: "Invoice", component: Invoice, beforeEnter: Guard },
  { path: '/franchise', name: "Franchise", component: Franchise },
  { path: '/return-list', name: "ReturnList", component: ReturnList, beforeEnter: Guard },
  { path: '/order-confirm/:id', name: "OrderConfirm", component: OrderConfirm },

  { path: '/404', name: "404Page", component: NotFoundPage, meta: { statusCode: 404 } },
  { path: '/:pathMatch(.*)*', redirect: { name: '404Page' } },
  //{ path: '/:pathMatch(.*)*', redirect: '/404', beforeEnter() { window.location.href = "/404" } },

  //policies
  { path: '/privacy-policy', name: "PrivacyPolicy", component: PrivacyPolicy },
  { path: '/return-policy', name: "ReturnPolicy", component: ReturnPolicy },
  { path: '/cacellation-policy', name: "CancellationPolicy", component: CancellationPolicy },
  { path: '/terms-conditions', name: "TermsAndCondition", component: TeramsAndCondition },

  { path: '/location', name: "Location", component: Location },
  { path: '/location-details/:url', name: "LocationDetails", component: LocationDetails },
  { path: '/tax-exempt', name: "TaxExempt", component: TaxExempt, beforeEnter: Guard },

  //Why choose Us
  { path: '/why-choose-us/:slug', name: "whyCHooseUsPage", component: WhyCHooseUsPage },
  ...additionalRoutes
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router