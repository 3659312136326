<template>
  <main class="bg_gray">
    <div class="container margin_30">
      <div class="page_header">
        <div class="breadcrumbs">
          <ul>
            <li>
              <a href="javascript:void(0)" @click="$router.push('/home')">Home</a>
            </li>
            <li>
              <a href="#">Checkout</a>
            </li>
          </ul>
        </div>
        <h1 class="text-center">Please Choose Your Shipping Method</h1>
      </div>
      <div class="container custom-width">
        <!-- Order Summery -->
        <CheckoutSummery :shippingCostNew="shippingCost" />
        <!-- Order Summery -->
        <div class="border p-3" style="background-color: white;">
          <h5 class="fw-bold" v-if="shippingMethodList.length > 0">Shipping Method</h5>
          <div class="mt-4">
            <div class="form-check" v-if="shippingMethodList.length > 0">
              <input
                class="form-check-input"
                style="cursor:pointer"
                type="radio"
                v-model="shippingType"
                value="ups"
                :checked="shippingType === 'ups'"
                name="shipping"
                id="flexRadioDefault2"
                @change="handleShippingOnChange"
              />
              <label
                class="form-check-label"
                for="flexRadioDefault1"
              >{{ shippingCmsData.upsDisplayName }}</label>
              <a href="javascript:void(0)" class="float-end">
                <img
                  v-if="shippingCmsData.upsImage"
                  :src="imagePath + shippingCmsData.upsImage"
                  width="70"
                  height="34"
                  alt
                />
                <img v-else src="core/assets/img/no_image.jpg" width="70" height="34" alt />
              </a>
            </div>
            <div v-else>
              <h5 class="fw-bold">Select UPS Shipping Services</h5>
            </div>
            <hr class="hr-padding" />
            <div class="form-check" v-if="shippingMethodList.length > 0">
              <input
                class="form-check-input"
                style="cursor:pointer"
                type="radio"
                v-model="shippingType"
                value="local"
                name="shipping"
                id="flexRadioDefault3"
                @change="handleShippingOnChange"
              />
              <label
                class="form-check-label"
                for="flexRadioDefault1"
              >{{ shippingCmsData.localDisplayName }}</label>
              <a href="javascript:void(0)" class="float-end">
                <img
                  v-if="shippingCmsData.localImage"
                  :src="imagePath + shippingCmsData.localImage"
                  width="70"
                  height="34"
                  alt
                />
                <img v-else src="core/assets/img/no_image.jpg" width="70" height="34" alt />
              </a>
            </div>
            <hr class="hr-padding" />
            <div v-if="!isServiceLoading">
              <h6 class="pb-2">Service Type</h6>
              <div class="row no-gutters" v-if="shippingType == 'fedex'">
                <div class="form-group">
                  <ul>
                    <li v-for="(data, index) in serviceTypeList" :key="index">
                      <label class="container_radio">
                        {{
                        data.serviceName
                        }}
                        <a
                          href="javascript:void(0)"
                          class="float-end"
                          style="color: black"
                        >${{ data.price }}</a>
                        <input
                          type="radio"
                          v-model="serviceType"
                          @click="setShippingCost(data)"
                          :value="data.serviceType"
                          :checked="data.serviceType == serviceType"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="row no-gutters" v-if="shippingType == 'ups'">
                <div class="form-group">
                  <ul style="list-style-type: none;">
                    <li v-for="(data, index) in serviceTypeList" :key="index">
                      <label class="container_radio">
                        {{
                        data.serviceName
                        }}
                        <a
                          href="javascript:void(0)"
                          class="float-end"
                          style="color: black"
                        >${{ data.price }}</a>
                        <input
                          type="radio"
                          v-model="serviceType"
                          :checked="data.serviceType === serviceType"
                          @click="setShippingCost(data.price, data.serviceName)"
                          :value="data.serviceType"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="row no-gutters" v-if="shippingType == 'local'">
                <div class="form-group">
                  <ul>
                    <li v-for="(data, index) in shippingMethodList" :key="index">
                      <label class="container_radio">
                        {{ data.name }}
                        <a
                          href="javascript:void(0)"
                          class="float-end"
                          style="color: black"
                        >${{ data.price }}</a>
                        <input
                          type="radio"
                          v-model="serviceType"
                          @click="setShippingCost(data.price, data.name)"
                          :value="data.name"
                          name="localShipping"
                          :checked="data.name === serviceType"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div v-if="isServiceLoading">
              <div class="d-flex p-2 justify-content-center">
                <div class="spinner-border" role="status">
                  <span class="visually-hidden"></span>
                </div>
              </div>
            </div>
          </div>
          <!-- <div v-if="state.isFreeShippingApplied">
            <div style="display: flex">
              <p>
                <span class="me-1" style="color:red">*</span>
                Free shipping coupon is applied.
              </p>
            </div>
            <div style="display: flex">
              <p>
                <span class="me-1" style="color:red">*</span>
                Coupon Code ( {{state.freeShippingCoupon.couponCode}} )
              </p>
            </div>
          </div>-->
        </div>
        <div
          class="d-flex flex-column flex-lg-row justify-content-between align-items-center gap-2 gap-lg-5 mt-4"
        >
          <button  style="background: #172134; color: #fff;"
            type="button"
            @click="$router.push(shippingSameAsBilling ? 'checkout-billing' : '/checkout-shipping')"
            class="btn_1  btn-checkout-back  full-width  d-none d-sm-block"
          ><i class="ti-back-left"></i> BACK </button>
          <button
            type="button"
            @click="saveAndProceed()"
            class="btn_1 full-width checkout-shipping-method-fillup"
            :disabled="state.weightExided"
          ><i class="ti-back-right"></i> PROCEED NEXT</button>
          <button  style="background: #172134; color: #fff;"
            type="button"
            @click="$router.push(shippingSameAsBilling ? 'checkout-billing' : '/checkout-shipping')"
            class="btn_1  btn-checkout-back  full-width  d-block d-sm-none"
          ><i class="ti-back-left"></i> BACK </button>
        </div>
      </div>
    </div>
    <!-- /page_header -->
  </main>
  <CheckoutSchemajson v-if="Object.values(pageTitles).length > 0" :metaData="pageTitles" />
</template>
  <script src="../js/checkoutShippingMethods.js"></script>
  <style scoped>
@import "../css/checkout.css";

.hr-padding {
  margin: 15px 0 15px 0 !important;
  border: 0;
  border-top: 1px solid #dddddd;
}

.btn-checkout-back:hover
{
  background-color: #FFC107 !important;
  color: #111 !important;
}
</style>