import siderbar from '../template/SideBar'
import { authHeader } from '@/auth';
export default {
  components: {
    siderbar
  },
  data() {
    return {
      resetFlag: false,
      passToken: this.$route.params.token,
      old_password: '',
      password: '',
      confirm_password: ''
    }
  },
  async mounted() {
    await this.getPageTitles();
    document.title = (this.pageTitles?.profilePageTitle ? this.pageTitles?.profilePageTitle : '');
    this.loadJSCSS();
  },
  methods: {
    loadJSCSS: function () {
      var styles = [
        '/core/assets/css/account.css'
      ]
      styles.forEach(style => {
        let tag = document.createElement('link');
        tag.type = "text/css";
        tag.rel = "stylesheet";
        tag.href = style;
        document.head.appendChild(tag);
      });
      var scripts = [
        "/core/assets/js/main.js",
      ];
      scripts.forEach(script => {
        let tag = document.createElement("script");
        tag.setAttribute("src", script);
        document.head.appendChild(tag);
      });
    },
    updatePassword: async function () {

      if (this.old_password == "") {
        this.$swal.fire({
          icon: "error",
          text: "Please enter your old password!",
        });
      }
      else if (this.password == "") {
        this.$swal.fire({
          icon: "error",
          text: "Please enter your new password!",
        });
      } else if (this.confirm_password == "") {

        this.$swal.fire({
          icon: "error",
          text: "Please enter your confirm new password!",
        });
      } else if (this.password != this.confirm_password) {

        this.$swal.fire({
          icon: "error",
          text: "New Password & Confirm New Password does not match!",
        });
      } else {
        if (this.password.length < 6) {

          this.$swal.fire({
            icon: "error",
            text: "You have to enter at least 6 digit!",
          });
        } else {

          let data = {
            currentPassword: this.old_password,
            newPassword: this.password,
          };
          try {
            let config = {
              method: "POST",
              data: data,
              url: this.$serverURL + this.$api.auth.changePassword,
              headers: {
                "Authorization": authHeader()
              }
            };
            this.$axios(config)
              .then((response) => {
                if (response.status == 200) {

                  this.$swal.fire({
                    icon: "success",
                    text: "Your password updated successfully",
                  });
                  this.$router.push('/home');
                }
                else {
                  this.$swal.fire({
                    icon: "error",
                    text: response.data.message,
                  });
                }
              })
              .catch((error) => {
                this.$swal.fire({
                  icon: "error",
                  text: error.response.data.message,
                });
              });
          } catch (error) {
            console.log(error);
            this.$swal.fire({
              icon: "error",
              text: "Failed! Please try again",
            });
          }
        }
      }
    },
  }
}