import siderbar from '../template/SideBar'
import { state } from '@/globalVariables';
import {authHeader} from "../../../auth";

export default {
    components: {
        siderbar
    },
    data() {
        return {
            state,
            taxFile: '',
            siteUrl: this.$serverURL + this.$api.profile.userProfileData,
            firstName: '',
            lastName: '',
            fullName: '',
            userId: '',
            profileData: {}
        }
    },
    async mounted() {
        await this.getPageTitles();
        document.title = (this.pageTitles?.profilePageTitle ? this.pageTitles?.profilePageTitle : '');
        this.loadCSS();
        await this.getProfileData();
    },
    methods: {
        loadCSS: function () {
            var styles = [
                '/core/assets/css/error_track.css'
            ]
            styles.forEach(style => {
                let tag = document.createElement('link');
                tag.type = "text/css";
                tag.rel = "stylesheet";
                tag.href = style;
                document.head.appendChild(tag);
            });
        },
        uploadTaxFile(event) {
            this.taxFile = event.target.files[0];
        },
        checkValidation: function() {
            if(!this.taxFile) {
                this.$swal.fire({
                    icon: "warning",
                    text: "Please upload your tax file!"
                });
                return false;
            }
            return true;
        },
        upload: async function () {
            if(this.checkValidation()) {
                let formData = new FormData();
                formData.append('userName', this.fullName);
                formData.append('userId', this.userId);
                formData.append('taxFile', this.taxFile);
                let config = {
                    method: "POST",
                    data: formData,
                    url: this.$serverURL + this.$api.taxExempt.requestURL,
                    headers: {
                        "Content-Type": "multipart/form-data",
                        "Authorization": authHeader()
                    }
                };
                await this.$axios(config).then(async (response) => {
                    if (response.data.statusCode == 201) {
                        this.$swal.fire({
                            icon: "success",
                            text: "Your tax file has been uploaded successfully."
                        });
                        this.taxFile = '';
                        this.$router.push('/dashboard');
                    }
                    else {
                        this.$swal.fire({
                            icon: "error",
                            text: "Failed! please try again."
                        });
                    }
                }).catch(error => {
                    this.state.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
            }
        },
        async getProfileData() {
            try {
                let config = {
                    method: "GET",
                    url: this.siteUrl,
                    headers: {
                        "Authorization": authHeader()
                    }
                }
                await this.$axios(config).then(async (response) => {
                    if (response.status == 200) {
                        this.profileData = response.data.data.userData;
                        this.userId = this.profileData.id;
                        this.firstName = this.profileData.firstName;
                        this.lastName = this.profileData.lastName;
                        this.fullName = this.firstName + ' ' + (this.lastName ? this.lastName : '');
                    }
                }).catch(error => {
                    console.log(error);
                });
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Failed! Please try again."
                });
            }
        }
    }
}