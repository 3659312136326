<template>
  <main class="bg_gray">
    <div class="container margin_90_65">
      <div class="main_title">
        <h1 :style="{color: state.componentUi.helpComponentUI.headerTitleFontColor}">{{cms.title}}</h1>
        <p
          :style="{color: state.componentUi.helpComponentUI.headerSubTitleFontColor}"
        >{{cms.subTitle}}</p>
      </div>
      <!-- <div class="row justify-content-center">
                <div class="col-lg-8">
                    <div class="search-input white">
                        <input type="text" :placeholder="cms.searchText">
                        <button type="submit"><i class="ti-search"></i></button>
                    </div>
                </div>
      </div>-->
    </div>
    <!-- /container -->

    <div
      class="bg_white"
      :style="{'background-color': state.componentUi.helpComponentUI.pageBackgroundColor}"
    >
      <div class="container margin_90_65">
        <div class="main_title">
          <h2
            :style="{color: state.componentUi.helpComponentUI.pageTitleFontColor}"
          >{{cms.supportSectionTitle}}</h2>
          <p
            :style="{color: state.componentUi.helpComponentUI.pageSubTitleFontColor}"
          >{{cms.supportSectionSubTitle}}</p>
        </div>
        <div class="row">
          <div class="col-md-6" v-for="(data, index) in content" :key="index">
            <a class="box_topic_2" href="#0">
              <i :class="data.iconClass"></i>
              <h3>{{data.title}}</h3>
              <p v-html="data.description"></p>
            </a>
          </div>
        </div>
      </div>
      <!-- /container -->
    </div>
    <!-- /bg_white -->
  </main>
  <Advertise v-if="state.advertiseList.includes('Help') && state.isAdvertise==true" />
  <NewsLetter v-if="state.newsletterList.includes('Help') && state.isNewsLetter==true" />
  <HelpSchemajson v-if="Object.values(pageTitles).length>0" :metaData="pageTitles" />
</template>
<script src="../js/help.js"></script>