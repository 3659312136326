<template>
  <main class="bg_gray" :style="{'background-color': state.componentUi.franchiseComponentUI.pageBackgroundColor}">
    <div class="top_banner">
      <div
        class="opacity-mask d-flex align-items-center"
        data-opacity-mask="rgba(0, 0, 0, 0.3)"
      >
        <div class="container">
          <div class="breadcrumbs">
            <ul>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/home')">Home</a>
              </li>
              <li>
                <a href="javascript:void(0)">Our Location</a>
              </li>
            </ul>
          </div>
          <h1 :style="{'color': state.componentUi.locationComponentUI.pageTitleFontColor}">{{ state.locationCms.locationPageTitle }}</h1>
        </div>
      </div>
      <img
        :src="imageURL + state.locationCms.backgroundImage"
        class="img-fluid"
        alt=""
      />
    </div>
    <!-- <div
      class="container margin_60"
      :style="{
        'background-image': `url(${imageURL}${state.locationCms.backgroundImage})`,
      }"
    >
      <div class="main_title" style="margin-bottom: 35px; margin-top: 35px">
        <h2>Our Location</h2>
      </div>
    </div> -->

    <!-- <div class="center-item" style="display: block; width: 100vw; text-align: center; padding-top: 3em;">
      <a href="/home" @click.prevent="checkIntoData">
        <p>
        <img src="/core/images/logo.png" height="70" alt="HGSHydro.com Intro Page" style="cursor: pointer; width: auto;" >
          </p>
      
      </a>
    </div>
    <div class="center-item" style="display: block; width: 100vw; text-align: center; padding-top: 3em;">
      <a
          href="/home"
          @click.prevent="checkIntoData"
          class="btn_1 btn-success" style="background-color: #006633;"
          > <i class="fa-solid fa-arrow-rotate-left"></i> Back To Home
        </a>
    </div>

    <h1 class="card-title" style="font-size: 2em; text-transform: uppercase; font-weight: 700; color: #006633 !important; text-align: center; margin-top: 2em;">
      <span><i aria-hidden="true" class="fa fa-home"></i></span>
      Visit Our Location
    </h1> -->

    <div :style="{'background-color': state.componentUi.locationComponentUI.pageBackgroundColor}">
      <div class="container py-5">
        <div
          class="row justify-content-center gy-3 gx-3"
          style="padding-top: 35px; padding-bottom: 35px"
        >
          <div
            v-for="(data, index) in dataList"
            :key="index"
            class="col-12 col-sm-6 col-md-5 col-lg-4"
          >
            <div class="card shadow-sm mb-3">
              <iframe
                :src="data.mapLink"
                width="100%"
                height="330"
                frameborder="0"
                allowfullscreen="allowfullscreen"
                aria-hidden="false"
                tabindex="0"
                class="card-img-top"
                style="border: 0"
              ></iframe>
              <div class="card-body d-flex flex-column">
                <h2 class="card-title" style="font-size: 1.3em; font-weight: 700;">
                  <span>
                    <i aria-hidden="true" class="fa fa-home"></i>
                  </span>
                  {{ data.location }}
                </h2>
                <ul class="list-unstyled mt-1 mb-1">
                  <li>
                    <a
                      :href="'https://maps.google.com/?q=' + encodeURIComponent(data.address)"
                      class="Location"
                      style="color: black; text-decoration: none"
                    >
                      <span data-v-1e6215ab="">
                        <i class="fa-solid fa-location-dot"></i>
                      </span>
                      &nbsp;{{ data.address }}
                    </a>
                  </li>
                  <li>
                    <a
                      :href="'tel:' + data.contactNumber"
                      class="Phone"
                      style="color: black; text-decoration: none"
                    >
                      <span data-v-1e6215ab="">
                        <i
                          aria-hidden="true"
                          class="fa fa-phone-square"
                        ></i> </span
                      >&nbsp;{{ data.contactNumber }}</a
                    >
                  </li>
                  <li>
                    <a
                      :href="'mailto:' + data.email"
                      class="Email"
                      style="color: black; text-decoration: none"
                    >
                      <span data-v-1e6215ab="">
                        <i aria-hidden="true" class="fa fa-envelope"></i> </span
                      >&nbsp;{{ data.email }}
                    </a>
                  </li>
                  <li>
                    <!-- <a style="color: black; text-decoration: none">
                      <span><i class="fa fa-clock-o"></i></span> &nbsp;
                      {{ data.timeFrame }}
                    </a> -->
                    <a
                      href="javascript:void(0)"
                      class="Email"
                      style="color: black; text-decoration: none"
                    >
                      <span data-v-1e6215ab="">
                        <i aria-hidden="true" class="fa fa-clock-o"></i> </span
                      >&nbsp;{{ data.timeFrame }}
                    </a>
                  </li>
                </ul>
                <a
                  :href="`/location-details/${data.urlSlug}`"
                  @click.prevent="gotoLocationDetails(data.urlSlug)"
                  class="btn_1 full-width" style="margin-top: 0.7rem;"
                >
                  <i class="fa-solid fa-store"></i> Show Details
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container margin-padding">
      <div class="store-title">
        <h2>
          {{ homeLocationCms.title }}
        </h2>
        <!-- <span>Hydroponic Stores</span> -->
        <p class="stores-descrip" v-html="homeLocationCms.description">
        </p>
      </div>
    </div>
  </main>
  <LocationSchemaJson :metaKeyword="metaKeyword" :metaDescription="metaDescription"></LocationSchemaJson>
</template>

<script src="../js/location.js">
</script>

<style scoped>
</style>
