import { state } from '@/globalVariables';
export default {
    data () {
        return {
            state,
            email: '',
            imagePath: this.$imageURL + 'footer/',
            imagePathSite: this.$imageURL + 'header/',
            imageFooterBranding: this.$imageURL + 'FooterBranding/',
            url: this.$serverURL + this.$api.subscribe.subscriberAPI,
        }
    },
    mounted() {
        
    },
    methods: {
        addSubscription: async function() {
            let data = {
                email: this.email
            };
            let config ={
                method: "POST",
                url: this.url,
                data: data
            };
            await this.$axios(config).then((response) => {
                if (response.status == 201) {
                    
                    this.$swal.fire({
                        toast: true,
                        position: 'center',
                        text: "Subscription Successfull",
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                    this.email = "";
                }
                else {
                    this.$swal.fire({
                        icon: "error",
                        text: "Failed! please try again."
                    });
                }
            }).catch(error => {
                
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },
    }
}