const additionalRoutes = [
    { path: '/api', redirect: '/' },
    //Image Broken Link Fix
    // { path: '/aseedoot/2019/02/801250-01-300x300.jpg', redirect: '/' },
    // { path: '/aseedoot/2019/02/736747-01.jpg', redirect: '/' },
    // { path: '/aseedoot/2019/02/700826-01-300x300.jpg', redirect: '/' },
    // { path: '/aseedoot/2019/02/746344-01-1000x1000.jpg', redirect: '/' },
    // { path: '/aseedoot/2019/02/724010-01.jpg', redirect: '/' },
    // { path: '/aseedoot/2019/02/727958-01.jpg', redirect: '/' },
    // { path: '/aseedoot/2019/02/860307-01.jpg', redirect: '/' },
    // { path: '/wp-content/uploads/2019/02/760513-01.jpg', redirect: '/' },
    // { path: '/aseedoot/2019/02/740860-01.jpg', redirect: '/' },
    // { path: '/aseedoot/2019/02/716734-01.jpg', redirect: '/' },
    // { path: '/wp-content/uploads/2019/04/Heavy16-BlackBottles-Fire-web.png', redirect: '/' },
    // { path: '/aseedoot/2019/02/748522-01.jpg', redirect: '/' },
    // { path: '/aseedoot/2019/02/717505-01.jpg', redirect: '/' },
    // { path: '/wp-content/uploads/2019/02/740865-01.jpg', redirect: '/' },
    // { path: '/wp-content/uploads/2019/02/010504-01.jpg', redirect: '/' },
    // { path: '/aseedoot/2019/02/960487-01.jpg', redirect: '/' },
    // { path: '/wp-content/uploads/2019/02/760532-01.jpg', redirect: '/' },
    // { path: '/wp-content/uploads/2019/02/717550-01.jpg', redirect: '/' },
    // { path: '/aseedoot/2019/02/310785-01.jpg', redirect: '/' },
    // { path: '/wp-content/uploads/2019/04/Heavy16-BlackBottles-BudAB-web.png', redirect: '/' },
    // { path: '/wp-content/uploads/2019/02/760515-01.jpg', redirect: '/' },
    // { path: '/wp-content/uploads/2019/02/749727-01.jpg', redirect: '/' },
    // { path: '/aseedoot/2019/02/736570-01.jpg', redirect: '/' },
    // { path: '/aseedoot/2019/02/716900-01.jpg', redirect: '/' },
    //category-old-site-broken-link
    { path: '/product-category/sun-system-electronic-ballasts/', redirect: {name:'Product'} },
    { path: '/product-category/sun-system-electronic-ballasts/?product_count=12', redirect: {name:'Product'} },
    { path: '/product-category/pots-and-containers/page/1/', redirect: {name:'Product'} },
    { path: '/product-category/timers-and-instruments/', redirect: {name:'Product'} },
    { path: '/product-category/led/', redirect: {name:'Product'} },
    { path: '/product-category/water-aeration/page/1/', redirect: {name:'Product'} },
    { path: '/product-category/garden-hygiene-and-safety/', redirect: {name:'Product'} },

    //old product old site url 
    { path: '/shop/titan-controls-saturn-5-replacement-sensor/', redirect: {name:'Product'} },
    { path: '/shop/sure-test-commercial-lab-meter-replacement-ph-probe/', redirect: {name:'Product'} },
    { path: '/shop/ecoplus-eco-2245-replacement-shaft-impeller/', redirect: {name:'Product'} },
    { path: '/shop/sure-test-commercial-multi-meter-w-ph-conductivity-probes-do-probe-sold-separately/', redirect: {name:'Product'} },
    { path: '/shop/current-culture-epicenter-complete-13/', redirect: {name:'Product'} },
    { path: '/shop/fast-fit-replacement-leg-18-in-12-cs/', redirect: {name:'Product'} },
    { path: '/shop/grozone-control-usco2-0-2000-ppm-single-zone-ultra-simple-co2-controller/', redirect: {name:'Product'} },
    { path: '/shop/gro-pro-essential-round-fabric-pot-w-handles-3-gallon-black-72-cs/', redirect: {name:'Product'} },
    { path: '/shop/hydro-logic-stealth-ro-300-w-upgraded-kdf-carbon-filter/', redirect: {name:'Product'} },
    { path: '/shop/hydro-flow-butterfly-hose-clamps-3-4-in-10-bag/', redirect: {name:'Product'} },
    { path: '/shop/natures-nectar-calcium-magnesium/', redirect: {name:'Product'} },
    { path: '/shop/rosin-industries-pollenex-1500-dry-sift-tumbler-drum/', redirect: {name:'Product'} },
    { path: '/shop/quest-dual-105-overhead-dehumidifier/', redirect: {name:'Product'} },
    { path: '/shop/emerald-harvest-king-kola/', redirect: {name:'Product'} },
    { path: '/shop/quest-supply-duct-kit-quest-dual-110-150/', redirect: {name:'Product'} },
    { path: '/shop/trimpro-original-silence-mixed-24-plt/', redirect: {name:'Product'} },
    { path: '/shop/quest-dehumidifier-70-pint/', redirect: {name:'Product'} },
    { path: '/shop/smart-pot-dirty-tan-150-gallon-20-cs/', redirect: {name:'Product'} },
    { path: '/shop/gro-pro-essential-round-fabric-pot-black-20-gallon-42-cs/', redirect: {name:'Product'} },
    { path: '/shop/quest-dual-155-overhead-dehumidifier/', redirect: {name:'Product'} },
    { path: '/shop/heavy-16-foliar-spray/', redirect: {name:'Product'} },
    { path: '/shop/all-per-plus-32-oz-rtu-12-cs/', redirect: {name:'Product'} },
    { path: '/shop/current-culture-under-current-12/', redirect: {name:'Product'} },
    { path: '/shop/hydro-flow-barbed-elbow-3-4-in-display-box-100-box/', redirect: {name:'Product'} },
    { path: '/shop/neptunes-harvest-crab-and-lobster-shell-44-lb/', redirect: {name:'Product'} },
    { path: '/shop/quest-f9-industrial-air-mover-fan/', redirect: {name:'Product'} },
    { path: '/shop/gh-defguard-biofungicide-bactericide-gallon-4-cs/', redirect: {name:'Product'} },
    { path: '/shop/ideal-air-pro-series-ultra-sonic-humidifier-150-pint/', redirect: {name:'Product'} },
    { path: '/shop/growlab-60/', redirect: {name:'Product'} },
    { path: '/shop/gro-pro-premium-nursery-pot-5-gallon-squat/', redirect: {name:'Product'} },
    { path: '/shop/vostermans-ventilation-multifan-v-flofan-120-volt/', redirect: {name:'Product'} },
    { path: '/shop/eye-powerveg-633-4-ft-54-watt-ho-t5-24-cs/', redirect: {name:'Product'} },
    { path: '/shop/anderson-pots-20-squat-200-plt/', redirect: {name:'Product'} },
    { path: '/shop/true-liberty-goose-bags-18-in-x-24-in-10-pack/', redirect: {name:'Product'} },
    { path: '/shop/la-crosse-alerts-remote-temperature-and-humidity-monitoring-system/', redirect: {name:'Product'} },
    { path: '/shop/grow-more-sea-grow-flower-bloom-5-lb/', redirect: {name:'Product'} },
    { path: '/shop/trap-medium-duffel-olive-8-cs/', redirect: {name:'Product'} },
    { path: '/shop/floraflex-nutrients-v1/', redirect: {name:'Product'} },
    { path: '/shop/55-gallon-drum-ring-clamp/', redirect: {name:'Product'} },
    { path: '/shop/rainmaker-1-2-gallon-2-liter-pump-sprayer-6-cs/', redirect: {name:'Product'} },
    { path: '/shop/roots-organics-extreme-serene/', redirect: {name:'Product'} },
    { path: '/shop/roots-organics-big-worm-1-cu-ft-60-plt/', redirect: {name:'Product'} },
    { path: '/shop/ecoplus-commercial-air-1-18-watt-single-outlet-793-gph-12-cs/', redirect: {name:'Product'} },
    { path: '/shop/greengro-natures-pride-bloom-fertilizer/', redirect: {name:'Product'} },
    { path: '/shop/dramm-colorstorm-premium-rubber-hose-5-8-in-330-ft-green/', redirect: {name:'Product'} },
    { path: '/shop/rosin-industries-25-micron-thickness-rosin-bag-1100-pack-12-cs/', redirect: {name:'Product'} },
    { path: '/shop/galaxy-remote-commercial-ballast-1000-watt-120-208-240-volt/', redirect: {name:'Product'} },
    { path: '/shop/xtreme-gardening-calcarb-6-oz/', redirect: {name:'Product'} },
    { path: '/shop/ez-stor-lid-for-8-and-13-gallon/', redirect: {name:'Product'} },
    { path: '/shop/dosatron-water-powered-doser-14-gpm-1100-to-110-6-cs/', redirect: {name:'Product'} },
    { path: '/shop/sns-217-mite-control-rtu-quart-10-cs/', redirect: {name:'Product'} },
    { path: '/shop/current-culture-uc-spin-tight-bulkhead-2-in-48-cs/', redirect: {name:'Product'} },
    { path: '/shop/botanicare-silica-blast/', redirect: {name:'Product'} },
    { path: '/shop/ez-clone-128-low-pro-system-white/', redirect: {name:'Product'} },
    { path: '/shop/gh-flora-duo-a/', redirect: {name:'Product'} },
    { path: '/shop/botanicare-115-gallon-reservoir-lid-white/', redirect: {name:'Product'} },
    { path: '/shop/fast-fit-rolling-bench-tray-stand-4-ft-x-8-ft-2-boxes/', redirect: {name:'Product'} },
    { path: '/shop/gh-rapid-rooter-50-cell-plug-tray-12-cs/', redirect: {name:'Product'} },
    { path: '/shop/gh-general-organics-diamond-black/', redirect: {name:'Product'} },
    { path: '/shop/quest-506-commercial-dehumidifier-500-pint/', redirect: {name:'Product'} },
    { path: '/shop/ez-clone-64-low-pro-system-black/', redirect: {name:'Product'} },
    { path: '/shop/centurionpro-mini-dry-4-plt/', redirect: {name:'Product'} },
    { path: '/shop/current-culture-under-current-4xl/', redirect: {name:'Product'} },
    { path: '/shop/heavy-16-prime-concentrate/', redirect: {name:'Product'} },
    { path: '/shop/bus-blend-biodynamic-compost-2-yrd-tote-1-plt/', redirect: {name:'Product'} },
    { path: '/shop/eye-powerveg-fs-uv-4-ft-54w-ho-t5-24-cs/', redirect: {name:'Product'} },
    { path: '/shop/horti-control-dust-shroom-10-in/', redirect: {name:'Product'} },
    { path: '/shop/sun-system-lec-630-277-volt-w-3100-k-lamps-12-plt/', redirect: {name:'Product'} },
    { path: '/shop/ultra-sun-600-hps-ho/', redirect: {name:'Product'} },
    { path: '/shop/current-culture-delivery-manifold-uc-double-barrel/', redirect: {name:'Product'} },
    { path: '/shop/azera-gardening-8-oz-12-cs/', redirect: {name:'Product'} },
    { path: '/shop/trimpro-automatik-standard-12-plt/', redirect: {name:'Product'} },
    { path: '/shop/royal-gold-mendo-mix-1-5-yd-tote-2-plt/', redirect: {name:'Product'} },
    { path: '/shop/sun-blaze-t5-led-48-4-ft-8-lamp-120-volt-24-plt/', redirect: {name:'Product'} },
    { path: '/shop/growers-edge-black-powder-free-nitrile-gloves-6-mil-small-100-box/', redirect: {name:'Product'} },
    { path: '/shop/gro-pro-heavy-duty-black-saucer-6-in-100-cs/', redirect: {name:'Product'} },
    { path: '/shop/gro-pro-premium-tall-nursery-pot-3-gallon/', redirect: {name:'Product'} },
    { path: '/shop/alchemist-h2o2-liquid-oxygen-34-quart-12-cs/', redirect: {name:'Product'} },
    { path: '/shop/super-sprouter-aqua-clone-24-site-deep-water-cloner-6-cs/', redirect: {name:'Product'} },
    { path: '/shop/grow-more-hawaiian-bud/', redirect: {name:'Product'} },
    { path: '/shop/sns-203-conc-pesticide-soil-drench-foliar-spray-4-oz-pouch-10-cs/', redirect: {name:'Product'} },
    { path: '/shop/growers-edge-green-trellis-netting-6-5-ft-x-100-ft-8-cs/', redirect: {name:'Product'} },
    { path: '/shop/agroled-isunlight-41-watt-t5-4-ft-bloom-led-lamp-25-cs/', redirect: {name:'Product'} },
    { path: '/shop/urban-oasis-collapsible-water-storage-barrel-104-gallon/', redirect: {name:'Product'} },
    { path: '/shop/current-culture-cch2o-epicenter-lid-white-36-cs/', redirect: {name:'Product'} },
    { path: '/shop/gh-prevasyn-insect-repellant-insecticide-pint-12-cs/', redirect: {name:'Product'} },
    { path: '/shop/gh-rapidstart-1-oz-display-box/', redirect: {name:'Product'} },
    { path: '/shop/azamax-4-oz-24-cs/', redirect: {name:'Product'} },
    { path: '/shop/spectralux-t5-vho-95-watt-4-ft-6500k-25-cs/', redirect: {name:'Product'} },
    { path: '/shop/smart-pot-black-15-gallon-50-cs/', redirect: {name:'Product'} },
    { path: '/shop/dramm-1000-water-breaker-nozzle-8-gpm/', redirect: {name:'Product'} },
    { path: '/shop/gavita-pro-classic-series-600-se-240-volt/', redirect: {name:'Product'} },
    { path: '/shop/leader-ecodiver-1200-1-hp-1620-gph/', redirect: {name:'Product'} },
    { path: '/shop/gro-pro-maxipot-black-9-6-in-x-9-6-in-x-11-5-in-25-cs/', redirect: {name:'Product'} },
    { path: '/shop/sunfilm-black-white-panda-film-10-ft-x-50-ft-roll/', redirect: {name:'Product'} },
    { path: '/shop/organic-bountea-superstart/', redirect: {name:'Product'} },
    { path: '/shop/smart-pot-dirty-tan-400-gallon-10-cs/', redirect: {name:'Product'} },
    { path: '/shop/duralastics-tray-3-ft-x-3-ft-id-black/', redirect: {name:'Product'} },
    { path: '/shop/wiggle-worm-soil-builder-earth-worm-castings-15-lb-150-plt/', redirect: {name:'Product'} },
    { path: '/shop/marrone-bio-innovations-regalia-cg-gallon-4-cs/', redirect: {name:'Product'} },
    { path: '/shop/gavita-booster-b200-10-cs/', redirect: {name:'Product'} },
    { path: '/shop/rosin-industries-pollenex-1500-gram-dry-sift-tumbler/', redirect: {name:'Product'} },
    { path: '/shop/pyganic-specialty-quart-6-cs/', redirect: {name:'Product'} },
    { path: '/shop/sky-hook-light-hanger-1-8-in-1-pair-12-cs/', redirect: {name:'Product'} },
    { path: '/shop/harvest-keeper-silver-silver-precut-bags-15-in-x-20-in-50-pack/', redirect: {name:'Product'} },
    { path: '/shop/vaporizer-cup/', redirect: {name:'Product'} },
    { path: '/shop/gh-general-organics-biomarine/', redirect: {name:'Product'} },
    { path: '/shop/down-to-earth-high-phosphorus-seabird-guano/', redirect: {name:'Product'} },
    { path: '/shop/magicalbutter-21up-2-ml-gummy-tray/', redirect: {name:'Product'} },
    { path: '/shop/floraflex-quick-disconnect-pipe-system-multi-flow-bubbler-1-in-elbow-12-cs/', redirect: {name:'Product'} },
    { path: '/shop/botanicare-kind-grow/', redirect: {name:'Product'} },
    { path: '/shop/fast-fit-trellis-support-4-piece/', redirect: {name:'Product'} },
    { path: '/shop/gh-ph-test-kit-1-oz-24-cs/', redirect: {name:'Product'} },
    { path: '/shop/gh-general-organics-camg/', redirect: {name:'Product'} },
    { path: '/shop/gro-pro-essential-round-fabric-pot-black-5-gallon-90-cs/', redirect: {name:'Product'} },
    { path: '/shop/mad-farmer-silica/', redirect: {name:'Product'} },
    { path: '/shop/anderson-pots-1-deep-3500-plt/', redirect: {name:'Product'} },
    { path: '/shop/ona-gel-fresh-linen-gallon-jar-4-cs/', redirect: {name:'Product'} },
    { path: '/shop/can-trol-variable-volt-control-240-volt-5-amp/', redirect: {name:'Product'} },
    { path: '/shop/hm-digital-waterproof-ph-meter/', redirect: {name:'Product'} },
    { path: '/shop/cultured-solutions-coco-cal/', redirect: {name:'Product'} },
    { path: '/shop/galcon-gsi-ag-24-station-110-vac/', redirect: {name:'Product'} },
    { path: '/shop/rainmaker-4-gallon-16-liter-backpack-sprayer/', redirect: {name:'Product'} },
    { path: '/shop/mad-farmer-oxygenator-6-gallon-1-cs/', redirect: {name:'Product'} },
    { path: '/shop/ecoplus-convertible-bottom-draw-water-pump-585-replacement-shaft-and-impeller/', redirect: {name:'Product'} },
    { path: '/shop/current-culture-2-site-expansion-kit-for-uc/', redirect: {name:'Product'} },
    { path: '/shop/integra-boost-5-gal-bucket-200g-desiccant-packs-30-bags-per-bucket/', redirect: {name:'Product'} },
    { path: '/shop/titan-controls-hades-2-replacement-sensor/', redirect: {name:'Product'} },
    { path: '/shop/current-culture-cch2o-net-pot-5-5-in-170-cs/', redirect: {name:'Product'} },
    { path: '/shop/agrotech-magnum-double-ended-reflector-30-plt/', redirect: {name:'Product'} },
    { path: '/shop/hydro-logic-evolution-ro1000-kdf-catalytic-carbon-pre-filter/', redirect: {name:'Product'} },
    { path: '/shop/gavita-pro-plus-1000-watt-400-volt-el-de-12-cs/', redirect: {name:'Product'} },
    { path: '/shop/spray-n-grow-8-oz/', redirect: {name:'Product'} },
    { path: '/shop/smart-pot-black-3-gallon-w-handles-50-cs/', redirect: {name:'Product'} },
    { path: '/shop/hydro-flow-netafim-techflow-pressure-compensating-dripper-w-check-valve-1-gph-125-bundle/', redirect: {name:'Product'} },
    { path: '/shop/centurionpro-mini-wet-4-plt/', redirect: {name:'Product'} },
    { path: '/shop/hurricane-inline-fan-4-in-171-cfm/', redirect: {name:'Product'} },
    { path: '/shop/gro-pro-premium-round-fabric-pot-w-handles-15-gallon-black-48-cs/', redirect: {name:'Product'} },
    { path: '/shop/gro-pro-square-plastic-pot-black-3-5-in-1375-cs/', redirect: {name:'Product'} },
    { path: '/shop/hydro-flow-barbed-reducer-tee-1-2-in-to-1-4-in-10-bag/', redirect: {name:'Product'} },
    { path: '/shop/harvest-keeper-black-clear-precut-bags-11-in-x-18-in-50-pack-16-cs/', redirect: {name:'Product'} },
    { path: '/shop/ultra-sun-mh-1000-10000k-12-cs/', redirect: {name:'Product'} },
    { path: '/shop/tnb-naturals-ph-down-1lb-454g-30-cs/', redirect: {name:'Product'} },
    { path: '/shop/roots-organics-sos-sap-off-soap-8-oz-12-cs/', redirect: {name:'Product'} },
    { path: '/shop/urban-oasis-collapsible-water-storage-barrel-248-gallon/', redirect: {name:'Product'} },
    { path: '/shop/botanicare-20-gallon-reservoir-white/', redirect: {name:'Product'} },
    { path: '/shop/ideal-air-dehumidifier-30-pint/', redirect: {name:'Product'} },
    { path: '/shop/bcuzz-bloom/', redirect: {name:'Product'} },
    { path: '/shop/floraflex-bubbler-flow-insert-6-gph-112-pack/', redirect: {name:'Product'} },
    { path: '/shop/growlab-290/', redirect: {name:'Product'} },
    { path: '/shop/?product_orderby=price', redirect: {name:'Product'} },
    { path: '/shop/hydro-flow-top-hat-grommet-1-4-in-10-bag/', redirect: {name:'Product'} },
    { path: '/shop/gh-diamond-nectar/', redirect: {name:'Product'} },
    { path: '/shop/hortilux-lu-1000-de-htl-double-ended-6-cs/', redirect: {name:'Product'} },
    { path: '/shop/rainmaker-telescopic-watering-wand-w-thumb-slide-flow-control-36-in-to-60-in-6-cs/', redirect: {name:'Product'} },
    { path: '/shop/can-fan-max-vinyl-ducting-14-in-x-25-ft/', redirect: {name:'Product'} },
    { path: '/shop/dyna-gro-k-l-n-conc-quart-12-cs/', redirect: {name:'Product'} },
    { path: '/shop/fast-fit-light-stand-extension-kit-2-each/', redirect: {name:'Product'} },
    { path: '/shop/ultra-sun-t5-54-watt-ho-lamp-4-ft-blue-6500k-25-cs/', redirect: {name:'Product'} },
    { path: '/shop/hydro-flow-netafim-disc-filter-3-4-in-mpt-x-mpt-140-mesh-17-gpm-maximum-flow-1-cs/', redirect: {name:'Product'} },
    { path: '/shop/sure-test-pro-ec-tds-pen/', redirect: {name:'Product'} },
    { path: '/shop/current-culture-delivery-manifold-uc-xl/', redirect: {name:'Product'} },
    { path: '/shop/roots-organics-emerald-mountain-mix-1-5-cu-ft-70-plt/', redirect: {name:'Product'} },
    { path: '/shop/current-culture-pro-lid-6-x-8-in-holes-w-2-x-porthole-cover/', redirect: {name:'Product'} },
    { path: '/shop/premier-pro-mix-cc40-mycorrhizae-3-8-cu-ft-30-plt/', redirect: {name:'Product'} },
    { path: '/shop/rosin-industries-x5-2-ton-electric-heat-press/', redirect: {name:'Product'} },
    { path: '/shop/current-culture-2-site-expansion-kit-for-uc-pro/', redirect: {name:'Product'} },
    { path: '/shop/gro-pro-premium-nursery-pot-3-gallon-squat/', redirect: {name:'Product'} },
    { path: '/shop/sun-blaze-t5-ho-41-4-ft-1-lamp-20-cs/', redirect: {name:'Product'} },
    { path: '/shop/gro-pro-premium-round-fabric-pot-w-handles-3-gallon-black-72-cs/', redirect: {name:'Product'} },
    { path: '/shop/titan-controls-zephyr-5-digital-cooling-controller-10-cs/', redirect: {name:'Product'} },
    { path: '/shop/trap-backpack-black-10-cs/', redirect: {name:'Product'} },
    { path: '/shop/abscent-medium-duffel-insert-black/', redirect: {name:'Product'} },
    { path: '/shop/mad-farmer-get-down-6-gallon-1-cs/', redirect: {name:'Product'} },
    { path: '/shop/ecoplus-185-replacement-filter/', redirect: {name:'Product'} },
    { path: '/shop/super-sprouter-deluxe-propagation-kit/', redirect: {name:'Product'} },
    { path: '/shop/bluelab-conductivity-pen/', redirect: {name:'Product'} },
    { path: '/shop/lightrail-heavy-duty-trolley-wheel-replacement-kit/', redirect: {name:'Product'} },
    { path: '/shop/fiskars-comfort-grip-floral-snips-12-cs/', redirect: {name:'Product'} },
    { path: '/shop/botanicare-sweet-citrus/', redirect: {name:'Product'} },
    { path: '/shop/floraflex-multi-flow-bubbler-72-cs/', redirect: {name:'Product'} },
    { path: '/shop/pure-flowers/', redirect: {name:'Product'} },
    { path: '/shop/grodan-delta-10-4-in-block-4-in-x-4-in-x-4-in-w-hole-24-cs/', redirect: {name:'Product'} },
    { path: '/shop/gh-floralicious-bloom/', redirect: {name:'Product'} },
    { path: '/shop/gh-vortex-12-volt-dc-sprayer/', redirect: {name:'Product'} },
    { path: '/shop/growers-edge-soft-mesh-trellis-netting-5-ft-x-15-ft-w-6-in-squares-12-cs/', redirect: {name:'Product'} },
    { path: '/shop/down-to-earth-alfalfa-meal-5-lb/', redirect: {name:'Product'} },
    { path: '/shop/hydro-flow-netafim-woodpecker-pressure-compensating-junior-dripper-6-6-gph-orange-grey-25-bag/', redirect: {name:'Product'} },
    { path: '/shop/black-ops-bug-screen-w-active-carbon-insert-6-in/', redirect: {name:'Product'} },
    { path: '/shop/gold-label-coco-50-liter-60-plt/', redirect: {name:'Product'} },
    { path: '/shop/jungle-juice-grow/', redirect: {name:'Product'} },
    { path: '/shop/growers-edge-green-eye-led-headlight-100-cs/', redirect: {name:'Product'} },
    { path: '/shop/gh-exile-insecticide-fungicide-miticide-quart-12-cs/', redirect: {name:'Product'} },
    { path: '/shop/myco2-mushroom-bag-bloom/', redirect: {name:'Product'} },
    { path: '/shop/rainmaker-high-flow-4-way-hose-adapter-outlet-4-cs/', redirect: {name:'Product'} },
    { path: '/shop/adjust-a-wings-defender-hellion-de-white-reflector-medium-72-plt/', redirect: {name:'Product'} },
    { path: '/shop/replacement-capacitors-hps-1000-26-mfd-525-volt-single-wet/', redirect: {name:'Product'} },
    { path: '/shop/tnb-naturals-co2-monitor-12-cs/', redirect: {name:'Product'} },
    { path: '/shop/ars-all-purpose-scissors-ss-330h-10-cs/', redirect: {name:'Product'} },
    { path: '/shop/hydro-flow-bulkhead-thread-thread-3-4-in-hole-size-1-1-2-in-10-bag/', redirect: {name:'Product'} },
    { path: '/shop/floraflex-3-4-in-nylon-valve-30-cs/', redirect: {name:'Product'} },
    { path: '/shop/duralastics-tray-4-ft-x-4-ft-id-black/', redirect: {name:'Product'} },
    { path: '/shop/current-culture-pro-lid-epicenter-blank-includes-2-x-porthole-cover/', redirect: {name:'Product'} },
    { path: '/shop/ecoplus-eco-air-8-eight-outlet-13-watt-380-gph-8-cs/', redirect: {name:'Product'} },
    { path: '/shop/hydro-flow-3-16-in-poly-tube-push-in-straight-fitting-display-box-250-box/', redirect: {name:'Product'} },
    { path: '/shop/hortilux-mt1000d-hor-htl-6-cs/', redirect: {name:'Product'} },
    { path: '/shop/snoops-premium-nutrients-bloom-a-circulating-hydro-recirculating/', redirect: {name:'Product'} },
    { path: '/shop/heavy-16-fire/', redirect: {name:'Product'} },
    { path: '/shop/ecoplus-396-replacement-filter/', redirect: {name:'Product'} },
    { path: '/shop/uvonair-cd-inline-duct-ozonator-12-in-3-cell-2-cs/', redirect: {name:'Product'} },
    { path: '/shop/roots-organics-greenfields-potting-soil-1-5-cu-ft-70-plt/', redirect: {name:'Product'} },
    { path: '/shop/growers-edge-jumbo-wall-thermometer-24-cs/', redirect: {name:'Product'} },
    { path: '/shop/hydro-flow-rubber-grommet-1-in-10-bag/', redirect: {name:'Product'} },
    { path: '/shop/dosatron-nutrient-delivery-system-mixing-chamber-kit/', redirect: {name:'Product'} },
    { path: '/shop/hydro-flow-butterfly-hose-clamps-3-4-in-bulk-display-box-100-box/', redirect: {name:'Product'} },
    { path: '/shop/ecoplus-hydrovescent-air-disc-6-in-12-cs/', redirect: {name:'Product'} },
    { path: '/shop/true-liberty-turkey-bags-18-in-x-20-in-25-pack/', redirect: {name:'Product'} },
    { path: '/shop/feliz-blue-fluorescent-200-watt-6500k-12-cs/', redirect: {name:'Product'} },
    { path: '/shop/growers-edge-green-trellis-netting-6-5-ft-x-25-ft-24-cs/', redirect: {name:'Product'} },
    { path: '/shop/rainmaker-2-gallon-8-liter-pump-sprayer/', redirect: {name:'Product'} },
    { path: '/shop/hydro-logic-upgrade-kit-to-convert-tall-boy-to-stealth-ro-150/', redirect: {name:'Product'} },
    { path: '/shop/hydro-flow-butterfly-hose-clamps-1-2-in-10-bag/', redirect: {name:'Product'} },
    { path: '/shop/ona-block-fresh-linen-6-oz-48-cs/', redirect: {name:'Product'} },
    { path: '/shop/bonide-pyrethrin-garden-insect-spray-conc-pint-12-cs/', redirect: {name:'Product'} },
    { path: '/shop/doktor-doom-fogger-3-oz-12-cs/', redirect: {name:'Product'} },
    { path: '/shop/sun-blaze-t5-led-24-2-ft-4-lamp-120-volt/', redirect: {name:'Product'} },
    { path: '/shop/hydroton-original-25-liter-60-plt/', redirect: {name:'Product'} },
    { path: '/shop/house-and-garden-drip-clean-60-liter-1-cs/', redirect: {name:'Product'} },
    { path: '/shop/receptacle-240-volt-15-amp/', redirect: {name:'Product'} },
    { path: '/shop/cyco-ph-up-500-ml-12-cs/', redirect: {name:'Product'} },
    { path: '/shop/ecoplus-replacement-eco-3-4-in-barbed-x-1-in-threaded-fitting/', redirect: {name:'Product'} },
    { path: '/shop/fiskars-forged-pruner-10-cs/', redirect: {name:'Product'} },
    { path: '/shop/gold-label-hydrocorn-36-liter-65-plt/', redirect: {name:'Product'} },
    { path: '/shop/monterey-once-a-year-insect-control-ii-gallon-4-cs/', redirect: {name:'Product'} },
    { path: '/shop/can-fan-q-max-10-in-1024-cfm/', redirect: {name:'Product'} },
    { path: '/shop/floraflex-4-in-floracap-2-0-160-cs/', redirect: {name:'Product'} },
    { path: '/shop/growers-edge-green-twist-tie-dispenser-w-cutter-164-ft-6-cs/', redirect: {name:'Product'} },
    { path: '/shop/royal-gold-basement-mix-1-5-cu-ft-60-plt/', redirect: {name:'Product'} },
    { path: '/shop/fast-fit-tray-stand-3-ft-x-6-ft/', redirect: {name:'Product'} },
    { path: '/shop/galcon-nine-station-outdoor-wall-mount-irrigation-misting-and-propagation-controller-8059s-ac-9s-3-cs/', redirect: {name:'Product'} },
    { path: '/shop/air-king-wall-mount-fan-18-in/', redirect: {name:'Product'} },
    { path: '/shop/can-fan-max-fan-14-in-1700-cfm/', redirect: {name:'Product'} },
    { path: '/shop/current-culture-under-current-evolution-24xxl-13/', redirect: {name:'Product'} },
    { path: '/shop/growers-edge-commercial-grade-trellis-netting-6-5-ft-x-3280-ft-bulk-roll/', redirect: {name:'Product'} },
    { path: '/shop/boveda-8g-2-way-humidity-58-10-pack/', redirect: {name:'Product'} },
    { path: '/shop/centurionpro-diverter-for-mini-original-silver-bullet/', redirect: {name:'Product'} },
    { path: '/shop/lec-e39-mogul-to-pgzx-socket-adapter-for-cmh/', redirect: {name:'Product'} },
    { path: '/shop/ultra-sun-mh-1000-u-bt-37-4200k-lamp-12-cs/', redirect: {name:'Product'} },
    { path: '/shop/botanicare-10-gallon-reservoir-lid-white/', redirect: {name:'Product'} },
    { path: '/shop/86-mites-and-mold-1-gallon-concentrate-makes-5-gallons-4-cs/', redirect: {name:'Product'} },
    { path: '/shop/bud-ignitor/', redirect: {name:'Product'} },
    { path: '/shop/duralastics-tray-3-ft-x-6-ft-id-white/', redirect: {name:'Product'} },
    { path: '/shop/floraflex-10-5-in-matrix-pad-112-bag/', redirect: {name:'Product'} },
    { path: '/shop/hydro-flow-click-tif-pressure-compensated-dripper-w-check-valve-2-0-gph-green-1100-bag/', redirect: {name:'Product'} },
    { path: '/shop/nuke-em-8-oz-12-cs/', redirect: {name:'Product'} },
    { path: '/shop/sns-244-fungicide-rtu-quart-10-cs/', redirect: {name:'Product'} },
    { path: '/shop/growers-edge-cleanroom-sticky-mat-24-in-x-36-in-10-cs/', redirect: {name:'Product'} },
    { path: '/shop/leader-ecojet-130-1-hp-1-115-volt-1260-gph/', redirect: {name:'Product'} },
    { path: '/shop/snoops-premium-nutrients-grow-a-coco/', redirect: {name:'Product'} },
    { path: '/shop/hydro-flow-black-holes-dripper-3-16-barbed-inlet-11-bag/', redirect: {name:'Product'} },
    { path: '/shop/hydrodynamics-green-fuse-bloom-conc/', redirect: {name:'Product'} },
    { path: '/shop/mad-farmer-detox-1-gallon-4-cs/', redirect: {name:'Product'} },
    { path: '/shop/roots-organics-b2-professional-growing-mix-2-cu-ft-75-plt/', redirect: {name:'Product'} },
    { path: '/shop/sun-grip-push-button-heavy-duty-light-hanger-1-4-in-12-cs/', redirect: {name:'Product'} },
    { path: '/shop/vermicrop-vermiworm-75-cu-ft-60-plt/', redirect: {name:'Product'} },
    { path: '/shop/titan-controls-helios-10-pre-wired-8-light-240-volt-controller-w-trigger-cord-timer/', redirect: {name:'Product'} },
    { path: '/shop/measure-master-small-platform-scale-11-lb-5-kg-5000-g-capacity-x-1-g-accuracy-40-cs/', redirect: {name:'Product'} },
    { path: '/shop/sentinel-gps-cppm-4i-fuzzy-logic-co2-controller-4-cs/', redirect: {name:'Product'} },
    { path: '/shop/sun-grip-push-button-light-hanger-1-8-in-1-pair-12-cs/', redirect: {name:'Product'} },
    { path: '/shop/hydroworxx-disease-control-rtu-24-oz-12-cs/', redirect: {name:'Product'} },
    { path: '/shop/hydro-flow-dripper-stake-w-basket-blue-display-box-100-bag/', redirect: {name:'Product'} },
    //brand Tag
    { path: '/brand/bluelab/', redirect: {name:'Product'} },
    { path: '/brand/gardeneer/', redirect: {name:'Product'} },
    { path: '/brand/natures-nectar/', redirect: {name:'Product'} },
    { path: '/brand/ez-clone/', redirect: {name:'Product'} },
    { path: '/brand/luster-leaf/', redirect: {name:'Product'} },
    { path: '/brand/milwaukee/feed/', redirect: {name:'Product'} },
    { path: '/brand/wiggle-worm/', redirect: {name:'Product'} },
    { path: '/brand/rose-city/', redirect: {name:'Product'} },
    { path: '/brand/heavy-harvest/', redirect: {name:'Product'} },
    { path: '/brand/rainmaker/', redirect: {name:'Product'} },
    //Product Tag
    { path: '/product-tag/black-gold/', redirect: {name:'Product'} },
    { path: '/product-tag/growers-edge/', redirect: {name:'Product'} },
    { path: '/product-tag/oasis/?product_view=grid', redirect: {name:'Product'} },
    { path: '/product-tag/soil-savvy/feed/', redirect: {name:'Product'} },
    { path: '/product-tag/true-liberty-bags/', redirect: {name:'Product'} },
    { path: '/product-tag/dip-n-gro/', redirect: {name:'Product'} },
    { path: '/product-tag/safergro/', redirect: {name:'Product'} },
    { path: '/product-tag/sun-hut/', redirect: {name:'Product'} },
    { path: '/product-tag/gold-label/', redirect: {name:'Product'} },
    { path: '/product-tag/blacksmith-bioscience/?product_orderby=rating', redirect: {name:'Product'} },
    { path: '/product-tag/blacksmith-bioscience/', redirect: {name:'Product'} },
    { path: '/product-tag/sun-blaster/', redirect: {name:'Product'} },
    { path: '/product-tag/lighting-components/', redirect: {name:'Product'} },


    //link live redirect 
    { path: '/blog-details/a-beginners-guide-of-how-to-start-hydroponic-farming/AC', redirect: '/blog-details/how-to-start-hydroponic-farming' },
    { path: '/product-details/bloom-gallon', redirect: '/product-details/dyna-gro-bloom-gallon' },
    { path: '/product-details/calmag-2-5-gallon', redirect: '/product-details/drip-hydro-calmag-2-5-gallon' },
    { path: '/blog-details/how-close-should-grow-lights-be-to-plants-a-comprehensive-guide', redirect: '/blog-details/how-close-should-grow-lights-be-to-plants' },
    { path: '/product-details/can-a-wipes-cleaning-wipes-30-pk', redirect: '/product-details/canawipes-cleaning-wipes-30-pk' },
    { path: '/blog-details/the-ultimate-guide-to-indoor-and-outdoor-gardening-supplies-hydro-shop-and-store', redirect: '/product-details/canawipes-cleaning-wipes-30-pk' },


    //Category Broken Link
    // { path: '/sub-category/AC', redirect: '/sub-category/ac' },
    // { path: '/category/Air Purification', redirect: '/category/air-purification' },
    // { path: '/category/Fans', redirect: '/category/fans' },
    // { path: '/category/Hydro', redirect: '/category/hydro' },
    // { path: '/sub-category/Seedling Heat Mats', redirect: '/sub-category/seedling-heat-mats' },
    // { path: '/sub-category/RO', redirect: '/sub-category/ro' },
    // { path: '/sub-category/Film', redirect: '/sub-category/film' },
    // { path: '/sub-category/YOYOS', redirect: '/sub-category/yoyos' },
    // { path: '/sub-category/CO2', redirect: '/sub-category/co2' },
    // { path: '/sub-category/Heaters', redirect: '/sub-category/heaters' },
    // { path: '/sub-category/Pumps', redirect: '/sub-category/pumps' },
    // { path: '/sub-category/Scales', redirect: '/sub-category/scales' },
    // { path: '/sub-category/Timers', redirect: '/sub-category/timers' },
    // { path: '/sub-category/Flood Tables', redirect: '/sub-category/flood-tables' },
    // { path: '/sub-category/CO2 Regulator', redirect: '/sub-category/co2-regulator' },
    // { path: '/sub-category/Tubing', redirect: '/sub-category/tubing' },
    // { path: '/sub-category/Water Meters', redirect: '/sub-category/water-meters' },
    // { path: '/sub-category/Floor Fans', redirect: '/sub-category/floor-fans' },
    // { path: '/sub-category/Wall Fans', redirect: '/sub-category/wall-fans' },
    // { path: '/sub-category/Carbon Filters', redirect: '/sub-category/carbon-filters' },
    // { path: '/sub-category/Pipettes', redirect: '/sub-category/pipettes' },
    // { path: '/sub-category/Dollys', redirect: '/sub-category/dollys' },
    // { path: '/sub-category/Body Suits', redirect: '/sub-category/body-suits' },
    // { path: '/sub-category/Brackets', redirect: '/sub-category/brackets' },
    // { path: '/category/Controllers Instruments', redirect: '/category/controllers-instruments' },
    // { path: '/sub-category/Scalpels', redirect: '/sub-category/scalpels' },
    // { path: '/sub-category/Garden Ties', redirect: '/sub-category/garden-ties' },
    // { path: '/category/Sanitation Safety', redirect: '/category/sanitation-safety' },
    // { path: '/sub-category/Hoods', redirect: '/sub-category/hoods' },
    // { path: '/sub-category/Scissors', redirect: '/sub-category/scissors' },
    // { path: '/sub-category/Inline Fans', redirect: '/sub-category/inline-fans' },
    // { path: '/sub-category/Bags', redirect: '/sub-category/bags' },
    // { path: '/sub-category/Clip Fans', redirect: '/sub-category/clip-fans' },
    // { path: '/sub-category/Sprayers Foggers', redirect: '/sub-category/sprayers-foggers' },
    // { path: '/category/Plant Care', redirect: '/category/plant-care' },
    // { path: '/sub-category/Hoses', redirect: '/sub-category/hoses' },
    // { path: '/category/Growing Medium', redirect: '/category/growing-medium' },
    // { path: '/sub-category/Cloning&Seed starting', redirect: '/sub-category/cloning-seed-starting' },
    // { path: '/sub-category/Environment Controller', redirect: '/sub-category/environment-controller' },
    // { path: '/sub-category/Humidity Packs', redirect: '/sub-category/humidity-packs' },
    // { path: '/sub-category/Humidity Controller', redirect: '/sub-category/humidity-controller' },
    // { path: '/sub-category/Fabric Pots', redirect: '/sub-category/fabric-pots' },
    // { path: '/sub-category/Masks', redirect: '/sub-category/masks' },
    // { path: '/sub-category/Air Stones', redirect: '/sub-category/air-stones' },
    // { path: '/sub-category/Ducting', redirect: '/sub-category/ducting' },
    // { path: '/sub-category/Grow Tents', redirect: '/sub-category/grow-tents' },
    // { path: '/sub-category/Odor Eliminator', redirect: '/sub-category/odor-eliminator' },
    // { path: '/sub-category/Collars', redirect: '/sub-category/collars' },
    // { path: '/sub-category/Plastic Trellis', redirect: '/sub-category/plastic-trellis' },
    // { path: '/sub-category/Gloves', redirect: '/sub-category/gloves' },
    // { path: '/sub-category/Cups', redirect: '/sub-category/cups' },
    // { path: '/sub-category/Wands', redirect: '/sub-category/wands' },
    // { path: '/sub-category/Cubes', redirect: '/sub-category/cubes' },
    // { path: '/sub-category/Bulbs', redirect: '/sub-category/bulbs' },
    // { path: '/sub-category/Drying Racks', redirect: '/sub-category/drying-racks' },
    // { path: '/sub-category/Bins', redirect: '/sub-category/bins' },
    // { path: '/sub-category/Accessories', redirect: '/sub-category/accessories' },
    // { path: '/sub-category/Saucers', redirect: '/sub-category/saucers' },
    // { path: '/sub-category/Glasses', redirect: '/sub-category/glasses' },
    // { path: '/sub-category/Additives', redirect: '/sub-category/additives' },
    // { path: '/sub-category/Hard Pots', redirect: '/sub-category/hard-pots' },
    // { path: '/sub-category/Plant Labels', redirect: '/sub-category/plant-labels' },
    // { path: '/sub-category/Organic', redirect: '/sub-category/organic' },
    // { path: '/sub-category/Pre-Carbon Filters', redirect: '/sub-category/pre-carbon-filters' },
    // { path: '/sub-category/Cloners', redirect: '/sub-category/cloners' },
    // { path: '/sub-category/Filters', redirect: '/sub-category/filters' },
    // { path: '/category/Environment', redirect: '/category/environment' },
    // { path: '/category/Propagation', redirect: '/category/propagation' },
    // { path: '/category/Garden Accessories', redirect: '/category/garden-accessories' },
    // { path: '/category/Lights', redirect: '/category/lights' },
    // { path: '/category/Pest Disease', redirect: '/category/pest-disease' },
    // { path: '/category/Nutrients', redirect: '/category/nutrients' },
    
  ]
  
  export default additionalRoutes;