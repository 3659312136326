import { authHeader } from "../../../auth";
import { state } from "@/globalVariables";
import LocationSchemaJson from '../../seoPlugin/LocationSchemaJson';

export default {
    name: "Location",
    components: {
        LocationSchemaJson
    },
    data() {
        return {
            state,
            siteLocationCmsUrl: this.$serverURL + this.$api.home.LocationHomeCms,
            dataList: [],
            imageURL: this.$imageURL+'location-cms/',
            metaKeyword: "",
            metaDescription: "",
            homeLocationCms: {}
        }
    },
    async mounted() {
        await this.getPageTitles();
        document.title = (this.pageTitles?.locationPageTitle ? this.pageTitles?.locationPageTitle : '');
        this.metaKeyword = this.pageTitles.locationPageMetaKeyword;
        this.metaDescription = this.pageTitles.locationPageMetaDescription;
        await this.fetchData();
        await this.getLocationHomeCmsData();
    },
    methods: {
        checkIntoData() {
            this.$router.push("/home");
        },
        fetchData: async function() {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.location.requestURL,
                headers: {
                    "Authorization": authHeader()
                }
            };
            await this.$axios(config).then(async (response) => {
                if (response.data.statusCode == 200) {
                    this.dataList = await response.data.data;
                }
            }).catch(error => {
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },
        gotoLocationDetails(url) {
            this.$router.push(`/location-details/${url}`);
        },
        getLocationHomeCmsData: async function() {
            try {
                const config = {
                    method: "GET",
                    url: this.siteLocationCmsUrl
                };
                const response = await this.$axios(config);
                this.homeLocationCms = response?.data?.data?.[0];
            } catch (error) {
                console.log(error);
            }
        }
    }
}
