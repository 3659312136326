export default {
    data() {
        return {
            orderDetails: {},
            orders: [],
            orderId: this.$route.params.id,
            billInfo: {},
            shipInfo: {},
            imagePath: this.$imageURL + 'productImage/',
            orderDiscount: 0,
        }
    },
    async mounted() {
        await this.getOrderDetails();
        this.pageTitles = {
            checkoutPageMetaKeyword:"",
            checkoutPageMetaDescription:""
        };
        document.title = "Order Confirmation | HGSHYDRO";
    },
    methods: {
        getOrderDetails: async function () {
            try {
                const config = {
                    method: "GET",
                    url: this.$serverURL + this.$api.order.ordersDetailsWithoutGuard + '/' + this.orderId,
                    headers: {
                        "Content-Type": "application/json"
                    }
                };
                this.state.isLoading = true;
                await this.$axios(config).then((response) => {
                    this.state.isLoading = false;
                    if (response.data.statusCode == 200) {
                        this.orders = [];
                        if(response.data.Orders.length>0){
                            this.orderDetails = response.data.Orders[0];
                            this.billInfo = JSON.parse(response.data.Orders[0].billingAddress);
                            this.shipInfo = JSON.parse(response.data.Orders[0].shippingAddress);
                            this.orders = response.data.Orders[0].orders;
                            this.orderDiscount = +this.orderDetails.totalDiscount + +this.orderDetails.couponDiscount;
                        }
                    }
                    else {
                        this.$swal.fire({
                            icon: "error",
                            text: "No Order found with this order id "
                        });
                    }
                }).catch(error => {
                    this.state.isLoading = false;
                    console.log(error);
                });
            } catch (error) {
                this.state.isLoading = false;
                console.log(error);
            }
        },
    }
}