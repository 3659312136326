<template>
  <button :class="isVisible ? 'd-block' : 'd-none'" class="scroll-to-top-button" @click="scrollToTop">
    <i class="fas fa-chevron-up fa-2x"></i>
    <div class='fw-bold'>
      TOP
    </div>
  </button>
</template>

<script>
export default {
  data() {
    return {
      isVisible: false,
    }
  },
  mounted() {
    this.handleScroll = () => {
      const scrollY = window.scrollY;
      if (scrollY > 200) {
        this.isVisible = true;
      } else {
        this.isVisible = false;
      }
    };

    window.addEventListener('scroll', this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }
}
</script>

<style scoped>
.scroll-to-top-button {
  position: fixed;
  bottom: 60px;
  right: 50px;
  width: 60px;
  height: 60px; 
  font-size: 10px;
  border-radius: 50%;
  background-color: white;
  color: rgb(9, 134, 72);
  border: 2px solid rgb(9, 134, 72);
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease-in-out;
  z-index: 5;
  opacity: 1;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

@media (max-width: 768px) {
  .scroll-to-top-button {
    bottom:18px !important;
    right: 18px !important;
    width: 50px;
    height: 50px; 
    font-size: 8px;
    background-color: white;
    color: rgb(9, 134, 72);
    border: 2px solid rgb(9, 134, 72);
    border-radius: 100%;
  }
}
</style>