import { state } from '@/globalVariables';
import NewsLetter from '../../Newsletter/template/NewsLetter';
import Advertise from '../../Advertise/template/Advertise';
import CheckoutSummery from '../template/CheckoutSummery';
import CheckoutSchemajson from '../../seoPlugin/CheckoutSchemajson';

export default {
    components: {
        NewsLetter,
        Advertise,
        CheckoutSchemajson,
        CheckoutSummery
    },
    data() {
        return {
            state,
            paymentMethod: 'Credit/Debit Card',
            paymentInfo: {
                name: "",
                cardNumber: "",
                securityCode: ""
            },
            expireDate: "",
            shippingCostNew: localStorage.getItem('shippingData') ? JSON.parse(localStorage.getItem('shippingData'))?.shippingCost : 0,

        }
    },
    created() {
        const paymentAndCardInfo = localStorage.getItem('paymentAndCardInfo') ? JSON.parse(localStorage.getItem('paymentAndCardInfo')) : null;
        if (paymentAndCardInfo) {
            this.setPaymentInformation(paymentAndCardInfo);
        }

        this.pageTitles = {
            checkoutPageMetaKeyword:"",
            checkoutPageMetaDescription:""
        };
        document.title = "Checkout Enter Your Card Information | HGSHYDRO";
    },

    async mounted() {

    },
    methods: {
        setPaymentInformation(param) {
            this.paymentInfo = {
                name: param.name,
                cardNumber: param.cardNumber,
                securityCode: param.securityCode,
            };
            this.expireDate = param.expireDate
        },
        showSweetAlert(msg, icon) {
            this.$swal.fire({
                toast: true,
                position: 'top-end',
                text: msg,
                icon: icon,
                showConfirmButton: false,
                timer: 2000,
                animation: false,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            });
        },
        paymentalidation: function () {
            if (!this.paymentInfo.name) {
                this.showSweetAlert("Please provide card holder name.", "info");
                return false;
            } else if (!this.paymentInfo.cardNumber) {
                this.showSweetAlert("Please provide card number.", "info");
                return false;
            } else if (!this.expireDate) {
                this.showSweetAlert("Please provide card expire date.", "info");
                return false;
            } else if (!this.paymentInfo.securityCode) {
                this.showSweetAlert("Please provide card security code.", "info");
                return false;
            } else {
                if (!this.expireDate.includes('/')) {
                    this.showSweetAlert("Expire date format should be 02/22.", "info")
                    return false;
                }
                return true;
            }
        },
        handlePayment: async function () {
            if (this.paymentMethod == 'Credit/Debit Card') {
                if (this.paymentalidation()) {
                    const paymentAndCard = {
                        paymentMethod: this.paymentMethod,
                        name: this.paymentInfo.name,
                        cardNumber: this.paymentInfo.cardNumber,
                        securityCode: this.paymentInfo.securityCode,
                        expireDate: this.expireDate,
                    };
                    localStorage.setItem('paymentAndCardInfo', JSON.stringify(paymentAndCard));
                    this.$router.push('/checkout-complete-order');
                }
            } else {
                this.$swal.fire({
                    icon: "error",
                    text: "Please select payment method."
                });
                return;
            }
        },
    },

    watch: {
        expireDate: function () {
            if (this.expireDate.length == 2) {
                this.expireDate += '/';
            }
        },
    }
}