<template>
  <main class="bg_gray">
    <div class="container margin_30">
      <div class="page_header">
        <div class="breadcrumbs">
          <ul>
            <li>
              <a href="javascript:void(0)" @click="$router.push('/home')">Home</a>
            </li>
            <li>
              <a href="#">Checkout</a>
            </li>
            <!-- <li>Page active</li> -->
          </ul>
        </div>
        <h1 class="text-center">Please Click Place Order To Complete Your Order</h1>
      </div>
      <div class="container custom-width">
        <div class="border" style="background-color: white;">
          <div
            class="d-flex justify-content-between align-items-center p-3"
            style="border-bottom: 1px solid rgb(203, 202, 202);"
          >
            <p class="mb-0">
              <i class="fa-solid fa-user me-2"></i>
              {{billingAddressData?.email}}
            </p>
            <img src="../../../../public/core/images/logo.png" style="width:90px" />
          </div>
          <div
            class="d-flex justify-content-between align-items-center p-3"
            style="border-bottom: 1px solid rgb(203, 202, 202);"
            @click="$router.push('/checkout-billing')"
            :style="'cursor:pointer'"
          >
            <p class="mb-0">Bill to</p>
            <p
              class="mb-0 fw-bold w-50 text-start fs-12"
            >{{billingAddressData?.address}} {{', '+billingAddressData?.postCode}} {{',' + billingAddressData?.city }} {{', '+billingAddressData?.stateCode }}</p>
            <div class="d-sm-none d-lg-flex icon-checkout-hover">
                <div class="ti-pencil-alt"></div>
            </div>
          </div>
          <div
            class="d-flex justify-content-between align-items-center p-3"
            style="border-bottom: 1px solid rgb(203, 202, 202);"
            @click="$router.push('/checkout-shipping')"
            :style="'cursor:pointer'"
          >
            <p class="mb-0">Ship to</p>
            <p class="mb-0 fw-bold text-start fs-12" v-if="shippingSameAsBilling">Same as Billing</p>
            <p
              class="mb-0 fw-bold text-start fs-12"
              v-else
            >{{shippingAddressData?.address}} {{', '+shippingAddressData?.postCode}} {{',' + shippingAddressData?.city }} {{', '+shippingAddressData?.stateCode }}</p>
            <div class="d-sm-none d-lg-flex icon-checkout-hover">
                <div class="ti-pencil-alt"></div>
            </div>
          </div>
          <div
            class="d-flex justify-content-between align-items-center p-3"
            style="border-bottom: 1px solid rgb(203, 202, 202);"
            @click="$router.push('/checkout-shipping-methods')"
            :style="'cursor:pointer'"
          >
            <p class="mb-0">Shipping Method</p>
            <p class="mb-0 fw-bold text-start fs-12">{{shippingType}}({{serviceTypeName}})</p>
            <div class="d-sm-none d-lg-flex icon-checkout-hover">
                <div class="ti-pencil-alt"></div>
            </div>
            <!-- <div class="d-none d-lg-flex" /> -->
          </div>
          <div
            class="d-flex justify-content-between align-items-center p-3"
            style="border-bottom: 1px solid rgb(203, 202, 202);"
            @click="$router.push('/checkout-payment')"
            :style="'cursor:pointer'"
          >
            <p class="mb-0">Card</p>
            <p
              class="mb-0 fw-bold text-start fs-12"
            >************{{paymentInfo?.cardNumber.slice(12,16)}}</p>
            <div class="d-sm-none d-lg-flex icon-checkout-hover">
                <div class="ti-pencil-alt"></div>
            </div>
          </div>
        </div>
        <div class="border mt-3" style="background-color: white;">
          <div
            class="d-flex justify-content-between align-items-center p-3"
            style="border-bottom: 1px solid rgb(203, 202, 202);"
          >
            <p class="mb-0"><i class="ti-shopping-cart-full"></i> Order Summary</p>
            <p class="mb-0 fw-bold text-start fs-12"></p>
            <div class="d-none d-lg-flex" />
          </div>
          <div
            class="d-flex justify-content-between align-items-center pt-3 px-3"
            style="border-bottom: 1px solid rgb(203, 202, 202);"
          >
            <div class="form-group d-flex w-100 gap-3">
              <input
                type="text"
                name="coupon-code"
                v-model="couponCode"
                placeholder="Promo code"
                class="form-control"
                style="height:41px"
              />
              <div class>
                <button type="button" class="btn_1 outline" @click="applyCoupon()">Apply</button>
              </div>
            </div>
          </div>
          <div class="p-3">
            <div class="d-flex justify-content-between align-items-center mb-1">
              <p class="mb-0">Subtotal</p>
              <p class="mb-0 fw-bold text-start fs-12">${{ state.totalAmount.toFixed(2) }}</p>
            </div>
            <div class="d-flex justify-content-between align-items-center mb-1">
              <p class="mb-0">
                Taxes ({{
                taxExemptApproved? 0 :
                parseFloat(state.salesTax?.percentage).toFixed(2)
                }}%):
              </p>
              <p
                class="mb-0 fw-bold text-start fs-12"
              >$(+)${{ taxExemptApproved? 0: calculateTax() }}</p>
            </div>
            <div class="d-flex justify-content-between align-items-center mb-2">
              <p class="mb-0">Shipping</p>
              <p class="mb-0 fw-bold text-start fs-12">${{shippingCost}}</p>
            </div>
            <div
              class="d-flex justify-content-between align-items-center mb-2"
              v-if="state.couponDiscount"
            >
              <p class="mb-0">Coupon Discount</p>
              <p class="mb-0 fw-bold text-start fs-12">${{ state.couponDiscount }}</p>
            </div>
            <div class="d-flex justify-content-between align-items-center">
              <p class="mb-0 fw-bold">Total</p>
              <p class="mb-0 fw-bold text-start">${{calculateGrandTotal()}}</p>
            </div>
          </div>
        </div>
        <button
          type="button"
          @click="placeOrder"
          class="custom-pay-now-button w-100 mt-3 p-2 rounded-3 checkout-complete-order-request"
        ><i class="ti-check-box"></i> PLACE ORDER</button>
        <!-- Order Summery -->
        <!-- <div class="mt-3">
              <CheckoutSummery/>
        </div>-->
        <!-- Order Summery -->
      </div>
    </div>
    <!-- /page_header -->
  </main>
  <CheckoutSchemajson v-if="Object.values(pageTitles).length > 0" :metaData="pageTitles" />
</template>
  <script src="../js/checkoutCompleteOrder.js"></script>
  <style scoped>
@import "../css/checkout.css";

.hr-padding {
  margin: 15px 0 15px 0 !important;
  border: 0;
  border-top: 1px solid #dddddd;
}

.icon-checkout-hover > div:hover{
  color: #FFC107;
}
</style>