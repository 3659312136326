import { state } from '@/globalVariables';
export default {
    data() {
        return {
            state,
            image: localStorage.getItem('image'),
            hasImageFile: false,
            updateImage: "",
            previewImage: "",
            name: localStorage.getItem("name")
        }
    },
    // async created() {
    //     var btnContainer = document.getElementById("nav");
    //     var btns = btnContainer.getElementsByClassName("nav-item");
    //     for (var i = 0; i < btns.length; i++) {
    //         btns[i].addEventListener("click", function() {
    //             var current = document.getElementsByClassName("active");

    //             // If there's no active class
    //             if (current.length > 0) {
    //                 current[0].className = current[0].className.replace(" active", "");
    //             }

    //             // Add the active class to the current/clicked button
    //             this.className += " active";
    //         });
    //     }

    // },
    methods: {
        activeNavItem: function(index) {

            let itemClass = document.getElementsByClassName("nav-item");

            for (let i = 0; i < itemClass.length; i++) {

                if (itemClass[i].classList.contains("active")) {
                    itemClass[i].classList.remove("active")
                }
            }
            itemClass[index].classList.add("active")


        },
        activeSubNav: function(subIndex) {
            let subClass = document.getElementsByClassName("subNav");

            for (let i = 0; i < subClass.length; i++) {
                if (subClass[i].classList.contains("active")) {
                    subClass[i].classList.remove("active")
                }
            }
            subClass[subIndex].classList.add("active")
        }
    },
    mounted() {
        var styles = [
            '/core/assets/css/listing.css'
        ]
        styles.forEach(style => {
            let tag = document.createElement('link');
            tag.type = "text/css";
            tag.rel = "stylesheet";
            tag.href = style;
            document.head.appendChild(tag);
        });

        var scripts = [
            "/core/assets/js/sticky_sidebar.min.js",
            "/core/assets/js/specific_listing.js",
            "/core/assets/js/main.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });


    }
}