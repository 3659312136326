<template>
  <teleport to="head">
    <link rel="canonical" :href="$route.path" />
    
    <meta name="title" :content="catMeataData.productPagetitle" />
    <meta name="keywords" :content="catMeataData.productPageMetaKeyword" />
    <meta name="description" :content="catMeataData.productPageMetaDescription" />
    <meta name="author" content="HGSHYDRO">
    <meta name="publisher" content="HGSHYDRO">
    <component :is="'script'" type="application/ld+json">
      {
      "@context": "https://schema.org/",
      "@type": "WebSite",
      "name": "HGS Hydro",
      "url": "https://hgshydro.com/",
      "potentialAction": {
      "@type": "SearchAction",
      "target": "https://hgshydro.com/products?keyword={search_term_string}",
      "query-input": "required name=search_term_string"
      }
      }
    </component>
    <component :is="'script'" type="application/ld+json">
      {
      "@context": "https://schema.org/",
      "@type": "WebSite",
      "name": "HGS Hydro",
      "url": "https://hgshydro.com/",
      "potentialAction": {
      "@type": "SearchAction",
      "target": "https://hgshydro.com/products?keyword={search_term_string}",
      "query-input": "required name=search_term_string"
      }
      }
    </component>
  </teleport>
</template>

<script>
export default {
  name: "CategorySchemajson ",
  props: ["categoryMeta"],
  data() {
    return {
      catMeataData: {}
    };
  },
  created() {
    console.log("in category seo", this.metaData);
  },
  watch: {
    categoryMeta: {
      immediate: true,
      handler(val, oldVal) {
        oldVal;
        this.catMeataData = val;
      }
    }
  }
};
</script>
