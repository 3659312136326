
export default {

    props: ["successData"],
    data() {
        return {
            data: {}
        }
    },
    
    async mounted() {
        console.log("this.$route.successData",JSON.parse(this.$route.params.objectParam));
    },
    watch: {
        successData: async function () {
            this.data = JSON.parse(this.$route.params.objectParam);
            console.log("this.data==",this.data);
        }
    }
}