<template>
  <teleport to="head">
    <link rel="canonical" :href="$route.path" />
    <meta name="keywords" :content="metaData.salePageMetaKeyword" />
    <meta name="description" :content="metaData.salePageMetaDescription" />
    <meta name="author" content="HGSHYDRO">
    <meta name="publisher" content="HGSHYDRO">
    <component :is="'script'" type="application/ld+json">
      {
      "@context": "https://schema.org/",
      "@type": "WebSite",
      "name": "HGS Hydro",
      "url": "https://hgshydro.com/",
      "potentialAction": {
      "@type": "SearchAction",
      "target": "https://hgshydro.com/products?keyword={search_term_string}",
      "query-input": "required name=search_term_string"
      }
      }
    </component>
    <component :is="'script'" type="application/ld+json">
      {
      "@context": "https://schema.org/",
      "@type": "WebSite",
      "name": "HGS Hydro",
      "url": "https://hgshydro.com/",
      "potentialAction": {
      "@type": "SearchAction",
      "target": "https://hgshydro.com/products?keyword={search_term_string}",
      "query-input": "required name=search_term_string"
      }
      }
    </component>
  </teleport>
</template>

<script>
export default {
  name: "Sale Schema json ",
  props: ["metaData"],
  data() {
    return {
      homeMetaKeyword: "",
      homeMetaDescription: ""
    };
  },
  created() {
    console.log("meta data", this.metaData);
  }
};
</script>
