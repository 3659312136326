import { state } from '@/globalVariables';
import NewsLetter from '../../Newsletter/template/NewsLetter';
import Advertise from '../../Advertise/template/Advertise';
import { authHeader } from '@/auth';
import { states } from '../../../../config/states-list';
import CheckoutSchemajson from '../../seoPlugin/CheckoutSchemajson';

export default {
    components: {
        NewsLetter,
        Advertise,
        CheckoutSchemajson,
    },
    data() {
        return {
            state,
            states: states,
            products: [],
            getAddressURL: this.$serverURL + this.$api.profile.getShippingBillingAddress,
            paymentMethod: "Credit/Debit Card",
            remarks: "",
            shippingType: "",
            serviceType: "",
            serviceTypeName: "",
            shippingCost: 0,
            serviceTypeList: [],
            pickUpTypeList: [],
            packageType: "",
            pickUpType: "",
            totalWeight: 0,
            orderConfirmed: false,
            showCard: false,
            paymentInfo: {
                name: "",
                cardNumber: "",
                securityCode: ""
            },
            expireDate: "",
            orderId: "",
            disableShipping: true,

            same: false,
            token: localStorage.getItem('token') ? localStorage.getItem('token') : null,
            taxAmount: 0,
            actualProductPrice: 0,
            shippingMethodList: [],
            test: "",
            shippingCmsData: {},
            imagePath: this.$imageURL + 'shipping-methods/',
            userId: localStorage.getItem('id'),
            taxExemptApproved: false,
            grandAmount: 0,
            salesTaxData: "",
            activeShipping: false,
            couponList: [],
            couponCode: "",
            isFreeShipping: false,
            appliedCoupon: {},
            isBillingAddress: true,
            shippingSameAsBilling: localStorage.getItem('shippingSameAsBilling') ? JSON.parse(localStorage.getItem('shippingSameAsBilling')) : false,
            shippingAddressData: {
                name: null,
                businessName: null,
                address: "",
                mobile: null,
                email: null,
                postCode: null,
                city: null,
                stateCode: null
            },
            billingAddressData: {
                name: null,
                businessName: null,
                address: "",
                mobile: null,
                email: null,
                postCode: null,
                city: null,
                stateCode: null
            },

        }
    },
    async created() {
        this.setAllSelectedValues();
        await this.formatProductList();
        await this.getCoupons();
        //document.title = (this.pageTitles?.checkoutPageTitle ? this.pageTitles?.checkoutPageTitle : '');
        this.pageTitles = {
            checkoutPageMetaKeyword:"",
            checkoutPageMetaDescription:""
        };
        document.title = "Checkout Place Order Request | HGSHYDRO";
    },

    computed: {
        toogleFreeShipping() {
            return this.state.isFreeShippingApplied;
        },

        isWeightExided() {
            return this.state.weightExided;
        }

    },

    async mounted() {
        this.calculateWeight();
        this.calculateTotalAmount();
        this.calculateGrandTotal();
        this.grandTotal = this.state.totalAmount;
        await this.getUserTaxExemptInfo();
    },
    methods: {

        async formatProductList() {
            this.products = await this.state.cartValue.map(el => {
                let variants = [];
                this.actualProductPrice = this.actualProductPrice + (el.data.price * el.quantity);
                return {
                    'productId': el.data.id,
                    'quantity': el.quantity,
                    'actualPrice': el.data.price,
                    "variants": variants,
                    'itemPrice': this.findMixinActualPrice(el.data),
                    'itemSubTotal': (this.findMixinActualPrice(el.data)) * el.quantity
                };
            });
        },

        setAllSelectedValues() {
            localStorage.setItem('inCompleteOrderPage', true);

            const shippingAddress = localStorage.getItem('tempShippingAddress') ? JSON.parse(localStorage.getItem('tempShippingAddress')) : null;
            if (shippingAddress) {
                this.shippingAddressData = shippingAddress;
            }
            const billingAddress = localStorage.getItem('tempBillingAddress') ? JSON.parse(localStorage.getItem('tempBillingAddress')) : null;
            if (billingAddress) {
                this.billingAddressData = billingAddress;
            }

            const shippingData = localStorage.getItem('shippingData') ? JSON.parse(localStorage.getItem('shippingData')) : null;
            if (shippingData) {
                this.shippingType = shippingData.shippingType;
                this.serviceType = shippingData.serviceType;
                this.shippingCost = shippingData.shippingCost;
                this.serviceTypeName = shippingData.serviceTypeName;
            }

            const paymentAndCardInfo = localStorage.getItem('paymentAndCardInfo') ? JSON.parse(localStorage.getItem('paymentAndCardInfo')) : null;
            if (paymentAndCardInfo) {
                this.paymentMethod = paymentAndCardInfo.paymentMethod;
                this.paymentInfo = {
                    name: paymentAndCardInfo.name,
                    cardNumber: paymentAndCardInfo.cardNumber,
                    securityCode: paymentAndCardInfo.securityCode,
                };
                this.expireDate = paymentAndCardInfo.expireDate;
            }
        },

        async getUserTaxExemptInfo() {
            try {
                let config = {
                    method: "GET",
                    url: this.$serverURL + this.$api.taxExempt.getUserTaxExemptURL + '/' + this.userId,
                    headers: {
                        "Authorization": authHeader()
                    }
                }
                await this.$axios(config).then(async (response) => {
                    if (response.data.statusCode == 200) {
                        if (response.data.statusCode == 200) {
                            if (response.data.data.length > 0) {
                                let data = response.data.data[0];
                                this.taxExemptApproved = data.isApproved;
                            } else {
                                this.taxExemptApproved = false;
                            }
                        }
                    }

                }).catch(error => {
                    console.log(error);
                });
            } catch (error) {
                console.log(error)
            }
        },

        async getCoupons() {
            try {
                let config = {
                    method: "GET",
                    url: this.$serverURL + this.$api.coupon.getCouponsURL,
                    header: {
                        'Content-Type': "application/json"
                    }
                };
                await this.$axios(config).then(response => {
                    this.couponList = response.data.data;
                    for (let index = 0; index < this.couponList.length; index++) {
                        const cupon = this.couponList[index];
                        if (cupon.couponType == "free shipping") {
                            if (this.state.totalAmount >= parseInt(cupon.discount)) {
                                this.isFreeShipping = true;
                                this.appliedCoupon = cupon;
                                // this.shippingCost = 0;
                                // this.serviceType = "Free Service";
                                // this.shippingType = "Free Shipping";
                            } else {
                                this.isFreeShipping = false;
                            }
                        }
                    }
                }).catch(error => {
                    console.log(error);
                })
            } catch (error) {
                console.log(error);
            }
        },

        applyCoupon() {
            let data = this.couponList.find(el => el.couponCode == this.couponCode)
            if (data) {
                this.appliedCoupon = data;
                this.state.couponId = data.couponCode;
                if (data.couponType == 'percent') {
                    this.state.couponDiscount = this.state.totalAmount * (parseFloat(data.discount) / 100);
                } else if (data.couponType == 'welcome') {
                    this.state.couponDiscount = parseFloat(parseFloat(this.state.totalAmount) * parseFloat((data.discount) / 100)).toFixed(2);
                } else {
                    this.state.couponDiscount = parseFloat(data.discount);
                }
            } else {
                this.showSweetAlert('Not a valid coupon!!', 'error');
            }
        },

        updateShippingAddress: async function () {
            try {
                let data = {
                    name: this.shippingAddressData.name,
                    businessName: this.shippingAddressData.businessName,
                    addressOne: this.shippingAddressData.addressOne,
                    addressTwo: this.shippingAddressData.addressTwo,
                    mobile: this.shippingAddressData.mobile,
                    email: this.shippingAddressData.email,
                    postCode: this.shippingAddressData.postCode,
                    stateCode: this.shippingAddressData.stateCode,
                    city: this.shippingAddressData.city,
                    country: 'United States of America',
                    countryCode: 'US',
                };
                let config = {
                    method: "POST",
                    url: this.$serverURL + this.$api.profile.shippingAddressURL,
                    data: data,
                    headers: {
                        "Authorization": authHeader()
                    }

                };
                await this.$axios(config).then((response) => {
                    if (response.data.statusCode) {
                        return;
                    }
                }).catch(error => {
                    console.log(error);
                });
            } catch (error) {
                console.log(error);
            }
        },

        updateBillingAddress: async function () {
            try {
                let data = {
                    name: this.billingAddressData.name,
                    businessName: this.billingAddressData.businessName,
                    address: this.billingAddressData.address,
                    mobile: this.billingAddressData.mobile,
                    email: this.billingAddressData.email,
                    postCode: this.billingAddressData.postCode,
                    stateCode: this.billingAddressData.stateCode,
                    city: this.billingAddressData.city,
                    country: 'United States of America',
                    countryCode: 'US',
                };
                let config = {
                    method: "POST",
                    url: this.$serverURL + this.$api.profile.billingAddressURL,
                    data: data,
                    headers: {
                        "Authorization": authHeader()
                    }

                };
                await this.$axios(config).then((response) => {
                    if (response.data.statusCode) {
                        return
                    }
                }).catch(error => {
                    console.log(error)
                });
            } catch (error) {
                console.log(error);
            }
        },

        finalValidation() {
            if (!this.billingAddressData) {
                this.showSweetAlert("Billing information is missing", "info");
                this.$router.push('/checkout-billing');
                return;
            } else if (!this.shippingAddressData) {
                this.showSweetAlert("Shipping information is missing", "info");
                this.$router.push('/checkout-shipping');
                return;
            } else if (!this.shippingType) {
                this.showSweetAlert("Shipping method information is missing", "info");
                this.$router.push('/checkout-shipping-methods');
                return;
            } else if (!this.paymentInfo.cardNumber) {
                this.showSweetAlert("Payment information is missing", "info");
                this.$router.push('/checkout-payment');
                return;
            }
            return true;
        },

        placeOrder: async function () {
            if (this.finalValidation()) {
                try {
                    this.state.isLoading = true;
                    if (this.token) {
                        this.updateBillingAddress();
                        this.updateShippingAddress();
                    }
                    let data = {
                        shippingAddress: JSON.stringify({
                            name: this.shippingAddressData.name, addressOne: this.shippingAddressData.address, addressTwo: this.shippingAddressData.addressTwo, mobile: this.
                                shippingAddressData.mobile, email: this.shippingAddressData.email, postCode: this.shippingAddressData.postCode, stateCode: this.shippingAddressData.stateCode, city: this.shippingAddressData.city, country: 'United States of America', countryCode: 'US',
                        }),
                        billingAddress: JSON.stringify({
                            name: this.billingAddressData.name, address: this.billingAddressData.address, mobile: this.billingAddressData.mobile, email: this.
                                billingAddressData.email, stateCode: this.billingAddressData.stateCode, postCode: this.billingAddressData.postCode, city: this.billingAddressData.city, country: 'United States of America', countryCode: 'US',
                        }),
                        shippingType: this.shippingType,
                        serviceType: this.serviceType,
                        weightValue: this.totalWeight,
                        products: this.products,
                        paymentMethodName: this.paymentMethod,
                        amount: this.grandAmount,
                        shippingCost: this.shippingCost,
                        remarks: this.remarks,
                        ccNum: this.paymentInfo.cardNumber,
                        ccExp: this.expireDate ? this.expireDate.replace('/', '') : '',
                        cvv: this.paymentInfo.securityCode,
                        coupon: this.state.couponId,
                        salesTaxTitle: this.state.salesTax?.name,
                        salesTaxRate: (this.taxExemptApproved == true) ? 0 : this.state.salesTax?.percentage,
                        salesTaxAmount: (this.taxExemptApproved == true) ? 0 : this.taxAmount.toFixed(2),
                    }

                    let config = {
                        method: "POST",
                        url: this.$serverURL + this.$api.order.orderURL,
                        data: data,
                        headers: {
                            "Authorization": authHeader()
                        }
                    };
                    let guestConfig = {
                        method: "POST",
                        url: this.$serverURL + this.$api.order.guestOrderURL,
                        data: data
                    }
                    this.state.isLoading = true;
                    await this.$axios(this.token ? config : guestConfig).then((response) => {
                        this.state.isLoading = false;
                        if (response.data.statusCode == 200) {
                            this.state.cartValue = [];
                            this.state.cartCounter = 0;

                            if (this.state.isNewUser == true) {
                                this.state.isNewUser = false;
                            }
                            this.showSweetAlert(response.data.message, 'success');
                            this.$router.push(`order-confirm/${response.data.orderId}`);
                            localStorage.setItem(this.$cartList, JSON.stringify(this.state.cartValue));
                            localStorage.removeItem('isFreeShipping');
                            localStorage.removeItem('shippingData');
                            localStorage.removeItem('inCompleteOrderPage');
                        } else if (response.data.statusCode == 400) {
                            this.$swal.fire({
                                toast: true,
                                position: 'top-end',
                                text: response.data.message,
                                icon: "error",
                                showConfirmButton: false,
                                timer: 3000,
                                animation: false,
                                timerProgressBar: true,
                                didOpen: (toast) => {
                                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                                }
                            }).then(result => {
                                console.log(result);
                                this.$swal.fire({
                                    toast: true,
                                    position: 'top-end',
                                    text: response.data.errors.responsetext,
                                    icon: "error",
                                    showConfirmButton: false,
                                    timer: 3000,
                                    animation: false,
                                    timerProgressBar: true,
                                    didOpen: (toast) => {
                                        toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                        toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                                    }
                                })
                            });
                        }
                    }).catch(error => {
                        this.state.isLoading = false;
                        this.showSweetAlert(error.response.data.message, 'error');
                    });

                } catch (error) {
                    this.state.isLoading = false;
                    console.log(error);
                    this.showSweetAlert("Something went wrong, please try agian later", 'error');
                }
            }
        },

        calculateWeight: function () {
            this.totalWeight = 0;
            for (let i = 0; i < this.state.cartValue.length; i++) {
                this.totalWeight = this.totalWeight + (parseFloat(this.state.cartValue[i].data.weight) * this.state.cartValue[i].quantity);
            }
        },

        calculateTax() {
            this.taxAmount = (this.actualProductPrice * (this.state.salesTax.percentage / 100));
            return this.taxAmount.toFixed(2);
        },

        calculateGrandTotal() {
            let taxAmount = 0;
            if (this.taxExemptApproved == true) {
                taxAmount = 0
            } else {
                taxAmount = this.taxAmount.toFixed(2);
            }
            // console.log(taxAmount);
            if (state.couponDiscount) {
                this.grandAmount = (this.state.totalAmount + this.shippingCost - this.state.couponDiscount + parseFloat(taxAmount)).toFixed(2);
                return this.grandAmount;
            } else {
                this.grandAmount = (this.state.totalAmount + this.shippingCost + parseFloat(taxAmount)).toFixed(2);
                return this.grandAmount;
            }
        },

        handleSalesTax: async function () {
            let stateObject = states.find(x => x.code === this.shippingAddressData.stateCode);
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.salesTax.findByStateURL + '/' + stateObject.name
            };
            this.state.isLoading = true;
            await this.$axios(config).then(response => {
                this.state.isLoading = false;
                if (response.data.statusCode == 200) {
                    this.salesTaxData = response.data.data;
                    if (Object.keys(this.salesTaxData).length != 0) {
                        let salesTaxAmount = this.salesTaxData[0].taxAmount;
                        this.state.salesTax.percentage = Number(salesTaxAmount);
                    } else {
                        this.state.salesTax.percentage = this.state.salesTax.globalTax;
                    }
                }
            }).catch(error => {
                this.state.isLoading = false;
                console.log(error)
            });
        },

        showSweetAlert(msg, icon) {
            this.$swal.fire({
                toast: true,
                position: 'top-end',
                text: msg,
                icon: icon,
                showConfirmButton: false,
                timer: 2000,
                animation: false,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            });
        },
    },

    watch: {
        toogleFreeShipping: function () {
            this.setAllSelectedValues();
            this.calculateGrandTotal();
        }
    }
}