import { authHeader } from "../../../auth";
import { state } from '@/globalVariables';
import NewsLetter from '../../Newsletter/template/NewsLetter';
import Advertise from '../../Advertise/template/Advertise';
// import VPagination from "@hennge/vue3-pagination";
// import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import VPagination from "../../pagination/VPagination";
import BlogSchemajson from '../../seoPlugin/BlogSchemajson'
import VueLazyload from 'vue-lazyload';

export default {
    components: {
        NewsLetter,
        Advertise,
        BlogSchemajson,
        VPagination
    },
    directives: {
        lazy: VueLazyload.directive,
    },
    data() {
        return {
            state,
            placeholderLazyLoading:'https://hgshydro.nyc3.cdn.digitaloceanspaces.com/default.jpg',
            blogPageCMS: {},
            blogPostList: [],
            blogCategoryList: [],
            formatedBlogCategoryList: [],
            latestBlogs: [],
            blogTagList: [],
            formatedBlogTagList: [],
            keyword: '',
            page: +this.$route.query.page || 1,
            limit: 10,
            totalPage: 0,
            imagePath: this.$imageURL + 'blog/',
            setURL: this.$serverURL + this.$api.blog.blogPageAPI,
            params: '',
            categoryId: '',
            tag: '',
            date: [],
        }

    },
    async created() {
        await this.getPageTitles();
        document.title = (this.pageTitles?.blogPageTitle ? this.pageTitles?.blogPageTitle : '');
        this.state.isLoading = true;
        this.scrollToTop();
    },
    async mounted() {
        this.params = "?page=" + this.page + "&limit=" + this.limit;
        if (this.$route.query.categoryId) {
            this.params += "&categoryId=" + this.$route.query.categoryId;
        }
        if (this.$route.query.keyword) {
            this.params += "&keyword=" + this.this.$route.query.keyword;
        }
        if (this.$route.query.tag) {
            this.params += "&tag=" + this.$route.query.tag;
        }

        let config = {
            method: "GET",
            url: this.setURL + this.params,
            headers: {
                "Authorization": authHeader()
            }
        };
        await this.getBlogList(config);
        this.loadCSS();
    },
    methods: {
        updateHandler: async function () {
            this.params = "?page=" + this.page + "&limit=" + this.limit;
            if (this.$route.query.categoryId) {
                this.params += "&categoryId=" + this.$route.query.categoryId;
            }
            if (this.$route.query.keyword) {
                this.params += "&keyword=" + this.this.$route.query.keyword;
            }
            if (this.$route.query.tag) {
                this.params += "&tagName=" + this.$route.query.tag;
            }

            let config = {
                method: "GET",
                url: this.setURL + this.params,
                headers: {
                    "Authorization": authHeader()
                }
            };
            await this.getBlogList(config);
        },
        loadCSS: function () {
            this.state.isLoading = true;
            var styles = [
                '/core/assets/css/blog.css'
            ]
            styles.forEach(style => {
                let tag = document.createElement('link');
                tag.type = "text/css";
                tag.rel = "stylesheet";
                tag.href = style;
                document.head.appendChild(tag);
            });
            var scripts = [
                "/core/assets/js/main.js",
            ];
            scripts.forEach(script => {
                let tag = document.createElement("script");
                tag.setAttribute("src", script);
                document.head.appendChild(tag);

            });
            this.state.isLoading = false;
        },
        searchCategory: async function (catId) {
            this.page = 1;
            this.limit = 6;
            this.categoryId = catId;
            this.params = "?page=" + this.page + "&limit=" + this.limit;
            if (this.categoryId) {
                this.params += "&categoryId=" + this.categoryId;
            }
            if (this.keyword) {
                this.params += "&keyword=" + this.keyword;
            }
            if (this.tag) {
                this.params += "&tagName=" + this.tag;
            }
            let config = {
                method: "GET",
                url: this.setURL + this.params,
                headers: {
                    "Authorization": authHeader()
                }
            };
            await this.getBlogList(config);

        },
        searchKeyword: async function () {
            this.page = 1;
            this.limit = 6;
            this.params = "?page=" + this.page + "&limit=" + this.limit;
            if (this.categoryId) {
                this.params += "&categoryId=" + this.categoryId;
            }
            if (this.keyword) {
                this.params += "&keyword=" + this.keyword;
            }
            if (this.tag) {
                this.params += "&tagName=" + this.tag;
            }
            let config = {
                method: "GET",
                url: this.setURL + this.params,
                headers: {
                    "Authorization": authHeader()
                }
            };
            await this.getBlogList(config);
        },
        searchTag: async function (tagName) {
            this.page = 1;
            this.limit = 6;
            this.tag = tagName;
            this.params = "?page=" + this.page + "&limit=" + this.limit;
            if (this.categoryId) {
                this.params += "&categoryId=" + this.categoryId;
            }
            if (this.keyword) {
                this.params += "&keyword=" + this.keyword;
            }
            if (this.tag) {
                this.params += "&tagName=" + this.tag;
            }
            let config = {
                method: "GET",
                url: this.setURL + this.params,
                headers: {
                    "Authorization": authHeader()
                }
            };
            await this.getBlogList(config);

        },
        getBlogList: async function (config) {
            this.state.isLoading = true;
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {
                    this.blogPageCMS = await response.data.data.cmsData;
                    this.blogPostList = await response.data.data.blogList.posts;
                    this.totalPage = await response.data.data.blogList.pageCount;
                    this.blogCategoryList = await response.data.data.blogCategoryList;
                    this.formatedBlogCategoryList = response.data.data.blogCategoryList.map((el) => {
                        return { value: el.id, label: el.categoryName };
                    })
                    this.blogTagList = await response.data.data.blogTagList;
                    this.formatedBlogTagList = response.data.data.blogTagList.map((el) => {
                        return { value: el.tagName, label: el.tagName }
                    })
                    this.latestBlogs = await response.data.data.latestBlogList
                    this.state.isLoading = false;
                }
                this.state.isLoading = false;
            }).catch(error => {
                this.state.isLoading = false;
                console.log(error);
            });
        },

        showToast(msg) {
            this.$swal.fire({
                toast: true,
                position: 'top-end',
                text: msg,
                icon: "info",
                showConfirmButton: false,
                timer: 3000,
                animation: false,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            })
        },

        async topFilter() {
            if (!this.categoryId && !this.tag && this.date?.length == 0) {
                this.showToast('Please select an option to filter!');
                return
            }
            this.page = 1;
            this.limit = 6;
            this.params = "?page=" + this.page + "&limit=" + this.limit;
            if (this.categoryId) {
                this.params += "&categoryId=" + this.categoryId;
            }
            if (this.tag) {
                this.params += "&tagName=" + this.tag;
            }
            if (this.date && this.date != null) {
                if (this.date?.length > 0) {
                    this.params += "&startDate=" + this.date[0].toISOString();
                    if (this.date.length > 1 && this.date[1] != null) {
                        this.params += "&endDate=" + this.date[1].toISOString();
                    } else {
                        this.params += "&endDate=" + this.date[0].toISOString();
                    }
                }
            }
            let config = {
                method: "GET",
                url: this.setURL + this.params,
                headers: {
                    "Authorization": authHeader()
                }
            };
            await this.getBlogList(config);
        }

    },
}