import CareerSchemajson from '../../seoPlugin/CareerSchemajson';
export default {
    components:{
        CareerSchemajson
    },
    data() {
        return {
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            resume: '',
            url: this.$serverURL + this.$api.career.careerCmsURL,
            pageCMS: {}
        }
    },
    async mounted() {
        await this.getPageTitles();
        document.title = (this.pageTitles?.careerPageTitle ? this.pageTitles?.careerPageTitle : '');
        this.loadJSCSS();
        this.scrollToTop();
        await this.getCareerCMS();
    },
    methods: {
        getCareerCMS: async function () {
            let config = {
                method: "GET",
                url: this.url
            }
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {
                    this.pageCMS = await response.data.data[0];
                }
            }).catch(error => {
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },
        loadJSCSS: function () {
            var styles = [
                '/core/assets/css/leave_review.css'
            ]
            styles.forEach(style => {
                let tag = document.createElement('link');
                tag.type = "text/css";
                tag.rel = "stylesheet";
                tag.href = style;
                document.head.appendChild(tag);
            });
        },
        uploadResume(event) {
            this.resume = event.target.files[0];
        },
        checkValidation: function() {
            if(!this.firstName) {
                this.$swal.fire({
                    icon: "warning",
                    text: "Please enter first name!"
                });
                return false;
            }
            if(!this.lastName) {
                this.$swal.fire({
                    icon: "warning",
                    text: "Please enter last name!"
                });
                return false;
            }
            if(!this.email) {
                this.$swal.fire({
                    icon: "warning",
                    text: "Please enter email!"
                });
                return false;
            }
            if(!this.phone) {
                this.$swal.fire({
                    icon: "warning",
                    text: "Please enter phone!"
                });
                return false;
            }
            if(!this.resume) {
                this.$swal.fire({
                    icon: "warning",
                    text: "Please upload your resume!"
                });
                return false;
            }
            return true;
        },
        addCareer: async function () {
            if(this.checkValidation()) {
                let formData = new FormData();
                formData.append('firstName', this.firstName);
                formData.append('lastName', this.lastName);
                formData.append('email', this.email);
                formData.append('phone', this.phone);
                formData.append('resume', this.resume);
                let config = {
                    method: "POST",
                    data: formData,
                    url: this.$serverURL + this.$api.career.requestURL,
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                };
                await this.$axios(config).then(async (response) => {
                    if (response.data.statusCode == 201) {
                        this.$swal.fire({
                            icon: "success",
                            text: "Your resume has been uploaded successfully."
                        });
                        this.firstName = '';
                        this.lastName = '';
                        this.email - '';
                        this.phone = '';
                        this.resume = '';
                        this.$router.push('/home');
                    }
                    else {
                        this.$swal.fire({
                            icon: "error",
                            text: "Failed! please try again."
                        });
                    }
                }).catch(error => {
                    this.state.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
            }
        }
    }
}