import {
    countries
} from '../../../../config/country';
import { state } from '@/globalVariables';
import NewsLetter from '../../Newsletter/template/NewsLetter';
import Advertise from '../../Advertise/template/Advertise';
import { googleSdkLoaded } from "vue3-google-login";
export default {
    components: {
        NewsLetter,
        Advertise
    },
    data() {
        return {
            state,
            countriesList: countries,
            customerEmail: '',
            customerPassword: '',
            confirmPassword: '',
            firstName: '',
            lastName: '',
            businessName: '',
            address: '',
            gender: '',
            dob: '',
            city: '',
            stateCode: '',
            postCode: '',
            countryName: '',
            mobile: '',
            email: '',
            password: '',
            resetEmail: '',
            terms_condition: '',
            inputType: "password",
            iconFlag: true,
            passinputType: "password",
            passiconFlag: true,
            coninputType: "password",
            coniconFlag: true,
            country: {
                name: 'United States of America',
                code: 'US',
            }

        }
    },
    async created() {
        await this.getPageTitles();
        document.title = (this.pageTitles?.accountPageTitle ? this.pageTitles?.accountPageTitle : '');
        this.loadJSCSS();
    },
    methods: {
        loadJSCSS: function () {
            var styles = [
                '/core/assets/css/account.css'
            ]
            styles.forEach(style => {
                let tag = document.createElement('link');
                tag.type = "text/css";
                tag.rel = "stylesheet";
                tag.href = style;
                document.head.appendChild(tag);
            });
            var scripts = [
                "/core/assets/js/main.js"
            ];
            scripts.forEach(script => {
                let tag = document.createElement("script");
                tag.setAttribute("src", script);
                document.head.appendChild(tag);
            });
        },
        async googleSignIn() {
            try {
                const googleUser = await this.$gAuth.signIn();
                if (googleUser) {

                    this.isLogin = this.$gAuth.isAuthorized;
                    const idToken = googleUser.getId();

                    await this.processGoogleLogin(idToken, googleUser);
                }
            } catch (error) {
                console.error(error);
            }
        },
        processGoogleLogin: async function (data) {
            this.state.isLoading = true;
            // let loggedData = googleUser.getBasicProfile();
            // let email = loggedData.getEmail();
            // let fullName = loggedData.getName();
            // let data = {
            //   token: idToken,
            //   email: email,
            //   name: fullName
            // };
            let config = {
                method: "POST",
                data: data,
                url: this.$serverURL + this.$api.auth.googleLogIn,
            };

            await this.$axios(config).then((response) => {
                this.state.isLoading = false;
                if (response.status == 201) {
                    this.state.token = response.data.token;
                    localStorage.setItem('token', response.data.token);
                    localStorage.setItem('id', response.data.user.id);
                    localStorage.setItem('name', data.name);
                    localStorage.setItem('firstname', response.data.user.firstname);
                    localStorage.setItem('lastname', '');
                    localStorage.setItem('mobile', response.data.user.phone);
                    localStorage.setItem('image', response.data.user.image);
                    this.$swal.fire({
                        icon: "success",
                        text: "Successfuly logged in!!",
                    });
                    this.$router.push('/home')
                }
                else {

                    this.$swal.fire({
                        icon: "error",
                        text: "Google Login Failed!"
                    });
                }
            })
                .catch((error) => {
                    this.state.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
        },
        login: function () {
            if (!this.email || !this.password) {

                this.$swal.fire({
                    icon: "error",
                    text: "Please enter e-mail and password!"
                });
            } else {
                this.state.isLoading = true;
                let data = {
                    email: this.email,
                    password: this.password
                };
                let config = {
                    method: "POST",
                    url: this.$serverURL + this.$api.auth.customerLogin,
                    data: data
                };
                this.$axios(config).then((response) => {
                    this.state.isLoading = false;
                    if (response.status == 201) {
                        this.state.token = response.data.token;
                        localStorage.setItem("token", response.data.token);
                        localStorage.setItem("id", response.data.user.id);
                        let name = response.data.user.firstName + " " + response.data.user.lastName;
                        localStorage.setItem("name", name);
                        localStorage.setItem('firstname', response.data.user.firstName);
                        localStorage.setItem('lastname', response.data.user.lastName);
                        localStorage.setItem("email", response.data.user.email);
                        localStorage.setItem("mobile", response.data.user.mobile);
                        localStorage.setItem("discountType", response.data.user.discountType);
                        localStorage.setItem("discountAmount", response.data.user.discountAmount);
                        this.$swal.fire({
                            toast: true,
                            position: 'top-end',
                            text: "Successfully Login",
                            icon: "success",
                            showConfirmButton: false,
                            timer: 3000,
                            animation: false,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                            }
                        });
                        this.$router.push('/home');
                    } else {
                        this.$swal.fire({
                            icon: "error",
                            text: "Incorrect e-mail and password combination!"
                        });
                    }
                }).catch(error => {
                    this.state.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message,
                    });
                })
            }
        },
        register: function () {

            if (!this.firstName) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter first name!"
                });
            } else if (!this.lastName) {
                this.$swal.fire({
                    icon: "error",
                    text: "please enter last name"
                });
            } else if (!this.address) {
                this.$swal.fire({
                    icon: "error",
                    text: "please provide street address!"
                });
            } else if (!this.customerEmail) {
                this.$swal.fire({
                    icon: "error",
                    text: "please enter email!"
                });
            } else if (!this.mobile) {
                this.$swal.fire({
                    icon: "error",
                    text: "please enter mobile number!"
                });
            } else if (!this.gender) {
                this.$swal.fire({
                    icon: "error",
                    text: "please provide gender!"
                });
            } else if (!this.dob) {
                this.$swal.fire({
                    icon: "error",
                    text: "please provide date of birth!"
                });
            } else if (!this.city) {
                this.$swal.fire({
                    icon: "error",
                    text: "please provide city!"
                });
            } else if (!this.customerPassword) {
                this.$swal.fire({
                    icon: "error",
                    text: "please enter password!"
                });
            } else if (!this.confirmPassword) {
                this.$swal.fire({
                    icon: "error",
                    text: "please enter confirm password!"
                });
            } else if (this.customerPassword != this.confirmPassword) {
                this.$swal.fire({
                    icon: "error",
                    text: "Password didn't match!"
                });
            }
            else if (this.customerPassword.length < 6) {
                this.$swal.fire({
                    icon: "error",
                    text: "You have to enter at least 6 digit!",
                });
            }
            else if (!this.terms_condition) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please accept terms & conditions!",
                });
            }
            else {
                this.state.isLoading = true;
                let data = {
                    firstName: this.firstName,
                    lastName: this.lastName,
                    businessName: this.businessName,
                    fullAddress: this.address,
                    gender: this.gender,
                    dateOfBirth: this.dob,
                    password: this.customerPassword,
                    mobile: this.mobile,
                    country: this.country.name,
                    email: this.customerEmail,
                    state: this.stateCode,
                    postalCode: this.postCode,
                    city: this.city,
                };
                let config = {
                    method: "POST",
                    url: this.$serverURL + this.$api.auth.customerRegistration,
                    data: data
                };
                this.$axios(config).then((response) => {
                    this.state.isLoading = false;
                    if (response.data.statusCode == 201) {
                        this.state.token = response.data.data.token;
                        localStorage.setItem("token", response.data.data.token);
                        localStorage.setItem("id", response.data.data.user.id);
                        let name = response.data.data.user.firstName + " " + response.data.data.user.lastName;
                        localStorage.setItem("name", name);
                        localStorage.setItem('firstname', response.data.data.user.firstName);
                        localStorage.setItem('lastname', response.data.data.user.lastName);
                        localStorage.setItem("email", response.data.data.user.email);
                        localStorage.setItem("mobile", response.data.data.user.mobile);
                        localStorage.setItem("discountType", response.data.data.user.discountType);
                        localStorage.setItem("discountAmount", response.data.data.user.discountAmount);
                        this.$swal.fire({
                            icon: "success",
                            text: "Successfully registered!"
                        });
                        this.$router.push("/");
                    } else {

                        this.$swal.fire({
                            icon: "error",
                            text: "Failed! please try again."
                        });
                    }
                }).catch(error => {
                    this.state.isLoading = false;
                    // console.log("error", error.response.data.statusCode);
                    let msg = 'Failed! please try again'
                    if (error.response.data.statusCode == 400) {
                        msg = 'User Already Exists!'
                    }
                    this.$swal.fire({
                        icon: "error",
                        text: msg
                    });
                });
            }
        },
        changeInputType: function () {
            if (this.inputType == "password") {
                this.inputType = "text";
                this.iconFlag = false;
            }
            else if (this.inputType == "text") {
                this.inputType = "password";
                this.iconFlag = true;
            }
        },
        changeInputTypeReg: function () {
            if (this.passinputType == "password") {
                this.passinputType = "text";
                this.passiconFlag = false;
            }
            else if (this.passinputType == "text") {
                this.passinputType = "password";
                this.passiconFlag = true;
            }
        },
        changeInputTypeCon: function () {
            if (this.coninputType == "password") {
                this.coninputType = "text";
                this.coniconFlag = false;
            }
            else if (this.coninputType == "text") {
                this.coninputType = "password";
                this.coniconFlag = true;
            }
        },
        loginGoogle: async function () {
            console.log("Vue Clicked Login");
            await googleSdkLoaded(async (google) => {
                await google.accounts.oauth2.initTokenClient({
                    client_id: this.$googleSiteClientID,
                    scope: 'email profile openid',
                    callback: async (response) => {
                        console.log("Handle the response", response.access_token)
                        //this.processGoogleLogin(response.code, google)

                        let config = {
                            method: "GET",
                            url: this.$api.auth.googleSDKHandler,
                            headers: {
                                'Authorization': 'Bearer ' + response.access_token
                            }
                        };

                        await this.$axios(config).then(async (respon) => {
                            console.log("respon = ", respon.data)

                            let data = {
                                token: response.access_token,
                                email: respon.data.email,
                                name: respon.data.name
                            };

                            await this.processGoogleLogin(data);
                        });

                    }
                }).requestAccessToken()
            })
        },
        resetPassword: function () {
            if (!this.resetEmail) {

                this.$swal.fire({
                    icon: "error",
                    text: "Please enter e-mail!"
                });
            } else {
                let data = {
                    email: this.resetEmail,
                };
                let config = {
                    method: "POST",
                    url: this.$serverURL + this.$api.auth.resetPassword,
                    data: data
                };
                this.$axios(config).then((response) => {
                    if (response.status == 201) {

                        this.$swal.fire({
                            icon: "success",
                            text: "Your reset password link has been send to your email"
                        });
                    } else {

                        this.$swal.fire({
                            icon: "error",
                            text: "Incorrect e-mail and password combination!"
                        });
                    }
                }).catch(error => {
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                })
            }
        }
    }
}