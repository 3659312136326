import { state } from '@/globalVariables';
import NewsLetter from '../../Newsletter/template/NewsLetter';
import Advertise from '../../Advertise/template/Advertise';
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import Slider from '@vueform/slider'
import "@vueform/slider/themes/default.css";
import { authHeader } from '@/auth';
import SaleSchemajson from '../../seoPlugin/SaleSchemajson';

export default {
    components: {
        Slider,
        NewsLetter,
        Advertise,
        VPagination,
        SaleSchemajson
    },
    data() {
        return {
            state,
            colorsList: [],
            sizesList: [],
            brandList: [],
            productList: [],
            categoryId: '',
            subCategoriesList: [],
            childCategoryList: [],
            subCategoryId: "",
            childCategoryId: "",
            brandName: '',
            colors: [],
            sizes: [],
            page: 1,
            limit: 12,
            totalPage: 0,
            pageCMS: [],
            sortBy: '',
            imagePath: this.$imageURL + 'productImage/',
            priceValue: [0, 10000],
            tagList: [],
            tags: [],
            searchText: '',
            preSearchText: "",
            filterToogle: false,
            productRecord: "Loading, Please wait...",
            freeShippingTarget: 0,
        }
    },
    async created() {
        await this.getCategories();
        await this.getPageTitles();
        await this.getCoupons();

        document.title = (this.pageTitles?.salePagetitle ? this.pageTitles?.salePagetitle : '');
        this.state.isLoading = true;
        this.scrollToTop();
        let params = '?page=' + this.page + '&limit=' + this.limit;

        if (this.$route.params.categorySlug) {
            for (let i = 0; i < this.categoriesList.length; i++) {
                if (this.categoriesList[i].categorySlug == this.$route.params.categorySlug) {
                    this.categoryId = this.categoriesList[i].id;
                    params += '&parentCategoryId=' + this.categoryId
                    if (this.categoriesList[i].hasChild == true) {
                        await this.getSubCategories(this.categoryId)
                    }
                    break;
                }
            }
        }

        if (this.$route.params.subCategorySlug) {
            await this.getAllSubCategories();
            if (this.subCategoryList.length > 0) {
                for (let i = 0; i < this.subCategoryList.length; i++) {
                    if (this.subCategoryList[i].subCategorySlug == this.$route.params.subCategorySlug) {
                        this.categoryId = this.subCategoryList[i].parentCategoryId;
                        await this.getSubCategories(this.categoryId);
                        this.subCategoryId = this.subCategoryList[i].id;
                        params += '&subCategoryId=' + this.subCategoryId;
                        this.getChildCategory(this.subCategoryId)
                        break;
                    }
                }
            }

        }

        if (this.$route.query.categoryId) {
            this.categoryId = this.$route.query.categoryId;
            params += '&parentCategoryId=' + this.$route.query.categoryId;
        }
        if (this.$route.query.brandName) {
            params += '&brandName=' + this.$route.query.brandName;
            this.brandName = this.$route.query.brandName;
        }
        if (this.$route.query.subCategoryId) {
            this.subCategoryId = this.$route.query.subCategoryId;
            params += '&subCategoryId=' + this.$route.query.subCategoryId;
        }
        if (this.$route.query.keyword) {
            params += '&keyword=' + this.$route.query.keyword;
        }
        if (this.$route.query.discountTitle) {
            params += '&discountTitle=' + this.$route.query.discountTitle;
        }
        let cmsURL = this.$serverURL + this.$api.product.saleCMS;

        let config = {
            method: "GET",
            url: this.$serverURL + this.$api.product.productOnSaleAll + params,
            ...(this.state.token && { headers: { "Authorization": authHeader() } })
        }
        if (this.state.token) {
            config['url'] = this.$serverURL + this.$api.product.productOnSaleAuthorizedAPI + params;
        }
        await this.getAllProducts(config);
        await this.getProductCMS(cmsURL);
        await this.getAllVariant();
        await this.loadJSCSS();
    },

    methods: {
        // calculateDiscountPrice: async function(data) {
        //     let userId = localStorage.getItem("id");
        //     data.userDiscountList.map(element => {
        //         if(element.userId === userId) {
        //             return data.discountedPrice > element.userDiscountedPrice ? element.userDiscountedPrice : data.discountedPrice;
        //         }
        //     });
        // },
        async getCoupons() {
            try {
                let config = {
                    method: "GET",
                    url: this.$serverURL + this.$api.coupon.getCouponsURL,
                    header: {
                        'Content-Type': "application/json"
                    }
                };
                await this.$axios(config).then(response => {
                    this.couponList = response.data.data;
                    for (let index = 0; index < this.couponList.length; index++) {
                        const coupon = this.couponList[index];
                        if (coupon.couponType == "free shipping") {
                            this.freeShippingTarget = parseInt(coupon.discount);
                            break;
                        }
                    }
                }).catch(error => {
                    console.log(error);
                })
            } catch (error) {
                console.log(error);
            }
        },

        checkForShipping: function (data) {
            return this.freeShippingTarget > 0 && this.findMixinActualPrice(data) >= this.freeShippingTarget;
        },

        checkCategory: async function (id) {
            if (this.categoryId == id) {
                //this.resetFilter();
                this.categoryId = '';
                this.subCategoriesList = [];
                this.subCategoryId = '';
                this.childCategoryList = [];
                this.childCategoryId = '';
            }
            else {
                this.categoryId = id;
                this.subCategoriesList = [];
                this.subCategoryId = '';
                this.childCategoryList = [];
                this.childCategoryId = '';
            }
            await this.filterProduct();
        },
        checkSubCategory: async function (id) {
            if (this.subCategoryId == id) {
                this.subCategoryId = '';
                this.childCategoryList = [];
                this.childCategoryId = '';
            }
            else {
                this.subCategoryId = id;
                this.childCategoryList = [];
                this.childCategoryId = '';
            }

            await this.filterProduct();
        },
        checkChildCategory: async function (id) {
            if (this.childCategoryId == id) {
                this.childCategoryId = '';
            }
            else {
                this.childCategoryId = id;
            }

            await this.filterProduct();
        },
        checkBrands: async function (id) {
            if (this.brandName == id) {
                this.brandName = '';
            }
            else {
                this.brandName = id;
            }

            await this.filterProduct();
        },
        updateHandler: async function () {
            //let params = "?page=" + this.page + "&limit=" + this.limit;
            this.scrollToTop();
            let params = '?page=' + this.page + '&limit=' + this.limit;
            if (this.categoryId) {

                params += '&parentCategoryId=' + this.categoryId;
            }
            if (this.subCategoryId) {
                params += '&subCategoryId=' + this.subCategoryId;
            }
            if (this.$route.query.keyword) {
                params += '&keyword=' + this.$route.query.keyword;
            }
            if (this.childCategoryId) {
                params += '&childCategoryId=' + this.childCategoryId;
            }
            if (this.brandName) {
                params += '&brandName=' + this.brandName;
            }

            // if (this.sizes.length > 0) {
            //     params += '&size=' + this.sizes;
            // }
            if (this.priceValue) {
                params += '&minPrice=' + this.priceValue[0];
                params += '&maxPrice=' + this.priceValue[1];
            }
            if (this.$route.query.discountTitle) {
                params += '&discountTitle=' + this.$route.query.discountTitle;
            }


            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.product.productOnSaleAll + params,
                ...(this.state.token && { headers: { "Authorization": authHeader() } })
            }
            if (this.state.token) {
                config['url'] = this.$serverURL + this.$api.product.productOnSaleAuthorizedAPI + params;
            }
            await this.getAllProducts(config);
        },
        selectSortBy: async function () {
            this.page = 1;
            this.limit = 12;
            let params = '?page=' + this.page + '&limit=' + this.limit;
            if (this.sortBy) {
                params += '&sortBy=' + this.sortBy;
            }

            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.product.productOnSaleAll + params,
                ...(this.state.token && { headers: { "Authorization": authHeader() } })
            }
            if (this.state.token) {
                config['url'] = this.$serverURL + this.$api.product.productOnSaleAuthorizedAPI + params;
            }
            await this.getAllProducts(config);
        },
        filterProduct: async function () {
            this.page = 1;
            this.limit = 12;
            let params = '?page=' + this.page + '&limit=' + this.limit;
            // console.log('this.categoryId = ', this.categoryId);
            if (this.categoryId) {
                params += '&parentCategoryId=' + this.categoryId;
            }
            if (this.subCategoryId) {
                params += '&subCategoryId=' + this.subCategoryId;
            }
            if (this.subCategoryId) {
                params += '&childCategoryId=' + this.childCategoryId;
            }
            if (this.brandName) {
                params += '&brandName=' + this.brandName;
            }
            if (this.tags.length > 0) {
                params += '&tag=' + this.tags;
            }
            // if (this.colors.length > 0) {
            //     params += '&color=' + this.colors;
            // }
            // if (this.sizes.length > 0) {
            //     params += '&size=' + this.sizes;
            // }
            if (this.priceValue) {
                params += '&minPrice=' + this.priceValue[0];
                params += '&maxPrice=' + this.priceValue[1];
            }
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.product.productOnSaleAll + params,
                ...(this.state.token && { headers: { "Authorization": authHeader() } })
            }
            if (this.state.token) {
                config['url'] = this.$serverURL + this.$api.product.productOnSaleAuthorizedAPI + params;
            }
            await this.getAllProducts(config);
            this.filterToogle = false;
            document.getElementById('filterCloseBtn').click();
        },
        resetFilter: async function () {
            this.categoryId = '';
            this.subCategoryId = '';
            this.childCategoryId = '';
            this.subCategoriesList = [];
            this.childCategoryList = [];
            this.brandName = '';
            this.colors = [];
            this.sizes = [];
            this.priceValue[0] = 0;
            this.priceValue[1] = 50000;

            this.page = 1;
            this.limit = 12;
            let params = '?page=' + this.page + '&limit=' + this.limit;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.product.productOnSaleAll + params,
                ...(this.state.token && { headers: { "Authorization": authHeader() } })
            }
            if (this.state.token) {
                config['url'] = this.$serverURL + this.$api.product.productOnSaleAuthorizedAPI + params;
            }
            await this.getAllProducts(config);
            this.filterToogle = false;
            document.getElementById('filterCloseBtn').click();
        },
        loadJSCSS: async function () {
            this.state.isLoading = true;
            var styles = [
                '/core/assets/css/listing.css'
            ]
            styles.forEach(style => {
                let tag = document.createElement('link');
                tag.type = "text/css";
                tag.rel = "stylesheet";
                tag.href = style;
                document.head.appendChild(tag);
            });

            var scripts = [
                "/core/assets/js/sticky_sidebar.min.js",
                // "/core/assets/js/specific_listing.js",
                "/core/assets/js/main.js",
            ];
            scripts.forEach(script => {
                let tag = document.createElement("script");
                tag.setAttribute("src", script);
                document.head.appendChild(tag);
            });
            this.state.isLoading = false;
        },
        getProductCMS: async function (url) {
            let config = {
                method: "GET",
                url: url,
            }
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {

                    this.pageCMS = await response.data.data[0];

                    this.scrollToTop();

                }
            }).catch(error => {
                console.log(error);
            });
        },
        getAllVariant: async function () {
            let url = this.$serverURL + this.$api.product.productVarient;
            let config = {
                method: "GET",
                url: url,
            }
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {
                    // this.colorsList = response.data.colorData;
                    this.tagList = response.data.tagData;
                    this.brandList = response.data.brandData;
                }
                else {

                    this.$swal.fire({
                        icon: "error",
                        text: "Failed! please try again."
                    });
                }
            }).catch(error => {
                console.log(error);
            });
        },
        getAllProducts: async function (config) {
            this.productRecord = "Loading, Please wait...";
            this.productList = [];
            this.state.isLoading = true;
            await this.$axios(config).then(async (response) => {
                if (response.data.statusCode == 200) {
                    this.productList = response.data.products?.data.length > 0 ? response.data.products.data : [];
                    this.totalPage = response.data.products.pageCount;
                    this.state.isLoading = false;

                    if(this.productList.length == 0)
                    {
                        this.productRecord = "No product found.";
                    }

                }
                else {
                    this.$swal.fire({
                        icon: "error",
                        text: "Failed! please try again."
                    });
                    this.state.isLoading = false;
                }
            }).catch(error => {
                this.state.isLoading = false;
                console.log(error);
            });
        },
        getSubCategories: async function (id) {
            this.subCategoriesList = [];
            this.subCategoryId = "";
            this.childCategoryList = [];
            this.childCategoryId = "";
            try {
                let config = {
                    method: "GET",
                    url: this.$serverURL + this.$api.product.productSubCategoryByParentId + '?parentCategoryId=' + id,
                    headers: {
                        "Content-Type": "application/json"
                    }
                };
                await this.$axios(config).then(async (response) => {
                    if (response.data.statusCode == 200) {
                        this.subCategoriesList = await response.data.data;
                    }

                }).catch(error => {
                    console.log(error);
                });
            } catch (error) {
                console.log(error);
            }
        },
        getChildCategory: async function (id) {
            this.childCategoryList = [];
            this.childCategoryId = "";
            let url = this.$serverURL + this.$api.product.childCategoryURL + '?subCategoryId=' + id;
            let config = {
                method: "GET",
                url: url,
            }
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {

                    this.childCategoryList = response.data.data;
                }
                else {

                    this.$swal.fire({
                        icon: "error",
                        text: "Failed! please try again."
                    });
                }
            }).catch(error => {

                this.$swal.fire({
                    icon: "error",
                    text: "Failed! please try again." + error
                });
            });
        },
        processWishListData(data, quantity) {
            // data.choosedColor = "N/A";
            // data.choosedSize = "N/A";
            this.addToWishList(data, quantity);
        },
        processCartData(data, quantity) {
            // data.choosedColor = "N/A";
            // data.choosedSize = "N/A";
            this.addToCart(data, quantity);
        },
        async searchFilter() {
            let params = '?page=' + this.page + '&limit=' + this.limit;
            params += '&keyword=' + this.searchText;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.product.productOnSaleAll + params,
                ...(this.state.token && { headers: { "Authorization": authHeader() } })
            }
            if (this.state.token) {
                config['url'] = this.$serverURL + this.$api.product.productOnSaleAuthorizedAPI + params;
            }
            await this.getAllProducts(config);
            this.categoryId = '';
            this.subCategoryId = '';
            this.childCategoryId = '';
            this.subCategoriesList = [];
            this.childCategoryList = [];
            this.brandName = '';
            this.colors = [];
            this.sizes = [];
            this.priceValue[0] = 0;
            this.priceValue[1] = 50000;

            this.page = 1;
            this.limit = 12;
            if (this.searchText != this.preSearchText) {
                let config = {
                    method: "POST",
                    url: this.$serverURL + this.$api.searchRecord.searchRecordURL,
                    data: {
                        search: this.searchText,
                    },
                    headers: {
                        "Content-Type": "application/json"
                    }
                };
                await this.$axios(config).catch(error => {
                    console.log(error);
                });
                this.searchText = ''
                this.state.isSearchActive = false;
                this.searchText = this.preSearchText
            }
        },
        closeFilterModalDiv() {
            console.log("Clicking");
            //this.$refs.dilterArea.style.display = 'none';
        }
    },
}