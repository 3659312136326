<template>
  <main :style="{ 'background-color': state.componentUi.productsComponentUI.pageBackgroundColor }">
    <div class="top_banner" v-if="pageCMS">
      <div class="opacity-mask d-flex align-items-center" data-opacity-mask="rgba(0, 0, 0, 0.3)">
        <div class="container">
          <div class="breadcrumbs">
           <ul>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/home')">Home</a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/deals')">Deals</a>
              </li>
            </ul>
          </div>
          <h1>{{ pageCMS.title }}</h1>
        </div>
      </div>
      <img :src="$imageURL + 'product-page-cms/' + pageCMS.image" class="img-fluid" :alt="pageCMS.title" />
    </div>
    <!-- /top_banner -->
    <div id="stick_here"></div>
    <div class="toolbox elemento_stick">
      <div class="container">
        <ul class="clearfix">
          <li>
            <div class="sort_select">
              <select v-model="sortBy" @change="selectSortBy" name="sort" id="sort">
                <option value style="display: none" selected>Sort by</option>
                <option value="new">Sort by newness</option>
                <option value="low">Sort by price: low to high</option>
                <option value="high">Sort by price: high to low</option>
              </select>
            </div>
          </li>
          <li>
            <a class="d-block d-lg-none" role='button' data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample2"
              @click="() => { filterToogle = true }" aria-controls="offcanvasExample2">
              <div style="display: flex; justify-content: center; cursor: pointer;">
                <i class="ti-filter me-2"></i>
                <h6>Filters</h6>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="d-block d-md-none search_mob_wp">
      <input type="text" class="form-control" v-model="searchText" :placeholder="state.headerCMS.searchPlaceholder"
        v-on:keyup.enter="searchFilter" />
      <div class="d-flex align-items-center">
        <input type="submit" class="btn_1 full-width" @click="searchFilter" value="Search" />
      </div>
    </div>
    <!-- <div class="d-block d-md-none">
      <div class="mx-3">
        <div class="custom-search-input">
              <input
                type="text"
                v-model="searchText"
                v-on:keyup.enter="searchFilter"
                :placeholder="state.headerCMS.searchPlaceholder"
              />
              <button type="submit" @click="searchFilter">
                <i class="header-icon_search_custom"></i>
              </button>
            </div>
      </div>   
    </div> -->
    <!-- /toolbox -->

    <div class="container margin_30">
      <div class="row">
        <div class="position-relative col-lg-3 mb-4">
          <div class="offcanvas11-lg offcanvas-start d-none d-lg-block" tabindex="-1" id="offcanvasExample"
            aria-labelledby="offcanvasExampleLabel">
            <div class="offcanvas-header text-end">
              <button type="btn" class="btn-close text-reset d-block d-lg-none" aria-label="Close"></button>
            </div>
            <div class="offcanvas-body">

              <!-- <div class="inner_bt">
              <a href="#" class="open_filters">
                <i class="ti-close"></i>
              </a>
            </div> -->
              <div class="buttons">
                <a href="javascript:void(0)" @click="resetFilter" class="btn_1">View All</a>
              </div>
              <div class="filter_type version_2 mt-3">
                <h4>
                  <a href="#filter_1" data-bs-toggle="collapse" class="closed opened">Categories</a>
                </h4>
                <div class="collapse show catPlace" id="filter_1">
                  <ul>
                    <li v-for="(data, index) in categoriesList" :key="index">
                      <label class="container_check">
                        {{ data.name }}
                        <input @click="checkCategory(data.id)" v-model="categoryId"
                          @change="getSubCategories(categoryId)" :value="data.id" type="radio" />
                        <span class="checkmark"></span>
                      </label>
                    </li>
                  </ul>
                </div>
                <!-- /filter_type -->
              </div>
              <div class="filter_type version_2" v-if="subCategoriesList.length > 0">
                <h4>
                  <a href="#filter_5" data-bs-toggle="collapse" class="closed opened">Sub Categories</a>
                </h4>
                <div class="collapse show subcatPlace" id="filter_1">
                  <ul>
                    <li v-for="(data, index) in subCategoriesList" :key="index">
                      <label class="container_check">
                        {{ data.name }}
                        <input @click="checkSubCategory(data.id)" v-model="subCategoryId"
                          @change="getChildCategory(subCategoryId)" :value="data.id" type="radio" />
                        <span class="checkmark"></span>
                      </label>
                    </li>
                  </ul>
                </div>
                <!-- /filter_type -->
              </div>
              <div class="filter_type version_2" v-if="childCategoryList.length > 0">
                <h4>
                  <a href="#filter_6" data-bs-toggle="collapse" class="closed opened">Child Categories</a>
                </h4>
                <div class="collapse show subcatPlace" id="filter_1">
                  <ul>
                    <li v-for="(data, index) in childCategoryList" :key="index">
                      <label class="container_check">
                        {{ data.name }}
                        <input @click="checkChildCategory(data.id)" v-model="childCategoryId" :value="data.id"
                          type="radio" />
                        <span class="checkmark"></span>
                      </label>
                    </li>
                  </ul>
                </div>
                <!-- /filter_type -->
              </div>
              <!-- /filter_type -->

              <div class="filter_type version_2" v-if="tagList.length > 0">
                <h4>
                  <a href="#filter_2" data-bs-toggle="collapse" class="closed opened">Tags</a>
                </h4>
                <div class="collapse" id="filter_2">
                  <ul>
                    <li v-for="(data, index) in tagList" :key="index">
                      <label class="container_check">
                        {{ data }}
                        <input v-model="tags" :value="data" type="checkbox" :id="data + '-tag'"
                          @change="filterProduct" />
                        <span class="checkmark"></span>
                      </label>
                    </li>
                  </ul>
                </div>
              </div>

              <!-- /filter_type -->
              <div class="filter_type version_2">
                <h4>
                  <a href="#filter_3" data-bs-toggle="collapse" class="closed opened">Brands</a>
                </h4>
                <div class="collapse catPlace" id="filter_3">
                  <ul>
                    <li v-for="(data, index) in brandList" :key="index">
                      <label class="container_check">
                        {{ data.brandName }}
                        <input @click="checkBrands(data.brandName)" v-model="brandName" :value="data.brandName"
                          :id="data.id" type="radio" />
                        <span class="checkmark"></span>
                      </label>
                    </li>
                  </ul>
                </div>
              </div>

              <!-- <div class="filter_type version_2">
              <h4>
                <a href="#filter_3" data-bs-toggle="collapse" class="closed"
                  >Size</a
                >
              </h4>
              <div class="collapse" id="filter_3">
                <ul>
                  <li v-for="(data, index) in sizesList" :key="index">
                    <label class="container_check"
                      >{{ data.sizeName }}
                      <input
                        v-model="sizes"
                        :value="data.sizeName"
                        type="checkbox"
                      />
                      <span class="checkmark"></span>
                    </label>
                  </li>
                </ul>
              </div>
            </div>-->

              <!-- /filter_type -->
              <div class="filter_type version_2">
                <h4 class="mb-5">
                  <a href="#filter_4" data-bs-toggle="collapse" class="closed opened">Price</a>
                </h4>
                <div class="collapse mb-3" id="filter_4">
                  <Slider class="mb-4" v-model="priceValue" :min="0" :max="10000" @click="filterProduct" />
                  <div class="row">
                    <div class="col-6">
                      <label for>Min Price:</label>
                      <input style="height: 10px" type="number" v-model="priceValue[0]" min="0" max="10000"
                        @change="filterProduct" class="form-control form-control-sm" />
                    </div>
                    <div class="col-6">
                      <label for>Max Price:</label>
                      <input style="height: 10px" type="number" v-model="priceValue[1]" min="0" max="10000"
                        @change="filterProduct" class="form-control form-control-sm" />
                    </div>
                  </div>
                </div>
              </div>
              <!-- /filter_type -->
              <div class="buttons">
                <!-- <a href="javascript:void(0)" @click="filterProduct" class="btn_1">Filter</a> -->
                <a href="javascript:void(0)" @click="resetFilter" class="btn_1">Reset</a>
              </div>
            </div>
          </div>

          <div class="position-absolute top-50 start-0">
            <div style="height:100vh; z-index:99999999; width:100vw;" class="offcanvas offcanvas-start" tabindex="-1"
              id="offcanvasExample2" aria-labelledby="offcanvasExampleLabel2">
              <div v-if="filterToogle">
                <div class="offcanvas-header d-flex justify-content-end me-3 mb-3">
                  <a type="btn" id="filterCloseBtn" class="btn-close text-reset d-none"
                    @click="() => { filterToogle = false; }" data-bs-dismiss="offcanvas" aria-label="close"></a>
                </div>
                <div class="px-5 mb-5 measure-left-filter">


                  <div class="buttons mt-0 mt-md-5 d-flex justify-content-between">
                    <a href="javascript:void(0)" @click="resetFilter" class="btn_1 custom_margin">View All</a>
                    <a type="btn" id="filterCloseBtn" class="btn-close text-reset d-block d-lg-none custom_margin"
                    @click="() => { filterToogle = false; }" data-bs-dismiss="offcanvas" aria-label="close"></a>
            
                  </div>

                  <div class="filter_type version_2 mt-3">
                    <h4>
                      <a href="#filter_1" data-bs-toggle="collapse" class="closed opened">Categories</a>
                    </h4>
                    <div class="collapse" id="filter_1">
                      <ul>
                        <li v-for="(data, index) in categoriesList" :key="index">
                          <label class="container_check">
                            {{ data.name }}
                            <input @click="checkCategory(data.id)" v-model="categoryId"
                              @change="getSubCategories(categoryId)" :value="data.id" type="radio" />
                            <span class="checkmark"></span>
                          </label>
                        </li>
                      </ul>
                    </div>
                    <!-- /filter_type -->
                  </div>
                  <div class="filter_type version_2" v-if="subCategoriesList.length > 0">
                    <h4>
                      <a href="#filter_5" data-bs-toggle="collapse" class="closed opened">Sub Categories</a>
                    </h4>
                    <div class="collapse subcatPlace" id="filter_5">
                      <ul>
                        <li v-for="(data, index) in subCategoriesList" :key="index">
                          <label class="container_check">
                            {{ data.name }}
                            <input @click="checkSubCategory(data.id)" v-model="subCategoryId"
                              @change="getChildCategory(subCategoryId)" :value="data.id" type="radio" />
                            <span class="checkmark"></span>
                          </label>
                        </li>
                      </ul>
                    </div>
                    <!-- /filter_type -->
                  </div>
                  <div class="filter_type version_2" v-if="childCategoryList.length > 0">
                    <h4>
                      <a href="#filter_6" data-bs-toggle="collapse" class="closed opened">Child Categories</a>
                    </h4>
                    <div class="collapse show subcatPlace" id="filter_1">
                      <ul>
                        <li v-for="(data, index) in childCategoryList" :key="index">
                          <label class="container_check">
                            {{ data.name }}
                            <input @click="checkChildCategory(data.id)" v-model="childCategoryId" :value="data.id"
                              type="radio" />
                            <span class="checkmark"></span>
                          </label>
                        </li>
                      </ul>
                    </div>
                    <!-- /filter_type -->
                  </div>
                  <!-- /filter_type -->

                  <div class="filter_type version_2" v-if="tagList.length > 0">
                    <h4>
                      <a href="#filter_2" data-bs-toggle="collapse" class="closed opened">Tags</a>
                    </h4>
                    <div class="collapse" id="filter_2">
                      <ul>
                        <li v-for="(data, index) in tagList" :key="index">
                          <label class="container_check">
                            {{ data }}
                            <input v-model="tags" :value="data" type="checkbox" :id="data + '-Mtag'"
                              @change="filterProduct" />
                            <span class="checkmark"></span>
                          </label>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <!-- /filter_type -->
                  <div class="filter_type version_2">
                    <h4>
                      <a href="#filter_3" data-bs-toggle="collapse" class="closed opened">Brands</a>
                    </h4>
                    <div :class="['collapse', brandName ? 'show catPlace' : ' catPlace']" id="filter_3">
                      <ul>
                        <li v-for="(data, index) in brandList" :key="index">
                          <label class="container_check">
                            {{ data.brandName }}
                            <input @click="checkBrands(data.brandName)" v-model="brandName" :value="data.brandName"
                              type="radio" />
                            <span class="checkmark"></span>
                          </label>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <!-- <div class="filter_type version_2">
              <h4>
                <a href="#filter_3" data-bs-toggle="collapse" class="closed"
                  >Size</a
                >
              </h4>
              <div class="collapse" id="filter_3">
                <ul>
                  <li v-for="(data, index) in sizesList" :key="index">
                    <label class="container_check"
                      >{{ data.sizeName }}
                      <input
                        v-model="sizes"
                        :value="data.sizeName"
                        type="checkbox"
                      />
                      <span class="checkmark"></span>
                    </label>
                  </li>
                </ul>
              </div>
            </div>-->

                  <!-- /filter_type -->
                  <div class="filter_type version_2">
                    <h4 class="mb-5">
                      <a href="#filter_4" data-bs-toggle="collapse" class="closed opened">Price</a>
                    </h4>
                    <div class="collapse mb-3" id="filter_4">
                      <Slider class="mb-4" v-model="priceValue" :min="0" :max="10000" @click="filterProduct" />
                      <div class="row">
                        <div class="col-6">
                          <label for>Min Price:</label>
                          <input style="height: 10px" type="number" v-model="priceValue[0]" min="0" max="10000"
                            @change="filterProduct" class="form-control form-control-sm" />
                        </div>
                        <div class="col-6">
                          <label for>Max Price:</label>
                          <input style="height: 10px" type="number" v-model="priceValue[1]" min="0" max="10000"
                            @change="filterProduct" class="form-control form-control-sm" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- /filter_type -->
                  <div class="buttons">
                    <!-- <a href="javascript:void(0)" @click="filterProduct" class="btn_1">Filter</a> -->
                    <a href="javascript:void(0)" @click="resetFilter" class="btn_1">Reset</a>
                  </div>

                </div>
              </div>
            </div>

          </div>




        </div>
        <!-- /col -->
        <div class="col-12 col-md-12 col-lg-9" v-if="productList.length > 0">
          <div class="row small-gutters">
            <div class="col-6 col-md-4" v-for="(data, index) in productList" :key="index">
              <div class="grid_item">
                <span class="ribbon off" v-if="data.discountTitle">
                  {{
                    data.discountTitle
                  }}
                </span>
                <figure>
                  <a :href="'/product-details/' + data.urlSlug">
                    <img class="img-fluid lazy" :src="imagePath + data.featuredImage"
                    :data-src="imagePath + data.featuredImage" style="height:275px;" :alt="data.name" :title="data.name" />
                    <div class="bg-price position-absolute fs-12 fw-bold text-center text-white z-2" style="top: 20px;left:-120px;right: inherit;height: 15px;width: 300px; transform: translate3d(0,0,1px) rotate(-45deg); ">Sale</div>
                  </a>
                </figure>
                <span>
                  <a style="color:#000; font-weight: 400; text-decoration:none; font-size: 0.8em;" href="javascript:void(0)" @click="
                    $router.push({
                      path: '/products',
                      query: {
                        ...(data.brandName && {
                          brandName: data.brandName,
                        }),
                      },
                    })
                  ">{{ data.brandName.length>15 ? data.brandName.slice(0, 15) + '..'  : data.brandName }}</a>
                </span>
                <br />
                <a :href="'/product-details/' + data.urlSlug" style="color: black">
                  <h3 style="font-size: 1em; color: #000; font-weight: 600; font-size: 0.9em;">{{ data.name }}</h3>
                  <!-- <h3 style="font-size: 1em; color: #000; font-weight: 600; font-size: 1em;">{{ data.name.length>15 ? data.name.slice(0, 15) + '..'  : data.name }}</h3> -->
                </a>
                <div class="price_box">
                  <span class="new_price">
                    <!-- {{data.userDiscountedPrice}} / {{data.discountedPrice}} / {{data.price}}
                    <hr />-->
                    ${{ findMixinActualPrice(data) }}
                    <!-- <hr /> -->
                    <!-- ${{ 
                            (data.userDiscountedPrice && data.userDiscountedPrice != null && data.userDiscountedPrice != "") 
                            ? (data.userDiscountedPrice > data.discountedPrice  ? data.discountedPrice : data.userDiscountedPrice) 
                            : data.discountedPrice ? data.discountedPrice : data.price
                    }}-->
                    &nbsp;
                  </span>
                  <span class="old_price" v-if="data.discountedPrice || data.userDiscountedPrice">${{
                    data.price
                  }}</span>
                </div>
                <ul>
                  <li>
                    <a href="javascript:void(0)" class="tooltip-1" data-bs-toggle="tooltip" data-bs-placement="left"
                      title="Add to favorites" @click="processWishListData(data, 1)">
                      <i class="ti-heart"></i>
                      <span>Add to favorites</span>
                    </a>
                  </li>
                  <li>
                    <a href="javascript:void(0)" @click="processCartData(data, 1)" class="tooltip-1"
                      data-bs-toggle="tooltip" data-bs-placement="left" title="Add to cart">
                      <i class="ti-shopping-cart"></i>
                      <span>Add to cart</span>
                    </a>
                  </li>
                </ul>
              </div>
              <!-- /grid_item -->
            </div>
          </div>
          <!-- <div class="row small-gutters" v-else>
            <p class="text-center">No Data Found.</p>
          </div> -->
          <nav class="page-navigation justify-content-center d-flex" aria-label="page-navigation">
            <v-pagination v-if="productList" v-model="page" @update:modelValue="updateHandler" :pages="totalPage"
              :range-size="1" active-color="#FF8400"></v-pagination>
          </nav>
        </div>
        <div class="col-12 col-md-12 col-lg-9" v-else>
          <div class="alert alert-warning" role="alert" style="text-align: center;">
            {{ productRecord }}
          </div>
        </div>
        <!-- /col -->
      </div>
      <!-- /row -->
    </div>


    <!-- /container -->
  </main>
  <!-- <Advertise v-if="state.advertiseList.includes('Products') && state.isAdvertise == true" />
  <NewsLetter v-if="
  state.newsletterList.includes('Products') && state.isNewsLetter == true
  " /> -->
    <SaleSchemajson v-if="Object.values(pageTitles).length>0" :metaData="pageTitles" />
</template>
<style scoped>
@import url("../../../assets/pagination.css");
@import url("../css/product.css");
@import url("../css/sale.css");
</style>
<script src="../js/sale.js"></script>