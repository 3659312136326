<template>
  <teleport to="head">
    <link rel="canonical" :href="$route.path" />
    <meta name="title" :content="catMeataData.metaTitle" />
    <meta name="keywords" :content="catMeataData.metaKeyword" />
    <meta name="description" :content="catMeataData.metaDescription" />
    <meta name="author" content="HGSHYDRO">
    <meta name="publisher" content="HGSHYDRO">
  </teleport>
</template>

<script>
export default {
  name: "CategorySchemajson ",
  props: ["categoryMeta"],
  data() {
    return {
      catMeataData: {}
    };
  },
  created() {
    console.log("in category seo", this.metaData);
  },
  watch: {
    categoryMeta: {
      immediate: true,
      handler(val, oldVal) {
        oldVal;
        this.catMeataData = val;
      }
    }
  }
};
</script>
