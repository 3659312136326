<template>
  <main :style="{ 'background-color': state.componentUi.productsComponentUI.pageBackgroundColor }">
    <div class="top_banner" v-if="pageCMS">
      <div class="opacity-mask d-flex align-items-center" data-opacity-mask="rgba(0, 0, 0, 0.3)">
        <div class="container">
          <div class="breadcrumbs">
            <ul v-if="pageCategoryView">
              <li>
                <a :href="'/home'">Home</a>
              </li>
              <li >
                <a :href="'/products'">Products </a>
              </li>
              <li >
                <a href="javascript:void(0)"> Category </a>
              </li>
            </ul>
            <ul v-else-if="pageSubCategoryView || pageChildCategoryView">
              <li>
                <a :href="'/home'">Home</a>
              </li>
              <li >
                <a :href="'/products'">Products </a>
              </li>
              <li >
                <a href="javascript:void(0)"> Category </a>
              </li>
              <li >
                <a href="javascript:void(0)"> Sub-Category </a>
              </li>
            </ul>
            <ul v-else>
              <li>
                <a :href="'/home'">Home</a>
              </li>
              <li>
                <a :href="'/products'">Products</a>
              </li>
            </ul>
          </div>
          <h1 v-if="pageCategoryView">{{ categoryNameTitleHeading }}</h1>
          <h1 v-else-if="pageSubCategoryView || pageChildCategoryView">{{ subCategoryNameTitleHeading }}</h1>
          <h1 v-else>{{ pageCMS.title }}</h1>
        </div>
      </div>
      <img :src="placeholderLazyLoading" v-lazy="$imageURL + 'product-page-cms/' + pageCMS.image" class="img-fluid" :alt="pageCMS.title" :title="pageCMS.title" />
    </div>
    <!-- /top_banner -->
    <div id="stick_here"></div>
    <div class="toolbox elemento_stick">
      <div class="container">
        <ul class="clearfix">
          <li>
            <div class="sort_select">
              <select v-model="sortBy" @change="selectSortBy" name="sort" id="sort">
                <option value style="display: none" selected>Sort by</option>
                <option value="new">Sort by newness</option>
                <option value="low">Sort by price: low to high</option>
                <option value="high">Sort by price: high to low</option>
              </select>
            </div>
          </li>
          <li>
            <a class="d-block d-lg-none" role='button' data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample2"
              @click="() => { filterToogle = true }" aria-controls="offcanvasExample2">
              <div style="display: flex; justify-content: center; cursor: pointer;">
                <i class="ti-filter me-2"></i>
                <h6>Filters</h6>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="d-block d-md-none search_mob_wp">
      <!-- <input type="text" class="form-control" v-model="searchText" :placeholder="state.headerCMS.searchPlaceholder"
        v-on:keyup.enter="searchFilter" />
      <div class="d-flex align-items-center">
        <input type="submit" class="btn_1 full-width" @click="searchFilter" value="Search" />
      </div> -->
      <div class="custom-search-input w-100">
        <input
          type="text"
          ref="searchInputProduct"
          v-model.trim="searchText"
          @input="searchElastic"
          v-on:keyup.enter="search"
          :placeholder="state.headerCMS.searchPlaceholder"
        />
        <div class="auto-com-box"  v-if="isActiveDropDown && suggestions.length > 0">
          <ul>
            <li
              v-for="data in suggestions"
              :key="data.id"
              @click="handleSugClick(data.brandNameAndProductName)"
              v-html="data?.highlight"
            >
            </li>
            <hr/>
            <li
              v-for="(brandName, index) in sugBrandName"
              :key="index"
              @click="handleSugClick(brandName)"
            >
              {{ brandName }}
            </li>
            <hr/>
            <div
              v-for="data in suggestions"
              :key="data.id + data.name"
              @click="$router.push('/product-details/' + data.urlSlug)"
            >
              <li>
                <div class="row">
                  <div class="col-3">
                    <img
                      class="img-fluid lazy"
                      :src="imagePath + data.featuredImage"
                      :data-src="imagePath + data.featuredImage"
                      :alt="data.name" 
                      :title="data.name" 
                    />
                  </div>
                  <div class="col-9">
                    <div v-html="data?.highlight"></div>
                    <div class="product-sku">SKU: {{ data.sku }}</div>
                    <div class="d-flex flex-row-reverse">
                      <span v-if="data.stock > 0" class="in-stock">In Stock</span>
                      <span v-else class="in-stock">Out of Stock</span>
                    </div>
                  </div>
                </div>
              </li>
              <hr/>
            </div>
            <li class="d-flex justify-content-center text-success text-bold" @click="search">View All {{ searchTotal }} products</li>
          </ul>
        </div>          
        <button type="submit" @click="search">
          <i class="header-icon_search_custom"></i>
        </button>
      </div>
    </div>
    <!-- /toolbox -->

    <div class="container margin_30">
      <div class="row">
        <div class="position-relative col-lg-3 mb-4">
          <div class="offcanvas11-lg offcanvas-start d-none d-lg-block" tabindex="-1" id="offcanvasExample"
            aria-labelledby="offcanvasExampleLabel">
            <div class="offcanvas-header text-end">
              <button type="btn" class="btn-close text-reset d-block d-lg-none" aria-label="Close"></button>
            </div>
            <div class="offcanvas-body">

              
              <div class="buttons">
                <a href="javascript:void(0)" @click="resetFilter" class="btn_1">View All</a>
              </div>
              <div class="filter_type version_2 mt-3">
                <h4>
                  <a href="#filter_1" data-bs-toggle="collapse" class="closed opened">Categories</a>
                </h4>
                <div class="collapse show catPlace" id="filter_1">
                  <ul>
                    <li v-for="(data, index) in categoriesList" :key="index">
                      <label class="container_check">
                        {{ data.name }}
                        <input @click="checkCategory(data.id, data.categorySlug)" v-model="categoryId"
                          @change="getSubCategories(categoryId)" :value="data.id" type="radio" />
                        <span class="checkmark"></span>
                      </label>
                    </li>
                  </ul>
                </div>
                <!-- /filter_type -->
              </div>
              <div class="filter_type version_2" v-if="subCategoriesList.length > 0">
                <h4>
                  <a href="#filter_5" data-bs-toggle="collapse" class="closed opened">Sub Categories</a>
                </h4>
                <div class="collapse show subcatPlace" id="filter_1">
                  <ul>
                    <li v-for="(data, index) in subCategoriesList" :key="index">
                      <label class="container_check">
                        {{ data.name }}
                        <input @click="checkSubCategory(data.id, data.subCategorySlug)" v-model="subCategoryId"
                          @change="getChildCategory(subCategoryId)" :value="data.id" type="radio" />
                        <span class="checkmark"></span>
                      </label>
                    </li>
                  </ul>
                </div>
                <!-- /filter_type -->
              </div>
              <div class="filter_type version_2" v-if="childCategoryList.length > 0">
                <h4>
                  <a href="#filter_6" data-bs-toggle="collapse" class="closed opened">Child Categories</a>
                </h4>
                <div class="collapse show subcatPlace" id="filter_1">
                  <ul>
                    <li v-for="(data, index) in childCategoryList" :key="index">
                      <label class="container_check">
                        {{ data.name }}
                        <input @click="checkChildCategory(data.id)" v-model="childCategoryId" :value="data.id"
                          type="radio" />
                        <span class="checkmark"></span>
                      </label>
                    </li>
                  </ul>
                </div>
                <!-- /filter_type -->
              </div>
              <!-- /filter_type -->

              <div class="filter_type version_2" v-if="tagList.length > 0">
                <h4>
                  <a href="#filter_2" data-bs-toggle="collapse" class="closed opened">Tags</a>
                </h4>
                <div class="collapse" id="filter_2">
                  <ul>
                    <li v-for="(data, index) in tagList" :key="index">
                      <label class="container_check">
                        {{ data }}
                        <input v-model="tags" :value="data" type="checkbox" :id="data + '-tag'"
                          @change="filterProduct" />
                        <span class="checkmark"></span>
                      </label>
                    </li>
                  </ul>
                </div>
              </div>

              <!-- /filter_type -->
              <div class="filter_type version_2">
                <h4>
                  <a href="#filter_3" data-bs-toggle="collapse" class="closed opened">Brands</a>
                </h4>
                <div class="collapse catPlace" id="filter_3">
                  <ul>
                    <li v-for="(data, index) in brandList" :key="index">
                      <label class="container_check">
                        {{ data.brandName }}
                        <input @click="checkBrands(data.brandName)" v-model="brandName" :value="data.brandName"
                          :id="data.id" type="radio" />
                        <span class="checkmark"></span>
                      </label>
                    </li>
                  </ul>
                </div>
              </div>

              <!-- <div class="filter_type version_2">
              <h4>
                <a href="#filter_3" data-bs-toggle="collapse" class="closed"
                  >Size</a
                >
              </h4>
              <div class="collapse" id="filter_3">
                <ul>
                  <li v-for="(data, index) in sizesList" :key="index">
                    <label class="container_check"
                      >{{ data.sizeName }}
                      <input
                        v-model="sizes"
                        :value="data.sizeName"
                        type="checkbox"
                      />
                      <span class="checkmark"></span>
                    </label>
                  </li>
                </ul>
              </div>
            </div>-->

              <!-- /filter_type -->
              <div class="filter_type version_2">
                <h4 class="mb-5">
                  <a href="#filter_4" data-bs-toggle="collapse" class="closed opened">Price</a>
                </h4>
                <div class="collapse mb-3" id="filter_4">
                  <Slider class="mb-4" v-model="priceValue" :min="0" :max="10000" @click="filterProduct" />
                  <div class="row">
                    <div class="col-6">
                      <label for>Min Price:</label>
                      <input style="height: 10px" type="number" v-model="priceValue[0]" min="0" max="10000"
                        @change="filterProduct" class="form-control form-control-sm" />
                    </div>
                    <div class="col-6">
                      <label for>Max Price:</label>
                      <input style="height: 10px" type="number" v-model="priceValue[1]" min="0" max="10000"
                        @change="filterProduct" class="form-control form-control-sm" />
                    </div>
                  </div>
                </div>
              </div>
              <!-- /filter_type -->
              <div class="buttons">
                <!-- <a href="javascript:void(0)" @click="filterProduct" class="btn_1">Filter</a> -->
                <a href="javascript:void(0)" @click="resetFilter" class="btn_1">Reset</a>
              </div>
            </div>
          </div>

          <div class="position-absolute top-50 start-0">
            <div style="height:100vh; z-index:99999999; width:100vw;" class="offcanvas offcanvas-start" tabindex="-1"
              id="offcanvasExample2" aria-labelledby="offcanvasExampleLabel2">
              <div v-if="filterToogle">
                <div class="offcanvas-header d-flex justify-content-end me-3 mb-3">
                  <a type="btn" id="filterCloseBtn" class="btn-close text-reset d-none"
                    @click="() => { filterToogle = false; }" data-bs-dismiss="offcanvas" aria-label="close"></a>
                </div>
                <div class="px-5 mb-5 measure-left-filter">


                  <div class="buttons mt-0 mt-md-5 d-flex justify-content-between">
                    <a href="javascript:void(0)" @click="resetFilter" class="btn_1 custom_margin">View All</a>
                    <a type="btn" id="filterCloseBtn" class="btn-close text-reset d-block d-lg-none custom_margin"
                    @click="() => { filterToogle = false; }" data-bs-dismiss="offcanvas" aria-label="close"></a>
            
                  </div>

                  <div class="filter_type version_2 mt-3">
                    <h4>
                      <a href="#filter_1" data-bs-toggle="collapse" class="closed opened">Categories</a>
                    </h4>
                    <div class="collapse" id="filter_1">
                      <ul>
                        <li v-for="(data, index) in categoriesList" :key="index">
                          <label class="container_check">
                            {{ data.name }}
                            <input @click="checkCategory(data.id)" v-model="categoryId"
                              @change="getSubCategories(categoryId)" :value="data.id" type="radio" />
                            <span class="checkmark"></span>
                          </label>
                        </li>
                      </ul>
                    </div>
                    <!-- /filter_type -->
                  </div>
                  <div class="filter_type version_2" v-if="subCategoriesList.length > 0">
                    <h4>
                      <a href="#filter_5" data-bs-toggle="collapse" class="closed opened">Sub Categories</a>
                    </h4>
                    <div class="collapse subcatPlace" id="filter_5">
                      <ul>
                        <li v-for="(data, index) in subCategoriesList" :key="index">
                          <label class="container_check">
                            {{ data.name }}
                            <input @click="checkSubCategory(data.id)" v-model="subCategoryId"
                              @change="getChildCategory(subCategoryId)" :value="data.id" type="radio" />
                            <span class="checkmark"></span>
                          </label>
                        </li>
                      </ul>
                    </div>
                    <!-- /filter_type -->
                  </div>
                  <div class="filter_type version_2" v-if="childCategoryList.length > 0">
                    <h4>
                      <a href="#filter_6" data-bs-toggle="collapse" class="closed opened">Child Categories</a>
                    </h4>
                    <div class="collapse show subcatPlace" id="filter_1">
                      <ul>
                        <li v-for="(data, index) in childCategoryList" :key="index">
                          <label class="container_check">
                            {{ data.name }}
                            <input @click="checkChildCategory(data.id)" v-model="childCategoryId" :value="data.id"
                              type="radio" />
                            <span class="checkmark"></span>
                          </label>
                        </li>
                      </ul>
                    </div>
                    <!-- /filter_type -->
                  </div>
                  <!-- /filter_type -->

                  <div class="filter_type version_2" v-if="tagList.length > 0">
                    <h4>
                      <a href="#filter_2" data-bs-toggle="collapse" class="closed opened">Tags</a>
                    </h4>
                    <div class="collapse" id="filter_2">
                      <ul>
                        <li v-for="(data, index) in tagList" :key="index">
                          <label class="container_check">
                            {{ data }}
                            <input v-model="tags" :value="data" type="checkbox" :id="data + '-Mtag'"
                              @change="filterProduct" />
                            <span class="checkmark"></span>
                          </label>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <!-- /filter_type -->
                  <div class="filter_type version_2">
                    <h4>
                      <a href="#filter_3" data-bs-toggle="collapse" class="closed opened">Brands</a>
                    </h4>
                    <div :class="['collapse', brandName ? 'show catPlace' : ' catPlace']" id="filter_3">
                      <ul>
                        <li v-for="(data, index) in brandList" :key="index">
                          <label class="container_check">
                            {{ data.brandName }}
                            <input @click="checkBrands(data.brandName)" v-model="brandName" :value="data.brandName"
                              type="radio" />
                            <span class="checkmark"></span>
                          </label>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <!-- <div class="filter_type version_2">
              <h4>
                <a href="#filter_3" data-bs-toggle="collapse" class="closed"
                  >Size</a
                >
              </h4>
              <div class="collapse" id="filter_3">
                <ul>
                  <li v-for="(data, index) in sizesList" :key="index">
                    <label class="container_check"
                      >{{ data.sizeName }}
                      <input
                        v-model="sizes"
                        :value="data.sizeName"
                        type="checkbox"
                      />
                      <span class="checkmark"></span>
                    </label>
                  </li>
                </ul>
              </div>
            </div>-->

                  <!-- /filter_type -->
                  <div class="filter_type version_2">
                    <h4 class="mb-5">
                      <a href="#filter_4" data-bs-toggle="collapse" class="closed opened">Price</a>
                    </h4>
                    <div class="collapse mb-3" id="filter_4">
                      <Slider class="mb-4" v-model="priceValue" :min="0" :max="10000" @click="filterProduct" />
                      <div class="row">
                        <div class="col-6">
                          <label for>Min Price:</label>
                          <input style="height: 10px" type="number" v-model="priceValue[0]" min="0" max="10000"
                            @change="filterProduct" class="form-control form-control-sm" />
                        </div>
                        <div class="col-6">
                          <label for>Max Price:</label>
                          <input style="height: 10px" type="number" v-model="priceValue[1]" min="0" max="10000"
                            @change="filterProduct" class="form-control form-control-sm" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- /filter_type -->
                  <div class="buttons">
                    <!-- <a href="javascript:void(0)" @click="filterProduct" class="btn_1">Filter</a> -->
                    <a href="javascript:void(0)" @click="resetFilter" class="btn_1">Reset</a>
                  </div>

                </div>
              </div>
            </div>

          </div>




        </div>
        <!-- /col -->
        <div class="col-12 col-md-12 col-lg-9" v-if="productList.length > 0">
          <div class="row small-gutters">
            <div class="col-6 col-md-4" v-for="(data, index) in productList" :key="index">
              <div class="grid_item">
                <span class="ribbon on" v-if="data.discountTitle">
                  {{
                    data.discountTitle
                  }}
                </span>
                <div
                  v-if="this.checkForShipping(data)"
                  class="ribbon--css ribbon--triangle" style="border-bottom: 20px solid rgb(9, 134, 72);"
                >
                  Free shipping
                </div>
                <figure>
                  <a :href="'/product-details/' + data.urlSlug">
                    <img class="img-fluid lazy" 
                      :src="placeholderLazyLoading" 
                      v-lazy="imagePath + data.featuredImage"
                      style="height:275px;"
                      :alt="data.name" 
                      :title="data.name" 
                    />
                  </a>
                </figure>
                <span>
                  <a style="color:#000; font-weight: 400; font-size: 0.8em; text-decoration:none;" :href="'/products?brandName='+ data?.brandName">{{ data?.brandName?.length>15 ? data?.brandName?.slice(0, 15) + '..'  : data?.brandName }}</a>
                </span>
                <br />
                <a :href="'/product-details/' + data.urlSlug" style="color: black">
                  <h3 style="font-size: 1em; color: #000; font-weight: 600;">{{ data.name }}</h3>
                </a>
                <p class="font-monospace mb-1 mt-1" v-if="data.sku">SKU #: {{data.sku}}</p>
                <div class="price_box">
                  <span class="new_price">
                    <!-- {{data.userDiscountedPrice}} / {{data.discountedPrice}} / {{data.price}}
                    <hr />-->
                    ${{ findMixinActualPrice(data) }}
                    <!-- <hr /> -->
                    <!-- ${{ 
                            (data.userDiscountedPrice && data.userDiscountedPrice != null && data.userDiscountedPrice != "") 
                            ? (data.userDiscountedPrice > data.discountedPrice  ? data.discountedPrice : data.userDiscountedPrice) 
                            : data.discountedPrice ? data.discountedPrice : data.price
                    }}-->
                    &nbsp;
                  </span>
                  <span class="old_price" v-if="data.discountedPrice || data.userDiscountedPrice">${{
                    data.price
                  }}</span>

                  <span class="rating">
                    <template v-if="data?.review_count > 0">
                      <i class="icon-star voted" v-for="index in parseInt(data?.review_avg)" :key="index"></i>
                    </template>

                    <template v-if="data?.review_count==0">
                      <i class="icon-star" v-for="index in 5" :key="index"></i>
                    </template>
                    <template v-else>
                      <i class="icon-star" v-for="index in 5-parseInt(data?.review_avg)" :key="index"></i>
                    </template>
                    <em>{{ parseInt(data?.review_avg ? data?.review_avg : 0) }}</em>
                    <p>{{ data?.review_count ? data?.review_count : 0 }} reviews</p>
                  </span>
                  <!-- <div v-if="data?.NoOfReview > 0">
                    <a href="javascript:void(0)">{{ data?.NoOfReview }} Review(s)</a>
                  </div> -->
                </div>
                <ul>
                  <li>
                    <a href="javascript:void(0)" class="tooltip-1" data-bs-toggle="tooltip" data-bs-placement="left"
                      title="Add to favorites" @click="processWishListData(data, 1)">
                      <i class="ti-heart"></i>
                      <span>Add to favorites</span>
                    </a>
                  </li>
                  <li>
                    <a href="javascript:void(0)" @click="processCartData(data, 1)" class="tooltip-1"
                      data-bs-toggle="tooltip" data-bs-placement="left" title="Add to cart">
                      <i class="ti-shopping-cart"></i>
                      <span>Add to cart</span>
                    </a>
                  </li>
                </ul>
              </div>
              <!-- /grid_item -->
            </div>
          </div>
          <!-- <div class="row small-gutters" v-else>
            <p class="text-center">No Data Found.</p>
          </div> -->
          <nav class="page-navigation justify-content-center d-flex m-3 mb-5" aria-label="page-navigation">
            <v-pagination 
              v-if="productList && totalPage!=1" v-model="page" @update:modelValue="updateHandler" :pages="totalPage"
              :range-size="1" active-color="#FF8400"
            >
            </v-pagination>
          </nav>
        </div>
        <div class="col-12 col-md-12 col-lg-9" v-else>
          <div class="alert alert-warning" role="alert" style="text-align: center;">
            {{ productRecord }}
          </div>
        </div>
        <div class="col-12" v-if="pageCategoryView">
            <p v-html="pageCategorySeoContent?.categorySeoContent"></p>
            <hr class="mt-3 mb-3"/>
            <div class="border-dark">
              <h3 class="p-2">Related Sub-Categories</h3>
              <hr style="margin: 10px 0px;" />
              <div class="d-flex">
                
                <span
                  v-for="(data, index) in subCategoriesList"
                  :key="index"
                  class="m-1"
                >
                  <a  :href="'/sub-category/'+data.subCategorySlug" @click.prevent="checkSubCategory(data.id, data.subCategorySlug)"><button class="btn btn-info px-2 btn-subcat-back">{{ data.name }}</button></a>
                  {{subCategoriesList.length != (index+1)?'/':''}} 
              </span> 
              </div>
            </div>              
        </div>
        <div class="col-12" v-else-if="pageSubCategoryView">
            <p v-html="pageSubCategorySeoContent?.subcategorySeoContent"></p>
        </div>
        <div class="col-12" v-else-if="pageChildCategoryView">
            <p v-html="pageChildCategorySeoContent?.childCategorySeoContent"></p>
        </div>
        <div class="col-12" v-else>
            <p v-html="pageCMS?.productSeoContent"></p>
        </div>

        <!-- /col -->
      </div>
      <!-- /row -->
    </div>


    <!-- /container -->
  </main>
  <Advertise v-if="state.advertiseList.includes('Products') && state.isAdvertise == true" />
  <NewsLetter v-if="
  state.newsletterList.includes('Products') && state.isNewsLetter == true
  " />
  <!-- <CategorySchemajson v-if="$route.path==`/category/${$route.params.categorySlug}` && Object.values(categoryMeta).length > 0" :categoryMeta="categoryMeta"/> -->
  <CategorySchemajson  v-if="pageCategoryView"  :categoryMeta="categoryMeta"/>
  <SubCategorySchemajson  v-if="pageSubCategoryView"  :subCategoryMeta="subCategoryMeta"/>
  <ProductsSchemajson v-if="!pageCategoryView && !pageSubCategoryView"  :categoryMeta="pageTitles"/>
</template>
<style scoped>
  @import url("../css/product.css");
</style>
<script src="../js/product.js"></script>