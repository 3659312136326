import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import axios from "axios";
import moment from "moment";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import mixin from "./mixin";
import cartMixin from "./cartMixin";
import wishListMixin from "./wishListMixin";
import Loader from "./components/loader/template/Loader";
import { VueReCaptcha } from "vue-recaptcha-v3";
import vue3GoogleLogin from "vue3-google-login";
import VueGoogleMaps from "@fawmi/vue-google-maps";
import VueImageZoomer from "vue-image-zoomer";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import VueLazyload from "vue-lazyload";

const app = createApp(App);
const server = require("../config/server.json");
app.component("Loader", Loader);
app.component("Multiselect", Multiselect);
app.component("VueDatePicker", VueDatePicker);
app.config.globalProperties.$api = require("../config/api.json");
app.config.globalProperties.$axios = axios;
app.config.globalProperties.$server = server;

app.use(VueLazyload, {
  preLoad: 1.3,
  error: "https://hgshydro.nyc3.cdn.digitaloceanspaces.com/default.jpg",
  loading: "https://hgshydro.nyc3.cdn.digitaloceanspaces.com/default.jpg",
  attempt: 3,
});

// if (location.hostname === "localhost" || location.hostname === "127.0.0.1") {
//   app.config.globalProperties.$hostURL = server.server_staging.HOST;
//   app.config.globalProperties.$serverURL = server.server_staging.SERVER_HOST;
//   app.config.globalProperties.$imageURL = server.server_staging.IMAGE_URL;
// } else {
  app.config.globalProperties.$hostURL = server.server.HOST;
  app.config.globalProperties.$serverURL = server.server.SERVER_HOST;
  app.config.globalProperties.$imageURL = server.server.IMAGE_URL;
// }

app.config.globalProperties.$advertiseList = [];
app.config.globalProperties.$newsletterList = [];
app.config.globalProperties.$cartList = "cartList";
app.config.globalProperties.$wishList = "wishList";
app.config.globalProperties.$filters = {
  formatDate(date) {
    return moment(String(date)).format("DD MMM. YYYY");
  },
  formatDate2(date) {
    return moment(String(date)).format("MMM DD, YYYY");
  },
  formatReviewDate(date) {
    return moment(date).fromNow();
  },
  getMonth(date) {
    return moment(String(date)).format("MMM")
  },
  getDate(date) {
    return moment(String(date)).format("DD")
  }
};
const googleKey = server.google.logInKey;
const captchaKey = server.google.recaptchaKeyv3;
app.config.globalProperties.$googleKey = googleKey;
const clientID = googleKey + ".apps.googleusercontent.com";
app.config.globalProperties.$googleSiteClientID = clientID;
app.use(VueSweetalert2);
app.use(vue3GoogleLogin, {
  clientId: clientID,
});
app.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyC1aLCHTX1FDqhU-eb1tOQTmXsTybe4pBc",
    libraries: "places",
  },
});
app.mixin(mixin);
app.mixin(cartMixin);
app.mixin(wishListMixin);
app.use(VueImageZoomer);
app.use(VueReCaptcha, { siteKey: captchaKey });
app.use(router).mount("#app");

import AOS from "aos";
import "aos/dist/aos.css";

AOS.init({
  // Global settings:
  offset: 120, // offset (in px) from the original trigger point
  delay: 0, // values from 0 to 3000, with step 50ms
  duration: 400, // values from 0 to 3000, with step 50ms
  easing: "ease", // default easing for AOS animations
  once: false, // whether animation should happen only once - while scrolling down
  anchorPlacement: "top-bottom", // defines which position of the element regarding to window should trigger the animation
});
