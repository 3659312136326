<template>
  <teleport to="head">
    <link rel="canonical" :href="$route.path" />
    <meta name="title" :content="catSubMeataData.metaTitle" />
    <meta name="keywords" :content="catSubMeataData.metaKeyword" />
    <meta name="description" :content="catSubMeataData.metaDescription" />
    <meta name="author" content="HGSHYDRO">
    <meta name="publisher" content="HGSHYDRO">
  </teleport>
</template>

<script>
export default {
  name: "SubCategorySchemajson ",
  props: ["subCategoryMeta"],
  data() {
    return {
      catSubMeataData: {}
    };
  },
  created() {
    console.log("catSubMeataData In = ", this.catSubMeataData);
  },
  watch: {
    subCategoryMeta: {
      immediate: true,
      handler(val, oldVal) {
        oldVal;
        this.catSubMeataData = val;
      }
    }
  }
};
</script>
