<template>
  <main class="bg_gray">
    <div class="container margin_30">
      <div class="page_header">
        <div class="breadcrumbs">
          <ul>
            <li>
              <a href="javascript:void(0)" @click="$router.push('/home')">Home</a>
            </li>
            <li>
              <a href="#">Checkout</a>
            </li>
          </ul>
        </div>
        <h1 class="text-center">Please Enter Your Billing Address Information</h1>
      </div>
      <div class="container custom-width">
        <!-- Order Summery -->
        <CheckoutSummery />
        <!-- Order Summery -->
        <div class="border p-3" style="background-color: white;">
          <h5 class="fw-bold mb-1">Billing Address</h5>
          <p class="mb-4" style="font-size: 10px;color:red;">Fields marked with (*) are mandatory</p>
          <div>
            <div
              :class="['tab-pane', 'fade', 'show active']"
              id="tab_1"
              role="tabpanel"
              aria-labelledby="tab_1"
            >
              <div class="form-group">
                <label>
                  Name
                  <span style="color: red">*</span>
                </label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Billing name"
                  v-model="billingAddressData.name"
                />
              </div>
              <div class="row no-gutters">
                <div class="form-group col-12 col-lg-6 form-group pl-1">
                  <label>
                    Contact No
                    <span style="color: red">*</span>
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Mobile No"
                    v-model="billingAddressData.mobile"
                  />
                </div>
                <div class="form-group col-12 col-lg-6 form-group pl-1">
                  <label>
                    Email
                    <span style="color: red">*</span>
                  </label>
                  <input
                    type="email"
                    class="form-control"
                    placeholder="Email"
                    v-model="billingAddressData.email"
                  />
                </div>
              </div>
              <div class="form-group">
                <label>
                  Street Address
                  <span style="color: red">*</span>
                </label>
                <GMapAutocomplete
                  @place_changed="setBillingAddressInput"
                  :value="billingAddressData.address"
                  :class="'form-control'"
                  v-on:input="billingAddressData.address = $event.target.value"
                ></GMapAutocomplete>
              </div>
              <div class="col-12 form-group pr-1">
                <label>
                  City
                  <span style="color: red">*</span>
                </label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="City"
                  v-model="billingAddressData.city"
                />
              </div>
              <div class="row no-gutters">
                <div class="col-12 col-lg-6 form-group pl-1">
                  <label>
                    Zip Code
                    <span style="color: red">*</span>
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Zip code"
                    v-model="billingAddressData.postCode"
                  />
                </div>
                <div class="col-12 col-lg-6 form-group pl-1">
                  <label>
                    State
                    <span style="color: red">*</span>
                  </label>
                  <select name="state" v-model="billingAddressData.stateCode" class="form-select">
                    <option
                      v-for="(data, index) in states"
                      :key="index"
                      :value="data.code"
                    >{{ data.name }}</option>
                  </select>
                </div>
                <div class="form-group">
                  <label>
                    Business Name
                    <span class="align-middle mb-0" style="font-size: 10px;">(Optional)</span>
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Business name"
                    v-model="billingAddressData.businessName"
                  />
                </div>
                <!-- <div class="col-6 form-group pr-1">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="State Name"
                  
                      v-model="billingAddressData.stateCode"
                    />
                </div>-->
              </div>
              <div class="form-group" hidden>
                <select name="country" v-model="country" class="form-control">
                  <option
                    v-for="(data, index) in countriesList"
                    :key="index"
                    :value="{
                      name: data.countryNameEn,
                      code: data.countryCode,
                    }"
                  >{{ data.countryNameEn }}</option>
                </select>
              </div>
              <!-- <hr style="margin-top:0; margin-bottom:0;"/> -->
              <div class="form-group">
                <label class="container_check">
                  Make shippping same as billing
                  <input
                    v-model="shippingSameAsBilling"
                    @click="handleShippingSameAsBilling($event)"
                    type="checkbox"
                  />
                  <span style="margin-top: 2px !important;" class="checkmark"></span>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div
          class="d-flex flex-column flex-lg-row justify-content-between align-items-center gap-2 gap-lg-5 mt-4"
        >
          <button style="background: #172134;"
            type="button"
            @click="$router.push('/products')"
            class="btn_1 btn-checkout-back full-width d-none d-sm-block"
          ><i class="ti-back-left"></i> CONTINUE SHOPPING</button>
          <button type="button" @click="submitFormAndProceed()" class="btn_1 full-width checkout-billing-fillup"><i class="ti-back-right"></i> PROCEED NEXT</button>
          <button
            type="button"
            @click="$router.push('/products')"
            class="btn_1 btn-checkout-back full-width d-block d-sm-none" style="background: #172134;"
          ><i class="ti-back-left"></i> CONTINUE SHOPPING</button>
        </div>
      </div>
    </div>
    <!-- /page_header -->
  </main>
  <CheckoutSchemajson v-if="Object.values(pageTitles).length > 0" :metaData="pageTitles" />
</template>
<script src="../js/checkoutBilling.js"></script>
<style scoped>
@import "../css/checkout.css";

.hr-padding {
  margin: 15px 0 15px 0 !important;
  border: 0;
  border-top: 1px solid #dddddd;
}

.btn-checkout-back:hover
{
  background-color: #FFC107 !important;
  color: #111 !important;
}
</style>