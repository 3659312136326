import { state } from '@/globalVariables';
import NewsLetter from '../../Newsletter/template/NewsLetter';
import Advertise from '../../Advertise/template/Advertise';
// import VPagination from "@hennge/vue3-pagination";
import VPagination from "../../pagination/VPagination";
// import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import Slider from '@vueform/slider'
import "@vueform/slider/themes/default.css";
import { authHeader } from '@/auth';
import CategorySchemajson from '../../../components/seoPlugin/CategorySchemajson.vue'
import SubCategorySchemajson from '../../../components/seoPlugin/SubCategorySchemajson.vue'
import ProductsSchemajson from '../../../components/seoPlugin/ProductsSchemajson.vue'
import VueLazyload from 'vue-lazyload';

export default {
    components: {
        Slider,
        NewsLetter,
        Advertise,
        VPagination,
        CategorySchemajson,
        SubCategorySchemajson,
        ProductsSchemajson
    },
    directives: {
        lazy: VueLazyload.directive,
    },
    data() {
        return {
            state,
            placeholderLazyLoading:'https://hgshydro.nyc3.cdn.digitaloceanspaces.com/default.jpg',
            colorsList: [],
            sizesList: [],
            brandList: [],
            totalProductList: [],
            tempTotalProductList: [],
            productList: [],
            categoryId: '',
            subCategoriesList: [],
            childCategoryList: [],
            subCategoryId: "",
            childCategoryId: "",
            brandName: '',
            colors: [],
            sizes: [],
            page: +this.$route.query.page || 1,
            limit: 12,
            totalPage: 0,
            pageCMS: [],
            sortBy: '',
            imagePath: this.$imageURL + 'productImage/',
            priceValue: [0, 10000],
            tagList: [],
            tags: [],
            searchText: '',
            isActiveDropDown: false,
            suggestions: [],
            sugBrandName: [],
            preSearchText: "",
            filterToogle: false,
            productRecord: "Loading, Please wait...",
            freeShippingTarget: 0,
            searchTotal: 0,
            categoryMeta: {},
            subCategoryMeta: {},
            pageCategoryView:false,
            pageSubCategoryView:false,
            pageChildCategoryView:false,
            pageCategorySeoContent:{},
            pageSubCategorySeoContent:{},
            pageChildCategorySeoContent:{},
            categoryNameTitleHeading: null,
            subCategoryNameTitleHeading: null,
        }
    },
    async created() {
        await this.getCategories();
        await this.getPageTitles();
        await this.getCoupons();
        console.log('this.pageTitles =', this.pageTitles);
        document.title = (this.pageTitles?.productPagetitle ? this.pageTitles?.productPagetitle : '');
        this.state.isLoading = true;
        this.scrollToTop();
        let params = '?page=' + this.page + '&limit=' + this.limit;

        if (this.$route.params.categorySlug) {
            console.log("category", this.$route.params.categorySlug);
            let matchFlag = false;
            for (let i = 0; i < this.categoriesList.length; i++) {
                if (this.categoriesList[i].categorySlug == encodeURIComponent(this.$route.params.categorySlug)) {
                    this.categoryId = this.categoriesList[i].id;
                    await this.getCategoryMeta(this.categoriesList[i].id);
                    await this.getCategorySeoContent(this.categoryId);
                    this.categoryNameTitleHeading = this.categoriesList[i].categoryTitle;
                    //this.categoryNameTitleHeading = this.categoriesList[i].name;
                    this.pageCategoryView = true;
                    this.pageSubCategoryView = false;
                    this.pageChildCategoryView = false;
                    params += '&parentCategoryId=' + this.categoryId;
                    if (this.categoriesList[i].hasChild == true) {
                        
                        console.log('this.categoriesList[i].id = ', this.categoriesList[i].name)
                        
                        await this.getSubCategories(this.categoryId)
                        
                    }
                    matchFlag = true;
                    break;
                }
            }

            if(!matchFlag) {
                this.$router.push("/404");
            }
        }

        if (this.$route.params.subCategorySlug) {
            await this.getAllSubCategories();
            if (this.subCategoryList.length > 0) {
                let matchFlag = false;
                for (let i = 0; i < this.subCategoryList.length; i++) {
                    if (this.subCategoryList[i].subCategorySlug == encodeURIComponent(this.$route.params.subCategorySlug)) {
                        this.categoryId = this.subCategoryList[i].parentCategoryId;
                        params += '&parentCategoryId=' + this.categoryId;

                        await this.getSubCategoryMeta(this.subCategoryList[i].id);
                        await this.getCategorySeoContent(this.subCategoryList[i].id);
                        await this.getSubCategorySeoContent(this.subCategoryList[i].id);

                        this.subCategoryNameTitleHeading = this.subCategoryList[i].subcategoryTitle;
                        console.log('this.subCategoryList[i] =', this.subCategoryList[i]);
                        
                        this.categoryNameTitleHeading = this.subCategoryList[i].parentCategoryName;
                        this.pageCategoryView = false;
                        this.pageSubCategoryView = true;
                        this.pageChildCategoryView = false;

                        await this.getSubCategories(this.categoryId);
                        this.subCategoryId = this.subCategoryList[i].id;
                        params += '&subCategoryId=' + this.subCategoryId;
                        this.getChildCategory(this.subCategoryId);
                        matchFlag = true;
                        break;
                    }
                }
                if(!matchFlag) {
                    this.$router.push("/404");
                }
            }

        }

        if (this.$route.query.categoryId) {
            this.categoryId = this.$route.query.categoryId;
            params += '&parentCategoryId=' + this.$route.query.categoryId;
            await this.getCategorySeoContent(this.categoryId);
        }
        if (this.$route.query.brandName) {
            params += '&brandName=' + encodeURIComponent(this.$route.query.brandName);

            console.log('&brandName=' + encodeURIComponent(this.$route.query.brandName));
            console.log('this.$route.query.brandName =', this.$route.query.brandName);
            this.brandName = this.$route.query.brandName;
        }
        if (this.$route.query.subCategoryId) {
            this.subCategoryId = this.$route.query.subCategoryId;
            params += '&subCategoryId=' + this.$route.query.subCategoryId;
            await this.getSubCategorySeoContent(this.subCategoryId);
        }
        if (this.$route.query.discountTitle) {
            params += '&discountTitle=' + this.$route.query.discountTitle;
        }
        let cmsURL = this.$serverURL + this.$api.product.productCMS;

        const isValidSearch = this.$route.query?.keyword && this.$route.query?.keyword.trim() != '';
        if (isValidSearch) {
            await this.getProductByElasticSearch();
        } else {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.product.productAPI + params,
                ...(this.state.token && { headers: { "Authorization": authHeader() } })
            }
            if (this.state.token) {
                config['url'] = this.$serverURL + this.$api.product.productAuthorizedAPI + params;
            }
            await this.getAllProducts(config);
        }

        await this.getProductCMS(cmsURL);
        await this.getAllVariant();
        await this.loadJSCSS();
    },

    mounted() {
        document.addEventListener('click', this.handleClickOutside);
    },
    beforeUnmount() {
        document.removeEventListener('click', this.handleClickOutside);
    },

    methods: {
        // calculateDiscountPrice: async function(data) {
        //     let userId = localStorage.getItem("id");
        //     data.userDiscountList.map(element => {
        //         if(element.userId === userId) {
        //             return data.discountedPrice > element.userDiscountedPrice ? element.userDiscountedPrice : data.discountedPrice;
        //         }
        //     });
        // },
        async getCoupons() {
            try {
                let config = {
                    method: "GET",
                    url: this.$serverURL + this.$api.coupon.getCouponsURL,
                    header: {
                        'Content-Type': "application/json"
                    }
                };
                await this.$axios(config).then(response => {
                    this.couponList = response.data.data;
                    for (let index = 0; index < this.couponList.length; index++) {
                        const coupon = this.couponList[index];
                        if (coupon.couponType == "free shipping") {
                            this.freeShippingTarget = parseInt(coupon.discount);
                            break;
                        }
                    }
                }).catch(error => {
                    console.log(error);
                })
            } catch (error) {
                console.log(error);
            }
        },

        checkForShipping: function (data) {
            return this.freeShippingTarget > 0 && this.findMixinActualPrice(data) >= this.freeShippingTarget;
        },

        checkCategory: async function (id, slug) {
            if (this.categoryId == id) {
                //this.resetFilter();
                this.categoryId = '';
                this.subCategoriesList = [];
                this.subCategoryId = '';
                this.childCategoryList = [];
                this.childCategoryId = '';
            }
            else {
                this.categoryId = id;
                this.subCategoriesList = [];
                this.subCategoryId = '';
                this.childCategoryList = [];
                this.childCategoryId = '';
            }
            // await this.filterProduct();
            this.$router.push({ name: 'CategoryProduct', params: { categorySlug: slug } });
        },
        checkSubCategory: async function (id, slug) {
            if (this.subCategoryId == id) {
                this.subCategoryId = '';
                this.childCategoryList = [];
                this.childCategoryId = '';
            }
            else {
                this.subCategoryId = id;
                this.childCategoryList = [];
                this.childCategoryId = '';
            }

            // await this.filterProduct();
            this.$router.push({ name: 'SubCategoryProduct', params: { subCategorySlug: slug } });
        },
        checkChildCategory: async function (id) {
            if (this.childCategoryId == id) {
                this.childCategoryId = '';
            }
            else {
                this.childCategoryId = id;
            }

            this.pageCategoryView = false;
            this.pageSubCategoryView = false;
            this.pageChildCategoryView = true;

            await this.getChildCategorySeoContent(this.childCategoryId)
            await this.filterProduct();
        },
        checkBrands: async function (id) {
            if (this.brandName == id) {
                this.brandName = '';
            }
            else {
                this.brandName = id;
            }

            await this.filterProduct();
        },
        updateHandler: async function () {
            //let params = "?page=" + this.page + "&limit=" + this.limit;
            this.scrollToTop();
            let params = '?page=' + this.page + '&limit=' + this.limit;
            if (this.categoryId) {

                params += '&parentCategoryId=' + this.categoryId;
            }
            if (this.subCategoryId) {
                params += '&subCategoryId=' + this.subCategoryId;
            }
            // if (this.$route.query.keyword) {
            //     params += '&keyword=' + this.$route.query.keyword;
            // }
            if (this.childCategoryId) {
                params += '&childCategoryId=' + this.childCategoryId;
            }
            if (this.brandName) {
                params += '&brandName=' + this.brandName;
            }

            // if (this.sizes.length > 0) {
            //     params += '&size=' + this.sizes;
            // }
            if (this.priceValue) {
                params += '&minPrice=' + this.priceValue[0];
                params += '&maxPrice=' + this.priceValue[1];
            }
            if (this.$route.query.discountTitle) {
                params += '&discountTitle=' + this.$route.query.discountTitle;
            }

            // let config = {
            //     method: "GET",
            //     url: this.$serverURL + this.$api.product.productAPI + params,
            // }
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.product.productAPI + params,
                ...(this.state.token && { headers: { "Authorization": authHeader() } })
            }
            if (this.state.token) {
                config['url'] = this.$serverURL + this.$api.product.productAuthorizedAPI + params;
            }

            const isValidSearch = this.$route.query?.keyword && this.$route.query?.keyword.trim() != '';
            if (isValidSearch) {
                const start = this.page > 0 ? this.page - 1 : 0;
                const end = this.limit > 0 ? this.limit : 12;
                this.productList = this.totalProductList.slice(start * end, (start + 1) * end);
            } else {
                await this.getAllProducts(config);
            }
        },
        selectSortBy: async function () {
            this.page = 1;
            this.limit = 12;
            let params = '?page=' + this.page + '&limit=' + this.limit;
            if (this.sortBy) {
                params += '&sortBy=' + this.sortBy;
            }
            // let config = {
            //     method: "GET",
            //     url: this.$serverURL + this.$api.product.productAPI + params,
            // }
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.product.productAPI + params,
                ...(this.state.token && { headers: { "Authorization": authHeader() } })
            }
            if (this.state.token) {
                config['url'] = this.$serverURL + this.$api.product.productAuthorizedAPI + params;
            }
            await this.getAllProducts(config);
        },
        filterProduct: async function () {
            this.page = 1;
            this.limit = 12;
            let params = '?page=' + this.page + '&limit=' + this.limit;
            // console.log('this.categoryId = ', this.categoryId);
            if (this.categoryId) {
                params += '&parentCategoryId=' + this.categoryId;
            }
            if (this.subCategoryId) {
                params += '&subCategoryId=' + this.subCategoryId;
            }
            if (this.childCategoryId) {
                params += '&childCategoryId=' + this.childCategoryId;
            }
            if (this.brandName) {
                params += '&brandName=' + this.brandName;
            }
            if (this.tags.length > 0) {
                params += '&tag=' + this.tags;
            }
            // if (this.colors.length > 0) {
            //     params += '&color=' + this.colors;
            // }
            // if (this.sizes.length > 0) {
            //     params += '&size=' + this.sizes;
            // }
            if (this.priceValue) {
                params += '&minPrice=' + this.priceValue[0];
                params += '&maxPrice=' + this.priceValue[1];
            }
            const isValidSearch = this.$route.query?.keyword && this.$route.query?.keyword.trim() != '';
            if (isValidSearch) {
                this.totalProductList = this.tempTotalProductList.filter(x => {
                    let flag = true;
                    if (this.categoryId) {
                        flag = flag && x.parentCategoryId === this.categoryId
                    }
                    if (this.subCategoryId) {
                        flag = flag && x.subCategoryId === this.subCategoryId;
                    }
                    if (this.childCategoryId) {
                        flag = flag && x.childCategoryId === this.childCategoryId;
                    }
                    if (this.brandName) {
                        flag = flag && x.brandName.toLowerCase() === this.brandName.toLowerCase();
                    }
                    if (this.priceValue) {
                        flag = flag && x.price >= this.priceValue[0] && x.price <= this.priceValue[1];
                    }

                    return flag;
                });

                this.totalPage = Math.ceil(this.totalProductList?.length / (this.limit > 0 ? this.limit : 1));

                const start = this.page > 0 ? this.page - 1 : 0;
                const end = this.limit > 0 ? this.limit : 12;
                this.productList = this.totalProductList.slice(start * end, (start + 1) * end);
            } else {
                let config = {
                    method: "GET",
                    url: this.$serverURL + this.$api.product.productAPI + params,
                    ...(this.state.token && { headers: { "Authorization": authHeader() } })
                }
                if (this.state.token) {
                    config['url'] = this.$serverURL + this.$api.product.productAuthorizedAPI + params;
                }
                await this.getAllProducts(config);
            }

            this.filterToogle = false;
            document.getElementById('filterCloseBtn').click();
        },
        resetFilter: async function () {
            this.categoryId = '';
            this.subCategoryId = '';
            this.childCategoryId = '';
            this.subCategoriesList = [];
            this.childCategoryList = [];
            this.brandName = '';
            this.colors = [];
            this.sizes = [];
            this.priceValue[0] = 0;
            this.priceValue[1] = 50000;

            this.page = 1;
            this.limit = 12;
            let params = '?page=' + this.page + '&limit=' + this.limit;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.product.productAPI + params,
                ...(this.state.token && { headers: { "Authorization": authHeader() } })
            }
            if (this.state.token) {
                config['url'] = this.$serverURL + this.$api.product.productAuthorizedAPI + params;
            }
            await this.getAllProducts(config);
            this.filterToogle = false;
            document.getElementById('filterCloseBtn').click();
        },
        loadJSCSS: async function () {
            this.state.isLoading = true;
            var styles = [
                '/core/assets/css/listing.css'
            ]
            styles.forEach(style => {
                let tag = document.createElement('link');
                tag.type = "text/css";
                tag.rel = "stylesheet";
                tag.href = style;
                document.head.appendChild(tag);
            });

            var scripts = [
                "/core/assets/js/sticky_sidebar.min.js",
                // "/core/assets/js/specific_listing.js",
                "/core/assets/js/main.js",
            ];
            scripts.forEach(script => {
                let tag = document.createElement("script");
                tag.setAttribute("src", script);
                document.head.appendChild(tag);
            });
            this.state.isLoading = false;
        },
        getProductCMS: async function (url) {
            let config = {
                method: "GET",
                url: url,
            }
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {

                    this.pageCMS = await response.data.data[0];

                    this.scrollToTop();

                }
            }).catch(error => {
                console.log(error);
            });
        },
        getAllVariant: async function () {
            let url = this.$serverURL + this.$api.product.productVarient;
            let config = {
                method: "GET",
                url: url,
            }
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {
                    // this.colorsList = response.data.colorData;
                    this.tagList = response.data.tagData;
                    this.brandList = response.data.brandData;
                }
                else {

                    this.$swal.fire({
                        icon: "error",
                        text: "Failed! please try again."
                    });
                }
            }).catch(error => {
                console.log(error);
            });
        },
        getAllProducts: async function (config) {
            this.productRecord = "Loading, Please wait...";
            this.productList = [];
            this.state.isLoading = true;
            await this.$axios(config).then(async (response) => {
                if (response.data.statusCode == 200) {
                    this.productList = response.data.products?.data.length > 0 ? response.data.products.data : [];
                    this.totalPage = response.data.products.pageCount;
                    this.state.isLoading = false;

                    if (this.productList.length == 0) {
                        this.productRecord = "No product found.";
                        return;
                    }

                    this.productList.forEach(data => {
                        if (data?.reviewListData?.length && data?.reviewListData?.length > 0) {
                            data.voted = 0
                            let sum = 0;
                            for (let i = 0; i < data.reviewListData.length; i++) {
                                sum = sum + parseInt(data.reviewListData[i].rating);
                            }
                            data.productRating = (sum / data.reviewListData.length).toFixed(1);
                            data.voted = Math.floor(sum / data.reviewListData.length);
                            data.notVoted = data.notVoted - data.voted;
                        } else {
                            data.voted = 0;
                            data.notVoted = 5;
                            data.productRating = 0;
                        }
                    });
                }
                else {
                    this.$swal.fire({
                        icon: "error",
                        text: "Failed! please try again."
                    });
                    this.state.isLoading = false;
                }
            }).catch(error => {
                this.state.isLoading = false;
                console.log(error);
            });
        },
        getCategorySeoContent: async function (id) {
            this.pageCategorySeoContent = {};
            try {
                let config = {
                    method: "GET",
                    url: this.$serverURL + this.$api.product.productCategorySeoContent + '/' + id,
                    headers: {
                        "Content-Type": "application/json"
                    }
                };
                await this.$axios(config).then(async (response) => {
                    if (response.data.statusCode == 200) {
                        if(response?.data?.data)
                        {
                            this.pageCategorySeoContent = await response?.data?.data;
                        
                            console.log('pageCategorySeoContent =', this.pageCategorySeoContent);
                        }
                        
                    }

                }).catch(error => {
                    console.log(error);
                });
            } catch (error) {
                console.log(error);
            }
        },
        getSubCategorySeoContent: async function (id) {
            this.pageSubCategorySeoContent = {};
            try {
                let config = {
                    method: "GET",
                    url: this.$serverURL + this.$api.product.productSubCategorySeoContent + '/' + id,
                    headers: {
                        "Content-Type": "application/json"
                    }
                };
                await this.$axios(config).then(async (response) => {
                    if (response.data.statusCode == 200) {
                        if(response?.data?.data)
                        {
                            this.pageSubCategorySeoContent = await response?.data?.data;
                        
                            console.log('pageSubCategorySeoContent =', this.pageSubCategorySeoContent);
                        }
                        
                    }

                }).catch(error => {
                    console.log(error);
                });
            } catch (error) {
                console.log(error);
            }
        },
        getChildCategorySeoContent: async function (id) {
            this.pageChildCategorySeoContent = {};
            try {
                let config = {
                    method: "GET",
                    url: this.$serverURL + this.$api.product.productChildCategorySeoContent + '/' + id,
                    headers: {
                        "Content-Type": "application/json"
                    }
                };
                await this.$axios(config).then(async (response) => {
                    if (response.data.statusCode == 200) {
                        if(response?.data?.data)
                        {
                            this.pageChildCategorySeoContent = await response?.data?.data;
                        
                            console.log('pageChildCategorySeoContent =', this.pageChildCategorySeoContent);
                        }
                        
                    }

                }).catch(error => {
                    console.log(error);
                });
            } catch (error) {
                console.log(error);
            }
        },
        getSubCategories: async function (id) {
            this.subCategoriesList = [];
            this.subCategoryId = "";
            this.childCategoryList = [];
            this.childCategoryId = "";
            try {
                let config = {
                    method: "GET",
                    url: this.$serverURL + this.$api.product.productSubCategoryByParentId + '?parentCategoryId=' + id,
                    headers: {
                        "Content-Type": "application/json"
                    }
                };
                await this.$axios(config).then(async (response) => {
                    if (response.data.statusCode == 200) {
                        this.subCategoriesList = await response.data.data;
                    }

                }).catch(error => {
                    console.log(error);
                });
            } catch (error) {
                console.log(error);
            }
        },
        getChildCategory: async function (id) {
            this.childCategoryList = [];
            this.childCategoryId = "";
            let url = this.$serverURL + this.$api.product.childCategoryURL + '?subCategoryId=' + id;
            let config = {
                method: "GET",
                url: url,
            }
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {

                    this.childCategoryList = response.data.data;
                }
                else {

                    this.$swal.fire({
                        icon: "error",
                        text: "Failed! please try again."
                    });
                }
            }).catch(error => {

                this.$swal.fire({
                    icon: "error",
                    text: "Failed! please try again." + error
                });
            });
        },
        processWishListData(data, quantity) {
            // data.choosedColor = "N/A";
            // data.choosedSize = "N/A";
            this.addToWishList(data, quantity);
        },
        processCartData(data, quantity) {
            // data.choosedColor = "N/A";
            // data.choosedSize = "N/A";
            this.addToCart(data, quantity, true);
        },
        async searchFilter() {
            let params = '?page=' + this.page + '&limit=' + this.limit;
            params += '&keyword=' + this.searchText;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.product.productAPI + params,
                ...(this.state.token && { headers: { "Authorization": authHeader() } })
            }
            if (this.state.token) {
                config['url'] = this.$serverURL + this.$api.product.productAuthorizedAPI + params;
            }
            await this.getAllProducts(config);
            this.categoryId = '';
            this.subCategoryId = '';
            this.childCategoryId = '';
            this.subCategoriesList = [];
            this.childCategoryList = [];
            this.brandName = '';
            this.colors = [];
            this.sizes = [];
            this.priceValue[0] = 0;
            this.priceValue[1] = 50000;

            this.page = 1;
            this.limit = 12;
            if (this.searchText != this.preSearchText) {
                let config = {
                    method: "POST",
                    url: this.$serverURL + this.$api.searchRecord.searchRecordURL,
                    data: {
                        search: this.searchText,
                    },
                    headers: {
                        "Content-Type": "application/json"
                    }
                };
                await this.$axios(config).catch(error => {
                    console.log(error);
                });
                this.searchText = ''
                this.state.isSearchActive = false;
                this.searchText = this.preSearchText
            }
        },
        closeFilterModalDiv() {
            console.log("Clicking");
            //this.$refs.dilterArea.style.display = 'none';
        },
        async getProductByElasticSearch(page = 1, limit = 12) {
            const keyword = this.$route.query.keyword;
            let config = {
                method: "POST",
                url: this.$serverURL + this.$api.product.elasticSearch,
                data: {
                    search: keyword
                },
                headers: {
                    "Content-Type": "application/json"
                }
            };
            await this.$axios(config).then(response => {
                this.totalProductList = response.data?.products?.data?.length > 0 ? response.data.products.data : [];
                this.tempTotalProductList = response.data?.products?.data?.length > 0 ? response.data.products.data : [];
                const start = page > 0 ? page - 1 : 0;
                const end = limit > 0 ? limit : 12;
                this.productList = this.totalProductList.slice(start * end, (start + 1) * end);
                this.totalPage = Math.ceil(response.data?.products?.total / (limit > 0 ? limit : 1));
                this.searchTotal = response.data?.products?.total;
                this.state.isLoading = false;

                if (this.productList.length == 0) {
                    this.productRecord = "No product found.";
                    return;
                }

                this.productList.forEach(data => {
                    if (data?.reviewListData?.length && data?.reviewListData?.length > 0) {
                        data.voted = 0
                        let sum = 0;
                        for (let i = 0; i < data.reviewListData.length; i++) {
                            sum = sum + parseInt(data.reviewListData[i].rating);
                        }
                        data.productRating = (sum / data.reviewListData.length).toFixed(1);
                        data.voted = Math.floor(sum / data.reviewListData.length);
                        data.notVoted = data.notVoted - data.voted;
                    } else {
                        data.voted = 0;
                        data.notVoted = 5;
                        data.productRating = 0;
                    }
                });
            })
                .catch(error => {
                    console.log(error);
                });
        },

        async searchElastic() {
            if (!this.searchText || this.searchText.trim() == '') {
                this.isActiveDropDown = false;
                this.suggestions = [];
                return;
            }

            let config = {
                method: "POST",
                url: this.$serverURL + this.$api.product.elasticSearch,
                data: {
                    search: this.searchText,
                    limit: 6,
                },
                headers: {
                    "Content-Type": "application/json"
                }
            };
            await this.$axios(config)
                .then(res => {
                    this.suggestions = res.data?.products?.data;
                    const brandName = res.data?.products?.data.map(x => x.brandName);
                    this.sugBrandName = new Set(brandName);
                    this.searchTotal = res.data?.products?.total;
                    this.isActiveDropDown = true;
                })
                .catch(error => {
                    console.log(error);
                });
        },

        async search() {
            this.$router.push({
                name: 'Product',
                query: { keyword: this.searchText },
            });
            this.state.isSearchActive = false;
            this.isActiveDropDown = false;
            this.searchText = "";
        },

        async handleSugClick(value) {
            this.searchText = value;
            await this.search();
        },

        handleClickOutside(event) {
            if (this.$refs.searchInputProduct.contains(event.target)) {
                this.isActiveDropDown = true;
                return;
            }

            this.isActiveDropDown = false;
            return;
        },

        async getCategoryMeta(catId) {
            let url = this.$serverURL + this.$api.meta.categoryMeta + '/' + catId;
            let config = {
                method: "GET",
                url: url,
            }
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {
                    this.categoryMeta = response.data.data.length > 0 ? response.data.data[0] : {};
                    console.log('this.categoryMeta =', this.categoryMeta);
                    document.title = (this.categoryMeta?.metaTitle)?(this.categoryMeta?.metaTitle):(this.pageTitles?.productPagetitle);
                }
            }).catch(error => {
                console.log(error);
            });
        },

        async getSubCategoryMeta(catId) {
            let url = this.$serverURL + this.$api.meta.subCategoryMeta + '/' + catId;
            let config = {
                method: "GET",
                url: url,
            }
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {
                    this.subCategoryMeta = response.data.data.length > 0 ? response.data.data[0] : {};
                    console.log('this.subCategoryMeta =', this.subCategoryMeta);
                    document.title = (this.subCategoryMeta?.metaTitle) ? (this.subCategoryMeta?.metaTitle):(this.pageTitles?.productPagetitle);
                }
            }).catch(error => {
                console.log(error);
            });
        }
    }
}