<template>
    <main class="bg_gray">
        <div class="container margin_120">
            <!-- /page_header -->
            <div class="row justify-content-center">
                <div class="col-xl-6 col-lg-6 col-md-8">
                    <div class="box_account">
                        <h3 class="client">Reset Your Password</h3>
                        <div class="form_container mt-3">
                            <div class="form-group" v-if="resetFlag">
                                <input type="password" class="form-control" v-model="password"  name="password" id="password" placeholder="New Password*">
                            </div>
                            <div class="form-group" v-if="resetFlag">
                                <input type="password" class="form-control" v-model="confirm_password"  name="newpassword" id="newpassword" placeholder="Confirm Password*">
                            </div>
                            <div class="text-center" @click="updatePassword" v-if="resetFlag"><input type="submit" value="Submit" class="btn_1 full-width"></div>
                        </div>
                        <!-- /form_container -->
                    </div>
                    <!-- /box_account -->

                    <!-- /row -->
                </div>
                
            </div>
            <!-- /row -->
        </div>
        <!-- /container -->
    </main>
</template>
<script src="../js/reset-password.js"></script>