import { state } from '@/globalVariables';
import NewsLetter from '../../Newsletter/template/NewsLetter';
import Advertise from '../../Advertise/template/Advertise';
import ProductDetailSchemaJson from '../../seoPlugin/ProductDetailSchemaJson';
import { authHeader } from "../../../auth";

import { VueImageZoomer } from 'vue-image-zoomer'
import 'vue-image-zoomer/dist/style.css';
import {
    Carousel,
    Navigation,
    Pagination,
    Slide
} from 'vue3-carousel';
// import 'vue3-carousel/dist/carousel.css';

export default {
    components: {
        NewsLetter,
        Advertise,
        ProductDetailSchemaJson,
        Carousel,
        Slide,
        Navigation,
        Pagination,
        VueImageZoomer,
    },
    data() {
        return {
            state,
            productDetails: [],
            gallery: [{ photoUrl: "" }],
            reviewListData: [],
            reviewListDataLimit: [],
            relatedProduct: [],
            imagePath: this.$imageURL + 'productImage/',
            isActive: [],
            colorName: '',
            sizeName: '',
            quantity: 1,
            colorList: '',
            sizeList: '',
            pageCMS: {},
            setURL: this.$serverURL + this.$api.product.productDetails + this.$route.params.id,
            productId: this.$route.params.id,
            size: "",
            productRating: 0,
            notVoted: 5,
            voted: 0,
            totalWeight: 0,
            productMeta: {},
            productImageSettings: {
                itemsToShow: 1,
                snapAlign: 'start',
                autoplay: 0,
                pauseAutoplayOnHover: true
            },
            thumbnailsSettings: {
                itemsToShow: 3,
                wrapAround: true,
                autoplay: 0,
                pauseAutoplayOnHover: true
            },
            relevantProductSettings: {
                itemsToShow: 1,
                wrapAround: true,
                autoplay: 0,
                pauseAutoplayOnHover: true
            },
            Productbreakpoints: {
                576: {
                    itemsToShow: 3
                },
                768: {
                    itemsToShow: 3
                },
                992: {
                    itemsToShow: 3
                }
            },
            relevantProductbreakpoints: {
                576: {
                    itemsToShow: 3
                },
                768: {
                    itemsToShow: 3
                },
                992: {
                    itemsToShow: 3
                }
            },
            showImage: null,
            currentSlide: 0,
            starCount: [0, 0, 0, 0, 0],
            reviewRating: 0,
            reviewText: "",
            reviewName: `${localStorage.getItem('firstName') ? localStorage.getItem('firstName') : ''} ${localStorage.getItem('lastname') ? localStorage.getItem('lastname') : ''}`,
            reviewEmail: localStorage.getItem('email'),
            userId: localStorage.getItem('id'),
            isShowMoreReview: true
        }
    },
    async created() {
        this.state.isLoading = true;
        // await this.getProductDetails();
        this.scrollToTop();
    },
    async mounted() {
        this.loadCSS();
        
        await this.getProductMeta();
        await this.getPageTitles();
        await this.getProductDetails();
        document.title = this.productDetails?.name?this.productDetails?.name:this.pageTitles?.productPagetitle;
        // setTimeout(() => {
        //     document.querySelector('.carousel__prev').style.backgroundColor = '#004dda';
        //     document.querySelector('.carousel__next').style.backgroundColor = '#004dda';
        // }, 4000);

    },
    methods: {
        slideTo(val) {
            this.currentSlide = val;
        },
        selectColor: function (index) {
            this.isActive = [];
            this.isActive[index] = !this.isActive[index];
            if (this.isActive[index]) {
                this.colorName = this.productDetails.colors[index].colorName;
            }
        },
        getProductDetails: async function () {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.product.productDetails + this.$route.params.id,
                ...(this.state.token && { headers: { "Authorization": authHeader() } })
            }
            if (this.state.token) {
                config['url'] = this.$serverURL + this.$api.product.productAuthorizedDetails + this.$route.params.id;
            }
            // let config = {
            //     method: "GET",
            //     url: this.setURL,
            //     headers: {
            //         "Authorization": authHeader()
            //     }
            // };
            this.state.isLoading = true;
            await this.$axios(config).then(async (response) => {
                //console.log('Status Found =', response.status);
                if (response.status == 200) {
                    this.productDetails = response.data.detailsData[0];
                    this.pageCMS = response.data.productDetailsPageCmsData[0];
                    this.reviewListData = response.data.reviewListData;
                    this.reviewListDataLimit = [...response.data.reviewListData];
                    if(this.reviewListDataLimit.length > 3) {
                        this.reviewListDataLimit.length = 3
                    }
                    this.relatedProduct = [...response.data.relatedProductData];
                    if(this.relatedProduct.length > 8) {
                        this.relatedProduct.length = 8;
                    }
                    this.gallery = response.data.detailsData[0].photos;

                    if (this.reviewListData.length > 0) {
                        this.voted = 0
                        let sum = 0;
                        for (let i = 0; i < this.reviewListData.length; i++) {
                            sum = sum + parseInt(this.reviewListData[i].rating);
                        }
                        this.productRating = (sum / this.reviewListData.length).toFixed(1);
                        this.voted = Math.floor(sum / this.reviewListData.length);
                        // this.notVoted = this.notVoted - this.voted;
                    } else {
                        this.voted = 0
                    }
                    this.state.isLoading = false;

                    for (let i = 0; i < this.reviewListData.length; i++) {
                        const starIndex = parseInt(this.reviewListData[i].rating);
                        if(starIndex < 1) continue;
                        this.starCount[starIndex - 1] += 1;
                    }
                }
                
                this.state.isLoading = false;
            }).catch(error => {
                //console.log('Status Found =', error);
                document.title = "Hgshydro | Page not found";
                  //  console.log('We are in 404');
                this.$router.push({name: '404Page'});
                    // return false;


                this.state.isLoading = false;
                console.log(error);
            });
        },
        getProductMeta: async function () {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.product.productMetaURL + this.$route.params.id
            };
            this.state.isLoading = true;
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {
                    this.productMeta = response.data.data[0];
                    this.state.isLoading = false;
                }
                this.state.isLoading = false;
            }).catch(error => {
                this.state.isLoading = false;
                console.log(error);
            });
        },
        // calculateDiscountPrice: function(productDetails) {
        //     let userId = localStorage.getItem("id");
        //     let ans = productDetails.userDiscountList.map(element => {
        //         if(element.userId === userId) {
        //             let res = (productDetails.discountedPrice > element.userDiscountedPrice) ? element.userDiscountedPrice : productDetails.discountedPrice;
        //             return res;
        //         }
        //     });
        //     return ans[0];
        // },
        leaveReview: function () {
            if (localStorage.getItem('token')) {
                this.$router.push({
                    name: 'ProductReview',
                    params: { 'productId': this.productId },
                    query: { 'productName': this.productDetails.name }
                });
            }
            else {
                this.$swal.fire({
                    icon: "error",
                    text: "Please login to leave a review!"
                });
            }

        },
        addReview: async function () {
            if (!localStorage.getItem('token')) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please login to leave a review!"
                });
            } else if (!this.reviewRating) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter your review rating"
                });
            }
            else if (!this.reviewText) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter your review"
                });
            } else if (!this.reviewName) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter your name"
                });
            } else if (!this.reviewEmail) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter your email"
                });
            } else {
                let formData = new FormData();
                formData.append("productId", this.productDetails.id);
                // formData.append("reviewTitle", this.title);
                formData.append("reviewDescription", this.reviewText);
                formData.append("rating", this.reviewRating);
                formData.append("userName", this.reviewName);
                formData.append("userId", this.userId);
                formData.append("email", this.reviewEmail);
                let config = {
                    method: "POST",
                    data: formData,
                    url: this.$serverURL + this.$api.product.productReview,
                    headers: {
                        "Authorization": authHeader(),
                        "Content-Type": "multipart/form-data",
                    }

                };
                await this.$axios(config).then(async (response) => {
                    if (response.data.statusCode == 200) {

                        this.$swal.fire({
                            icon: "success",
                            text: "Your Review has been created."
                        });
                        this.reviewText = "";
                        this.reviewRating = 0;

                        await this.getProductDetails();
                    }
                    else {
                        this.$swal.fire({
                            icon: "error",
                            text: "Failed! please try again."
                        });
                    }
                }).catch(error => {
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
            }
        },
        loadCSS: function () {
            this.state.isLoading = true;
            var styles = [
                '/core/assets/css/product_page.css'
            ]
            styles.forEach(style => {
                let tag = document.createElement('link');
                tag.type = "text/css";
                tag.rel = "stylesheet";
                tag.href = style;
                document.head.appendChild(tag);
            });
            var scripts = [
                "/core/assets/js/main.js",
                "/core/assets/js/carousel-home.min.js",
            ];
            scripts.forEach(script => {
                let tag = document.createElement("script");
                tag.setAttribute("src", script);
                document.head.appendChild(tag);
            });
            this.state.isLoading = false;
        },
        deductQuantity() {
            (this.quantity > 1) ? (this.quantity = this.quantity - 1) : (this.quantity = 1);
        },
        increaseQuantity() {
            this.quantity = this.quantity + 1;
        },
        processWishListData(data) {
            // data.choosedColor = this.colorName ? this.colorName : "N/A";
            // data.choosedSize = this.size ? this.size : "N/A";
            this.addToWishList(data, this.quantity);
            //this.$router.push('/products');
        },
        processCartData(data) {
            // data.choosedColor = this.colorName ? this.colorName : "N/A";
            // data.choosedSize = this.size ? this.size : "N/A";
            this.addToCart(data, this.quantity);
            // this.$router.push('/cart')
        },
        processCartAccessoriesData(data) {
            this.updateToCartForAccessories(data, this.quantity);
        },
        processPresentInCart(data) {
            return this.isPresentInCart(data);
        },
        getAllProducts: async function (url) {
            this.productList = [];
            let config = {
                method: "GET",
                url: url,
                headers: {
                    "Authorization": authHeader()
                }
            }
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {
                    if (response.data.data) {
                        this.productList = await response.data.data;
                    } else if (response.data.products) {
                        this.productList = await response.data.products;
                    }
                }
                else {

                    this.$swal.fire({
                        icon: "error",
                        text: "Failed! please try again."
                    });
                }
            }).catch(error => {

                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },
        showAllReview: function() {
            this.reviewListDataLimit = this.reviewListData;
            this.isShowMoreReview = false;
        }
    }
}