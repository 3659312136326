<template>
  <div class="container">
    <div
      class="d-md-flex justify-content-between align-items-center bg-white py-2"
    >
      <div class="d-flex align-items-center gap-3">
        <p class="mb-0" style="font-size: 13px">
        <a :href="'/account'" style="text-decoration: none; color: #000; font-family: Montserrat; font-size: 13px; font-style: normal; font-weight: 400;
line-height: 26px;">Commercial accounts</a></p>
        <p class="mb-0" style="font-size: 13px"><a :href="'/blog'" style="text-decoration: none; color: #000; font-family: Montserrat; font-size: 13px; font-style: normal; font-weight: 400;
line-height: 26px;">Learning Center</a></p>
      </div>
      <div class=" d-none d-md-flex justify-content-between align-items-center gap-4">
        <div class="d-md-flex align-items-center gap-2">
          <img
            src="/core/images/map-pin.png"
            alt="map marker"
            title="map marker"
            style="background-color: #edf2f3; padding: 8px; border-radius: 50%; height: 29px;"
          />
          <div>
            <span style="font-size: 10px; color: #000; font-family: Montserrat; font-style: normal; font-weight: 400; line-height: normal;">visit</span>
            <h6><a :href="'/location'" class="m-0" style="font-size: 13px; text-decoration: none; color: #000; font-family: Montserrat; font-style: normal; font-weight: 500; line-height: normal;">Store Locator</a></h6>
          </div>
        </div>

        <div class="d-md-flex align-items-center gap-2">
          <img
            src="/core/images/phone-call (1).png"
            alt="map marker"
            title="map marker"
            style="background-color: #edf2f3; padding: 8px; border-radius: 50%; height: 29px;"
          />
          <div>
            <span style="font-size: 10px; color: #000; font-family: Montserrat; font-style: normal; font-weight: 400; line-height: normal;">24 Line</span>
            <h6><a :href="'tel:'+state.footerCMS.contactNumber" class="m-0" style="font-size: 13px; text-decoration: none; color: #000; font-family: Montserrat; font-style: normal; font-weight: 500; line-height: normal;">{{state.footerCMS.contactNumber}}</a></h6>
          </div>
        </div>

        <div class="d-md-flex align-items-center gap-2">
          <img
            src="/core/images/mail.png"
            alt="map marker"
            title="map marker"
            style="background-color: #edf2f3; padding: 8px; border-radius: 50%; height: 29px;"
          />
          <div>
            <span style="font-size: 10px; color: #000; font-family: Montserrat; font-style: normal; font-weight: 400; line-height: normal;">Drop a line</span>
            <h6><a :href="'mailto:'+state.footerCMS.contactEmail" class="m-0" style="font-size: 13px; text-decoration: none; color: #000; font-family: Montserrat; font-style: normal; font-weight: 500; line-height: normal;">{{ state.footerCMS.contactEmail }}</a></h6>
          </div>
        </div>
      </div>
    </div>
  </div>
  <header class="version_1">
    <div
      class="layer2"
      :class="state.isActiveSidebar ? 'layer-is-visible' : ''"
    ></div>
    <div v-if="state.headerCMS" class="main_header position-relative">
      <div class="container">
        <div class="row small-gutters">
          <div
            class="col-xl-2 col-lg-2 col-6 logo-margin d-lg-flex align-items-center"
          >
            <div id="logo">
              <a :href="'/'" title="Home Page">
                <img
                  :src="imagePath + state.headerCMS.logo"
                  alt="hgshydro logo"
                  title="hgshydro logo"
                />
              </a>
            </div>
          </div>
          <nav class="col-xl-9 col-lg-9 col-1">
            <a
              @click="state.isActiveSidebar = true"
              class="open_close"
              href="javascript:void(0);"
            >
              <div class="hamburger hamburger--spin">
                <div class="hamburger-box">
                  <div class="hamburger-inner"></div>
                </div>
              </div>
            </a>
            <!-- Mobile menu button -->
            <div
              class="main2-menu"
              :class="state.isActiveSidebar ? 'shows' : ''"
            >
              <div id="header_menu" v-if="state.headerCMS?.logo">
                <a :href="'/'">
                  <img
                    :src="imagePath + state.headerCMS?.logo"
                    alt="HgsHydro Logo"
                    title="HgsHydro Logo"
                    style="width: 200px !important; height: auto !important"
                  />
                </a>
                <a
                  @click="state.isActiveSidebar = false"
                  href="#"
                  class="open_close"
                  id="close_in"
                >
                  <i class="ti-close"></i>
                </a>
              </div>
              <ul>
                <li @click="state.isActiveSidebar = false">
                  <a :href="'/'" class="show-submenu">Home</a>
                </li>
                <li @click="state.isActiveSidebar = false">
                  <a :href="'/products'">Products</a>
                </li>
                <li @click="state.isActiveSidebar = false">
                  <a :href="'/deals'">Deals</a>
                </li>
                <li @click="state.isActiveSidebar = false">
                  <a :href="'/blog'">Blog</a>
                </li>
                <li @click="state.isActiveSidebar = false">
                  <a :href="'/franchise'">Franchise</a>
                </li>
                <li @click="state.isActiveSidebar = false">
                  <a :href="'/quotes'">Quotes</a>
                </li>
                <li @click="state.isActiveSidebar = false">
                  <a :href="'/about-us'">About US</a>
                </li>
                <li @click="state.isActiveSidebar = false">
                  <a :href="'/career'">Careers</a>
                </li>

                <li @click="state.isActiveSidebar = false">
                  <a :href="'/location'">Locations</a>
                </li>
              </ul>
            </div>
            <!--/main-menu -->
          </nav>
          <div
            v-if="fedxList.length > 0"
            class="col-xl-1 col-lg-1 col-4 d-lg-flex align-items-center"
          >
            <div class="dropdown dropdown-access">
              <a
                class="d-flex flex-column lh-base p-1 cp h-100"
                style="background: #f3f3f3; border-radius: 5px"
              >
                <div
                  class="d-flex align-items-center justify-content-between gap-3"
                  style="background: #dbdbdb; border-radius: 10px"
                >
                  <div class="text-center ps-2">
                    <img
                      width="15"
                      src="/core/images/truck-fast-solid.svg"
                      alt="ups shipping"
                      title="ups shipping"
                    />
                  </div>
                  <div class="d-flex">
                    <div
                      class="px-2 py-1 w-100 fw-bolder"
                      style="
                        background: #000000;
                        color: #ffffff;
                        border-radius: 10px;
                        font-size: 12px;
                      "
                    >
                      {{ defaultTimer?.hour }}:{{ defaultTimer?.minutes }}:{{
                        defaultTimer?.seconds
                      }}
                    </div>
                  </div>
                </div>

                <div
                  class="text-wrap py-1 dropdown-toggle"
                  style="font-size: 12px"
                >
                  <span class="fw-bolder">{{ selectedFedx.title }}</span>
                  {{ selectedFedx.subTitle }} &nbsp; &nbsp;
                </div>
              </a>
              <div class="dropdown-menu cutoff-domestic">
                <div class="head-back">
                  <span aria-label="time-icon" class="time-icn">
                    <strong>{{ fedxCms.title }}</strong>
                  </span>
                  <span class="cutoff-textw">{{ fedxCms.subTitle }}</span>
                  <span aria-label="van-image" class="cutoff-type-img van-img"
                    >&nbsp;</span
                  >
                </div>
                <ul class="select-del-opt" v-if="fedxList.length > 0">
                  <li
                    v-for="(data, index) in fedxList"
                    :key="index"
                    class
                    style="border-top: none"
                  >
                    <input
                      v-if="!data.hide"
                      type="radio"
                      name="listGroupRadio"
                      v-model="selectedFedx"
                      :value="data"
                      id="Radio1"
                      @change="handlCutoffSelect"
                      :checked="data.title == selectedFedx.title"
                    />
                    <label
                      class="custom-form-check-label"
                      for="Radio1"
                      v-if="!data.hide"
                    >
                      <span>
                        <span class="fw-bolder fs-6">{{ data.title }}</span>
                        {{ data.subTitle }}
                      </span>
                      <span
                        class="d-flex gap-2"
                        v-if="!countdown(data.time).isCompleted"
                      >
                        <span class="text-center">
                          {{ countDowns[index]?.hour }}
                          <br />hours
                        </span>
                        <span class="vr"></span>
                        <span class="text-center">
                          {{ countDowns[index]?.minutes }}
                          <br />minutes
                        </span>
                        <span class="vr"></span>
                        <span class="text-center">
                          {{ countDowns[index]?.seconds }}
                          <br />seconds
                        </span>
                      </span>
                      <span v-else>Expired</span>
                    </label>
                  </li>
                </ul>
                <ul class="select-del-opt" v-else>
                  <li style="border-top: none">
                    <label class="custom-form-check-label">
                      <span>
                        <span class="fw-bolder fs-6">No Data Found</span>
                      </span>
                    </label>
                  </li>
                </ul>
                <!-- <div class="free-del">
                  <span>
                    <strong>Free</strong> when you spend over
                  </span>
                  <span class="ver-line"></span>
                  <span class="free-prize">$750</span>
                </div> -->
              </div>
            </div>
          </div>
          <div
            v-if="state.headerCMS.length > 0"
            class="col-xl-3 col-lg-2 d-lg-flex align-items-center justify-content-end text-end"
          >
            <a
              class="phone_top"
              :href="'tel:' + state.headerCMS.helpLineNumber"
            >
              <strong>
                <span>{{ state.headerCMS.helpLineText }}</span>
                {{ state.headerCMS.helpLineNumber }}
              </strong>
            </a>
          </div>
        </div>
        <!-- /row -->
      </div>
    </div>
    <!-- /main_header -->

    <div class="main_nav Sticky">
      <div class="container">
        <div class="row small-gutters">
          <div class="col-lg-4" style="display: flex">
            <nav class="categories" style="margin-right: 15px">
              <ul class="clearfix">
                <li class>
                  <span>
                    <a href="#" class="text-nowrap">
                      <span class="hamburger hamburger--spin">
                        <span class="hamburger-box">
                          <span class="hamburger-inner"></span>
                        </span>
                      </span>
                      Categories
                    </a>
                  </span>
                  <div id="menu" v-if="categoriesList.length > 0">
                    <ul class="desktopMenuDisplay">
                      <li v-for="(data, index) in categoriesList" :key="index">
                        <span>
                          <a
                            @mouseover="getSubCategories(data.id)"
                            :href="'/category/' + data.categorySlug"
                          >
                            {{ data.name }}
                          </a>
                        </span>
                        <ul v-if="data.hasChild">
                          <li
                            v-for="(data, index) in subCategoriesList"
                            :key="index"
                          >
                            <a
                              :href="
                                '/sub-category/' +
                                encodeURIComponent(data.subCategorySlug)
                              "
                              :title="data.name"
                              >{{ data.name }}</a
                            >
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </nav>
            <nav class="categories">
              <ul class="clearfix">
                <li>
                  <span>
                    <a href="#" class="text-nowrap">
                      <span class="hamburger hamburger--spin">
                        <span class="hamburger-box">
                          <span class="hamburger-inner"></span>
                        </span>
                      </span>
                      Shop By Brands
                    </a>
                  </span>
                  <div id="menu" v-if="state.brandData.length > 0">
                    <ul class="minMenuDesktop">
                      <li v-for="(data, index) in state.brandData" :key="index">
                        <span>
                          <a
                            :href=" '/products?brandName=' + encodeURIComponent(data.brandName)"
                            :title="data.brandName"
                          >
                            {{ data.brandName }}
                          </a>
                        </span>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </nav>
          </div>
          <div class="col d-none d-md-block">
            <div class="custom-search-input w-100" style="border-radius: 20px">
              <input
                type="text"
                ref="searchInput"
                v-model.trim="searchText"
                @input="searchElastic"
                v-on:keyup.enter="search"
                :placeholder="state.headerCMS.searchPlaceholder"
                class="cut-text"
              />
              <div
                class="auto-com-box"
                v-if="isActiveDropDown && suggestions.length > 0"
              >
                <ul>
                  <li
                    v-for="data in suggestions"
                    :key="data.id"
                    @click="handleSugClick(data.brandNameAndProductName)"
                    v-html="data?.highlight"
                  ></li>
                  <hr />
                  <li
                    v-for="(brandName, index) in sugBrandName"
                    :key="index"
                    @click="handleSugClick(brandName)"
                  >
                    {{ brandName }}
                  </li>
                  <hr />
                  <div
                    v-for="data in suggestions"
                    :key="data.id + data.name"
                    @click="$router.push('/product-details/' + data.urlSlug)"
                  >
                    <li>
                      <div class="row">
                        <div class="col-3">
                          <img
                            class="img-fluid lazy"
                            :src="productImagePath + data.featuredImage"
                            :data-src="productImagePath + data.featuredImage"
                            :alt="data.name"
                            :title="data.name"
                          />
                        </div>
                        <div class="col-9">
                          <div v-html="data?.highlight"></div>
                          <div class="product-sku">SKU: {{ data.sku }}</div>
                          <div class="d-flex flex-row-reverse">
                            <span v-if="data.stock > 0" class="in-stock"
                              >In Stock</span
                            >
                            <span v-else class="in-stock">Out of Stock</span>
                          </div>
                        </div>
                      </div>
                    </li>
                    <hr />
                  </div>
                  <li
                    class="d-flex justify-content-center text-success text-bold"
                    @click="search"
                  >
                    View All {{ searchTotal }} products
                  </li>
                </ul>
              </div>

              <button type="submit" @click="search">
                <i
                  class="header-icon_search_custom"
                  style="margin-right: 10px"
                ></i>
              </button>
            </div>
          </div>
          <!--  -->
          <div class="col">
            <ul class="top_tools">
              <!-- cart icon -->
              <li>
                <div class="dropdown dropdown-cart">
                  <a
                    href="javascript:void(0)"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasRight"
                    aria-controls="offcanvasRight"
                    id="cart-icon-a-button"
                  >
                  <img
                    src="/core/images/cart.png"
                    alt="cart"
                    title="cart"
                    style="width: 20px"
                  />
                    <strong class="countBadge">{{ state.cartCounter }}</strong>
                  </a>
                </div>
              </li>

              <!-- wishlist icon -->
              <li>
                <a :href="'/wishlist'" @click.prevent="$router.push('/wishlist')" class="">
                  <img
                    src="/core/images/wishlist.png"
                    alt="wishlist"
                    title="wishlist"
                    style="width: 20px"
                  />
                  <strong class="countBadge">
                    {{ state.wishlistCounter }}
                  </strong>
                </a>
              </li>

              <li>
                <div class="dropdown dropdown-access">
                  <a href="javascript:void(0)" class="">
                  <img
                    src="/core/images/user people person users man.png"
                    alt="wishlist"
                    title="wishlist"
                    style="width: 20px"
                  />
                    <span>Account</span>
                  </a>
                  <div class="dropdown-menu">
                    <a v-if="!state.token" :href="'/account'" class="btn_1"
                      >Sign In or Sign Up</a
                    >
                    <ul>
                      <li v-if="state.token">
                        <a :href="'/dashboard'">
                          <i class="ti-user"></i>My Profile
                        </a>
                      </li>
                      <li v-if="state.token">
                        <a
                          href="javascript:void(0)"
                          @click="$router.push('/total-orders')"
                        >
                          <i class="ti-package"></i>My Orders
                        </a>
                      </li>
                      <li v-if="state.token">
                        <a
                          href="javascript:void(0)"
                          @click="$router.push('/track-order')"
                        >
                          <i class="ti-truck"></i>Track your Order
                        </a>
                      </li>
                      <li>
                        <a
                          href="javascript:void(0)"
                          @click="$router.push('/help')"
                        >
                          <i class="ti-help-alt"></i>Help
                        </a>
                      </li>
                      <li v-if="state.token">
                        <a href="javascript:void(0)" @click="logout()">
                          <i class="fa-solid fa-right-from-bracket"></i>Logout
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <!-- /dropdown-access-->
              </li>
              <li class="d-md-none">
                <a
                  @click="state.isSearchActive = !state.isSearchActive"
                  href="javascript:void(0);"
                  class="btn_search_mob2"
                >
                  <span>Search</span>
                </a>
              </li>
              <li class="d-block d-md-none hgscat-mob">
                <a @click="activeCategory" class="btn_cat_mob cp">
                  <div class="hamburger hamburger--spin" id="hamburger">
                    <div class="hamburger-box">
                      <div class="hamburger-inner"></div>
                    </div>
                  </div>
                  Categories
                </a>
              </li>
              <li class="d-block d-md-none hgscat-brd">
                <a @click="activeBrands" class="btn_cat_mob cp">
                  <div class="hamburger hamburger--spin" id="hamburger">
                    <div class="hamburger-box">
                      <div class="hamburger-inner"></div>
                    </div>
                  </div>
                  Brands
                </a>
              </li>
            </ul>
          </div>
        </div>
        <!-- /row -->
      </div>
      <div class="search_mob_wp" :class="state.isSearchActive ? 'd-block' : ''">
        <!-- <input
          type="text"
          class="form-control"
          v-model="searchText"
          :placeholder="state.headerCMS.searchPlaceholder"
          v-on:keyup.enter="search"
        />
        <div class="d-flex align-items-center">
          <input type="submit" class="btn_1 full-width" @click="search" value="Search" />
          <button @click="state.isSearchActive = false" class="btn-close shadow-none"></button>
        </div>-->
        <div class="custom-search-input w-100">
          <input
            type="text"
            ref="searchInputMobile"
            v-model.trim="searchText"
            @input="searchElastic"
            v-on:keyup.enter="search"
            :placeholder="state.headerCMS.searchPlaceholder"
            class="cut-text"
          />
          <div
            class="auto-com-box"
            v-if="isActiveDropDown && suggestions.length > 0"
          >
            <ul>
              <li
                v-for="data in suggestions"
                :key="data.id"
                @click="handleSugClick(data.brandNameAndProductName)"
                v-html="data?.highlight"
              ></li>
              <hr />
              <li
                v-for="(brandName, index) in sugBrandName"
                :key="index"
                @click="handleSugClick(brandName)"
              >
                {{ brandName }}
              </li>
              <hr />
              <div
                v-for="data in suggestions"
                :key="data.id + data.name"
                @click="$router.push('/product-details/' + data.urlSlug)"
              >
                <li>
                  <div class="row">
                    <div class="col-3">
                      <img
                        class="img-fluid lazy"
                        :src="productImagePath + data.featuredImage"
                        :data-src="productImagePath + data.featuredImage"
                        :alt="data.name"
                        :title="data.name"
                      />
                    </div>
                    <div class="col-9">
                      <div v-html="data?.highlight"></div>
                      <div class="product-sku">SKU: {{ data.sku }}</div>
                      <div class="d-flex flex-row-reverse">
                        <span v-if="data.stock > 0" class="in-stock"
                          >In Stock</span
                        >
                        <span v-else class="in-stock">Out of Stock</span>
                      </div>
                    </div>
                  </div>
                </li>
                <hr />
              </div>
              <li
                class="d-flex justify-content-center text-success text-bold"
                @click="search"
              >
                View All {{ searchTotal }} products
              </li>
            </ul>
          </div>
          <button type="submit" @click="search">
            <i class="header-icon_search_custom"></i>
          </button>
        </div>
      </div>
      <!-- /search_mobile -->
    </div>
    <!-- /main_nav -->
    <!-- /main_nav -->
    <div class="category-mm" :style="showCategory ? 'left: 0' : ''">
      <div class="mx-3 mt-3">
        <div>
          <div class="d-block">
            <input
              type="text"
              class="form-control"
              v-model="searchText"
              :placeholder="state.headerCMS.searchPlaceholder"
              v-on:keyup.enter="
                search();
                closeCategory();
              "
              style="margin-bottom: 15px"
            />
            <div class="d-flex align-items-center">
              <input
                type="submit"
                class="btn_1 full-width"
                @click="
                  search();
                  closeCategory();
                "
                value="Search"
              />
            </div>
          </div>
        </div>
      </div>
      <ul
        class="category-mm-list"
        v-if="categoriesList.length > 0"
        :style="isActiveSubcategory ? 'right: -100%' : ''"
      >
        <li>
          <div class="text-center category-mm-list-title">Menu</div>
        </li>
        <!-- <li class="d-block d-md-none bottom-0 start-50 translate-middle-x w-100">
          <div class="mx-3 mb-4">
            <div>
              <div class="d-block">
                <input type="text" class="form-control" v-model="searchText"
                  :placeholder="state.headerCMS.searchPlaceholder" v-on:keyup.enter="search(); closeCategory()"
                  style="margin-bottom:15px;" />
                <div class="d-flex align-items-center">
                  <input type="submit" class="btn_1 full-width" @click="search(); closeCategory()" value="Search" />
                </div>
              </div>
            </div>
          </div>
        </li>-->
        <li v-for="(data, index) in categoriesList" :key="index">
          <div
            class="d-flex justify-content-between cp"
            @click="
              data.hasChild ? activeSubcategory(index) : closeCategory();
              getSubCategories(data.id);
            "
          >
            <a
              @change="getSubCategories(data.id)"
              href="javascript:void(0)"
              @click="$router.push('/category/' + data.categorySlug)"
              >{{ data.name }}</a
            >
            <div v-if="data.hasChild" class="category-mm-next">&nbsp;</div>
          </div>
          <ul
            class="sub-category-mm-list"
            v-if="data.hasChild"
            :style="
              index + 1 === currentIndex
                ? isActiveSubcategory
                  ? 'left: 0'
                  : ''
                : ''
            "
          >
            <li>
              <div
                @click="deactivateSubcategory(index)"
                class="text-center category-mm-list-title cp position-relative category-mm-prev text-capitalize"
              >
                {{ data.name }}
              </div>
            </li>
            <li v-for="(data, index) in subCategoriesList" :key="index">
              <a
                href="javascript:void(0)"
                @click="
                  $router.push('/sub-category/' + data.subCategorySlug);
                  closeCategory();
                "
              >
                {{ data.name }}
              </a>
            </li>
            <!-- <li class="d-block d-md-none position-absolute bottom-0 start-50 translate-middle-x w-100">
              <div class="mx-3 mb-4">
                <div>
                  <div class="d-block">
                    <input type="text" class="form-control" v-model="searchText"
                      :placeholder="state.headerCMS.searchPlaceholder" v-on:keyup.enter="search(); closeCategory()"
                      style="margin-bottom:15px;" />
                    <div class="d-flex align-items-center">
                      <input type="submit" class="btn_1 full-width" @click="search(); closeCategory()" value="Search" />
                    </div>
                  </div>
                </div>
              </div>
            </li>-->
          </ul>
        </li>
      </ul>

      <div class="category-mm-footer">© 2023 HGSHYDRO</div>
    </div>
    <div
      @click="closeCategory"
      class="category-mm-layer"
      :style="showCategory ? 'display: block;opacity: .3;' : ''"
    ></div>

    <div class="category-mm" :style="showBrands ? 'left: 0' : ''">
      <div class="mx-3 mt-3">
        <div>
          <div class="d-block">
            <input
              type="text"
              class="form-control"
              v-model="searchText"
              :placeholder="state.headerCMS.searchPlaceholder"
              v-on:keyup.enter="
                search();
                closeBrands();
              "
              style="margin-bottom: 15px"
            />
            <div class="d-flex align-items-center">
              <input
                type="submit"
                class="btn_1 full-width"
                @click="
                  search();
                  closeBrands();
                "
                value="Search"
              />
            </div>
          </div>
        </div>
      </div>
      <ul
        class="category-mm-list"
        v-if="state.brandData.length > 0"
        :style="isActiveSubcategory ? 'right: -100%' : ''"
      >
        <li>
          <div class="text-center category-mm-list-title">Brands</div>
        </li>
        <li v-for="(data, index) in state.brandData" :key="index">
          <div class="d-flex justify-content-between cp">
            <a
              href="javascript:void(0)"
              @click="
                () => {
                  $router.push('/products?brandName=' + data.brandName);
                  closeBrands();
                }
              "
              >{{ data.brandName }}</a
            >
            <!-- <div v-if="data.hasChild" class="category-mm-next">&nbsp;</div> -->
          </div>
          <!-- <ul class="sub-category-mm-list" v-if="data.hasChild"
            :style="index + 1 === currentIndex ? (isActiveSubcategory ? 'left: 0' : '') : ''">
            <li>
              <div @click="deactivateSubcategory(index)"
                class="text-center category-mm-list-title cp position-relative category-mm-prev text-capitalize">{{
                  data.name
                }}</div>
            </li>
            <li v-for="(data, index) in subCategoriesList" :key="index">
              <a href="javascript:void(0)"
                @click="$router.push({ name: 'Product', query: { subCategoryId: data.id }, }); closeCategory()">{{
                  data.name
                }}</a>
            </li>
          </ul>-->
        </li>
        <!-- <li class="d-block d-md-none position-absolute bottom-0 start-50 translate-middle-x w-100">
          <div class="mx-3 mb-4">
            <div>
              <div class="d-block">
                <input type="text" class="form-control" v-model="searchText"
                  :placeholder="state.headerCMS.searchPlaceholder" v-on:keyup.enter="search(); closeBrands();"
                  style="margin-bottom:15px;" />
                <div class="d-flex align-items-center">
                  <input type="submit" class="btn_1 full-width" @click="search(); closeBrands();" value="Search" />
                </div>
              </div>
            </div>
          </div>
        </li>-->
      </ul>

      <div class="category-mm-footer">© 2023 HGSHYDRO</div>
    </div>
    <div
      @click="closeBrands"
      class="category-mm-layer"
      :style="showBrands ? 'display: block;opacity: .3;' : ''"
    ></div>

    <!-- /main_nav -->
  </header>

  <!-- <div class="d-flex justify-content-center justify-content-md-end px-4">
    <div
      style="z-index:99999;right: 0"
      class="offcanvas offcanvas-end custom_offcanvas_cart px-4"
      tabindex="-1"
      id="offcanvasRight"
      aria-labelledby="offcanvasRightLabel"
    >
      <div class="offcanvas-header d-flex justify-content-between mb-3">
        <h5 id="offcanvasRightLabel" class="text-bold">Cart</h5>
        <button
          type="button"
          class="btn-close text-reset"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div class="offcanvas-body mb-3">
        <li class="list-unstyled" v-for="(data, index) in state.cartValue" :key="index">
          <div class="d-flex justify-content-between">
            <div class>
              <figure>
                <img
                  v-if="data.data.featuredImage"
                  :src="
                              $imageURL +
                              'productImage/' +
                              data.data.featuredImage
                            "
                  :data-src="
                                  $imageURL +
                                  'productImage/' +
                                  data.data.featuredImage
                                "
                  alt
                  width="50"
                  height="50"
                  class="lazy"
                />
                <img
                  v-else
                  src="/core/assets/img/products/product_placeholder_square_small.jpg"
                  data-src="/core/assets/img/products/shoes/thumb/1.jpg"
                  alt
                  width="50"
                  height="50"
                  class="lazy"
                />
              </figure>
            </div>
            <div class="me-4 ms-3">
              <p class="my-auto fw-bold product_name">&nbsp;{{ data.data.name }}</p>
              <div class="numbers-row my-2">
                <input
                  type="text"
                  :value="data.quantity"
                  id="quantity_1"
                  class="qty2"
                  name="quantity_1"
                />
                <div class="inc button_inc" @click="increaseProductQuantity(index)">+</div>
                <div class="dec button_inc" @click="decreaseProductQuantity(index)">-</div>
              </div>
              <P class="fw-bold">
                &nbsp;${{
                parseFloat(findMixinActualPrice(data.data) * data.quantity).toFixed(2)
                }}
              </P>
            </div>
            <div class="my-auto">
              <a
                href="javascript:void(0)"
                class="badge badge-pill-sm badge-soft-success"
                @click="removeFromCart(index)"
              >
                <i class="ti-trash"></i>
              </a>
            </div>
          </div>

          <hr class="hr_tag" />
        </li>
        <div class="total_drop" v-if="state.cartValue.length > 0">
          <div class="d-flex justify-content-between">
            <h5>Total</h5>
            <p>${{ parseFloat(state.totalAmount).toFixed(2) }}</p>
          </div>
          <a
            href="javascript:void(0)"
            class="btn_1 outline w-100 mt-3"
            @click="$router.push('/cart')"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          >View Cart</a>
          <a
            v-if="state.token"
            href="javascript:void(0)"
            @click="$router.push('/checkout')"
            class="btn_1"
          >Checkout</a>
        </div>
        <div class="total_drop" v-else>
          <center>
            <strong>Cart is empty</strong>
          </center>
        </div>
      </div>
    </div>
  </div>-->
</template>
<script src="../js/header.js"></script>

<style scoped>
/* @import url("https://fonts.googleapis.com/css2?family=Jost:wght@300;400;500&display=swap&family=Urbanist&display=swap&family=Montserrat"); */
.countBadge {
  background-color: #00832B;
  color: #fff !important;
  font-size: 10px;
  font-size: 0.625rem;
  font-weight: 700;
  width: 16px;
  height: 16px;
  text-indent: 0;
  display: block;
  text-align: center;
  position: absolute;
  bottom: 10px;
  right: -3px;
  line-height: 17px !important;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}

.layer2 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-width: 100%;
  min-height: 100%;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.35);
  -moz-transition: all 0.1s ease;
  -o-transition: all 0.1s ease;
  -webkit-transition: all 0.1s ease;
  -ms-transition: all 0.1s ease;
  transition: all 0.1s ease;
  opacity: 0;
  visibility: hidden;
}

.layer-is-visible {
  opacity: 1;
  visibility: visible;
}

.main2-menu {
  position: relative;
  z-index: 9;
  width: auto;
}

.main2-menu ul,
.main2-menu ul li,
.main2-menu ul li a {
  position: relative;
  margin: 0;
  padding: 0;
}

header.version_1 .main2-menu > ul > li > a {
  color: #fff;
}

.main2-menu ul li a {
  display: block;
  padding: 3px 10px;
}

.main2-menu > ul > li > a {
  padding: 30px 18px 25px 18px;
  font-size: 13px;
  font-size: 0.8125rem;
  font-weight: 700;
  line-height: 1;
  text-transform: uppercase;
}

header ul.top_tools > li a.btn_search_mob2:before,
header ul.top_tools > li a.search_panel:before {
  content: "\0045";
  font-size: 26px;
  font-size: 1.625rem;
}

header ul.top_tools > li a:before {
  font-family: "header_icons";
  font-size: 26px;
  font-size: 1.625rem;
  font-weight: 500 !important;
}

/*category*/
.cp {
  cursor: pointer !important;
}

.category-mm {
  width: 80%;
  min-width: 200px;
  max-width: 450px;
  height: 100%;
  background: #ffffff;
  position: fixed;
  top: 0;
  left: -100%;
  overflow: hidden;
  z-index: 99999;
  display: flex;
  flex-direction: column;
  transition: all 0.4s ease;
}

.category-mm-list {
  width: 100%;
  font: inherit;
  font-size: 14px;
  line-height: 20px;
  height: calc(100% - 40px);
  margin-bottom: 0 !important;
  list-style-type: none;
  margin: 0;
  padding: 0 20px;
  overflow-x: hidden;
  overflow-y: auto;
  border-color: rgba(0, 0, 0, 0.1);
  position: relative;
  right: 0;
  top: 0;
  transition: all 0.4s ease;
}

.category-mm-list .category-mm-list-title {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.3) !important;
  font-size: 16px;
  text-transform: uppercase;
  padding: 10px 0;
}

.category-mm-list > li > a,
.category-mm-list > li > div,
.category-mm-list > li > div a {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: inherit !important;
  display: block;
  margin: 0;
  padding: 5px 0;
}

.category-mm-list > li {
  position: relative;
}

.category-mm-list > li:after {
  content: "";
  width: 120%;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-color: rgba(0, 0, 0, 0.1);
  position: absolute;
  left: 0;
  bottom: 0;
}

.category-mm-list > li:first-child:after {
  left: -20px;
}

.category-mm-next,
.category-mm-prev {
  position: relative;
}

.category-mm-next:after {
  border-color: rgba(0, 0, 0, 0.3);
}

.category-mm-next:after {
  -webkit-transform: rotate(-46deg);
  -ms-transform: rotate(-46deg);
  transform: rotate(-46deg);
  right: 3px;
  left: auto;
}

.category-mm-prev:before {
  -webkit-transform: rotate(134deg);
  -ms-transform: rotate(134deg);
  transform: rotate(134deg);
  right: auto;
  left: 3px;
}

.category-mm-next:after,
.category-mm-prev:before {
  content: "";
  border-top: 2px solid transparent;
  border-left: 2px solid transparent;
  border-right: 2px solid rgba(0, 0, 0, 0.3);
  border-bottom: 2px solid rgba(0, 0, 0, 0.3);
  display: block;
  width: 8px;
  height: 8px;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
}

.category-mm-footer {
  border-top-style: solid;
  border-top-width: 1px;
  bottom: 0;
  height: 40px;
  color: rgba(0, 0, 0, 0.3);
  font-size: 16px;
  text-align: center;
  padding: 10px 0;
}

.sub-category-mm-list {
  list-style-type: none;
  margin: 0;
  width: 80%;
  min-width: 200px;
  max-width: 450px;
  font: inherit;
  font-size: 14px;
  line-height: 20px;
  height: calc(100% - 40px);
  margin-bottom: 0 !important;
  padding: 0 20px;
  overflow-x: hidden;
  overflow-y: auto;
  border-color: rgba(0, 0, 0, 0.1);
  position: fixed;
  left: -100%;
  top: 110px;
  transition: all 0.4s ease;
}

.sub-category-mm-list > li > a,
.sub-category-mm-list > li > div,
.sub-category-mm-list > li > div a {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: inherit !important;
  display: block;
  margin: 0;
  padding: 5px 0;
}

.sub-category-mm-list > li {
  position: relative;
}

.sub-category-mm-list > li:after {
  content: "";
  width: 120%;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-color: rgba(0, 0, 0, 0.1);
  position: absolute;
  left: 0;
  bottom: 0;
}

.sub-category-mm-list > li:first-child:after {
  left: -20px;
}

.category-mm-layer {
  background: #000000;
  display: none;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99989;
  opacity: 0;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

@media only screen and (min-width: 992px) {
  .main2-menu ul > li {
    display: inline-block;
  }
}

@media only screen and (max-width: 991px) {
  .main2-menu.shows {
    transform: translateX(0);
    left: 0;
  }

  .main2-menu {
    overflow: auto;
    transform: translateX(-200%);
    top: 0;
    left: -110%;
    bottom: 0;
    width: 45%;
    height: 100%;
    position: fixed;
    background-color: #fff;
    z-index: 9999;
    margin: 0;
    -webkit-transition: all 0.5s cubic-bezier(0.77, 0, 0.175, 1);
    -moz-transition: all 0.5s cubic-bezier(0.77, 0, 0.175, 1);
    -ms-transition: all 0.5s cubic-bezier(0.77, 0, 0.175, 1);
    -o-transition: all 0.5s cubic-bezier(0.77, 0, 0.175, 1);
    transition: all 0.5s cubic-bezier(0.77, 0, 0.175, 1);
  }

  .main2-menu li,
  .main2-menu a {
    display: block;
    color: #444 !important;
  }

  .main2-menu ul li {
    border-bottom: 1px solid #ededed;
  }

  .main2-menu ul li > a {
    padding: 15px;
  }
  .search_mob_wp {
    margin-bottom: 1rem;
  }
}

@media only screen and (min-width: 768px) {
  .category-mm {
    display: none;
  }

  .category-mm-layer {
    display: none !important;
  }
}

@media only screen and (max-width: 580px) {
  .main2-menu {
    width: 100%;
  }

  .logo-margin {
    margin-left: 1.9rem;
  }
}

/* Only for Brand and category view */

@media (min-width: 310px) {
  .hgscat-mob {
    float: left !important;
    margin-left: 0px !important;
    font-size: 0.7rem;
  }

  .hgscat-brd {
    float: left;
    margin-left: 10px !important;
    font-size: 0.7rem;
  }
}

@media (min-width: 350px) {
  .hgscat-mob {
    float: left !important;
    margin-left: 0px !important;
    font-size: 0.9rem;
  }

  .hgscat-brd {
    float: left;
    margin-left: 20px !important;
    font-size: 0.9rem;
  }
}

/*scrolling category on desktop */
.minMenuDesktop {
  height: 600px;
  max-height: 80vh;
  overflow-y: scroll;
  overflow-x: hidden;
}
.minMenuDesktop::-webkit-scrollbar {
  width: 10px;
}

.minMenuDesktop::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

.minMenuDesktop::-webkit-scrollbar-thumb {
  background: rgb(0, 106, 255);
  border-radius: 10px;
}

.minMenuDesktop::-webkit-scrollbar-thumb:hover {
  background: rgb(0, 106, 255);
}

.custom-search-input ul {
  list-style: none;
  padding: 0;
  position: absolute;
  z-index: 1;
  background-color: #fff;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 5px 5px;
  width: 100%;
  max-height: 30rem;
  overflow-y: scroll;
  overflow-x: hidden;
}

.custom-search-input li {
  padding: 10px;
  cursor: pointer;
}

.custom-search-input li:hover {
  background-color: #f5f5f5;
}

.auto-com-box hr {
  margin: 0;
  opacity: 0.75;
  background-color: transparent !important;
}

.auto-com-box img {
  max-width: 75px;
  max-height: 75px;
  object-fit: cover;
}

.product-sku {
  margin-top: 5px;
  margin-bottom: -2px;
  color: #6c6c6c;
  font-size: 70%;
}

.in-stock {
  margin-top: 5px;
  background: #efefef;
  border-radius: 9px;
  padding: 0px 8px;
  font-size: 11px;
  font-weight: 500;
  border: 1px solid #e5e5e5;
  color: #75bb6a;
}

.auto-com-box ul::-webkit-scrollbar {
  width: 8px;
}

.auto-com-box ul::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

.auto-com-box ul::-webkit-scrollbar-thumb {
  background: rgb(82, 230, 156);
  border-radius: 10px;
}

.auto-com-box ul::-webkit-scrollbar-thumb:hover {
  background: rgb(82, 230, 156);
}

.cut-text {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 90% !important;
  white-space: nowrap;
}
.s-method-name:after {
  content: "v";
}
.cutoff-domestic {
  width: 380px !important;
  height: auto;
  background: #fff;
  box-shadow: 0 1.54647px 11.2119px rgba(0, 0, 0, 0.19) !important;
  border-radius: 10px !important;
  overflow: hidden;
  padding: 0 !important;
  border: none !important;
  margin-left: -270px;
}
.cutoff-domestic .head-back {
  float: left;
  width: 400px !important;
  height: 105px !important;
  background-color: #242422;
  transform: rotate(353deg);
  margin-left: -11px;
  margin-top: -21px;
}
.cutoff-domestic .head-back > span {
  float: left;
  color: #fff;
  font-weight: 500;
  line-height: 16px;
  font-size: 11px;
  height: 35px;
  width: 56%;
  transform: rotate(6.5deg);
  padding-top: 0;
  padding-left: 32px;
  background-position: 35px 25px;
  background-repeat: no-repeat;
}
.cutoff-domestic .head-back > span.time-icn {
  float: left;
  color: #fff;
  font-weight: 400;
  line-height: 13px;
  font-size: 15px;
  background-image: url(https://hgshydro.nyc3.cdn.digitaloceanspaces.com/frontend/default/time-1.svg);
  background-position: 31px 33px;
  background-repeat: no-repeat;
  height: 53px;
  width: 100%;
  transform: rotate(7deg);
  padding-top: 38px;
  padding-left: 60px;
  background-size: 20px;
  position: relative;
  top: 5px;
}
.cutoff-domestic .head-back > span.cutoff-textw {
  width: 46%;
}
.cutoff-domestic .head-back > span.van-img {
  position: relative;
}
.cutoff-domestic .head-back > span.van-img::after {
  content: "";
  position: absolute;
  background-image: url("https://hgshydro.nyc3.cdn.digitaloceanspaces.com/frontend/default/van-1.png");
  background-repeat: no-repeat;
  height: 80px;
  width: 170px;
  top: -57px;
  left: 190px;
  background-size: 180px;
  background-position: -7px -18px;
}

ul.select-del-opt {
  float: left !important;
  width: 90% !important;
  margin: 25px 5% !important;
}
ul.select-del-opt > li {
  float: left;
  width: 100%;
  margin: 10px 0;
}
ul.select-del-opt > li > input[type="radio"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: inline-block;
  width: 18px;
  height: 18px;
  padding: 2px;
  background-clip: content-box;
  border: 2px solid #bbb;
  background-color: #fff;
  border-radius: 50%;
  float: left;
  cursor: pointer;
  margin-top: 14px;
}
ul.select-del-opt > li > label {
  color: #404040;
  font-weight: 400;
  background: #fff;
  box-shadow: 0 1.54647px 9.2788px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  font-size: 11px;
  padding: 7px 10px;
  margin-left: 10px;
  text-transform: capitalize;
  width: 89%;
  float: left;
}
ul.select-del-opt > li > input[type="radio"]:checked {
  background-color: #f10600;
  border-color: #f10600;
}
.custom-form-check-label {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.custom-form-check-label.active {
  border: 1px solid #000000;
  z-index: 1000;
}
ul.select-del-opt > li > .default-timer {
  display: block;
  background-color: #e8e8e8;
  width: 105px;
  font-size: 11px;
  font-weight: 500;
  border-radius: 0 0 5.79925px 5.79925px;
  color: #000;
  margin-top: 36px;
  margin-left: 48px;
  padding-top: 6px;
  padding-left: 15px;
}
.cutoff-domestic .free-del {
  width: 86%;
  margin: 25px 7% 20px;
  background-color: #f5f5f5;
  border-radius: 16px;
  float: left;
}
.cutoff-domestic .free-del > span {
  font-size: 12px;
  font-weight: 400;
  color: #000;
  padding: 8px 0 8px 10%;
  display: inline-block;
  padding-right: 10px;
}
.cutoff-domestic .free-del > span.ver-line {
  width: 1px;
  height: 13px;
  display: inline-block;
  background-color: #000;
  vertical-align: middle;
  padding: 0;
  margin-left: 10px;
}
.cutoff-domestic .free-del > span.free-prize {
  display: inline-block;
  padding-left: 20px;
}
@media (max-width: 968px) {
  cutoff-domestic {
    margin-left: -70px;
  }
}
@media (max-width: 768px) {
  .cutoff-domestic {
    width: 350px !important;
    height: auto;
    background: #fff;
    box-shadow: 0 1.54647px 11.2119px rgba(0, 0, 0, 0.19) !important;
    border-radius: 10px !important;
    overflow: hidden;
    padding: 0 !important;
    border: none !important;
    margin-left: -234px;
  }
}
</style>
