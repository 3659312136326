<template>
    <main>
        <div>
            <div class="top_banner" v-if="pageCMS">
            <div class="opacity-mask d-flex align-items-center" data-opacity-mask="rgba(0, 0, 0, 0.3)">
                <div class="container">
                    <div class="breadcrumbs">
                        <ul>
                            <li>
                                <a href="javascript:void(0)" @click="$router.push('/home')">Home</a>
                            </li>
                            <li>
                                <a href="javascript:void(0)" @click="$router.push('/career')">Career</a>
                            </li>
                        </ul>
                    </div>
                    <h1 :style="{color: state.componentUi.careerComponentUI.headerTitleFontColor}">{{ pageCMS.title }}</h1>
                </div>
            </div>
            <img :src="$imageURL + 'career/' + pageCMS.image" class="img-fluid" alt="" />
        </div>
        <div class="sign-up__content" :style="{'background-color': state.componentUi.careerComponentUI.pageBackgroundColor}">
            <header class="sign-up__header" v-if="pageCMS">
                <h2 class="sign-up__title" :style="{color: state.componentUi.careerComponentUI.formTitleFontColor}">
                    {{ pageCMS.formTitle }}
                </h2>
                <p class="sign-up__descr" :style="{color: state.componentUi.careerComponentUI.formSubTitleFontColor}">
                    {{ pageCMS.formSubTitle }}
                </p>
            </header>
            <form class="sign-up__form form">
                <div class="form__row form__row--two">
                    <div class="input form__inline-input">
                        <div class="input__container">
                            <input class="input__field" id="first-name" v-model="firstName" placeholder="First Name *" type="text"/>
                            <label class="input__label" for="first-name">First Name</label>
                        </div>
                    </div>
                    <div class="input form__inline-input">
                        <div class="input__container">
                            <input class="input__field" id="last-name" v-model="lastName" placeholder="Last Name *" type="text"/>
                            <label class="input__label" for="last-name">Last Name</label>
                        </div>
                    </div>
                </div>
                <div class="form__row form__row--two">
                    <div class="input form__inline-input">
                        <div class="input__container">
                            <input class="input__field" id="email" placeholder="Email *" v-model="email" type="text"/>
                            <label class="input__label" for="email">Email</label>
                        </div>
                    </div>
                    <div class="input form__inline-input">
                        <div class="input__container">
                            <input class="input__field" id="password" placeholder="Phone *" v-model="phone" type="text"/>
                            <label class="input__label" for="password">Phone</label>
                        </div>
                    </div>
                </div>
                <div class="form__row">
                    <div class="input">
                        <div class="input__container">
                            <input class="form-control" type="file" @change="uploadResume" ref="addResume" id="formFile">
                            <!-- <label class="input__label">Resume</label> -->
                        </div>
                    </div>
                </div>
                <div class="form__row">
                    <div class="component component--primary form__button">
                        <button class="btn btn--regular" type="button" @click="addCareer" id="sign-up-button" tabindex="0">Submit</button>
                    </div>
                </div>
            </form>
        </div>
        </div>
        
    </main>
    <CareerSchemajson v-if="Object.values(pageTitles).length>0" :metaData="pageTitles" />
</template>

<style>
    @import url('../css/career.css');
</style>

<script src="../js/career.js"></script>