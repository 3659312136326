import { reactive } from "vue";
export const state = reactive({
    isActiveSidebar: false,
    isSearchActive: false,
    popUpSettings: '',
    adCMS: {},
    newsletterCMS: {},
    headerCMS: '',
    footerCMS: '',
    popularSearch: {},
    whyChooseUs: {},
    footerBrandingData: {},
    paymentMethods: [],
    token: localStorage.getItem('token') ? localStorage.getItem('token') : null,
    advertiseList: [],
    newsletterList: [],
    footerCategories: [],
    socialLinks: [],
    profileImage: localStorage.getItem('image') ? localStorage.getItem('image') : null,
    cartCounter: cartCounterValue(),
    wishlistCounter: wishListCounterValue(),
    cartValue: (localStorage.getItem('cartList') && localStorage.getItem('cartList') != 'null') ? JSON.parse(localStorage.getItem('cartList')) : [],
    totalAmount: 0,
    couponDiscount: 0,
    couponId: "",
    isLoading: true,
    isNewsLetter: sessionStorage.getItem('isNewsLetter') ? sessionStorage.getItem('isNewsLetter') : true,
    isAdvertise: sessionStorage.getItem('isAdvertise') ? sessionStorage.getItem('isAdvertise') : true,
    locationCms: {},
    hearAboutUsOptions: [],
    isNewUser: false,
    salesTax: {
        percentage: 0,
        name: "",
        globalTax: 0
    },
    instagramData: [],
    componentUi: {
        aboutUsComponentUI: {},
        blogComponentUI: {},
        careerComponentUI: {},
        contactComponentUI: {},
        franchiseComponentUI: {},
        quotationComponentUI: {},
        helpComponentUI: {},
        homeComponentUI: {},
        locationComponentUI: {},
        policyComponentUI: {},
        productsComponentUI: {},
        productsDetailsComponentUI: {},
        locationDetailsComponentUI: {}
    },
    brandData: [],
    freeShippingCoupon: null,
    isFreeShippingApplied: localStorage.getItem('isFreeShipping') ? JSON.parse(localStorage.getItem('isFreeShipping')) : false,
    weightExided: localStorage.getItem('weightExided') ? JSON.parse(localStorage.getItem('weightExided')) : false,
});

function cartCounterValue() {
    let counter = 0;
    if (localStorage.getItem('cartList') && localStorage.getItem('cartList') != 'null') {
        let cartValue = JSON.parse(localStorage.getItem('cartList'));
        for (let i = 0; i < cartValue.length; i++) {
            counter = counter + cartValue[i].quantity;
        }
        return counter;
    } else {
        return 0;
    }
}

function wishListCounterValue() {
    let counter = 0;
    if (localStorage.getItem('wishList') && localStorage.getItem('wishList') != 'null') {
        let wishListValue = JSON.parse(localStorage.getItem('wishList'));
        for (let i = 0; i < wishListValue.length; i++) {
            counter = counter + wishListValue[i].quantity;
        }
        return counter;
    } else {
        return 0;
    }
}
