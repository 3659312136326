import { state } from '@/globalVariables';
import NewsLetter from '../../Newsletter/template/NewsLetter';
import Advertise from '../../Advertise/template/Advertise';
import { authHeader } from '@/auth';
import { countries } from "../../../../config/country";
import { states } from '../../../../config/states-list';
import CheckoutSummery from '../template/CheckoutSummery'
import $ from 'jquery';
import CheckoutSchemajson from '../../seoPlugin/CheckoutSchemajson';

export default {
    props: ['shippingCostNew'],
    components: {
        NewsLetter,
        Advertise,
        CheckoutSchemajson,
        CheckoutSummery
    },
    data() {
        return {
            state,
            states: states,
            products: [],
            getAddressURL: this.$serverURL + this.$api.profile.getShippingBillingAddress,
            countriesList: countries,
            country: {
                name: 'United States of America',
                code: 'US',
            },
            shippingAddressData: {
                name: null,
                businessName: null,
                addressOne: "",
                addressTwo: "",
                mobile: null,
                email: null,
                postCode: null,
                city: null,
                stateCode: null
            },
            billingAddressData: {
                name: null,
                businessName: null,
                address: "",
                mobile: null,
                email: null,
                postCode: null,
                city: null,
                stateCode: null
            },
            shippingCountry: "",
            billingCountry: "",
            sameAsBillingAddress: false,
            paymentMethod: "Credit/Debit Card",
            remarks: "",
            shippingType: "",
            serviceTypeList: [],
            pickUpTypeList: [],
            serviceType: "",
            packageType: "",
            pickUpType: "",
            totalWeight: 0,
            orderConfirmed: false,
            showCard: false,
            paymentInfo: {
                name: "",
                cardNumber: "",
                securityCode: ""
            },
            expireDate: "",
            orderId: "",
            disableShipping: true,

            same: false,
            token: localStorage.getItem('token'),
            taxAmount: 0,
            actualProductPrice: 0,
            shippingMethodList: [],
            test: "",
            shippingCmsData: {},
            imagePath: this.$imageURL + 'shipping-methods/',
            userId: localStorage.getItem('id'),
            taxExemptApproved: false,
            grandAmount: 0,
            salesTaxData: "",
            activeShipping: false,
            couponList: [],
            couponCode: "",
            isFreeShipping: false,
            appliedCoupon: {},
            isBillingAddress: true,
            defaultShippingCost: localStorage.getItem('shippingData') ? JSON.parse(localStorage.getItem('shippingData'))?.shippingCost : 0

        }
    },
    async created() {
        //await this.getPageTitles();
        await this.getCoupons();
        //document.title = (this.pageTitles?.checkoutPageTitle ? this.pageTitles?.checkoutPageTitle : '');
        this.scrollToTop();
        $('#shipping_methods input').attr('disabled', true);
        this.products = await this.state.cartValue.map(el => {
            let variants = [];
            this.actualProductPrice = this.actualProductPrice + (el.data.price * el.quantity);
            return {
                'productId': el.data.id,
                'quantity': el.quantity,
                'actualPrice': el.data.price,
                "variants": variants,
                'itemPrice': this.findMixinActualPrice(el.data),
                'itemSubTotal': (this.findMixinActualPrice(el.data)) * el.quantity
            };
        });
        await this.shippingCms();

        const modal = document.querySelector("#offcanvasRight");
        const button = document.querySelector('.cart_bt');

        if (!modal.classList.contains("show")) {
            console.log('Modal not open');
        }
        else {
            button.click();
        }
    },

    computed: {
        grandShippingCost() {
            return this.shippingCostNew || this.defaultShippingCost;
        }
    },

    async mounted() {
        await this.getShippingBillingInformation();
        await this.getUserTaxExemptInfo();
        //this.checkSB();
        this.calculateWeight();
        //await this.checkShipping();
        this.calculateTotalAmount();
        this.calculateGrandTotal();
        this.loadCSS();
        this.grandTotal = this.state.totalAmount;
    },
    methods: {
        async getUserTaxExemptInfo() {
            try {
                let config = {
                    method: "GET",
                    url: this.$serverURL + this.$api.taxExempt.getUserTaxExemptURL + '/' + this.userId,
                    headers: {
                        "Authorization": authHeader()
                    }
                }
                await this.$axios(config).then(async (response) => {
                    if (response.data.statusCode == 200) {
                        if (response.data.statusCode == 200) {
                            if (response.data.data.length > 0) {
                                let data = response.data.data[0];
                                this.taxExemptApproved = data.isApproved;
                            } else {
                                this.taxExemptApproved = false;
                            }
                        }
                    }

                }).catch(error => {
                    console.log(error);
                });
            } catch (error) {
                console.log(error)
            }
        },
        shippingMethods: async function () {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.shippingMethods.requestURL,
                headers: {
                    "Authorization": authHeader()
                }
            };
            this.state.isLoading = true;
            await this.$axios(config).then(response => {
                this.state.isLoading = false;
                if (response.data.statusCode == 200) {
                    this.shippingMethodList = response.data.data;
                }
            }).catch(error => {
                this.state.isLoading = false;
                console.log(error)
            });

        },
        loadCSS: function () {
            var styles = [
                '/core/assets/css/checkout.css'
            ]
            styles.forEach(style => {
                let tag = document.createElement('link');
                tag.type = "text/css";
                tag.rel = "stylesheet";
                tag.href = style;
                document.head.appendChild(tag);
            });
            var scripts = [
                "/core/assets/js/main.js"
            ];
            scripts.forEach(script => {
                let tag = document.createElement("script");
                tag.setAttribute("src", script);
                document.head.appendChild(tag);
            });
        },
        setBillingAddress(locationData) {
            let location = locationData.formatted_address;
            let addressComponent = locationData.address_components;
            let locationSplit = location.split(',');
            if (locationSplit[0].trim() != locationData.name) {
                location = locationData.name + ', ' + location;
            }
            this.billingAddressData.address = locationData.name;
            this.billingAddressData.city = "";
            this.billingAddressData.postCode = "";
            this.billingAddressData.stateCode = "";

            for (let i = 0; i < addressComponent.length; i++) {
                if (addressComponent[i].types[0] == "locality") {
                    this.billingAddressData.city = addressComponent[i].long_name;
                } else if (addressComponent[i].types[0] == "postal_code") {
                    this.billingAddressData.postCode = addressComponent[i].long_name;
                } else if (addressComponent[i].types[0] == "administrative_area_level_1") {
                    this.billingAddressData.stateCode = addressComponent[i].short_name;
                }
            }
            this.checkSB();
        },
        setShippingAddressOne(locationData) {
            let location = locationData.formatted_address;
            let addressComponent = locationData.address_components;
            let locationSplit = location.split(',');
            if (locationSplit[0].trim() != locationData.name) {
                location = locationData.name + ', ' + location;
            }
            this.shippingAddressData.addressOne = locationData.name;
            //this.shippingAddressData.city = locationData.vicinity;
            this.shippingAddressData.city = "";
            this.shippingAddressData.postCode = "";
            this.shippingAddressData.stateCode = "";

            for (let i = 0; i < addressComponent.length; i++) {
                if (addressComponent[i].types[0] == "locality") {
                    this.shippingAddressData.city = addressComponent[i].long_name;
                } else if (addressComponent[i].types[0] == "postal_code") {
                    this.shippingAddressData.postCode = addressComponent[i].long_name;
                } else if (addressComponent[i].types[0] == "administrative_area_level_1") {
                    this.shippingAddressData.stateCode = addressComponent[i].short_name;
                }
            }
            this.handleSalesTax();
        },
        setShippingAddressTwo(locationData) {
            let location = locationData.formatted_address;
            let locationSplit = location.split(',');
            if (locationSplit[0].trim() != locationData.name) {
                location = locationData.name + ', ' + location;
            }
            this.shippingAddressData.addressTwo = locationData.name;
            //this.checkSB();
        },
        getShippingBillingInformation: async function () {
            try {
                let config = {
                    method: "GET",
                    url: this.getAddressURL,
                    headers: {
                        "Authorization": authHeader()
                    }
                }
                await this.$axios(config).then(async (response) => {
                    if (response.data.statusCode == 200) {
                        if (response.data.data.shippingAddress) {
                            if (Object.values(response.data.data?.shippingAddress).length > 0) {
                                this.shippingAddressData = response.data.data.shippingAddress;
                                this.cloneShippingAddress = response.data.data.shippingAddress;
                            } else {
                                this.shippingAddressData = {
                                    name: null,
                                    businessName: null,
                                    addressOne: null,
                                    addressTwo: null,
                                    mobile: null,
                                    email: null,
                                    postCode: null,
                                    city: null,
                                    stateCode: null
                                }
                            }
                            await this.handleSalesTax();
                        }
                        else {
                            this.shippingAddressData = {
                                name: null,
                                businessName: null,
                                addressOne: null,
                                addressTwo: null,
                                mobile: null,
                                email: null,
                                postCode: null,
                                city: null,
                                stateCode: null
                            }
                        }

                        if (response.data.data.billingAddress) {
                            if (Object.values(response.data.data?.billingAddress).length > 0) {
                                this.billingAddressData = response.data.data.billingAddress;
                            } else {
                                this.billingAddressData = {
                                    name: null,
                                    businessName: null,
                                    address: null,
                                    mobile: null,
                                    email: null,
                                    postCode: null,
                                    city: null,
                                    stateCode: null
                                }
                            }
                        }
                        else {
                            this.billingAddressData = {
                                name: null,
                                businessName: null,
                                address: null,
                                mobile: null,
                                email: null,
                                postCode: null,
                                city: null,
                                stateCode: null
                            }
                        }
                    }
                    else {
                        this.$swal.fire({
                            icon: "error",
                            text: "Failed! please try again."
                        });
                    }
                }).catch(error => {
                    console.log(error);
                });
            } catch (error) {
                console.log(error);
            }
        },
        updateShippingAddress: async function () {
            try {
                let data = {
                    name: this.shippingAddressData.name,
                    businessName: this.shippingAddressData.businessName,
                    addressOne: this.shippingAddressData.addressOne,
                    addressTwo: this.shippingAddressData.addressTwo,
                    mobile: this.shippingAddressData.mobile,
                    email: this.shippingAddressData.email,
                    postCode: this.shippingAddressData.postCode,
                    stateCode: this.shippingAddressData.stateCode,
                    city: this.shippingAddressData.city,
                    country: this.country.name,
                    countryCode: this.country.code,
                };
                let config = {
                    method: "POST",
                    url: this.$serverURL + this.$api.profile.shippingAddressURL,
                    data: data,
                    headers: {
                        "Authorization": authHeader()
                    }

                };
                await this.$axios(config).then((response) => {
                    if (response.data.statusCode) {
                        this.shippingAddressData = response.data.data;
                        this.shippingCountry = "";
                    } else {
                        this.$swal.fire({
                            icon: "info",
                            text: response.data.message,
                        });
                    }
                }).catch(error => {
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            }
        },
        updateBillingAddress: async function () {
            try {
                // console.log("Billing Country: ", this.billingCountry.code)
                let data = {
                    name: this.billingAddressData.name,
                    businessName: this.billingAddressData.businessName,
                    address: this.billingAddressData.address,
                    mobile: this.billingAddressData.mobile,
                    email: this.billingAddressData.email,
                    postCode: this.billingAddressData.postCode,
                    stateCode: this.billingAddressData.stateCode,
                    city: this.billingAddressData.city,
                    country: this.country.name,
                    countryCode: this.country.code,
                };
                let config = {
                    method: "POST",
                    url: this.$serverURL + this.$api.profile.billingAddressURL,
                    data: data,
                    headers: {
                        "Authorization": authHeader()
                    }

                };
                await this.$axios(config).then((response) => {
                    if (response.data.statusCode) {
                        this.billingAddressData = response.data.data;
                        this.billingCountry = "";
                    } else {
                        this.$swal.fire({
                            icon: "info",
                            text: response.data.message,
                        });
                    }
                }).catch(error => {
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
            } catch (error) {
                console.log(error);
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            }
        },
        setShippingAddress: async function () {
            //  console.log("data:", this.billingAddressData);
            // if (this.sameAsBillingAddress === true) {

            this.shippingAddressData.name = this.billingAddressData.name;
            this.shippingAddressData.businessName = this.billingAddressData.businessName;
            this.shippingAddressData.addressOne = this.billingAddressData.address;
            this.shippingAddressData.addressTwo = "";
            this.shippingAddressData.mobile = this.billingAddressData.mobile;
            this.shippingAddressData.email = this.billingAddressData.email;
            this.shippingAddressData.city = this.billingAddressData.city;
            this.shippingAddressData.postCode = this.billingAddressData.postCode;
            this.shippingAddressData.stateCode = this.billingAddressData.stateCode;
            this.shippingAddressData.country = this.country.name;
            this.shippingAddressData.countryCode = this.country.code;
            if (this.shippingAddressData.stateCode) {
                await this.handleSalesTax();
            }


            // } else if (this.sameAsBillingAddress === false) {

            //     this.shippingAddressData = {
            //         name: null,
            //         businessName: null,
            //         addressOne: null,
            //         addressTwo: null,
            //         mobile: null,
            //         email: null,
            //         postCode: null,
            //         city: null,
            //         stateCode: null
            //     }

            //}
        },
        Validation: function () {
            if (!this.shippingType) {
                this.$swal.fire({
                    icon: "info",
                    text: "Select Shipping Type.",
                }).then(() => {
                    this.scrollToTop();
                });

                return false;
            } else if (!this.serviceType) {
                this.$swal.fire({
                    icon: "info",
                    text: "Select Shipping Service Type.",
                }).then(() => {
                    this.scrollToTop();
                });
                return false;
            }
            else {
                return true;
            }
        },
        setShippingStateCode: async function (dataShipping) {
            console.log('dataShipping = ', dataShipping);
        },

        checkSB: async function () {
            if (!this.billingAddressData.name) {
                this.activeShipping = false;
            } else if (!this.billingAddressData.address) {
                this.activeShipping = false;
            }
            else if (!this.billingAddressData.mobile) {
                this.activeShipping = false;
            } else if (!this.billingAddressData.city) {
                this.activeShipping = false;
            } else if (!this.billingAddressData.email) {
                this.activeShipping = false;
            }
            else if (!this.billingAddressData.postCode) {
                this.activeShipping = false;
            } else if (!this.billingAddressData.stateCode) {
                this.activeShipping = false;
            } else {
                this.$swal.fire({
                    icon: "info",
                    text: "Please Provide Shipping Information."
                }).then(result => {
                    this.scrollToTop();
                    if (result.isConfirmed == true) {
                        this.activeShipping = true;
                    }
                })

            }
            //shipping
            // else if (!this.shippingAddressData.name) {
            //     return false;
            // } else if (!this.shippingAddressData.addressOne) {
            //     return false;
            // }
            // else if (!this.shippingAddressData.email) {
            //     return false;
            // } else if (!this.shippingAddressData.mobile) {
            //     return false;
            // } else if (!this.shippingAddressData.city) {
            //     return false;
            // } else if (!this.shippingAddressData.postCode) {
            //     return false;
            // } else if (!this.shippingAddressData.stateCode) {
            //     return false;
            // }
            // else {
            //     this.disableShipping = false;
            //     //this.shippingType = 'fedex';
            //     await this.handleShipping();
            // }
        },

        async getCoupons() {
            try {
                let config = {
                    method: "GET",
                    url: this.$serverURL + this.$api.coupon.getCouponsURL,
                    header: {
                        'Content-Type': "application/json"
                    }
                };
                await this.$axios(config).then(response => {
                    this.couponList = response.data.data;
                    for (let index = 0; index < this.couponList.length; index++) {
                        const cupon = this.couponList[index];
                        if (cupon.couponType == "free shipping") {
                            if (this.state.totalAmount >= parseInt(cupon.discount)) {
                                this.isFreeShipping = true;
                                this.appliedCoupon = cupon;
                                this.shippingCost = 0;
                                this.serviceType = "Free Service";
                                this.shippingType = "Free Shipping";
                                console.log(this.serviceType);
                            } else {
                                this.isFreeShipping = false
                            }
                        }
                    }
                }).catch(error => {
                    console.log(error);
                })
            } catch (error) {
                console.log(error);
            }
        },

        applyCoupon() {
            let data = this.couponList.find(el => el.couponCode == this.couponCode)
            if (data) {
                this.appliedCoupon = data;
                this.state.couponId = data.couponCode;
                if (data.couponType == 'percent') {
                    this.state.couponDiscount = this.state.totalAmount * (parseFloat(data.discount) / 100);
                } else if (data.couponType == 'welcome') {
                    this.state.couponDiscount = parseFloat(parseFloat(this.state.totalAmount) * parseFloat((data.discount) / 100)).toFixed(2);
                } else {
                    this.state.couponDiscount = parseFloat(data.discount);
                }
            } else {
                this.$swal.fire({
                    icon: "error",
                    text: "Not a valid coupon!!!"
                });
            }
        },

        shippingBillingValidation: function () {
            if (!this.billingAddressData.name) {

                this.$swal.fire({
                    icon: "info",
                    text: "Enter Billing Name.",
                }).then(() => {
                    this.scrollToTop();
                });
                this.shippingType = false;
                return false;
            }
            else if (!this.billingAddressData.address) {
                this.$swal.fire({
                    icon: "info",
                    text: "Enter Billing Address.",
                }).then(() => {
                    this.scrollToTop();
                });
                this.shippingType = false;
                return false;
            }
            else if (!this.billingAddressData.city) {
                this.$swal.fire({
                    icon: "info",
                    text: "Enter Billing City.",
                });
                this.shippingType = false;
                return false;
            }
            else if (!this.billingAddressData.postCode) {
                this.$swal.fire({
                    icon: "info",
                    text: "Enter Billing Zip/PostCode.",
                });
                this.shippingType = false;
                return false;
            }
            else if (!this.billingAddressData.stateCode) {
                this.$swal.fire({
                    icon: "info",
                    text: "Enter Billing State.",
                });
                this.shippingType = false;
                return false;
            }
            else if (!this.billingAddressData.mobile) {
                this.$swal.fire({
                    icon: "info",
                    text: "Enter Billing Mobile Number.",
                });
                this.shippingType = false;
                return false;
            }

            else if (this.billingAddressData.mobile.length <= 9) {
                this.$swal.fire({
                    icon: "info",
                    text: "Mobile number must be at least 10 digit",
                });
                this.shippingType = false;
                return false;
            }

            else if (!this.billingAddressData.email) {
                this.$swal.fire({
                    icon: "info",
                    text: "Enter Billing Email.",
                });
                this.shippingType = false;
                return false;
            }

            //shipping
            else if (!this.shippingAddressData.name) {
                this.$swal.fire({
                    icon: "info",
                    text: "Enter Shipping Name.",
                });
                this.shippingType = false;
                return false;
            } else if (!this.shippingAddressData.addressOne) {
                this.$swal.fire({
                    icon: "info",
                    text: "Enter Shipping Address One.",
                });
                this.shippingType = false;
                return false;
            }
            else if (!this.shippingAddressData.city) {
                this.$swal.fire({
                    icon: "info",
                    text: "Enter Shipping City.",
                });
                this.shippingType = false;
                return false;
            }
            else if (!this.shippingAddressData.postCode) {
                this.$swal.fire({
                    icon: "info",
                    text: "Enter Shipping Zip/PostCode Code.",
                });
                this.shippingType = false;
                return false;
            }
            else if (!this.shippingAddressData.stateCode) {
                this.$swal.fire({
                    icon: "info",
                    text: "Enter Shipping State.",
                });
                this.shippingType = false;
                return false;
            }

            else if (!this.shippingAddressData.mobile) {
                this.$swal.fire({
                    icon: "info",
                    text: "Enter Shipping Mobile Number.",
                });
                this.shippingType = false;
                return false;
            }
            else if (this.shippingAddressData.mobile.length < 9) {
                this.$swal.fire({
                    icon: "info",
                    text: "Mobile number must be at least 10 digit",
                });
                this.shippingType = false;
                return false;
            }

            else if (!this.shippingAddressData.email) {
                this.$swal.fire({
                    icon: "info",
                    text: "Enter Shipping Email.",
                });
                this.shippingType = false;
                return false;
            }
            else {
                this.disableShipping = false;
                return true;
            }
        },

        setShippingCost: function (price) {
            this.shippingCost = Number(price);
        },

        checkShipping: async function () {
            this.serviceTypeList = [];
            // if (this.token) {
            //     await this.updateShippingAddress();
            // }

            // else if (!this.packageType) {
            //     this.$swal.fire({
            //         icon: "info",
            //         text: "Select package type.",
            //     });
            // }
            let data = {
                shippingAddress: this.shippingAddressData,
                weightValue: this.totalWeight
            };
            let config = {
                method: "POST",
                url: this.$serverURL + this.$api.order.checkShippingURL,
                data: data,
                // headers: {
                //     "Authorization": authHeader()
                // }
            };
            this.state.isLoading = true;
            await this.$axios(config).then((response) => {
                this.state.isLoading = false;
                if (response.status == 200 && response.data.statusCode) {
                    if (response.data.statusCode == 400 || response.data.statusCode == 401 || response.data.statusCode == 403 || response.data.statusCode == 404 || response.data.statusCode == 500 || response.data.statusCode == 503) {
                        for (let i = 0; i < response.data.data.errors.length; i++) {
                            this.$swal.fire({
                                icon: "error",
                                text: response.data.data.errors[i].message
                            });
                        }
                    }
                }
                else if (response.status == 200) {
                    this.serviceTypeList = response.data;
                }

            }).catch(error => {
                this.state.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },

        checkPaymentMethod: function () {
            if (this.Validation()) {
                if (this.paymentMethod == 'Credit/Debit Card') {
                    this.scrollToTop();
                    this.showCard = true;
                } else {
                    this.showCard = false;
                }
            }

        },

        upsCheckShipping: async function () {
            this.serviceTypeList = [];
            // if (this.token) {
            //     await this.updateShippingAddress();
            // }
            let data = {
                shippingAddress: this.shippingAddressData,
                weightValue: this.totalWeight
            };
            let config = {
                method: "POST",
                url: this.$serverURL + this.$api.order.upsCheckShippingURL,
                data: data,
                // headers: {
                //     "Authorization": authHeader()
                // }
            };
            this.state.isLoading = true;
            await this.$axios(config).then((response) => {
                // console.log(response);
                this.state.isLoading = false;
                if (response.status == 200 && response.data.statusCode) {
                    if (response.data.statusCode == 400 || response.data.statusCode == 401 || response.data.statusCode == 403 || response.data.statusCode == 404 || response.data.statusCode == 500 || response.data.statusCode == 503) {
                        for (let i = 0; i < response.data.data.response.errors.length; i++) {
                            this.$swal.fire({
                                icon: "error",
                                text: response.data.data.response.errors[i].message
                            });
                        }
                    }
                }
                else if (response.status == 200) {
                    this.serviceTypeList = response.data;
                }

            }).catch(error => {
                this.state.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },
        handleShipping: async function () {
            if (this.shippingBillingValidation()) {
                if (this.shippingType == 'fedex') {
                    this.checkShipping();
                } else if (this.shippingType == 'ups') {
                    this.upsCheckShipping();
                } else if (this.shippingType == 'local') {
                    await this.shippingMethods();
                }
            }

        },
        paymentalidation: function () {
            if (!this.paymentInfo.name) {
                this.$swal.fire({
                    icon: 'info',
                    text: "Please provide card holder name."
                }).then(() => {
                    this.scrollToTop();
                });
                return false;
            } else if (!this.paymentInfo.cardNumber) {
                this.$swal.fire({
                    icon: 'info',
                    text: "Please provide card number."
                }).then(() => {
                    this.scrollToTop();
                });
                return false;
            } else if (!this.expireDate) {
                this.$swal.fire({
                    icon: 'info',
                    text: "Please provide card expire date."
                });
                return false;
            } else if (!this.paymentInfo.securityCode) {
                this.$swal.fire({
                    icon: 'info',
                    text: "Please provide card security code."
                });
                return false;
            } else {
                if (!this.expireDate.includes('/')) {
                    this.$swal.fire({
                        icon: 'info',
                        text: "Expire date format should be 02/22."
                    });
                    return false;
                }
                return true;
            }
        },
        handlePayment: async function () {
            // if (this.paymentMethod == 'Cash on delivery') {
            //     this.placeOrder();
            // } else
            if (this.paymentMethod == 'Credit/Debit Card') {
                if (this.paymentalidation()) {
                    this.placeOrder();
                }
            } else {
                this.$swal.fire({
                    icon: "error",
                    text: "Please select payment method."
                });
                return;
            }
        },
        placeOrder: async function () {
            try {
                this.state.isLoading = true;
                if (this.token) {
                    this.updateBillingAddress();
                    this.updateShippingAddress();
                }
                let data = {
                    shippingAddress: JSON.stringify({
                        name: this.shippingAddressData.name, addressOne: this.shippingAddressData.addressOne, addressTwo: this.shippingAddressData.addressTwo, mobile: this.
                            shippingAddressData.mobile, email: this.shippingAddressData.email, postCode: this.shippingAddressData.postCode, stateCode: this.shippingAddressData.stateCode, city: this.shippingAddressData.city, country: this.shippingCountry.name ? this.shippingCountry.name : this.shippingAddressData.country, countryCode: this.shippingCountry.code ? this.shippingCountry.code : this.shippingAddressData.countryCode
                    }),
                    billingAddress: JSON.stringify({
                        name: this.billingAddressData.name, address: this.billingAddressData.address, mobile: this.billingAddressData.mobile, email: this.
                            billingAddressData.email, stateCode: this.billingAddressData.stateCode, postCode: this.billingAddressData.postCode, city: this.billingAddressData.city, country: this.billingCountry ? this.billingCountry : this.billingAddressData.country
                    }),
                    shippingType: this.shippingType,
                    serviceType: this.serviceType,
                    weightValue: this.totalWeight,
                    products: this.products,
                    paymentMethodName: this.paymentMethod,
                    amount: this.grandAmount,
                    // amount: this.state.couponDiscount ? (parseFloat(this.state.totalAmount + this.shippingCost + this.taxAmount) - this.state.couponDiscount).toFixed(2) : parseFloat(this.state.totalAmount + this.shippingCost + this.taxAmount).toFixed(2),
                    shippingCost: this.shippingCost,
                    remarks: this.remarks,
                    ccNum: this.paymentInfo.cardNumber,
                    ccExp: this.expireDate ? this.expireDate.replace('/', '') : '',
                    cvv: this.paymentInfo.securityCode,
                    coupon: this.state.couponId,
                    salesTaxTitle: this.state.salesTax?.name,
                    salesTaxRate: (this.taxExemptApproved == true) ? 0 : this.state.salesTax?.percentage,
                    salesTaxAmount: (this.taxExemptApproved == true) ? 0 : this.taxAmount.toFixed(2),
                }


                //console.log('Checkout Data =',data);
                // return false;

                let config = {
                    method: "POST",
                    url: this.$serverURL + this.$api.order.orderURL,
                    data: data,
                    headers: {
                        "Authorization": authHeader()
                    }
                };
                let guestConfig = {
                    method: "POST",
                    url: this.$serverURL + this.$api.order.guestOrderURL,
                    data: data
                }
                this.state.isLoading = true;
                await this.$axios(this.token ? config : guestConfig).then((response) => {
                    this.state.isLoading = false;
                    if (response.data.statusCode == 200) {
                        this.state.cartValue = [];
                        this.state.cartCounter = 0;
                        localStorage.setItem(this.$cartList, JSON.stringify(this.state.cartValue));
                        if (this.state.isNewUser == true) {
                            this.state.isNewUser = false;
                        }
                        this.$swal.fire({
                            icon: "success",
                            text: response.data.message,
                        });
                        // this.$router.push('/dashboard');
                        this.$router.push(`order-confirm/${response.data.orderId}`);
                    } else if (response.data.statusCode == 400) {
                        this.$swal.fire({
                            toast: true,
                            position: 'top-end',
                            text: response.data.message,
                            icon: "error",
                            showConfirmButton: false,
                            timer: 3000,
                            animation: false,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                            }
                        }).then(result => {
                            console.log(result);
                            this.$swal.fire({
                                toast: true,
                                position: 'top-end',
                                text: response.data.errors.responsetext,
                                icon: "error",
                                showConfirmButton: false,
                                timer: 3000,
                                animation: false,
                                timerProgressBar: true,
                                didOpen: (toast) => {
                                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                                }
                            })
                        });
                    }
                }).catch(error => {
                    this.state.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });

            } catch (error) {
                this.state.isLoading = false;
                console.log(error);
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong, please try agian later."
                });
            }
        },
        calculateWeight: function () {
            this.totalWeight = 0;
            for (let i = 0; i < this.state.cartValue.length; i++) {
                this.totalWeight = this.totalWeight + (parseFloat(this.state.cartValue[i].data.weight) * this.state.cartValue[i].quantity);
            }
        },
        confirmedOrder: function () {
            if (this.Validation()) {
                this.orderConfirmed = true;
            }
        },
        calculateTax() {
            this.taxAmount = (this.actualProductPrice * (this.state.salesTax.percentage / 100));
            return this.taxAmount.toFixed(2);
        },
        calculateGrandTotal() {
            let taxAmount = 0;
            if (this.taxExemptApproved == true) {
                taxAmount = 0
            } else {
                taxAmount = this.taxAmount.toFixed(2);
            }
            // console.log(taxAmount);
            if (state.couponDiscount) {
                this.grandAmount = (this.state.totalAmount + (this.grandShippingCost) - this.state.couponDiscount + parseFloat(taxAmount)).toFixed(2);
                return this.grandAmount;
            } else {
                this.grandAmount = (this.state.totalAmount + (this.grandShippingCost) + parseFloat(taxAmount)).toFixed(2);
                return this.grandAmount;
            }
        },
        shippingCms: async function () {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.cms.shippingCmsURL,
            };
            await this.$axios(config).then((response) => {
                if (response.data.statusCode == 200) {
                    this.shippingCmsData = response.data.data.length > 0 ? response.data.data[0] : {};
                }
            }).catch(error => {
                console.log(error)
            });
        },
        handleSalesTax: async function () {
            let stateObject = states.find(x => x.code === this.shippingAddressData.stateCode);
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.salesTax.findByStateURL + '/' + stateObject.name
            };
            this.state.isLoading = true;
            await this.$axios(config).then(response => {
                this.state.isLoading = false;
                if (response.data.statusCode == 200) {
                    this.salesTaxData = response.data.data;
                    if (Object.keys(this.salesTaxData).length != 0) {
                        let salesTaxAmount = this.salesTaxData[0].taxAmount;
                        this.state.salesTax.percentage = Number(salesTaxAmount);
                    } else {
                        this.state.salesTax.percentage = this.state.salesTax.globalTax;
                    }
                }
            }).catch(error => {
                this.state.isLoading = false;
                console.log(error)
            });
        },
        isBillingAddressToggle() {
            this.isBillingAddress = !this.isBillingAddress
        }
    },

    watch: {
        sameAsBillingAddress: function () {
            if (this.sameAsBillingAddress == true) {
                this.setShippingAddress();
                //this.checkSB();
            } else if (this.sameAsBillingAddress == false) {

                this.shippingAddressData = {
                    name: null,
                    addressOne: null,
                    addressTwo: null,
                    mobile: null,
                    email: null,
                    postCode: null,
                    city: null,
                    country: null
                }
                //this.checkSB();
            }
        },

        shippingType: function () {
            if (this.isFreeShipping == false) {
                this.orderConfirmed = false;
                this.serviceType = '';
            }

        },

        expireDate: function () {
            if (this.expireDate.length == 2) {
                this.expireDate += '/';
            }
        },

        shippingCostNew: function () {
            this.calculateGrandTotal();
        }

        
    }
}