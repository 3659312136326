<template>
  <main class="bg_gray" :style="{'background-color': state.componentUi.policyComponentUI.pageBackgroundColor}">
    <div class="container margin_60_35 add_bottom_30">
      <div class="main_title">
        <h1 :style="{color: state.componentUi.policyComponentUI.pageTitleFontColor}">{{cmsData.title}}</h1>
      </div>
      <div class="row justify-content-center align-items-center">
        <p v-html="cmsData.description"></p>
      </div>
    </div>
  </main>
  <TermsPolicySchemajson v-if="Object.values(pageTitles).length>0" :metaData="pageTitles" />
</template>
<script src="../js/terms-and-condition.js"></script>