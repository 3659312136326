import { state } from '@/globalVariables';
export default {
    data() {
        return {
            state,
            imagePath: this.$imageURL + 'header/',
            productImagePath: this.$imageURL + 'productImage/',
            subCategoriesList: [],
            lastId: "",
            searchText: '',
            showSubSection: false,
            showCategory: false,
            isActiveSubcategory: false,
            currentIndex: 0,
            showBrands: false,
            suggestions: [],
            sugBrandName: [],
            isActiveDropDown: false,
            searchTotal: 0,
            fedxCms: {},
            fedxList: [],
            countDowns: [],
            selectedFedx: {title:''},
            defaultTimer: { hour: '00', minutes: '00', seconds: '00' },
            completedCount: 0,

        }
    },
    async created() {
        await this.getCategories();
        await this.getFedxCutoffTimesAndCms();
    },
    mounted() {
        document.addEventListener('click', this.handleClickOutside);
        setInterval(() => {
            if (this.fedxList.length > 0 && this.checkCountDownIsCompleted()) {
                this.fedxList.map((el, index) => {
                    this.countDowns[index] = this.countdown(el);
                    if (this.selectedFedx && el.title.toLowerCase() === this.selectedFedx.title.toLowerCase()) {
                        this.defaultTimer = this.countDowns[index];
                    } else if (!this.selectedFedx && el.default) {
                        this.defaultTimer = this.countDowns[index];
                    }
                    if (this.countDowns[index].isCompleted) {
                        el.hide = true;
                    }
                });
            }
        }, 1000);
    },
    beforeUnmount() {
        document.removeEventListener('click', this.handleClickOutside);
    },
    computed: {

    },
    methods: {
        countdown(data) {
            let endDate = new Date(data.time);
            const diffSeconds = Math.floor((endDate.getTime() - Date.now()) / 1000);
            if (diffSeconds < 0) {
                if (data.title.toLowerCase() === this.selectedFedx.title.toLowerCase()) {
                    this.selectedFedx = "";
                    sessionStorage.setItem("fedxcutoff", "");
                    this.setFedxCutofftime();
                }
                return { isCompleted: true };
            }
            const diffHours = Math.floor(diffSeconds / 3600);
            const diffMinutes = Math.floor((diffSeconds % 3600) / 60);
            let remainingSeconds = diffSeconds % 60;
            if (remainingSeconds < 10) {
                remainingSeconds = '0' + remainingSeconds;
            }
            return { hour: diffHours?diffHours:0, minutes: diffMinutes, seconds: remainingSeconds }

        },
        checkCountDownIsCompleted() {
            if (this.completedCount == this.fedxList) {
                return false;
            }
            return true;
        },
        getSubCategories: async function (id) {
            if (this.lastId != id) {
                this.lastId = id;
                this.subCategoriesList = [];
                try {
                    let config = {
                        method: "GET",
                        url: this.$serverURL + this.$api.product.productSubCategoryByParentId + '?parentCategoryId=' + id,
                        headers: {
                            "Content-Type": "application/json"
                        }
                    };
                    await this.$axios(config).then(async (response) => {
                        if (response.data.statusCode == 200) {
                            this.subCategoriesList = await response.data.data;
                        }

                    }).catch(error => {
                        console.log(error);
                    });
                } catch (error) {
                    console.log(error);
                }
            }
        },
        async search() {
            this.$router.push({
                name: 'Product',
                query: { keyword: this.searchText },
            });
            this.state.isSearchActive = false;
            this.isActiveDropDown = false;
            this.searchText = "";
        },
        activeCategory() {
            this.showCategory = true
        },
        closeCategory() {
            this.showCategory = false;
            this.isActiveSubcategory = false;
            this.currentIndex = 0;
        },
        activeBrands() {
            this.showBrands = true
        },
        closeBrands() {
            this.showBrands = false;
            // this.isActiveSubcategory = false;
            this.currentIndex = 0;
        },
        activeSubcategory(index) {
            this.currentIndex += index + 1
            if (this.currentIndex === index + 1) {
                this.isActiveSubcategory = true;
            }
        },
        deactivateSubcategory(index) {
            this.currentIndex -= index + 1
            this.isActiveSubcategory = false;

        },

        async searchElastic() {
            if (!this.searchText || this.searchText.trim() == '') {
                this.isActiveDropDown = false;
                this.suggestions = [];
                return;
            }

            let config = {
                method: "POST",
                url: this.$serverURL + this.$api.product.elasticSearch,
                data: {
                    search: this.searchText,
                    limit: 5,
                },
                headers: {
                    "Content-Type": "application/json"
                }
            };
            await this.$axios(config)
                .then(res => {
                    this.suggestions = res.data?.products?.data;
                    const brandName = res.data?.products?.data.map(x => x.brandName);
                    this.sugBrandName = new Set(brandName);
                    this.searchTotal = res.data?.products?.total;
                    this.isActiveDropDown = true;
                })
                .catch(error => {
                    console.log(error);
                });
        },

        async handleSugClick(value) {
            this.searchText = value;
            await this.search();
        },

        handleClickOutside(event) {
            if (this.$refs.searchInput.contains(event.target)) {
                this.isActiveDropDown = true;
                return;
            }

            if (this.$refs.searchInputMobile.contains(event.target)) {
                this.isActiveDropDown = true;
                return;
            }

            this.isActiveDropDown = false;
            return;
        },

        checkDateExpired(date) {
            const currentDate = new Date();
            const itemDate = new Date(date);
            if (itemDate < currentDate) {
                return true;
            }
            return false
        },

        setRandomValidDate() {
            for (let i = 0; i < this.fedxList.length; i++) {
                if (!this.checkDateExpired(this.fedxList[i].time)) {
                    this.selectedFedx = this.fedxList[i];
                    let selected = JSON.stringify(this.selectedFedx);
                    sessionStorage.setItem("fedxcutoff", selected);
                    break;
                }
            }

        },

        setFedxCutofftime() {
            let hasSelectedFedx = (sessionStorage.getItem("fedxcutoff") && sessionStorage.getItem("fedxcutoff") != '') ? JSON.parse(sessionStorage.getItem("fedxcutoff")) : null;
            let hasDefault = this.fedxList.filter(el => (el.default));
            if (hasDefault.length > 0) {
                if (hasSelectedFedx) {
                    if (!this.checkDateExpired(hasSelectedFedx.time)) {
                        this.selectedFedx = hasSelectedFedx;
                    }
                }
                else if (!this.checkDateExpired(hasDefault[0].time)) {
                    this.selectedFedx = hasDefault[0];
                    let selected = JSON.stringify(this.selectedFedx);
                    sessionStorage.setItem("fedxcutoff", selected);
                } else {
                    this.setRandomValidDate();
                }
            } else if (hasSelectedFedx) {
                if (!this.checkDateExpired(hasSelectedFedx.time)) {
                    this.selectedFedx = hasSelectedFedx;
                }
            } else if ((hasDefault.length == 0) && !hasSelectedFedx) {
                this.setRandomValidDate();
            }
        },

        async getFedxCutoffTimesAndCms() {
            try {
                let config = {
                    method: "GET",
                    url: this.$serverURL + this.$api.fedxCutoff.requestUrl,
                };
                await this.$axios(config).then((response) => {
                    if (response.data.statusCode == 200) {
                        let data = response.data.data;
                        this.fedxCms = data.fedexCutoffCMS.length > 0 ? data.fedexCutoffCMS[0] : {};
                        let validDateList = []
                        data.fedexCutoffTime.map((el) => {
                            if (!this.checkDateExpired(el.time)) {
                                el.hide = false;
                                validDateList.push(el);
                            }

                        });
                        this.fedxList = validDateList;
                        if (this.fedxList.length > 0) {
                            this.setFedxCutofftime();
                        } else {
                            this.selectedFedx = "";
                            sessionStorage.setItem("fedxcutoff", "");
                        }
                    }
                }).catch(error => {
                    console.log(error);
                });
            } catch (error) {
                console.log(error);
            }
        },

        handlCutoffSelect() {
            let selected = JSON.stringify(this.selectedFedx);
            sessionStorage.setItem("fedxcutoff", selected);
        }
    }
}