<template>
  <div class="accordion mb-3" id="accordionExample">
    <div class="accordion-item">
      <h2 class="accordion-header" id="headingTwo">
        <button
          class="accordion-button shadow-none collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapseTwo"
          aria-expanded="false"
          aria-controls="collapseTwo"
        >
          <h5 class="mb-0 fw-bold fs-14">
            Order Summery
            <span class="custom-margin">USD &nbsp;&nbsp;&nbsp;&nbsp;${{ calculateGrandTotal() }}</span>
          </h5>
        </button>
      </h2>
      <div
        id="collapseTwo"
        class="accordion-collapse collapse shadow-none"
        aria-labelledby="headingTwo"
        data-bs-parent="#accordionExample"
      >
        <div class="accordion-body">
          <!-- <strong>This is the second item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow. -->
          <div class="box_general summary" style="border:none">
            <ul style="border-bottom: none;">
              <li class="clearfix" v-for="(data, index) in state.cartValue" :key="index">
                <em>{{ data.quantity }}x ({{ data.data.brandName }}) {{ data.data.name }}</em>
                <span>${{ (findMixinActualPrice(data.data) * data.quantity).toFixed(2) }}</span>
              </li>
            </ul>
            <ul>
              <li class="clearfix">
                <em>
                  <strong>Subtotal</strong>
                </em>
                <span>${{ state.totalAmount.toFixed(2) }}</span>
              </li>
              <li class="clearfix" v-if="state.salesTax">
                <em>
                  <strong>
                    Sales Tax ({{
                    taxExemptApproved? 0:
                    parseFloat(state.salesTax?.percentage).toFixed(2)
                    }}%):
                  </strong>
                </em>
                <span>(+)${{ taxExemptApproved? 0: calculateTax() }}</span>
              </li>
              <li class="clearfix" v-if="grandShippingCost && !state.isFreeShippingApplied">
                <em>
                  <strong>Shipping cost</strong>
                </em>
                <span>${{ grandShippingCost }}</span>
              </li>
              <li class="clearfix" v-if="isFreeShippingApplied">
                <em>
                  <strong>Shipping cost</strong>
                </em>
                <span>${{ 0 }}</span>
              </li>
              <li class="clearfix" v-if="state.couponDiscount">
                <em>
                  <strong>Coupon discount</strong>
                </em>
                <span>- ${{ state.couponDiscount }}</span>
              </li>
            </ul>
            <div class="total clearfix">
              TOTAL
              <span>${{ calculateGrandTotal() }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="../js/checkout-summery.js"></script>