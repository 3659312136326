import TermsPolicySchemajson from '../../seoPlugin/TermsPolicySchemajson';
export default {
    components:{
        TermsPolicySchemajson
    },
    data() {
        return {
            cmsData: {},
            siteUrl: this.$serverURL + this.$api.policies.whyChooseUsAPIUrl+'/'+this.$route.params.slug
        }
    },
    async created(){
        await this.getPageTitles();
        document.title = (this.pageTitles?.termsPageTitle ? this.pageTitles?.termsPageTitle : '');
        await this.getPageCms();
    },
    methods: {
        getPageCms: async function () {
            try {
                let config = {
                    method: "GET",
                    url: this.siteUrl,
                }
                await this.$axios(config).then((response) => { 
                    if(response.data.statusCode == 200){
                        this.cmsData = response.data.data;
                        console.log(this.cmsData);
                    }
                }).catch(error => {
                    console.log(error);
                })
            } catch (error) {
                console.log(error);
            }
        }
    }
}