import ContactSchemaJson from '../../seoPlugin/ContactSchemaJson';
import Loading from "vue-loading-overlay";
import { authHeader } from "../../../auth";
import { state } from '@/globalVariables';
import NewsLetter from '../../Newsletter/template/NewsLetter';
import Advertise from '../../Advertise/template/Advertise';
import vueRecaptcha from 'vue3-recaptcha2';
import VueClientRecaptcha from "vue-client-recaptcha";

export default {
    components: {
        Loading,
        NewsLetter,
        Advertise,
        ContactSchemaJson,
        vueRecaptcha,
        VueClientRecaptcha
    },
    data() {
        return {
            state,
            pageCMS: {},
            contentData: [],
            name: '',
            email: '',
            message: '',
            inputValue: null,
            captchaCode: '',
            showRecaptcha: true,
            token: '',
            isSubmit: false,
        }
    },
    async created() {
        await this.getPageTitles();
        document.title = (this.pageTitles?.contactPageTitle ? this.pageTitles?.contactPageTitle : '');
        this.scrollToTop();
    },
    async mounted() {
        await this.getContactData();
        this.loadJSCSS();
    },
    methods: {
        loadJSCSS: function () {
            var styles = [
                '/core/assets/css/contact.css'
            ]
            styles.forEach(style => {
                let tag = document.createElement('link');
                tag.type = "text/css";
                tag.rel = "stylesheet";
                tag.href = style;
                document.head.appendChild(tag);
            });
            var scripts = [
                "/core/assets/js/main.js",
            ];
            scripts.forEach(script => {
                let tag = document.createElement("script");
                tag.setAttribute("src", script);
                document.head.appendChild(tag);

            });
        },
        getContactData: async function () {

            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.contact.contactPage
            };
            await this.$axios(config).then(async (response) => {
                if (response.data.statusCode == 200) {

                    this.pageCMS = await response.data.data.cmsData;
                    this.contentData = await response.data.data.contentData;

                }
            }).catch(error => {
                console.log(error);
            });

        },
        sendMessage: async function () {
            if (!this.name || !this.email || !this.message) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please Enter your name, email and message!"
                });
            }
            else {
                this.token = await this.recaptcha();
                let data = {
                    name: this.name,
                    email: this.email,
                    message: this.message,
                    token: this.token
                };
                let config = {
                    method: "POST",
                    data: data,
                    url: this.$serverURL + this.$api.contact.sendMessage,
                    headers: {
                        "Authorization": authHeader(),
                    }

                };

                
                await this.$axios(config).then(async (response) => {
                    if (response.data.statusCode == 201) {

                        this.$swal.fire({
                            icon: "success",
                            text: "Your message has been send."
                        });
                        this.name = '';
                        this.email = '';
                        this.message = '';
                        this.token = '';
                    }
                    else {

                        this.$swal.fire({
                            icon: "error",
                            text: "Failed! please try again."
                        });
                    }
                }).catch(error => {
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
            }
        },
        getCaptchaCode: function(value) {
            this.captchaCode = value;
        },
        checkValidCaptcha: function(value) {
            if(value == true) {
                this.isSubmit = true;
            }
            else {
                this.isSubmit = false;
            }
        },
        async recaptcha() {
            await this.$recaptchaLoaded()
            const token = await this.$recaptcha('login');
            return token;
        }

    }
}