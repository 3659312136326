<template>
  <main :style="{'background-color': state.componentUi.quotationComponentUI.pageBackgroundColor}">
    <div class="top_banner" v-if="pageCMS">
      <div class="opacity-mask d-flex align-items-center" data-opacity-mask="rgba(0, 0, 0, 0.3)">
        <div class="container">
          <div class="breadcrumbs">
            <ul>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/home')">Home</a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/quote')">Quotation</a>
              </li>
            </ul>
          </div>
          <h1
            :style="{'color': state.componentUi.quotationComponentUI.headerTitleFontColor}"
          >{{pageCMS.title}}</h1>
        </div>
      </div>
      <img :src="pageCMS.image" class="img-fluid" alt />
    </div>
    <!-- /container -->
    <div
      class="sign-up__content"
      :style="{'background-color': state.componentUi.quotationComponentUI.pageBackgroundColor}"
    >
      <header class="sign-up__header" v-if="pageCMS">
        <h2
          class="sign-up__title"
          :style="{'color': state.componentUi.quotationComponentUI.formTitleFontColor, 'font-size':'3em', 'font-weight':'700', 'textTransform':'uppercase'}"
        >{{pageCMS.formTitle}}</h2>
        <p
          class="sign-up__descr"
          :style="{'color': state.componentUi.quotationComponentUI.formSubTitleFontColor}"
        >{{pageCMS.formSubTitle}}</p>
      </header>
      <form class="sign-up__form form">
        <div class="form__row">
          <div class="form-group m-t-30 has-float-label">
              <input name="firstName" type="text" autocomplete="false" class="form-control" id="firstName" placeholder="First Name" v-model="firstName">
              <label for="firstName" style="font-weight:bolder !important;">First Name*</label> 
          </div>
          <div class="form-group m-t-30 has-float-label">
              <input name="lastName" type="text" autocomplete="false" class="form-control" id="lastName" placeholder="Last Name" v-model="lastName">
              <label for="lastName" style="font-weight:bolder !important;">Last Name</label> 
          </div>
          <div class="form-group m-t-30 has-float-label">
              <input name="email" type="text" autocomplete="false" class="form-control" id="email" placeholder="Email" v-model="email">
              <label for="email" style="font-weight:bolder !important;">Email*</label> 
          </div>
          <div class="form-group m-t-30 has-float-label">
              <input name="phone" type="text" autocomplete="false" class="form-control" id="phone" placeholder="Phone" v-model="phone">
              <label for="phone" style="font-weight:bolder !important;">Phone</label> 
          </div>
          <div class="form-group m-t-30 has-float-label">
              <input name="company" type="text" autocomplete="false" class="form-control" id="company" placeholder="Company" v-model="company">
              <label for="company" style="font-weight:bolder !important;">Company</label> 
          </div>
          <div class="form-group m-t-30 has-float-label">
              <textarea
                v-model="notes" id="notes" class="form-control" placeholder="What items would you like a quote for?"></textarea>
              <label for="notes" style="font-weight:bolder !important;">What items would you like a quote for?*</label> 
          </div>
        </div>

        <div class="form__row">
          <div class="sample-captcha">
            <VueClientRecaptcha
              :value="inputValue"
              @getCode="getCaptchaCode"
              @isValid="checkValidCaptcha"
            />
            <input
              type="text"
              class="input__field mt-2"
              v-model="inputValue"
              placeholder="Enter captcha code*"
            />
            <p class="text-success mt-2" v-if="isSubmit">
              <strong>Captcha Verified!</strong>
            </p>
            <p class="mt-2" style="color: red;" v-else>Please verify captcha!</p>
          </div>
        </div>
        <div class="form__row">
          <div class="component component--primary form__button">
            <button
              class="btn btn--regular"
              type="button"
              @click="addQuote"
              :disabled="!isSubmit"
              id="sign-up-button"
              tabindex="0"
            >Submit</button>
          </div>
        </div>
      </form>
    </div>
    <QuoteSchemajson v-if="Object.values(pageTitles).length>0" :metaData="pageTitles" />
  </main>
</template>
<style>
@import url("../css/quote.css");

.vue_client_recaptcha {
  display: flex;
  justify-content: left;
  flex-direction: row;
}

.vue_client_recaptcha_icon {
  text-align: center;
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #eee;
}

.vue_client_recaptcha_icon:hover {
  background-color: #cccccc;
}

.vue_client_recaptcha .captcha_canvas {
  background: #eee;
  padding: 10px 0px;
}
</style>
<script src="../js/quote.js"></script>