import { state } from '@/globalVariables';
import NewsLetter from '../../Newsletter/template/NewsLetter';
import Advertise from '../../Advertise/template/Advertise';
import { authHeader } from '@/auth';
import { states } from '../../../../config/states-list';
import CheckoutSummery from '../template/CheckoutSummery';
import CheckoutSchemajson from '../../seoPlugin/CheckoutSchemajson';

export default {
    components: {
        NewsLetter,
        Advertise,
        CheckoutSchemajson,
        CheckoutSummery
    },
    data() {
        return {
            state,
            states: states,
            getAddressURL: this.$serverURL + this.$api.profile.getShippingBillingAddress,
            billingAddressData: {
                name: null,
                businessName: null,
                address: "",
                mobile: null,
                email: null,
                postCode: null,
                city: null,
                stateCode: null
            },
            shippingSameAsBilling: localStorage.getItem('shippingSameAsBilling') ? JSON.parse(localStorage.getItem('shippingSameAsBilling')) : false,
            inCompleteOrderPage: localStorage.getItem('inCompleteOrderPage') ? JSON.parse(localStorage.getItem('inCompleteOrderPage')) : false,
            token: localStorage.getItem('token'),
            changeAfter: false,

        }
    },
    async created() {
        //await this.getPageTitles();
        this.pageTitles = {
            checkoutPageMetaKeyword:"",
            checkoutPageMetaDescription:""
        };
        document.title = "Checkout Billing Information | HGSHYDRO";
    },

    async mounted() {
        if (this.token) {
            await this.getShippingBillingInformation();
        } else {
            this.setBillingAddressFromLocalStorage();
        }
    },
    methods: {

        getShippingBillingInformation: async function () {
            try {
                let config = {
                    method: "GET",
                    url: this.getAddressURL,
                    headers: {
                        "Authorization": authHeader()
                    }
                }
                await this.$axios(config).then(async (response) => {
                    if (response.data.statusCode == 200) {
                        if (response.data.data.billingAddress) {
                            if (Object.values(response.data.data?.billingAddress).length > 0) {
                                this.billingAddressData = response.data.data.billingAddress;

                            } else {
                                this.setBillingAddressFromLocalStorage();
                            }
                        }
                    }
                    else {
                        this.$swal.fire({
                            icon: "error",
                            text: "Failed! please try again."
                        });
                    }
                }).catch(error => {
                    console.log(error);
                });
            } catch (error) {
                console.log(error);
            }
        },

        setBillingAddressFromLocalStorage() {
            const localStorageBillingAddress = localStorage.getItem('tempBillingAddress') ? JSON.parse(localStorage.getItem('tempBillingAddress')) : null;
            if (Object.keys(localStorageBillingAddress).length > 0) {
                this.billingAddressData = {
                    name: localStorageBillingAddress.name,
                    businessName: localStorageBillingAddress.businessName,
                    address: localStorageBillingAddress.address,
                    mobile: localStorageBillingAddress.mobile,
                    email: localStorageBillingAddress.email,
                    postCode: localStorageBillingAddress.postCode,
                    city: localStorageBillingAddress.city,
                    stateCode: localStorageBillingAddress.stateCode,
                }
            }
        },

        setBillingAddressInput(locationData) {
            let location = locationData.formatted_address;
            let addressComponent = locationData.address_components;
            let locationSplit = location.split(',');
            if (locationSplit[0].trim() != locationData.name) {
                location = locationData.name + ', ' + location;
            }
            this.billingAddressData.address = locationData.name;
            this.billingAddressData.city = "";
            this.billingAddressData.postCode = "";
            this.billingAddressData.stateCode = "";

            for (let i = 0; i < addressComponent.length; i++) {
                if (addressComponent[i].types[0] == "locality") {
                    this.billingAddressData.city = addressComponent[i].long_name;
                } else if (addressComponent[i].types[0] == "postal_code") {
                    this.billingAddressData.postCode = addressComponent[i].long_name;
                } else if (addressComponent[i].types[0] == "administrative_area_level_1") {
                    this.billingAddressData.stateCode = addressComponent[i].short_name;
                }
            }
        },

        showSweetAlert(msg, icon) {
            this.$swal.fire({
                toast: true,
                position: 'top-end',
                text: msg,
                icon: icon,
                showConfirmButton: false,
                timer: 2000,
                animation: false,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            });
        },

        handleShippingSameAsBilling(event) {
            localStorage.setItem('shippingSameAsBilling', event.target.checked);
            if (event.target.checked) {
                localStorage.setItem('tempShippingAddress', JSON.stringify(this.billingAddressData));
            } else {
                localStorage.removeItem('tempShippingAddress');
            }

            if(this.inCompleteOrderPage){
                this.changeAfter = true;
            }
        },

        validateBillingAddress() {
            if (!this.billingAddressData.name) {

                this.showSweetAlert("Billing name required", "info")
                return false;
            }
            else if (!this.billingAddressData.mobile) {

                this.showSweetAlert("Please provide contact no", "info")
                return false;
            }

            else if (this.billingAddressData.mobile.length <= 9) {

                this.showSweetAlert("Mobile length must be atleast 10 digit", "info")
                return false;
            }
            else if (!this.billingAddressData.email) {

                this.showSweetAlert("Please provide email address", "info")
                return false;
            }
            else if (!this.billingAddressData.address) {

                this.showSweetAlert("Billing address required", "info")
                return false;
            }
            else if (!this.billingAddressData.city) {

                this.showSweetAlert("Please enter city name", "info")
                return false;
            }
            else if (!this.billingAddressData.postCode) {

                this.showSweetAlert("Please provide zip code", "info")
                return false;
            }
            else if (!this.billingAddressData.stateCode) {

                this.showSweetAlert("Please provide state code", "info")
                return false;
            }

            return true;
        },


        submitFormAndProceed() {
            if (this.validateBillingAddress()) {
                localStorage.setItem('tempBillingAddress', JSON.stringify(this.billingAddressData));
                if (this.inCompleteOrderPage && this.shippingSameAsBilling) {
                    this.$router.push('/checkout-complete-order');
                } 
                else if(this.inCompleteOrderPage && this.changeAfter){
                    this.$router.push('/checkout-shipping')
                } 
                else if(this.inCompleteOrderPage && !this.changeAfter && !this.shippingSameAsBilling){
                    this.$router.push('/checkout-complete-order');
                }
                else if (this.shippingSameAsBilling) {
                    this.$router.push('/checkout-shipping-methods');
                } 
                else{
                    this.$router.push('/checkout-shipping')
                }
            }
        }


    },

    watch: {

    }
}