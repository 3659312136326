import { state } from '@/globalVariables';
import NewsLetter from '../../Newsletter/template/NewsLetter';
import Advertise from '../../Advertise/template/Advertise';
import { authHeader } from '@/auth';
import { states } from '../../../../config/states-list';
import CheckoutSummery from '../template/CheckoutSummery';
import CheckoutSchemajson from '../../seoPlugin/CheckoutSchemajson';

export default {
    components: {
        NewsLetter,
        Advertise,
        CheckoutSchemajson,
        CheckoutSummery
    },
    data() {
        return {
            state,
            states: states,
            getAddressURL: this.$serverURL + this.$api.profile.getShippingBillingAddress,
            shippingAddressData: {
                name: null,
                businessName: null,
                address: "",
                mobile: null,
                email: null,
                postCode: null,
                city: null,
                stateCode: null
            },
            shippingSameAsBilling: localStorage.getItem('shippingSameAsBilling') ? JSON.parse(localStorage.getItem('shippingSameAsBilling')) : false,
            inCompleteOrderPage: localStorage.getItem('inCompleteOrderPage') ? JSON.parse(localStorage.getItem('inCompleteOrderPage')) : false,
            token: localStorage.getItem('token'),

        }
    },
    async created() {
        //await this.getPageTitles();
        this.pageTitles = {
            checkoutPageMetaKeyword:"",
            checkoutPageMetaDescription:""
        };
        document.title = "Checkout Shipping Information | HGSHYDRO";
    },

    async mounted() {
        if (this.shippingSameAsBilling) {
            this.setShippingAddressAsBillingAddress();
        } else {
            if (this.token) {
                await this.getShippingBillingInformation();
            } else {
                this.setShippingAddressFromLocalStorage();
            }
        }

    },
    methods: {

        getShippingBillingInformation: async function () {
            try {
                let config = {
                    method: "GET",
                    url: this.getAddressURL,
                    headers: {
                        "Authorization": authHeader()
                    }
                }
                await this.$axios(config).then(async (response) => {
                    if (response.data.statusCode == 200) {
                        if (response.data.data.shippingAddress) {
                            if (Object.values(response.data.data?.shippingAddress).length > 0) {
                                this.shippingAddressData = response.data.data.shippingAddress;
                                this.shippingAddressData.address = this.shippingAddressData.addressOne;
                            } else {
                                this.setShippingAddressFromLocalStorage();
                            }
                        }
                    }
                    else {
                        this.$swal.fire({
                            icon: "error",
                            text: "Failed! please try again."
                        });
                    }
                }).catch(error => {
                    console.log(error);
                });
            } catch (error) {
                console.log(error);
            }
        },

        setShippingAddressAsBillingAddress() {
            const localStorageBillingAddress = localStorage.getItem('tempBillingAddress') ? JSON.parse(localStorage.getItem('tempBillingAddress')) : null;
            if (Object.keys(localStorageBillingAddress).length > 0) {
                this.shippingAddressData = {
                    name: localStorageBillingAddress.name,
                    businessName: localStorageBillingAddress.businessName,
                    address: localStorageBillingAddress.address,
                    mobile: localStorageBillingAddress.mobile,
                    email: localStorageBillingAddress.email,
                    postCode: localStorageBillingAddress.postCode,
                    city: localStorageBillingAddress.city,
                    stateCode: localStorageBillingAddress.stateCode,
                }
            }
        },

        setShippingAddressFromLocalStorage() {
            const localStorageShippingAddress = localStorage.getItem('tempShippingAddress') ? JSON.parse(localStorage.getItem('tempShippingAddress')) : {};

            console.log(localStorageShippingAddress);
            if (Object.keys(localStorageShippingAddress).length > 0) {
                this.shippingAddressData = {
                    name: localStorageShippingAddress.name,
                    businessName: localStorageShippingAddress.businessName,
                    address: localStorageShippingAddress.address,
                    mobile: localStorageShippingAddress.mobile,
                    email: localStorageShippingAddress.email,
                    postCode: localStorageShippingAddress.postCode,
                    city: localStorageShippingAddress.city,
                    stateCode: localStorageShippingAddress.stateCode,
                }
            }
        },

        handleShippingSameAsBilling(event) {
            localStorage.setItem('shippingSameAsBilling', event.target.checked);
            if (event.target.checked) {
                this.setShippingAddressAsBillingAddress();
            } else {
                this.shippingAddressData = {
                    name: null,
                    businessName: null,
                    address: "",
                    mobile: null,
                    email: null,
                    postCode: null,
                    city: null,
                    stateCode: null
                }
            }


        },

        setShippingAddressInput(locationData) {
            let location = locationData.formatted_address;
            let addressComponent = locationData.address_components;
            let locationSplit = location.split(',');
            if (locationSplit[0].trim() != locationData.name) {
                location = locationData.name + ', ' + location;
            }
            this.shippingAddressData.address = locationData.name;
            this.shippingAddressData.city = "";
            this.shippingAddressData.postCode = "";
            this.shippingAddressData.stateCode = "";

            for (let i = 0; i < addressComponent.length; i++) {
                if (addressComponent[i].types[0] == "locality") {
                    this.shippingAddressData.city = addressComponent[i].long_name;
                } else if (addressComponent[i].types[0] == "postal_code") {
                    this.shippingAddressData.postCode = addressComponent[i].long_name;
                } else if (addressComponent[i].types[0] == "administrative_area_level_1") {
                    this.shippingAddressData.stateCode = addressComponent[i].short_name;
                }
            }
        },

        showSweetAlert(msg, icon) {
            this.$swal.fire({
                toast: true,
                position: 'top-end',
                text: msg,
                icon: icon,
                showConfirmButton: false,
                timer: 2000,
                animation: false,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            });
        },

        validateShippingAddress() {
            if (!this.shippingAddressData.name) {

                this.showSweetAlert("Shipping name required", "info")
                return false;
            }
            else if (!this.shippingAddressData.mobile) {

                this.showSweetAlert("Please provide contact no", "info")
                return false;
            }

            else if (this.shippingAddressData.mobile.length <= 9) {

                this.showSweetAlert("Mobile length must be atleast 10 digit", "info")
                return false;
            }
            else if (!this.shippingAddressData.email) {

                this.showSweetAlert("Please provide email address", "info")
                return false;
            }
            else if (!this.shippingAddressData.address) {

                this.showSweetAlert("Shipping address required", "info")
                return false;
            }
            else if (!this.shippingAddressData.city) {

                this.showSweetAlert("Please enter city name", "info")
                return false;
            }
            else if (!this.shippingAddressData.postCode) {

                this.showSweetAlert("Please provide zip code", "info")
                return false;
            }
            else if (!this.shippingAddressData.stateCode) {

                this.showSweetAlert("Please provide state code", "info")
                return false;
            }

            return true;
        },


        submitFormAndProceed() {
            if (this.validateShippingAddress()) {
                localStorage.setItem('tempShippingAddress', JSON.stringify(this.shippingAddressData));

                if (this.inCompleteOrderPage) {
                    this.$router.push('/checkout-complete-order');
                } else {
                    this.$router.push('/checkout-shipping-methods');
                }
            }
        }


    },

    watch: {

    }
}