<template>
  <!-- <div
    id="page"
    v-if="rawURLlist.includes($route.path) || $route.path.startsWith('/location-details')"
  >
    <Loader :spin="state.isLoading"></Loader>
    <router-view :key="$route.fullPath"></router-view>
  </div> -->
  <div id="page">
    <Loader :spin="state.isLoading"></Loader>
    <Header />
    <CartComponent />
    <router-view :key="$route.fullPath"></router-view>
    <Footer />
    <ScrollToTopButton />
  </div>
</template>

<script>
import Header from "./components/layouts/template/Header";
import Footer from "./components/layouts/template/Footer";
import CartComponent from "./components/cartComponent/template/CartComponent";
import ScrollToTopButton from "./components/scrollToTopButton/template/ScrollToTopButton";
import { state } from "@/globalVariables";
import { authHeader } from "./auth";
export default {
  components: {
    Header,
    Footer,
    CartComponent,
    ScrollToTopButton
  },
  data() {
    return {
      state,
      token: localStorage.getItem("token"),
      watchLoginCheck: false,
      date: new Date(),
      rawURLlist: ["/location", "/location-details/"]
    };
  },
  async created() {
    var w = window.innerWidth;
    console.log("width", w);
    console.log("this.$route.path =", this.$route.path);
    this.calculateTotalAmount();
    await this.getSiteSettings();
    await this.getComponentUi();

    if (!localStorage.getItem(this.$cartList)) {
      localStorage.setItem(this.$cartList, null);
    }
    if (!localStorage.getItem(this.$wishList)) {
      localStorage.setItem(this.$wishList, null);
    }
    this.token ? await this.checkUserType() : null;
    if (
      this.$route.path == "/account" ||
      this.$route.path == "/reset-password"
    ) {
      if (this.token) {
        this.$router.push("/");
      }
    }

    // setInterval(() => {
    //   let link = document.querySelector("link[rel='canonical']");
    //   link.href = window.location.href;
    //   console.log(window.location.href);
    // }, 1000);

    setTimeout(() => {
      this.state.isLoading = false;
    }, 2000);
  },
  async mounted() {
    await this.getCoupons();
  },
  computed: {
    checkUser() {
      return this.state.token;
    },
    totalCartPrice() {
      return this.state.totalAmount;
    }
  },
  watch: {
    $route() {
      // let link = document.querySelector("link[rel='canonical']");
      // link.href = window.location.href;

      if (
        this.$route.path == "/account" ||
        this.$route.path == "/reset-password"
      ) {
        if (this.token) {
          this.$router.push("/");
        }
      } else {
        if (this.watchLoginCheck == false) {
          let config = {
            method: "POST",
            url: this.$serverURL + this.$api.auth.validateTokenURL,
            headers: {
              Authorization: authHeader()
            }
          };
          this.$axios(config)
            .then(response => {
              if (response.status == 201) {
                if (response.data === false) {
                  this.clearLocalVariables();
                  this.watchLoginCheck = true;
                } else {
                  this.watchLoginCheck = false;
                }
              }
            })
            .catch(error => {
              console.log(error);
            });
        }
      }

      if (this.$route.path == "/checkout-complete-order") {
        const shippingData = localStorage.getItem("shippingData")
          ? JSON.parse(localStorage.getItem("shippingData"))
          : null;
        if (shippingData == null || Object.keys(shippingData).length == 0) {
          this.$router.push("/checkout-shipping-methods");
        }
      }
    },
    checkUser: function() {
      this.checkUserType();
    },

    totalCartPrice: function(newVal, oldVal) {
      newVal;
      oldVal;
      // const shippingData = localStorage.getItem('shippingData') ? JSON.parse(localStorage.getItem('shippingData')) : {};
      if (
        this.state.totalAmount <
        parseInt(this.state.freeShippingCoupon?.discount)
      ) {
        localStorage.setItem("isFreeShipping", false);
        this.state.isFreeShippingApplied = false;
        if (
          this.$route.path == "/checkout-payment" ||
          this.$route.path == "/checkout-complete-order"
        ) {
          this.showSweetAlert(
            "Free shipping coupon removed. Please Select a shipping method",
            "success"
          );
          this.$router.push("/checkout-shipping-methods");
        }
        localStorage.removeItem("shippingData");
      } else if (
        this.state.totalAmount >=
        parseInt(this.state.freeShippingCoupon?.discount)
      ) {
        localStorage.setItem("isFreeShipping", true);
        this.state.isFreeShippingApplied = true;
        const shippingData = {
          shippingType: "Free Shipping",
          serviceType: "Free Service",
          shippingCost: 0,
          serviceTypeName: "Free Service"
        };
        localStorage.setItem("shippingData", JSON.stringify(shippingData));
        this.showSweetAlert("Your order qualified for free shipping.");
      }
    }
  },
  methods: {
    getSiteSettings: async function() {
      try {
        let config = {
          method: "GET",
          url: this.$serverURL + this.$api.site.siteSettings
        };
        await this.$axios(config)
          .then(response => {
            if (response.data.statusCode == 200) {
              this.state.adCMS = response.data.data.advertiseCMS
                ? response.data.data.advertiseCMS
                : {};
              this.state.newsletterCMS = response.data.data.newsletterCMS
                ? response.data.data.newsletterCMS
                : {};
              this.state.popUpSettings = response.data.data.popupCMS
                ? response.data.data.popupCMS
                : {};
              this.state.headerCMS = response.data.data.headerCMS
                ? response.data.data.headerCMS
                : {};
              this.state.footerCMS = response.data.data.footerCMS
                ? response.data.data.footerCMS
                : {};
              this.state.paymentMethods = response.data.data.paymentMethod
                ? response.data.data.paymentMethod
                : [];
              this.state.advertiseList = response.data.data.popupCMS?.advertise
                ? JSON.parse(response.data.data.popupCMS.advertise)
                : [];
              this.state.newsletterList = response.data.data.popupCMS
                ?.newsletter
                ? response.data.data.popupCMS.newsletter
                : [];
              this.state.footerCategories = response.data.data.footerCategories
                ? response.data.data.footerCategories
                : [];
              this.state.socialLinks = response.data.data.siteSocialLinksData
                ? response.data.data.siteSocialLinksData
                : [];
              this.state.locationCms = response.data.data.locationCms[0]
                ? response.data.data.locationCms[0]
                : {};
              //this.state.hearAboutUsOptions = this.state.locationCms ? this.state.locationCms.hearAboutUsOptions.split(",") : [];
              this.state.salesTax.percentage = response.data.data.salesTax
                ? Number(response.data.data.salesTax.salesTax)
                : 0;
              this.state.salesTax.globalTax = response.data.data.salesTax
                ? Number(response.data.data.salesTax.salesTax)
                : 0;
              this.state.salesTax.name = response.data.data.salesTax
                ? response.data.data.salesTax.name
                : "N/A";
              this.state.brandData =
                response.data.data.brandData.length > 0
                  ? response.data.data.brandData
                  : [];
              // console.log("sales tax", this.state.salesTax);

              this.state.popularSearch =
                response.data.data.popularSearch.length > 0
                  ? response.data.data.popularSearch
                  : [];

              this.state.whyChooseUs =
                response.data.data.whyChooseUs.length > 0
                  ? response.data.data.whyChooseUs
                  : [];

              this.state.footerBrandingData =
                response?.data?.data?.footerBrandingData;

              this.state.instagramData = response.data.data.instagramData
                ? response.data.data.instagramData
                : [];
            } else {
              this.$swal.fire({
                icon: "error",
                text: "Failed! Try Again"
              });
              this.$router.push("/account");
            }
          })
          .catch(error => {
            this.$swal.fire({
              icon: "error",
              text: error.response.data.message
            });
            this.$router.push("/account");
          });
      } catch (error) {
        console.log("Error Found");
      }
    },

    async getCoupons() {
      try {
        let config = {
          method: "GET",
          url: this.$serverURL + this.$api.coupon.getCouponsURL
        };
        await this.$axios(config)
          .then(response => {
            this.couponList = response.data.data;
            for (let index = 0; index < this.couponList.length; index++) {
              const cupon = this.couponList[index];
              if (cupon.couponType == "free shipping") {
                this.state.freeShippingCoupon = cupon;
                break;
              }
            }
          })
          .catch(error => {
            console.log(error);
          });
      } catch (error) {
        console.log(error);
      }
    }
  }
};
</script>
<style scoped>
</style>
