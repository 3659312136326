<template>
  <main class="bg_gray">
    <div class="container margin_30">
      <div class="row">
        <siderbar />
        <div class="col-lg-9">
          <div class="card mb-4">
            <header class="card-header">
              <h4 class="card-title">Return Product List</h4>
            </header>
            <div class="card-body" v-if="returnList.length > 0">
              <div class="table-responsive">
                <div class="table-responsive">
                  <table class="table align-middle table-nowrap mb-0">
                    <thead class="table-light">
                      <tr>
                        <th class="align-middle" scope="col">Order Id</th>
                        <th class="align-middle" scope="col">Product Name</th>
                        <th class="align-middle" scope="col">Quantity</th>
                        <th class="align-middle" scope="col">Item Price</th>
                        <th class="align-middle" scope="col">Total Price</th>
                        <th class="align-middle" scope="col">Return Status</th>
                      </tr>
                    </thead>
                    <tbody style="border-top: 0px">
                      <tr v-for="(data, index) in returnList" :key="index">
                        <td><a href="javascript:void(0)" @click="$router.push('/order-details/' + data.orderId)"
                            style="color:black">#{{
                              data.orderId
                            }}</a> </td>
                        <td>{{ data.productName }}</td>
                        <td>{{ data.quantity }}</td>
                        <td>{{ data.actualPrice }}</td>
                        <td>{{ data.totalPrice }}</td>
                        <td>{{ data.status }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <!-- table-responsive end// -->
            </div>
            <div class="card-body" v-else>
              <p class="text-center">No data found.</p>
            </div>
            <nav class="page-navigation justify-content-center d-flex" aria-label="page-navigation">
              <v-pagination v-if="orderList.length > 0" v-model="page" @update:modelValue="updateHandler"
                :pages="totalPage" :range-size="1" active-color="#FF8400"></v-pagination>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<style scoped>
@import url(../css/style.css);
@import url("../../../assets/pagination.css");
</style>
<script src="../js/return-list.js"></script>