const countries = [
  
  {
    "countryNameEn": "United States of America",
    "countryNameLocal": "United States of America",
    "countryCode": "US",
    "currencyCode": "USD",
    "currencyNameEn": "United States dollar",
    "tinType": "EIN",
    "tinName": "Tax Identification Number",
    "officialLanguageCode": "en",
    "officialLanguageNameEn": "English",
    "officialLanguageNameLocal": "English",
    "countryCallingCode": "1",
    "areaCodes": [],
    "region": "North America",
    "flag": "🇺🇸"
  }
  
]

module.exports = { countries };