import { state } from '@/globalVariables';
import NewsLetter from '../../Newsletter/template/NewsLetter';
import Advertise from '../../Advertise/template/Advertise';
import { authHeader } from '@/auth';
import { states } from '../../../../config/states-list';
import CheckoutSummery from '../template/CheckoutSummery';
import CheckoutSchemajson from '../../seoPlugin/CheckoutSchemajson';

export default {
    components: {
        NewsLetter,
        Advertise,
        CheckoutSchemajson,
        CheckoutSummery
    },
    data() {
        return {
            state,
            states: states,
            products: [],
            getAddressURL: this.$serverURL + this.$api.profile.getShippingBillingAddress,
            shippingCountry: "",
            billingCountry: "",
            sameAsBillingAddress: false,
            paymentMethod: "Credit/Debit Card",
            remarks: "",
            shippingType: "",
            serviceTypeList: [],
            pickUpTypeList: [],
            serviceType: "",
            serviceTypeName: "",
            packageType: "",
            pickUpType: "",
            shippingCost: 0,
            orderConfirmed: false,
            showCard: false,
            paymentInfo: {
                name: "",
                cardNumber: "",
                securityCode: ""
            },
            expireDate: "",
            orderId: "",
            disableShipping: true,

            same: false,
            token: localStorage.getItem('token'),
            taxAmount: 0,
            actualProductPrice: 0,
            shippingMethodList: [],
            test: "",
            shippingCmsData: {},
            imagePath: this.$imageURL + 'shipping-methods/',
            userId: localStorage.getItem('id'),
            taxExemptApproved: false,
            grandAmount: 0,
            salesTaxData: "",
            activeShipping: false,
            couponList: [],
            couponCode: "",
            isFreeShipping: localStorage.getItem('isFreeShipping') ? JSON.parse(localStorage.getItem('isFreeShipping')) : false,
            appliedCoupon: {},
            shippingSameAsBilling: localStorage.getItem('shippingSameAsBilling') ? JSON.parse(localStorage.getItem('shippingSameAsBilling')) : false,
            inCompleteOrderPage: localStorage.getItem('inCompleteOrderPage') ? JSON.parse(localStorage.getItem('inCompleteOrderPage')) : false,
            isServiceLoading: false,
        }
    },
    async created() {
        //await this.getPageTitles();
        //document.title = (this.pageTitles?.checkoutPageTitle ? this.pageTitles?.checkoutPageTitle : '');
        await this.shippingCms();
        await this.shippingMethods();

        this.pageTitles = {
            checkoutPageMetaKeyword:"",
            checkoutPageMetaDescription:""
        };
        document.title = "Checkout Shipping Methods Information | HGSHYDRO";

    },

    async mounted() {
        await this.getCoupons();
        const shippingInfo = localStorage.getItem('shippingData') ? JSON.parse(localStorage.getItem('shippingData')) : null;
        if (shippingInfo) {
            this.setSelectedShippingDetails(shippingInfo)
        }
        this.calculateTotalAmount();
        this.calculateGrandTotal();
        this.grandTotal = this.state.totalAmount;

        // if(this.serviceType)
        // {
        //     console.log('this.serviceTypeList = ', this.serviceTypeList)
        // }

        // console.log('this.serviceType = ', this.serviceType);

    },
    computed: {
        totalWeight() {
            let totalWeight = 0;
            for (let i = 0; i < this.state.cartValue.length; i++) {
                console.log("weight", this.state.cartValue[i].data.weight)
                totalWeight = totalWeight + (parseFloat(this.state.cartValue[i].data.weight) * this.state.cartValue[i].quantity);
            }
            return totalWeight;

        },

        totalCartPrice() {
            return this.state.totalAmount;
        }

    },
    methods: {
        setSelectedShippingDetails(param) {
            this.shippingType = param.shippingType;
            this.serviceType = param.serviceType;
            this.shippingCost = param.shippingCost;
            this.handleShipping();
        },

        shippingMethods: async function () {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.shippingMethods.requestURL,
                headers: {
                    "Authorization": authHeader()
                }
            };
            await this.$axios(config).then(async (response) => {
                if (response.data.statusCode == 200) {
                    this.shippingMethodList = response.data.data;
                    if (this.shippingMethodList.length == 0) {
                        this.shippingType = 'ups';
                        await this.handleShipping();
                    }
                }
            }).catch(error => {
                this.state.isLoading = false;
                console.log(error)
            });

        },

        checkFedxShipping: async function () {
            this.serviceTypeList = [];
            let data = {
                shippingAddress: localStorage.getItem('tempShippingAddress') ? JSON.parse(localStorage.getItem('tempShippingAddress')) : null,
                weightValue: this.totalWeight,
            };
            let config = {
                method: "POST",
                url: this.$serverURL + this.$api.order.checkShippingURL,
                data: data,
            };
            this.state.isLoading = true;
            await this.$axios(config).then((response) => {
                this.state.isLoading = false;
                if (response.status == 200 && response.data.statusCode) {
                    if (response.data.statusCode == 400 || response.data.statusCode == 401 || response.data.statusCode == 403 || response.data.statusCode == 404 || response.data.statusCode == 500 || response.data.statusCode == 503) {
                        for (let i = 0; i < response.data.data.errors.length; i++) {
                            this.$swal.fire({
                                icon: "error",
                                text: response.data.data.errors[i].message
                            });
                        }
                    }
                }
                else if (response.status == 200) {
                    this.serviceTypeList = response.data;
                }

            }).catch(error => {
                this.state.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },

        checkUpsShipping: async function () {
            this.serviceTypeList = [];
            let data = {
                shippingAddress: localStorage.getItem('tempShippingAddress') ? JSON.parse(localStorage.getItem('tempShippingAddress')) : null,
                weightValue: this.totalWeight
            };
            let config = {
                method: "POST",
                url: this.$serverURL + this.$api.order.upsCheckShippingURL,
                data: data,
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200 && response.data.statusCode) {
                    if (response.data.statusCode == 400 || response.data.statusCode == 401 || response.data.statusCode == 403 || response.data.statusCode == 404 || response.data.statusCode == 500 || response.data.statusCode == 503) {
                        for (let i = 0; i < response.data.data.response.errors.length; i++) {
                            this.$swal.fire({
                                icon: "error",
                                text: response.data.data.response.errors[i].message
                            });
                        }
                    }
                    this.state.weightExided = true;
                }
                else if (response.status == 200) {
                    this.serviceTypeList = [];
                    this.serviceTypeList = response.data;
                    if (this.state.isFreeShippingApplied) {
                        this.serviceTypeList.push({
                            "serviceName": "Free Service",
                            "serviceType": "Free Service",
                            "price": 0
                        })
                    }
                    this.state.weightExided = false;
                    localStorage.removeItem('weightExided')

                    if(this.shippingType=="ups")
                    {
                        console.log('serviceType =', this.serviceType);
                        console.log('serviceTypeList =', this.serviceTypeList);
                        for (let i = 0; i < this.serviceTypeList.length; i++) {
                            if(this.serviceType == this.serviceTypeList[i].serviceType)
                            {
                                console.log('this.serviceTypeList[i].serviceType =', this.serviceTypeList[i].serviceType);
                                this.setShippingCost(this.serviceTypeList[i].price, this.serviceTypeList[i].serviceName);
                            }
                        }
                    }

                }

            }).catch(error => {
                this.state.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },


        async getCoupons() {
            try {
                let config = {
                    method: "GET",
                    url: this.$serverURL + this.$api.coupon.getCouponsURL,
                    header: {
                        'Content-Type': "application/json"
                    }
                };
                await this.$axios(config).then(response => {
                    this.couponList = response.data.data;
                    for (let index = 0; index < this.couponList.length; index++) {
                        const cupon = this.couponList[index];
                        if (cupon.couponType == "free shipping") {
                            if (this.state.totalAmount >= parseInt(cupon.discount)) {
                                this.isFreeShipping = true;
                                this.appliedCoupon = cupon;
                                this.shippingCost = 0;
                                this.serviceType = "Free Service";
                                this.shippingType = "Free Shipping";
                                this.state.freeShippingCoupon = cupon;
                                this.state.isFreeShippingApplied = true
                                break;
                            } else {
                                this.isFreeShipping = false;
                                this.state.isFreeShippingApplied = false;
                            }
                        }
                    }
                }).catch(error => {
                    console.log(error);
                })
            } catch (error) {
                console.log(error);
            }
        },

        setShippingCost: function (price, serviceName) {
            this.shippingCost = Number(price);
            this.serviceTypeName = serviceName;
        },

        handleShipping: async function () {
            try {
                this.isServiceLoading = true;
                if (this.shippingType == 'fedex') {
                    await this.checkFedxShipping();
                } else if (this.shippingType == 'ups') {
                    await this.checkUpsShipping();
                } else if (this.shippingType == 'local') {
                    await this.shippingMethods();
                }
            } catch (error) {
                console.log(error);
            } finally {
                this.isServiceLoading = false;
            }

        },

        handleShippingOnChange: async function () {
            try {
                this.isServiceLoading = true;
                if (this.shippingType == 'fedex') {
                    await this.checkFedxShipping();
                } else if (this.shippingType == 'ups') {
                    await this.checkUpsShipping();
                } else if (this.shippingType == 'local') {
                    await this.shippingMethods();
                }
                this.serviceType = "";
            } catch (error) {
                console.log(error);
            } finally {
                this.isServiceLoading = false;
            }
        },

        calculateTax() {
            this.taxAmount = (this.actualProductPrice * (this.state.salesTax.percentage / 100));
            return this.taxAmount.toFixed(2);
        },

        calculateGrandTotal() {
            let taxAmount = 0;
            if (this.taxExemptApproved == true) {
                taxAmount = 0
            } else {
                taxAmount = this.taxAmount.toFixed(2);
            }
            // console.log(taxAmount);
            if (state.couponDiscount) {
                this.grandAmount = (this.state.totalAmount + this.shippingCost - this.state.couponDiscount + parseFloat(taxAmount)).toFixed(2);
                return this.grandAmount;
            } else {
                this.grandAmount = (this.state.totalAmount + this.shippingCost + parseFloat(taxAmount)).toFixed(2);
                return this.grandAmount;
            }
        },

        shippingCms: async function () {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.cms.shippingCmsURL,
            };
            await this.$axios(config).then((response) => {
                if (response.data.statusCode == 200) {
                    this.shippingCmsData = response.data.data.length > 0 ? response.data.data[0] : {};
                }
            }).catch(error => {
                console.log(error)
            });
        },

        showSweetAlert(msg, icon) {
            this.$swal.fire({
                toast: true,
                position: 'top-end',
                text: msg,
                icon: icon,
                showConfirmButton: false,
                timer: 2000,
                animation: false,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            });
        },

        Validation: function () {
            if (!this.shippingType) {
                this.showSweetAlert("Shipping type required", "info")
                return false;
            } else if (!this.serviceType) {
                this.showSweetAlert("Service type required", "info")
                return false;
            }
            else {
                return true;
            }
        },

        handleRouteChange() {
            if (this.inCompleteOrderPage) {
                this.$router.push('/checkout-complete-order');
            } else {
                this.$router.push('/checkout-payment');
            }
        },

        saveAndProceed() {
            // this.state.isFreeShippingApplied = this.isFreeShipping;
            // localStorage.setItem('isFreeShipping', this.isFreeShipping);

            if (this.Validation() && !this.state.isFreeShippingApplied) {
                const shippingData = {
                    shippingType: this.shippingType,
                    serviceType: this.serviceType,
                    shippingCost: this.shippingCost,
                    serviceTypeName: this.serviceTypeName,
                }
                localStorage.setItem('shippingData', JSON.stringify(shippingData));
                this.handleRouteChange();

            } else if (this.state.isFreeShippingApplied && this.serviceType == 'Free Service') {
                const shippingData = {
                    shippingType: "Free Shipping",
                    serviceType: "Free Service",
                    shippingCost: 0,
                    serviceTypeName: "Free Service",
                }
                localStorage.setItem('shippingData', JSON.stringify(shippingData));
                this.handleRouteChange();
            } else if(this.Validation() && this.serviceType != 'Free Service'){
                const shippingData = {
                    shippingType: this.shippingType,
                    serviceType: this.serviceType,
                    shippingCost: this.shippingCost,
                    serviceTypeName: this.serviceTypeName,
                }
                localStorage.setItem('shippingData', JSON.stringify(shippingData));
                this.handleRouteChange();
            }

        }
    },

    watch: {

        // shippingType: function () {
        //     if (this.isFreeShipping == false) {
        //         this.orderConfirmed = false;
        //         this.serviceType = '';
        //     }
        //     this.shippingCost = 0;

        // },

        expireDate: function () {
            if (this.expireDate.length == 2) {
                this.expireDate += '/';
            }
        },

        totalCartPrice: async function () {
            if (this.state.isFreeShippingApplied) {
                this.serviceType = 'Free Service'
                this.isServiceLoading = true;
                await this.checkUpsShipping();
                this.isServiceLoading = false;
                const shippingData = localStorage.getItem('shippingData') ? JSON.parse(localStorage.getItem('shippingData')) : null;
                if (shippingData == null || shippingData.shippingType !== 'Free Shipping') {
                    localStorage.removeItem('shippingData');
                }

            } else if (!this.state.isFreeShippingApplied) {
                this.serviceType = "";
                const shippingData = localStorage.getItem('shippingData') ? JSON.parse(localStorage.getItem('shippingData')) : null;
                this.isServiceLoading = true;
                await this.checkUpsShipping();
                this.isServiceLoading = false;
                if (shippingData) {
                    localStorage.removeItem('shippingData');
                }
            }
        }

    }
}