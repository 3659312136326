import { state } from "@/globalVariables";
export default {
    data() {
        return {
            state,
            wishList: (localStorage.getItem(this.$wishList) && localStorage.getItem(this.$wishList) != 'null') ? JSON.parse(localStorage.getItem(this.$wishList)) : [],
        }
    },
    methods: {
        addToWishList(data, quantity) {
            let duplicate = false;
            this.wishList.forEach((el, index) => {
                if (el.data.id == data.id) {
                    // if (el.data.choosedColor == data.choosedColor) {
                    //     if (el.data.choosedSize == data.choosedSize) {
                    duplicate = true
                    this.wishList[index].quantity = this.wishList[index].quantity + quantity;
                    this.state.wishlistCounter = this.state.wishlistCounter + quantity;
                    //     }
                    // }
                }
            });
            if (duplicate == true) {
                localStorage.setItem(this.$wishList, JSON.stringify(this.wishList));
            }
            if (!duplicate) {
                this.wishList.push({ data: data, quantity: quantity });
                this.state.wishlistCounter = this.state.wishlistCounter + quantity;
                this.$swal.fire({
                    toast: true,
                    position: 'top-end',
                    text: "Product added to favourites successfully",
                    icon: "success",
                    showConfirmButton: false,
                    timer: 2000,
                    animation: false,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', this.$swal.stopTimer)
                        toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                    }
                });
                localStorage.setItem(this.$wishList, JSON.stringify(this.wishList));
                return;
            }
        },
        increaseWishListProductQuantity: function (index) {
            this.wishList[index].quantity++;
            this.state.wishlistCounter++;
            localStorage.setItem(this.$wishList, JSON.stringify(this.wishList));
        },
        decreaseWishListProductQuantity: function (index) {
            (this.wishList[index].quantity == 1) ? (this.wishList[index].quantity = 1) : (this.wishList[index].quantity--, this.state.wishlistCounter--);
            localStorage.setItem(this.$wishList, JSON.stringify(this.wishList));
        },
    }
}