<template>
  <teleport to="head">
    <link rel="canonical" :href="product?.productCanonical" />
    <meta name="title" :content="productMetaData?.metaTitle" />
    <meta name="keywords" :content="productMetaData?.metaKeyword" />
    <meta name="description" :content="productMetaData?.metaDescription" />
    <meta name="author" content="HGSHYDRO">
    <meta name="publisher" content="HGSHYDRO">
    <component :is="'script'" type="application/ld+json">
      {
      "@context": "https://schema.org/",
      "@type": "Product",
      "name": "{{ product?.brandName + ' | ' + product?.name }}",
      "image": "{{ 'https://hgshydro.com:5001/productImage/'+product?.featuredImage }}",
      "description": "{{ productShortDetail }}",
      "brand": {
      "@type": "Brand",
      "name": "{{ product?.brandName }}"
      },
      "offers": {
      "@type": "Offer",
      "url": "",
      "priceCurrency": "USD",
      "price": "{{ findMixinActualPrice(product) }}"
      },
      "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "{{ productReview.ratingValue }}",
      "bestRating": "{{ productReview.bestRating }}",
      "worstRating": "{{ productReview.worstRating }}",
      "ratingCount": "{{ productReview.ratingCount }}"
      }
      }
    </component>
  </teleport>
</template>
<script>
export default {
  name: "ProductDetailSchemaJson",
  props: ["prodData", "productMeta","prodReviewData"],
  data() {
    return {
      product: {},
      productReview: [],
      productMetaData: {},
      productShortDetail: null
    };
  },
  methods: {
    cleanTextForSchema(text) {
            // Remove HTML tags
            const strippedText = text.replace(/(<([^>]+)>)/gi, "");
      
            // Remove special characters
            const cleanedText = strippedText.replace(/[^\w\s]/gi, "");
      
            // Trim whitespace
            const trimmedText = cleanedText.trim();
      
            return trimmedText;
    },
  },
  watch: {
    prodData: {
      handler: function(prodData) {
        this.product = prodData;
        this.productShortDetail = this.cleanTextForSchema(prodData?.shortDescription);
      }
    },
    prodReviewData: {
      handler: function(prodReviewData) {
        

        let totalRating = 0;
        let lowestRating = Infinity;
        let ratingCount = 0;
        prodReviewData.forEach((item) => {
          let rating = parseInt(item.rating);
          totalRating += rating;
          ratingCount += 1;
          lowestRating = Math.min(lowestRating, rating);
        });

        let averageRating = totalRating / prodReviewData.length;

        // Format the desired output
        let output = {
          ratingValue: averageRating.toFixed(1),
          bestRating: "5",
          worstRating: lowestRating.toString(),
          ratingCount: ratingCount.toString()
        };


        this.productReview = output;

        console.log('Schema =', output);
      }
    },
    productMeta: {
      handler: function(productMeta) {
        this.productMetaData = productMeta;

      }
    }
  }
};
</script>
