import { state } from '@/globalVariables';
import NewsLetter from '../../Newsletter/template/NewsLetter';
import Advertise from '../../Advertise/template/Advertise';
import AboutUsSchemajson from '../../seoPlugin/AboutUsSchemajson';
export default {
    components: {
        AboutUsSchemajson,
        NewsLetter,
        Advertise
    },
    data() {
        return {
            state,
            cms: {},
            content: [],
            service: [],
            teamMembers: [],
            url: this.$serverURL + this.$api.aboutUs.aboutUsPageURL,
            imageUrl: this.$imageURL + "about/"
        }
    },

    async created() {
        await this.getPageTitles();
        document.title = (this.pageTitles?.aboutPageTitle ? this.pageTitles?.aboutPageTitle : '');
        this.scrollToTop();
    },

    async mounted() {
        await this.getData();
        var styles = [
            '/core/assets/css/about.css'
        ]
        styles.forEach(style => {
            let tag = document.createElement('link');
            tag.type = "text/css";
            tag.rel = "stylesheet";
            tag.href = style;
            document.head.appendChild(tag);
        });
        var scripts = [
            "/core/assets/js/main.js",
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
    },
    methods: {
        getData: async function () {
            try {
                let config = {
                    method: "GET",
                    url: this.url,
                    headers: {
                        "Content-Type": "application/json"
                    }
                };
                await this.$axios(config).then(async (response) => {
                    this.cms = await response.data.data.cmsData;
                    this.content = await response.data.data.contentData;
                    this.service = await response.data.data.serviceData;
                    this.teamMembers = await response.data.data.teamMemberData;
                }).catch((error) => {
                    console.log(error);
                })
            } catch (error) {
                console.log(error);
            }
        }
    }
}