import siderbar from '../template/SideBar'
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import { authHeader } from '@/auth';
export default {
    components: {
        siderbar,
        VPagination
    },
    data() {
        return {
            page: 1,
            limit: 6,
            totalPage: 0,
            returnList: [],
        }
    },
    async mounted() {
        await this.getPageTitles();
        document.title = (this.pageTitles?.profilePageTitle ? this.pageTitles?.profilePageTitle : '');
        let params = "?page=" + this.page + "&limit=" + this.limit;
        await this.getReturnList(params);
    },
    methods: {
        getReturnList: async function (params) {
            try {
                let config = {
                    method: "GET",
                    url: this.$serverURL + this.$api.return.listURL + params,
                    headers: {
                        "Authorization": authHeader()
                    }
                };
                await this.$axios(config).then((response) => {
                    if (response.status == 200) {
                        this.returnList = response.data.data;
                        console.log(this.returnList)
                    }
                    else {
                        this.$swal.fire({
                            icon: "error",
                            text: "No Order found with this order id "
                        });
                    }
                }).catch(error => {
                    console.log(error);
                });
            } catch (error) {
                console.log(error);
            }
        },
        updateHandler: async function () {
            let params = "?page=" + this.page + "&limit=" + this.limit;
            await this.getReturnList(params);
            this.totalPage = this.pageCount;
        },
    },

}