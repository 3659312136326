import { state } from './globalVariables';

export default {
    data() {
        return {
            state
        }
    },
    methods: {
        addToCart: function (data, quantity, isAutoClose = false) {

            const modal = document.querySelector("#offcanvasRight");
            // const button = document.querySelector('.cart_bt');
            const button = document.querySelector("#cart-icon-a-button");

            if (!modal.classList.contains("show")) {
                button.click();
            }

            let duplicate = false;
            this.state.cartValue.forEach((el, index) => {
                if (el.data.id == data.id) {
                    // if (el.data.choosedColor == data.choosedColor) {
                    //     if (el.data.choosedSize == data.choosedSize) {
                    this.state.cartValue[index].quantity = this.state.cartValue[index].quantity + quantity;
                    this.state.cartCounter = this.state.cartCounter + quantity;
                    duplicate = true;
                    //     }
                    // }
                }
            });
            if (duplicate == true) {
                localStorage.setItem(this.$cartList, JSON.stringify(this.state.cartValue));
            }

            if (!duplicate) {
                this.state.cartValue.push({ data: data, quantity: quantity });
                this.state.cartCounter = this.state.cartCounter + quantity;
                this.$swal.fire({
                    toast: true,
                    position: 'top-end',
                    text: "Product added to cart successfully",
                    icon: "success",
                    showConfirmButton: false,
                    timer: 2000,
                    animation: false,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', this.$swal.stopTimer)
                        toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                    }
                });
                localStorage.setItem(this.$cartList, JSON.stringify(this.state.cartValue));
            }

            this.calculateTotalAmount();

            if(isAutoClose) {
                setTimeout(() => {
                    document.getElementById("cartCloseButton").click();
                }, 3000);
            }
        },

        updateToCartForAccessories: function (data, quantity) {
            let duplicate = false;
            this.state.cartValue.forEach((el, index) => {
                if (el.data.id == data.id) {
                    this.state.cartValue.splice(index, 1);
                    this.state.cartCounter = this.state.cartCounter - quantity;
                    duplicate = true;

                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: "Product remove from cart successfully",
                        icon: "success",
                        showConfirmButton: false,
                        timer: 1000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                }
            });

            if (duplicate == true) {
                localStorage.setItem(this.$cartList, JSON.stringify(this.state.cartValue));
            }

            if (!duplicate) {
                this.state.cartValue.push({ data: data, quantity: quantity });
                this.state.cartCounter = this.state.cartCounter + quantity;
                this.$swal.fire({
                    toast: true,
                    position: 'top-end',
                    text: "Product added to cart successfully",
                    icon: "success",
                    showConfirmButton: false,
                    timer: 1000,
                    animation: false,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', this.$swal.stopTimer)
                        toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                    }
                });
                localStorage.setItem(this.$cartList, JSON.stringify(this.state.cartValue));
            }

            this.calculateTotalAmount();
        },

        isPresentInCart: function (data) {
            return this.state.cartValue.find(el => el.data.id == data.id) ? true : false;
        },

        calculateTotalAmount: function () {
            this.state.totalAmount = 0;
            if (this.state.cartValue) {
                this.state.cartValue.forEach((el) => {
                    this.state.totalAmount = this.state.totalAmount + this.findMixinActualPrice(el.data) * el.quantity;
                });
                return this.state.totalAmount;
            } else {
                return this.state.totalAmount;
            }
        },

        findMixinActualPrice: function (data) {
            //console.log('Actual OBject = ', data);
            // console.log('Discounted price = ', data.discountedPrice);
            // console.log('Product wise user discounted price = ', data.userDiscountedPrice);
            let userSpecialDiscountPrice = 0;
            let userDiscountType = localStorage.getItem("discountType");
            let userDiscountAmount = localStorage.getItem("discountAmount");
            if (userDiscountType == "Amount") {
                userSpecialDiscountPrice = data.price - userDiscountAmount;
            }
            else if (userDiscountType == "Percentage") {
                if (userDiscountAmount != 0) {
                    userSpecialDiscountPrice = (data.price * userDiscountAmount) / 100;
                    userSpecialDiscountPrice = (data.price - userSpecialDiscountPrice);
                }
                else {
                    userSpecialDiscountPrice = data.price;
                }
            }
            // console.log('User discount final price = ', userSpecialDiscountPrice);
            let returnPrice = 0;
            if (data.discountedPrice > 0 && data.price > data.discountedPrice) {
                if (parseFloat(data.userDiscountedPrice) > 0) {
                    if (parseFloat(data.userDiscountedPrice) > data.discountedPrice && parseFloat(userSpecialDiscountPrice) > data.discountedPrice) {
                        returnPrice = data.discountedPrice;
                    }
                    else if (data.discountedPrice > parseFloat(data.userDiscountedPrice) && parseFloat(userSpecialDiscountPrice) > parseFloat(data.userDiscountedPrice)) {
                        returnPrice = parseFloat(data.userDiscountedPrice);
                    }
                    else if (data.discountedPrice > parseFloat(userSpecialDiscountPrice) && parseFloat(data.userDiscountedPrice) > parseFloat(userSpecialDiscountPrice)) {
                        returnPrice = parseFloat(userSpecialDiscountPrice);
                    }
                }
                else if (data.userDiscountedPrice == null || data.userDiscountedPrice == '') {
                    if (parseFloat(userSpecialDiscountPrice) > data.discountedPrice) {
                        returnPrice = data.discountedPrice;
                    }
                    else if (data.discountedPrice > parseFloat(userSpecialDiscountPrice) && parseFloat(userSpecialDiscountPrice) > 0) {
                        returnPrice = parseFloat(userSpecialDiscountPrice);
                    } else if (parseFloat(userSpecialDiscountPrice) == 0) {
                        returnPrice = data.discountedPrice;
                    }
                }
            }
            else if (data.discountedPrice == 0) {
                if (parseFloat(data.userDiscountedPrice) > 0) {
                    if (parseFloat(userSpecialDiscountPrice) > parseFloat(data.userDiscountedPrice)) {
                        returnPrice = parseFloat(data.userDiscountedPrice);
                    }
                    else if (parseFloat(data.userDiscountedPrice) > parseFloat(userSpecialDiscountPrice)) {
                        returnPrice = parseFloat(userSpecialDiscountPrice);
                    }
                }
                else if (data.userDiscountedPrice == null || data.userDiscountedPrice == '') {
                    if(parseFloat(userSpecialDiscountPrice) == 0){
                        returnPrice = parseFloat(data.price);
                    }else{
                        returnPrice = parseFloat(userSpecialDiscountPrice);
                    }
                }
            }
            else {
                returnPrice = data.price;
            }
            // console.log('Return price = ', returnPrice);
            // console.log('####################');
            return parseFloat(returnPrice).toFixed(2);
        },

        increaseProductQuantity: function (index) {
            this.state.cartValue[index].quantity++;
            this.state.cartCounter++;
            localStorage.setItem(this.$cartList, JSON.stringify(this.state.cartValue));
            this.calculateTotalAmount();
        },

        decreaseProductQuantity: function (index) {
            (this.state.cartValue[index].quantity == 1) ? (this.state.cartValue[index].quantity = 1) : (this.state.cartValue[index].quantity--, this.state.cartCounter--);
            localStorage.setItem(this.$cartList, JSON.stringify(this.state.cartValue));
            this.calculateTotalAmount();
        },

        removeFromCart(index) {
            this.state.cartCounter = this.state.cartCounter - this.state.cartValue[index].quantity;
            this.state.cartValue.splice(index, 1);
            localStorage.setItem(this.$cartList, JSON.stringify(this.state.cartValue));
            this.calculateTotalAmount();
        },

    },
}