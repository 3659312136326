<template>
    <main class="bg_gray">
    <div class="container margin_30">
        <!-- /top_banner -->
      <div class="page_header">
          <div class="breadcrumbs">
            <ul>
              <li><a href="javascript:void(0)" @click="$router.push('/home')">Home</a></li>
              <li><a href="javascript:void(0)" @click="$router.push('/profile')">My Account</a></li>
              <li><a href="#">Change Password</a></li>
            </ul>
        </div>
        <h1>Change Password</h1>
      </div>
      <!-- /toolbox -->
      <div class="row">
        <siderbar/>
        <!-- /col -->
        <div class="col-lg-9">
          <div class="row justify-content-center">
                <div class="col-xl-6 col-lg-6 col-md-8">
                    <div class="box_account">
                        <h3 class="client">Change Your Password</h3>
                        <div class="form_container mt-3">
                            <div class="form-group" >
                                <input type="password" class="form-control" v-model="old_password"  name="password" id="password" placeholder="Old Password*">
                            </div>
                            <div class="form-group" >
                                <input type="password" class="form-control" v-model="password"  name="password" id="password" placeholder="New Password*">
                            </div>
                            <div class="form-group" >
                                <input type="password" class="form-control" v-model="confirm_password"  name="newpassword" id="newpassword" placeholder="Confirm Password*">
                            </div>
                            <div class="text-center" @click="updatePassword" ><input type="submit" value="Submit" class="btn_1 full-width"></div>
                        </div>
                        <!-- /form_container -->
                    </div>
                    <!-- /box_account -->

                    <!-- /row -->
                </div>
                
            </div>
        </div>
        <!-- /col -->
      </div>
      <!-- /row -->
    </div>
    <!-- /container -->
  </main>
</template>
<script src="../js/change-password.js"></script>