<template>
  <footer>
    <div class="container">
      <div class="row">
        <div class="col-sm-9">
          <div class="row">
            <div class="col-lg-3 col-md-6">
              <h3 data-bs-target="#collapse_1">
                {{ state.footerCMS.menuOneTitle }}
              </h3>
              <div class="collapse dont-collapse-sm links" id="collapse_1">
                <ul>
                  <li>
                    <a :href="'/about-us'"
                      >About us</a
                    >
                  </li>
                  <li>
                    <a :href="'/help'"
                      >Help</a
                    >
                  </li>
                  <li>
                    <a :href="'/profile'"
                      >My account</a
                    >
                  </li>
                  <li>
                    <a :href="'/blog'"
                      >Blog</a
                    >
                  </li>
                  <li>
                    <a :href="'/contact'"
                      >Contact</a
                    >
                  </li>
                  <li>
                    <a :href="'/career'"
                      >Career</a
                    >
                  </li>
                </ul>
              </div>

              <h3 data-bs-target="#collapse_2">
                  {{ state.footerCMS.menuTwoTitle }}
                </h3>
                <div class="collapse dont-collapse-sm links" id="collapse_2">
                  <ul>
                    <li v-for="(data, index) in state.footerCategories" :key="index">
                      <a :href="'/category/' + data.categorySlug">
                        {{ data.name }}
                      </a>
                    </li>
                  </ul>
                </div>
            </div>
              <!-- POPULAR SEARCHES -->
              <div class="col-lg-4 col-md-6">
                <h3 data-bs-target="#collapse_2">
                  Popular Searches
                </h3>
                <div class="collapse dont-collapse-sm links" id="collapse_5">
                  <ul>
                    <li v-for="(data, index) in state.popularSearch" :key="index">
                      <a :href="data.searchLink">
                        {{ data.searchTitle }}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-lg-4 col-md-6">
                <h3 data-bs-target="#collapse_2">
                  why choose us
                </h3>
                <div class="collapse dont-collapse-sm links" id="collapse_5">
                  <ul>
                    <li v-for="(data, index) in state.whyChooseUs" :key="index">
                      <a :href="'/why-choose-us/' + data.urlSlug">
                        {{ data.title }}
                      </a>
                    </li>
                  </ul>
                </div>

                <h3 data-bs-target="#collapse_3">
                  {{ state.footerCMS.menuThreeTitle }}
                </h3>
                <div class="collapse dont-collapse-sm contacts" id="collapse_3">
                  <ul>
                    <li>
                      <i :class="state.footerCMS.contactAddressIcon"></i
                      >{{ state.footerCMS.contactAddress }}
                    </li>
                    <li>
                      <i :class="state.footerCMS.contactNumberIcon"></i
                      ><a :href="'tel:'+state.footerCMS.contactNumber">{{ state.footerCMS.contactNumber }}</a>
                    </li>
                    <li>
                      <i :class="state.footerCMS.contactEmailIcon"></i
                      ><a :href="'mailto:'+state.footerCMS.contactEmail">{{ state.footerCMS.contactEmail }}</a>
                    </li>
                  </ul>
                </div>
              </div>
            
             
             
          </div>
        </div>
        <div class="col-sm-3">
          <div class="row">
            <div class="col-lg-12 col-md-6">
             
              <div class="footer_card">
                <div class="footer_card_content text-center" :style="{background:state?.footerBrandingData?.backgroundColor}">
                  <div class="mb-3 text-center">
                    <a :href="state?.footerBrandingData?.topLogoLink">
                      <img :src="state?.footerBrandingData?.topLogo" 
                      :alt="'HgsHydro Logo'"
                      :title="'HgsHydro Logo'"
                      />
                    </a>
                  </div>
                  <ul class="list-unstyled">
                    <li class="d-flex gap-2 align-items-stretch justify-content-center">
                      <span class="footer_card-contact-icon">
                        <!-- <i class="ti-headphone-alt"></i> -->
                        <img :src="state?.footerBrandingData?.contactIconImage" height="20"
                        alt="hgshydro contact icon"
                        title="hgshydro contact icon"
                         />
                      </span>
                      <p class="footer_card-contact-text mb-0"  :style="{color:state?.footerBrandingData?.boxFontColor+' !important'}">
                        {{state?.footerBrandingData?.contactText}} <br>
                        <a class="text-decoration-underline"  :style="{color:state?.footerBrandingData?.boxFontColor+' !important'}" :href="'tel:'+state?.footerBrandingData?.contactNumber">{{state?.footerBrandingData?.contactNumber}}</a>
                      </p>
                    </li>
                  </ul>
                  <p  :style="{color:state?.footerBrandingData?.boxFontColor+' !important'}">{{state?.footerBrandingData?.socialConnectTitle}}:</p>
                  <div class="d-flex gap-4 justify-content-center mb-4">
                    
                    <a v-if="state?.footerBrandingData?.facebookStatus==1" :href="state?.footerBrandingData?.facebookLink" target="_blank" class="social-icon text-success">
                      <i class="ti-facebook"></i>
                    </a>

                    <a v-if="state?.footerBrandingData?.instagramStatus==1" :href="state?.footerBrandingData?.instagramLink" target="_blank" class="social-icon text-success">
                      <i class="ti-instagram"></i>
                    </a>

                    <a v-if="state?.footerBrandingData?.twitterStatus==1" :href="state?.footerBrandingData?.twitterLink" target="_blank" class="social-icon text-success">
                      <i class="ti-twitter-alt"></i>
                    </a>

                    <a v-if="state?.footerBrandingData?.youtubeStatus==1" :href="state?.footerBrandingData?.youTubeLink" target="_blank" class="social-icon text-success">
                      <i class="ti-youtube"></i>
                    </a>

                  </div>
                  <p  :style="{color:state?.footerBrandingData?.boxFontColor+' !important'}">{{ state?.footerBrandingData?.paymentTitle }}:</p>
                  <div class="d-flex justify-content-center mb-3">
                    <img class="w-75" :src="state?.footerBrandingData?.paymentImage" 
                    alt="hgshydro payment methods"
                    title="hgshydro payment methods"
                    />
                  </div>
                  <div class="text-center">
                    <a :href="state?.footerBrandingData?.bottomLogoLink">
                      <img :src="state?.footerBrandingData?.bottomLogo" 
                      alt="hgshydro site logo"
                      title="hgshydro site logo"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /row-->
      <hr />
      <div class="row add_bottom_25">
        <div class="col-lg-4">
          <ul v-if="state.footerCMS.length > 0" class="footer-selector clearfix">
            <li>
              <img
                :src="imagePath + state.footerCMS.paymentImageOne"
                :data-src="imagePath + state.footerCMS.paymentImageOne"
                alt=""
                width="50"
                height="30"
                class="lazy"
              />
            </li>
            <li>
              <img
                :src="imagePath + state.footerCMS.paymentImageTwo"
                :data-src="imagePath + state.footerCMS.paymentImageTwo"
                alt=""
                width="50"
                height="30"
                class="lazy"
              />
            </li>
            <li>
              <img
                :src="imagePath + state.footerCMS.paymentImageThree"
                :data-src="imagePath + state.footerCMS.paymentImageThree"
                alt=""
                width="50"
                height="30"
                class="lazy"
              />
            </li>
            <li>
              <img
                :src="imagePath + state.footerCMS.paymentImageFour"
                :data-src="imagePath + state.footerCMS.paymentImageFour"
                alt=""
                width="50"
                height="30"
                class="lazy"
              />
            </li>
          </ul>
        </div>
        <div class="col-lg-8">
          <ul class="additional_links">
            <li>
              <a
                :href="'/terms-conditions'" 
                title="Terms & Condition"
                >Terms & Condition</a
              >
            </li>
            <li>
              <a
                :href="'/privacy-policy'"
                title="Privacy Policy"
                >Privacy Policy</a
              >
            </li>
            <li>
              <a
                :href="'/return-policy'"
                title="Return Policy"
                >Return Policy</a
              >
            </li>
            <li>
              <a
                :href="'/cacellation-policy'"
                title="Cancellation Policy"
                >Cancellation Policy</a
              >
            </li>
          </ul>
        </div>
      </div>
      <div class="row add_bottom_25">
        <div class="col-lg-4"></div>
        <div class="col-lg-4">
          <ul class="additional_links">
            <li>
              <span class="powered-by"
                >Built by
                <a href="https://neutrix.co" title="Built by Neutrix" target="_blank">Neutrix</a> -
                Powered by
                <a href="https://neutrix.co" title="Neutrix Systems" target="_blank"
                  >Neutrix Systems</a
                ></span
              >
            </li>
          </ul>
        </div>
        <div class="col-lg-4"></div>
      </div>
    </div>
  </footer>
</template>
<script src="../js/footer.js"></script>
<style scoped>
.footer_card{
  position: relative;
  width: 100%;
  min-height: 400px;
  background: #e0dddd;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.footer_card:after{
  content: '';
  position: absolute;
  width: 500px;
  height: 500px;
  background-image: conic-gradient(transparent,transparent,transparent,#34a50a);
  animation: animate 4s linear infinite;
  animation-delay: -2s;

}
.footer_card:before{
  content: '';
  position: absolute;
  width: 500px;
  height: 500px;
  background-image: conic-gradient(transparent,transparent,transparent,#00ccff);
  animation: animate 4s linear infinite;

}
.footer_card_content{
  content: '';
  position: absolute;
  inset: 3px;
  background: #0A0B2E;
  z-index: 1;
  padding: 1rem;
}
@keyframes animate {
  0%{
    transform: rotate(0deg);
  }100%{
       transform: rotate(360deg)
     }
}
.footer_card-contact-icon{
  width: 30px;
  height: 30px;
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer_card-contact-text, .footer_card-contact-text a{
  color: #ffffff!important;
}
.social-icon{
  width: 30px;
  height: 30px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.social-icon:before{
  width: 30px;
  height: 30px;
  position: absolute;
  content: '';
  transform: rotate(45deg);
  background: #ffffff;
  z-index: -1;
}
</style>
