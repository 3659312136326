import { state } from "@/globalVariables";
export default {
    data() {
        return {
            state,
            data: [],
            url: this.$route.params.url,
            name: '',
            email: null,
            phone: null,
            comments: '',
            media: '',
            imageURL: this.$imageURL + 'location-cms/'
        }
    },
    async created() {
        await this.getPageTitles();
        document.title = (this.pageTitles?.locationPageTitle ? this.pageTitles?.locationPageTitle : '');
        this.scrollToTop();
    },
    async mounted() {
        await this.getLocationData();

    },
    methods: {
        checkIntoData(option) {
            console.log(option);
            localStorage.setItem('intopage', option);
            this.$router.push("/home");
            return option;
        },
        getLocationData: async function () {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.location.requestURL + '/url-slug/' + this.url
            };
            await this.$axios(config).then(async (response) => {
                if (response.data.statusCode == 200) {
                    this.data = await response.data.data[0];
                }
            }).catch(error => {
                console.log(error);
            });
        },
        sendMessage: async function () {
            if (!this.name || !this.email || !this.comments || !this.phone) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please Enter your name, email, phone and message!"
                });
            }
            else {
                let data = {
                    name: this.name,
                    email: this.email,
                    comments: this.comments,
                    phone: this.phone,
                    media: this.media,
                    location: this.data.location,
                };
                let config = {
                    method: "POST",
                    data: data,
                    url: this.$serverURL + this.$api.enquiry.requestURL
                };
                await this.$axios(config).then(async (response) => {
                    if (response.data.statusCode == 201) {
                        this.$swal.fire({
                            icon: "success",
                            text: "Your message has been send."
                        });
                        this.name = '';
                        this.email = '';
                        this.comments = '';
                        this.phone = '';
                        this.media = '';
                    }
                }).catch(error => {
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
            }

        },
    }
}