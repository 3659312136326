<template>
  <main class="bg_gray">
    <div class="container margin_30">
      <div class="page_header">
        <div class="breadcrumbs">
          <ul>
            <li>
              <a href="javascript:void(0)" @click="$router.push('/home')">Home</a>
            </li>
            <li>
              <a href="javascript:void(0)" @click="$router.push('/profile')"
                >My Account</a
              >
            </li>
            <li><a href="#">Tax Exempt</a></li>
            <!-- <li>Tax Exempt</li> -->
          </ul>
        </div>
        <h1>Tax Exempt Form</h1>
      </div>
      <div class="row">
        <siderbar />
        <div class="col-lg-9">
          <div id="track_order">
            <div class="container">
              <div class="row justify-content-center text-center">
                <div class="col-xl-7 col-lg-9">
                  <p>Upload Tax Exempt Form</p>
                  <form>
                    <div class="search_bar">
                      <div class="input">
                        <div class="input__container">
                          <input
                            class="form-control"
                            type="file"
                            @change="uploadTaxFile"
                            ref="addTaxFile"
                            id="formFile"
                          />
                        </div>
                      </div>
                      <div class="form__row">
                        <div class="component component--primary form__button">
                          <button
                            class="btn btn--regular mt-3"
                            type="button"
                            @click="upload"
                            id="sign-up-button"
                            tabindex="0"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script src="../js/tax-exempt.js"></script>
