<template>
    <teleport to="head">
      <link rel="canonical" :href="$route.path" />
      
      <meta name="title" :content="blogMetaData.title" />
      <meta name="keywords" :content="blogMetaData.metaKeyword" />
      <meta name="description" :content="blogMetaData.metaDescription" />
      <meta name="author" content="HGSHYDRO">
      <meta name="publisher" content="HGSHYDRO">
      <component :is="'script'" type="application/ld+json">
        {
        "@context": "https://schema.org/",
        "@type": "WebSite",
        "name": "HGS Hydro",
        "url": "https://hgshydro.com/",
        "potentialAction": {
        "@type": "SearchAction",
        "target": "https://hgshydro.com/products?keyword={search_term_string}",
        "query-input": "required name=search_term_string"
        }
        }
      </component>
      <component :is="'script'" type="application/ld+json">
        {
        "@context": "https://schema.org/",
        "@type": "WebSite",
        "name": "HGS Hydro",
        "url": "https://hgshydro.com/",
        "potentialAction": {
        "@type": "SearchAction",
        "target": "https://hgshydro.com/products?keyword={search_term_string}",
        "query-input": "required name=search_term_string"
        }
        }
      </component>
    </teleport>
  </template>
  
  <script>
  export default {
    name: "CategorySchemajson ",
    props: ["metaData"],
    data() {
      return {
        blogMetaData: {}
      };
    },
    created() {
      console.log("in Blog seo", this.metaData);
    },
    watch: {
        metaData: {
        immediate: true,
        handler(val, oldVal) {
          oldVal;
          this.blogMetaData = val;
        }
      }
    }
  };
  </script>