<template>
    <main class="bg_gray">
        <div class="popup_wrapper">
            <div class="popup_content">
                <span class="popup_close" @click="setIsAdvertise">Close</span>
                <a href="javascript:void(0)" @click="$router.push(state.adCMS.buttonLink)"><img class="img-fluid"
                        :src="imagePath + state.adCMS.image" alt="" width="500" height="500"></a>
            </div>
        </div>
    </main>
</template>
<script src="../js/advertise.js"></script>