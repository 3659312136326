<template>
  <main :style="{'background-color': '#EBF0F5'}">
    
    <!-- /container -->
    <div
      class="sign-up__content"
      :style="{'background-color': '#EBF0F5'}"
    >
      <header class="sign-up__header" >
        <div class="cards success-page">
        <div style="border-radius:200px; height:200px; width:200px; background: #F8FAF5; margin:0 auto;">
          <i class="checkmark">✓</i>
        </div>
          <h1>Thank You</h1> 
          <p>Your request has been submitted;<br/> we'll be in touch shortly!</p>
          <div class="buttons m-2"><a style="background-color: #88B04B !important;" @click="$router.push('/home')" class="btn_1">Back To Home Page</a></div>
        </div>
      </header>
    </div>
  </main>
</template>
<style>
@import url("../css/success.css");
</style>
<script src="../js/success.js"></script>