<template>
    <teleport to="head">
        <link rel="canonical" :href="$route.path" />
        <meta name="keywords" :content="metaKeyword">
        <meta name="description" :content="metaDescription">
        <meta name="author" content="HGSHYDRO">
        <meta name="publisher" content="HGSHYDRO">
        <component :is="'script'" type="application/ld+json">
            {
                "@context": "https://schema.org/",
                "@type": "WebSite",
                "name": "HGS Hydro",
                "url": "https://hgshydro.com/",
                "potentialAction": {
                    "@type": "SearchAction",
                    "target": "https://hgshydro.com/products?keyword={search_term_string}",
                    "query-input": "required name=search_term_string"
                }
            }
        </component>
    </teleport>
</template>

<script>
export default {
    name: 'HomeSchemaJson',
    props: ['metaKeyword', 'metaDescription'],
    data() {
        return {
           homeMetaKeyword: "",
           homeMetaDescription: ""
        }
    },
    watch:{
        metaKeyword: {
            handler: function(metaKeyword) {
                this.homeMetaKeyword = metaKeyword;
            }
        },
        metaDescription: {
            handler: function(metaDescription) {
                this.homeMetaDescription = metaDescription;
            }
        }
    }
}   
</script>
