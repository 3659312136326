
import siderbar from '../template/SideBar';
import { authHeader } from '@/auth';
import { state } from '@/globalVariables';
import html2pdf from 'html2pdf.js'
export default {
    components: {
        siderbar
    },
    data() {
        return {
            state,
            orderDetails: {},
            orders: [],
            orderId: this.$route.params.id,
            email: localStorage.getItem('email'),
            mobile: localStorage.getItem('mobile'),
            customerInfo: [],
            imagePath: this.$imageURL + 'productImage/',
            selectAll: "",
            selectedReturnProducts: [],
            returnProductList: [],
            returnReason: "",
            filteredRetrunList: [],
            hasNote: false,

        }
    },
    async mounted() {
        await this.getPageTitles();
        document.title = (this.pageTitles?.profilePageTitle ? this.pageTitles?.profilePageTitle : '');
        await this.getOrderDetails();
    },
    methods: {
        getOrderDetails: async function () {
            try {
                let config = {
                    method: "GET",
                    url: this.$serverURL + this.$api.order.orderURL + '/' + this.orderId,
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": authHeader()
                    }
                };
                this.state.isLoading = true;
                await this.$axios(config).then((response) => {
                    this.state.isLoading = false;
                    if (response.status == 200) {
                        this.orders = [];
                        this.filteredRetrunList = [];
                        this.orderDetails = response.data.Orders[0];
                        this.customerInfo = JSON.parse(response.data.Orders[0].shippingAddress);
                        this.orders = response.data.Orders[0].orders;
                        this.orders.forEach(element => {
                            if (element.quantity > element.returnQuantity) {
                                element.remainingQty = (element.quantity - element.returnQuantity);
                                element.insertQty = element.remainingQty;
                                element.note = "";
                                this.filteredRetrunList.push(element);
                            }
                        });
                    }
                    else {
                        this.$swal.fire({
                            icon: "error",
                            text: "No Order found with this order id "
                        });
                    }
                }).catch(error => {
                    this.state.isLoading = false;
                    console.log(error);
                });
            } catch (error) {
                this.state.isLoading = false;
                console.log(error);
            }
        },
        generatePDF: function () {
            var doc = document.getElementById('doc');
            var opt = {
                filename: this.orderDetails.orderId + '.pdf',
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { scale: 2 },
                jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' },
                pagebreak: { before: '#page2el' }
            };
            html2pdf().from(doc).set(opt).save();
        },
        increaseQuantity: function (data) {
            if (data.insertQty >= data.remainingQty) {
                data.insertQty = data.remainingQty;
            } else {
                data.insertQty = data.insertQty + 1;
            }
        },

        decreaseQuantity: function (data) {
            if (data.insertQty <= 1) {
                data.insertQty = 1;
            } else {
                data.insertQty = data.insertQty - 1;
            }
        },
        handleSelectAll: function () {
            if (this.selectAll == true) {
                this.filteredRetrunList.map((el, index) => {
                    this.selectedReturnProducts[index] = true;
                    this.returnProductList.push(el);
                })
            } else if (this.selectAll == false) {
                this.filteredRetrunList.map((el, index) => {
                    this.selectedReturnProducts[index] = false;
                    this.returnProductList = [];
                })
            }
        },
        handleSelect: function (index, status, data) {
            if (status == true) {
                this.returnProductList[index] = data
            } else if (status == false) {
                this.returnProductList[index] = 0
            }
        },
        confirmReturn: async function () {
            if (this.returnProductList.length == 0 || this.selectedReturnProducts.length == 0) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please select a product first."
                })
                return;
            }
            if (this.returnProductList.length > 0) {
                let requests = [...new Set(this.returnProductList)];
                let confirmedRequest = requests.filter(item => !(item == 0 || item == undefined));
                let productList = confirmedRequest.map(el => {
                    return {
                        id: el.productId,
                        name: el.name,
                        quantity: el.insertQty,
                        actualPrice: el.itemPrice,
                        note: el.note,
                    }
                })
                for (let i = 0; i < productList.length; i++) {
                    if (!productList[i].note) {
                        this.hasNote = false
                        break;
                    } else {
                        this.hasNote = true;
                    }
                }
                if (this.hasNote == false) {
                    this.$swal.fire({
                        text: "Please give reason for return products.",
                        icon: "info"
                    })
                    return;
                }
                this.$swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, Return Product!'
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        document.getElementById('close-btn').click();
                        let data = {
                            orderId: this.orderDetails.orderId,
                            customerId: this.orderDetails.customerId,
                            customerName: this.orderDetails.customerName,
                            products: productList,
                        }
                        let config = {
                            method: "POST",
                            url: this.$serverURL + this.$api.return.requestURL,
                            data: data,
                            headers: {
                                "Authorization": authHeader()
                            }
                        };
                        this.state.isLoading = true;
                        await this.$axios(config).then(async (response) => {
                            this.state.isLoading = false;
                            if (response.data.statusCode == 201) {
                                await this.getOrderDetails();
                                this.selectAll = false;
                                this.returnProductList = [];
                                this.selectedReturnProducts = [];
                                this.$swal.fire({
                                    icon: 'success',
                                    text: "Return request successful. Waiting for admin approval."
                                })

                            }
                        }).catch(error => {
                            this.state.isLoading = false;
                            console.log(error);
                        });

                    }
                })

            }
        }

    },

}

// id
// name
// quantity
// actualPrice
