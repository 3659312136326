<template>
  <table
    class="container"
    cellpadding="0"
    cellspacing="0"
    style="min-width: 100vw; background-color: #41b4e0"
    role="presentation"
  >
    <tbody>
      <tr>
        <th valign="top" style="mso-line-height-rule: exactly">
          <div>
            <table border="0" cellpadding="0" cellspacing="0" align="center" class="email-container" role="presentation">
              <tbody>
                <tr>
                  <th valign="top" style="mso-line-height-rule: exactly">
                    <table
                      class="section_wrapper main"
                      data-id="main"
                      id="section-main"
                      width="100%"
                      cellpadding="0"
                      cellspacing="0"
                      style="min-width: 100%; width: 55vw"
                      role="presentation"
                    >
                      <tbody>
                        <tr>
                          <td class="section_wrapper_th" style="mso-line-height-rule: exactly" bgcolor="#ffffff">
                            <table
                              border="0"
                              width="100%"
                              cellpadding="0"
                              cellspacing="0"
                              align="center"
                              style="min-width: 100%"
                              id="mixContainer"
                              role="presentation"
                            >
                              <tbody>
                                <tr id="section-1468266" class="section heading">
                                  <th
                                    style="mso-line-height-rule: exactly; color: #4b4b4b; padding: 26px 52px 13px"
                                    bgcolor="#ffffff"
                                  >
                                    <h1 class="d-flex justify-content-center text-show">Order Confirmation</h1>
                                  </th>
                                </tr>
                                <tr id="section-1468267" class="section introduction">
                                  <th style="mso-line-height-rule: exactly; padding: 13px 52px" bgcolor="#ffffff">
                                    <p class="text-p d-flex justify-content-center">Hey Guest,</p>
                                    <p class="text-p d-flex justify-content-center">
                                      We have got your order! Your world is about to look a whole lot better.
                                    </p>
                                    <p class="text-p d-flex justify-content-center">
                                      We will drop you another email when your order ships.
                                    </p>
                                  </th>
                                </tr>
                                <tr class="section">
                                  <h2 class="order-no d-flex justify-content-center">Order No. {{ orderDetails?.orderId }}</h2>
                                  <p class="muted d-flex justify-content-center order-date">
                                    {{ $filters.formatDate(orderDetails?.createdAt) }}
                                  </p>
                                </tr>
                                <tr class="section">
                                  <h3 class="order-items">Items ordered</h3>
                                </tr>
                                <tr class="section">
                                  <div class="products_with_pricing">
                                    <div v-for="(data, index) in orders" :key="index">
                                      <div class="product">
                                        <img class="product-image" :src="imagePath + data.featuredImage" :alt="data.name" />
                                        <p class="cut-text">
                                          <a href="javascript:void(0)">{{ data.name }}</a>
                                        </p>
                                      </div>
                                      <div class="price">
                                        <p>${{ data.itemPrice }}&nbsp;×&nbsp;{{ data.quantity }}</p>
                                        <p>${{ data.itemTotal }}</p>
                                      </div>
                                    </div>
                                  </div>
                                </tr>
                                <tr>
                                  <div class="details-bill">
                                    <div>Subtotal</div>
                                    <div>${{ +orderDetails?.subTotal - +orderDetails?.totalDiscount }}</div>
                                  </div>
                                  <div class="details-bill">
                                    <div>Discount (-)</div>
                                    <div>${{ orderDiscount }}</div>
                                  </div>
                                  <div class="details-bill">
                                    <div>Sales Tax ({{ orderDetails?.salesTaxRate }}%) (+)</div>
                                    <div>${{ orderDetails?.salesTaxAmount }}</div>
                                  </div>
                                  <div class="details-bill">
                                    <div>Shipping Method ({{ orderDetails?.shippingType }})</div>
                                    <div>${{ orderDetails?.shippingCost }}</div>
                                  </div>
                                  <div class="details-bill">
                                    <div>Total</div>
                                    <div>${{ getOrderTotal(orderDetails) }}</div>
                                  </div>
                                </tr>
                                <tr class="section">
                                  <h3 class="payment-info">Payment Info</h3>
                                  <div class="card-info">
                                    <div class="card-info-data">
                                      <img
                                        width="60"
                                        alt="Mastercard Icon"
                                        src="https://orderlyemails.com/mobile-receipt-mastercard.png"
                                      />
                                      <div>Card</div>
                                      <span style="font-size: 14px; font-weight: bold; color: #bdbdbd">
                                        (************{{ orderDetails?.cardNumber?.slice(-4) }})
                                      </span>
                                    </div>
                                    <div>
                                      <p>${{ getOrderTotal(orderDetails) }}</p>
                                    </div>
                                  </div>
                                </tr>
                                <tr class="section customer-shipping-address">
                                  <div>
                                    <div v-if="billInfo">
                                      <h3>BILLING INFO</h3>
                                      <div>{{ billInfo?.name }}</div>
                                      <div>{{ billInfo?.mobile }}</div>
                                      <div>{{ billInfo?.address }}</div>
                                      <div>{{ billInfo?.stateCode }}</div>
                                      <div>{{ billInfo?.postCode }}</div>
                                      <div>{{ billInfo?.city }}</div>
                                      <div class="email">{{ billInfo?.email }}</div>
                                    </div>
                                    <div>
                                      <h3>SHIPPING ADDRESS</h3>
                                      <div>{{ shipInfo?.name }}</div>
                                      <div>{{ shipInfo?.mobile }}</div>
                                      <div>{{ shipInfo?.addressOne }}</div>
                                      <div>{{ shipInfo?.stateCode }}</div>
                                      <div>{{ shipInfo?.postCode }}</div>
                                      <div>{{ shipInfo?.city }}</div>
                                      <div class="email">{{ shipInfo?.email }}</div>
                                    </div>
                                  </div>
                                </tr>
                                <tr>
                                  <p class="help-info" style="display: block;">
                                    If you need help with anything please don't hesitate to drop us an email: <a href="mailto:contact@hgshydro.com">contact@hgshydro.com</a>)
                                  </p>
                                </tr>
                                <tr>
                                  <div class="order-footer">
                                    <div>
                                      <button type="button" class="btn btn-primary" @click="$router.push('/products')">
                                        Continue Shopping
                                      </button>
                                    </div>
                                    <div>
                                      <button type="button" class="btn btn-primary" @click="$router.push('/dashboard')">
                                        Dashboard
                                      </button>
                                    </div>
                                  </div>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </th>
                </tr>
              </tbody>
            </table>
          </div>
        </th>
      </tr>
    </tbody>
  </table>
</template>

<script src="../js/order-confirm.js"></script>

<style scoped>
  .email {
    color: #ecba78;
  }

  .order-no {
    color: #4b4b4b;
    font-size: 20px;
    line-height: 26px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin: 0;
  }

  .order-items {
    color: #bdbdbd;
    font-size: 16px;
    line-height: 52px;
    font-weight: 700;
    text-transform: uppercase;
    border-bottom-width: 2px;
    border-bottom-color: #dadada;
    border-bottom-style: solid;
    letter-spacing: 1px;
    margin: 0 3rem;
  }

  .payment-info {
    color: #bdbdbd;
    font-size: 16px;
    line-height: 52px;
    font-weight: 700;
    text-transform: uppercase;
    border-bottom-width: 0;
    border-bottom-color: #dadada;
    border-bottom-style: solid;
    letter-spacing: 1px;
    margin: 0 3rem;
  }

  .order-date {
    font-size: 14px;
    line-height: 26px;
    font-weight: normal;
    color: #bdbdbd;
  }

  .order-footer {
    display: flex;
    justify-content: space-around;
    margin: 3rem 0;
  }

  .help-info {
    font-size: 16px;
    line-height: 26px;
    font-weight: 400;
    color: #666363;
    margin: 3rem;
    display: flex;
    justify-content: center;
  }

  .products_with_pricing {
    color: #bdbdbd;
    font-size: 16px;
    line-height: 52px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin: 0 3rem;
    border-bottom-color: #dadada;
    border-bottom-style: solid;
    border-bottom-width: 2px;
  }

  .product-image {
    height: 50px;
    width: 50px;
    object-fit: cover;
  }

  .products_with_pricing .product {
    display: flex;
    align-items: center;
  }

  .product .cut-text {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 18vw;
    white-space: nowrap;
  }

  .products_with_pricing .price {
    display: flex;
  }

  .products_with_pricing .price p {
    font-size: 16px;
    line-height: 26px;
    font-weight: 400;
    color: #666363;
    margin: 0;
    margin-left: 2rem;
  }

  .products_with_pricing > div {
    margin: 0.7rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .products_with_pricing div p {
    margin-bottom: 0;
  }

  .products_with_pricing div p a {
    color: #666363;
    text-decoration: none !important;
    text-transform: none;
    word-wrap: break-word;
    text-align: left !important;
    font-weight: bold;
    align-items: center;
    margin-left: 0.6rem;
  }

  .details-bill {
    display: flex;
    justify-content: space-between;
    margin: 1rem 3rem;
  }

  .details-bill div {
    font-size: 16px;
    color: #666363;
  }

  .details-bill div:first-child {
    font-weight: bold;
  }

  .details-bill div:last-child {
    font-weight: 400;
  }

  .text-show {
    font-size: 28px;
    line-height: 46px;
    font-weight: 700;
    color: #4b4b4b;
    text-transform: none;
    background-color: #ffffff;
    margin: 0;
  }

  .text-p {
    font-size: 16px;
    line-height: 26px;
    font-weight: 400;
    color: #666363;
    margin: 0 0 13px;
  }

  .card-info {
    display: flex;
    justify-content: space-between;
    margin: 0 3rem;
  }

  .card-info img {
    margin-right: 1rem;
  }

  .card-info p {
    font-size: 16px;
    color: #666363;
    font-weight: 400;
    margin-bottom: 0;
  }

  .card-info > div {
    display: flex;
    align-items: center;
  }

  .customer-shipping-address > div {
    display: flex;
    justify-content: space-between;
    margin-left: 3rem;
    margin-right: 3rem;
  }

  .customer-shipping-address > div:first-child {
    margin-top: 2rem;
    margin-bottom: 0;
  }

  .customer-shipping-address h3 {
    color: #bdbdbd;
    font-size: 16px;
    line-height: 52px;
    font-weight: 700;
    text-transform: uppercase;
    border-bottom-width: 0;
    border-bottom-color: #dadada;
    border-bottom-style: solid;
    letter-spacing: 1px;
  }

  .customer-shipping-address > div > div {
    font-family: -apple-system, BlinkMacSystemFont;
    font-size: 16px;
    line-height: 26px;
    font-weight: 400;
    color: #666363;
    margin: 0;
  }

  .customer-shipping-address > div > div:nth-child(2) {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .customer-shipping-address > div:last-child {
    border-bottom-color: #dadada;
    border-bottom-style: solid;
    letter-spacing: 1px;
    border-bottom-width: 2px;
    padding-bottom: 3rem;
  }

  @media (max-width: 600px) {
    .customer-shipping-address h3 {
      font-size: 14px;
    }

    .customer-shipping-address > div {
      flex-direction: column;
      align-items: flex-start;
    }

    .customer-shipping-address > div > div:nth-child(2) {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .payment-info {
      font-size: 14px;
    }

    .order-items {
      font-size: 14px;
    }
  }
</style>
